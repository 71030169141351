import _ from "lodash";
import { v4 as uuid } from "uuid";

import React from "react";

import { QuestionProps } from "components/passport/PassportUtils";
import MapMarkerLink from "components/map/MapMarkerLink";

const MapMarkerQuestion: React.FC<QuestionProps> = ({ field, mainObject }) => {
  const latitude = _.get(mainObject, "latitude");
  const longitude = _.get(mainObject, "longitude");

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <div className="font-size-normal  col-md-3 d-flex flex-column p-2 company-details" key={uuid()}>
      <MapMarkerLink latitude={latitude} longitude={longitude} />
    </div>
  );
};

export default MapMarkerQuestion;
