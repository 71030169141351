import React, { useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import RouteInterceptor from "./RouteInterceptor";
import { PrivateRoute } from "./PrivateRoute";
import { useMessage } from "hooks";

import ResetPasswordSendEmailContainer from "pages/resetPassword/ResetPasswordSendEmailContainer";
import ResetPasswordContainer from "pages/resetPassword/ResetPasswordContainer";
import LoginContainer from "pages/login/LoginContainer";
import RegisterContainer from "pages/register/RegisterContainer";
import UsersContainer from "pages/users/UsersContainer";
import ErrorPageContainer from "pages/errorPage/ErrorPageContainer";
import UserFormContainer from "pages/users/UserFormContainer";
import SetPasswordContainer from "pages/setPassword/SetPasswordContainer";
import CompaniesContainer from "pages/companies/CompaniesContainer";
import CompanyDetails from "pages/companyDetails/CompanyDetails";
import AccountSettings from "pages/accountSettings/AccountSettings";
import ImportContainer from "pages/import/ImportContainer";
import ExportContainer from "pages/export/ExportContainer";
import GlnPassportContainer from "pages/glnPassport/GlnPassportContainer";
import MyCompanyDetailsContainer from "pages/myData/MyCompanyDetailsContainer";
import WhiteListContainer from "pages/myData/WhiteListContainer";
import HistoryContainer from "pages/myData/HistoryContainer";
import WaitingRoomContainer from "pages/waitingRoom/WaitingRoomContainer";
import MyLocations from "../../pages/myData/MyLocations";
import GlnContainer from "../../pages/gln/GlnContainer";
import OfflineMode from "../../pages/offlineMode/OfflineMode";
import TermsContainer from "../../pages/terms/TermsContainer";
import PrivacyPolicyContainer from "../../pages/privacyPolicy/PrivacyPolicyContainer";
import ContactContainer from "../../pages/contact/ContactContainer";
import { useTranslation } from "react-i18next";

export const Routes: React.FC = () => {
  const { addMessage, removeCurrentMessage } = useMessage();
  const { t } = useTranslation();
  const [networkOnline, setNetworkOnline] = useState(navigator.onLine);

  const handleNetworkChange = () => {
    setNetworkOnline(navigator.onLine);
    console.log(
      `%c NETWORK_STATUS: %c ${navigator.onLine ? "online" : "offline"}`,
      "padding:3px; color:white; background:red; font0weight: bold; border-radius:1em;",
      "",
    );
    !navigator.onLine
      ? addMessage({
          type: "warning",
          text: t("navigator.offline"),
        })
      : removeCurrentMessage();
  };

  useEffect(() => {
    window.addEventListener("offline", handleNetworkChange);
    window.addEventListener("online", handleNetworkChange);
    return () => {
      window.removeEventListener("offline", handleNetworkChange);
      window.removeEventListener("online", handleNetworkChange);
    };
  }, [networkOnline]);

  return (
    <>
      <Switch>
        {/**
         * Private routes
         */}
        <RouteInterceptor
          networkOnline={networkOnline}
          availableOffline={false}
          path={"/awaiting"}
          component={WaitingRoomContainer}
        />
        <RouteInterceptor
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/passport/:glnId"}
          component={() => <GlnPassportContainer networkOnline={networkOnline} />}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/companies/:companyID"}
          component={CompanyDetails}
        />
        <RouteInterceptor
          networkOnline={networkOnline}
          availableOffline={false}
          path={"/users/edit/:userId"}
          component={() => <UserFormContainer typeOfContainer="edit" />}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/companies"}
          component={CompaniesContainer}
        />
        <RouteInterceptor
          networkOnline={networkOnline}
          availableOffline={false}
          path={"/users/add"}
          component={() => <UserFormContainer typeOfContainer="add" />}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/users"}
          component={UsersContainer}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/user/settings"}
          component={AccountSettings}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/data/export"}
          component={ExportContainer}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/my_data/company"}
          component={MyCompanyDetailsContainer}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/my_data/whitelist"}
          component={WhiteListContainer}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/my_data/import"}
          component={ImportContainer}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/my_data/history"}
          component={HistoryContainer}
        />
        <RouteInterceptor
          availableOffline={false}
          networkOnline={networkOnline}
          path={"/my_data/my_locations"}
          component={MyLocations}
        />
        <RouteInterceptor
          availableOffline={true}
          networkOnline={networkOnline}
          path={"/offline_mode"}
          component={() => {
            return networkOnline ? <OfflineMode networkOnline={networkOnline} /> : <Redirect to={"/"} />;
          }}
        />
        {/**
         * This route cannot be in intercepted (login errors)
         */}
        <PrivateRoute
          networkOnline={networkOnline}
          component={() => <GlnContainer networkOnline={networkOnline} />}
          path={"/"}
          exact={true}
        />
        {/**
         * Public routes
         */}
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/login"}
          component={LoginContainer}
        />
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/register"}
          component={RegisterContainer}
        />
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/set_password/:activationKey"}
          component={() => <SetPasswordContainer />}
        />
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/reset_password/:resetPasswordKey"}
          component={ResetPasswordContainer}
        />
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/reset_password/"}
          component={ResetPasswordSendEmailContainer}
        />
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/terms"}
          component={TermsContainer}
        />
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/privacy"}
          component={PrivacyPolicyContainer}
        />
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"/contact"}
          component={ContactContainer}
        />
        <RouteInterceptor
          privateRoute={false}
          networkOnline={networkOnline}
          availableOffline={true}
          path={"*"}
          component={ErrorPageContainer}
        />
      </Switch>
    </>
  );
};

export default Routes;
