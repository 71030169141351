import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Footer, Header } from "components";
import { useSelector } from "react-redux";
import { ScrollToTop } from "utils/ScrollUp";

import { State } from "models/StoreModel";

const TermsContainer: React.FC = () => {
    const { t } = useTranslation();
    const account = useSelector((state: State) => state.account);
    
    let headerProps = {
        withBottom: account.user ? true : false,
        withTitle: account.user ? "" : " "
    }

    useEffect(() => {
        ScrollToTop("auto");
    }, []);

    return (
        <>
        <Header withBottom={headerProps.withBottom} withTitle={headerProps.withTitle} />
            <div className="container my-2 terms-page my-5">
                <h2>{t("terms.header")}</h2>
                <h3>{t("terms.subheader")}</h3>
                <section>
                    <h4>{t("terms.firstParagraph.header")}</h4>
                    <ol>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem1.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem1.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem2.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem2.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem3.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem3.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem4.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem4.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem5.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem5.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem6.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem6.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem7.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem7.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem8.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem8.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem9.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem9.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem10.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem10.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem11.term")}</span>
                            <span>{" - "}</span>
                            <Trans i18nKey="terms.firstParagraph.listItem11.explanation">
                                terms.firstParagraph.listItem11.explanation[0]
                                <a
                                    className="link"
                                    href={`https://${t("terms.firstParagraph.listItem11.explanation.www")}`}
                                    target="blank"
                                >
                                    {t("terms.firstParagraph.listItem11.explanation.www")}
                                </a>
                            </Trans>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem12.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem12.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem13.term")}</span>
                            <span>{" - "}</span>
                            <Trans i18nKey="terms.firstParagraph.listItem13.explanation">
                                terms.firstParagraph.listItem13.explanation[0]
                                <a
                                    className="link"
                                    href={`https://${t("terms.firstParagraph.listItem13.explanation.www")}`}
                                    target="blank"
                                >
                                    {t("terms.firstParagraph.listItem13.explanation.www")}
                                </a>
                            </Trans>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem14.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem14.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem15.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem15.explanation")}</span>
                        </li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.firstParagraph.listItem15.itemOne")}</li>
                            <li>
                                <Trans i18nKey="terms.firstParagraph.listItem15.itemTwo">
                                    terms.firstParagraph.listItem15.itemTwo[0]
                                    <a
                                        className="link"
                                        href={`https://${t("terms.firstParagraph.listItem15.itemTwo.www")}`}
                                        target="blank"
                                    >
                                        {t("terms.firstParagraph.listItem15.itemTwo.www")}
                                    </a>
                                </Trans>
                            </li>
                        </ol>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem16.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem16.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem17.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem17.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem18.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem18.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem19.term")}</span>
                            <span>{" - "}</span>
                            <Trans i18nKey="terms.firstParagraph.listItem19.explanation">
                                terms.firstParagraph.listItem19.explanation[0]
                                <a
                                    className="link"
                                    href={`https://${t("terms.firstParagraph.listItem19.explanation.www")}`}
                                    target="blank"
                                >
                                    {t("terms.firstParagraph.listItem19.explanation.www")}
                                </a>
                                terms.firstParagraph.listItem19.explanation[2]
                                <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem19.explanation.bold1")}</span>
                                terms.firstParagraph.listItem19.explanation[4]
                                <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem19.explanation.bold2")}</span>
                                terms.firstParagraph.listItem19.explanation[6]
                                <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem19.explanation.bold3")}</span>
                            </Trans>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem20.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem20.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem21.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem21.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem22.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem22.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem23.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem23.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem24.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem24.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem25.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem25.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem26.term")}</span>
                            <span>{" - "}</span>
                            <Trans i18nKey="terms.firstParagraph.listItem26.explanation">
                                terms.firstParagraph.listItem26.explanation[0]
                                <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem26.explanation.bold")}</span>
                            </Trans>                        
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem27.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem27.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem28.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem28.explanation")}</span>
                        </li>
                        <li>
                            <span className="font-size-normal font-weight-bold">{t("terms.firstParagraph.listItem29.term")}</span>
                            <span>{" - " + t("terms.firstParagraph.listItem29.explanation")}</span>
                        </li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.secondParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.secondParagraph.listItem1")}</li>
                        <li>{t("terms.secondParagraph.listItem2")}</li>
                        <li>
                            <Trans i18nKey="terms.secondParagraph.listItem3">
                                terms.secondParagraph.listItem3[0]
                                <a
                                    className="link"
                                    href={`https://${t("terms.secondParagraph.listItem3.www")}`}
                                    target="blank"
                                >
                                    {t("terms.secondParagraph.listItem3.www")}
                                </a>
                            </Trans>
                        </li>
                        <li>{t("terms.secondParagraph.listItem4")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.thirdParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.thirdParagraph.listItem1")}</li>
                        <li>{t("terms.thirdParagraph.listItem2")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.thirdParagraph.listItem2.itemOne")}</li>
                            <li>{t("terms.thirdParagraph.listItem2.itemTwo")}</li>
                        </ol>
                        <li>{t("terms.thirdParagraph.listItem3")}</li>
                        <li>{t("terms.thirdParagraph.listItem4")}</li>
                        <li>{t("terms.thirdParagraph.listItem5")}</li>
                        <li>{t("terms.thirdParagraph.listItem6")}</li>
                        <li>{t("terms.thirdParagraph.listItem7")}</li>
                        <li>{t("terms.thirdParagraph.listItem8")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.fourthParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.fourthParagraph.listItem1")}</li>
                        <li>{t("terms.fourthParagraph.listItem2")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.fourthParagraph.listItem2.itemOne")}</li>
                            <li>{t("terms.fourthParagraph.listItem2.itemTwo")}</li>
                            <li>{t("terms.fourthParagraph.listItem2.itemThree")}</li>
                            <li>{t("terms.fourthParagraph.listItem2.itemFour")}</li>
                        </ol>
                        <li>{t("terms.fourthParagraph.listItem3")}</li>
                        <li>{t("terms.fourthParagraph.listItem4")}</li>
                        <li>{t("terms.fourthParagraph.listItem5")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.fifthParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.fifthParagraph.listItem1")}</li>
                        <li>{t("terms.fifthParagraph.listItem2")}</li>
                        <li>{t("terms.fifthParagraph.listItem3")}</li>
                        <li>{t("terms.fifthParagraph.listItem4")}</li>
                        <li>{t("terms.fifthParagraph.listItem5")}</li>
                        <li>{t("terms.fifthParagraph.listItem6")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.sixthParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.sixthParagraph.listItem1")}</li>
                        <li>{t("terms.sixthParagraph.listItem2")}</li>
                        <li>{t("terms.sixthParagraph.listItem3")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.sixthParagraph.listItem3.itemOne")}</li>
                            <li>{t("terms.sixthParagraph.listItem3.itemTwo")}</li>
                            <li>{t("terms.sixthParagraph.listItem3.itemThree")}</li>
                        </ol>
                        <li>{t("terms.sixthParagraph.listItem4")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.sixthParagraph.listItem4.itemOne")}</li>
                            <li>{t("terms.sixthParagraph.listItem4.itemTwo")}</li>
                            <li>{t("terms.sixthParagraph.listItem4.itemThree")}</li>
                            <li>{t("terms.sixthParagraph.listItem4.itemFour")}</li>
                        </ol>
                        <li>{t("terms.sixthParagraph.listItem5")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.sixthParagraph.listItem5.itemOne")}</li>
                            <li>{t("terms.sixthParagraph.listItem5.itemTwo")}</li>
                            <li>{t("terms.sixthParagraph.listItem5.itemThree")}</li>
                            <li>{t("terms.sixthParagraph.listItem5.itemFour")}</li>
                            <li>{t("terms.sixthParagraph.listItem5.itemFive")}</li>
                        </ol>
                        <li>{t("terms.sixthParagraph.listItem6")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.seventhParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.seventhParagraph.listItem1")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.seventhParagraph.listItem1.itemOne")}</li>
                            <ol className="lower-roman-parenthesis">
                                <li>{t("terms.seventhParagraph.listItem1.itemOne.partOne")}</li>
                                <li>{t("terms.seventhParagraph.listItem1.itemOne.partTwo")}</li>
                            </ol>
                            <li>{t("terms.seventhParagraph.listItem1.itemTwo")}</li>
                            <ol className="lower-roman-parenthesis">
                                <li>{t("terms.seventhParagraph.listItem1.itemOne.partOne")}</li>
                                <li>{t("terms.seventhParagraph.listItem1.itemTwo.partTwo")}</li>
                            </ol>
                        </ol>
                        <li>{t("terms.seventhParagraph.listItem2")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.seventhParagraph.listItem2.itemOne")}</li>
                            <li>{t("terms.seventhParagraph.listItem2.itemTwo")}</li>
                        </ol>
                        <li>{t("terms.seventhParagraph.listItem3")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.seventhParagraph.listItem3.itemOne")}</li>
                            <li>{t("terms.seventhParagraph.listItem3.itemTwo")}</li>
                            <li>{t("terms.seventhParagraph.listItem3.itemThree")}</li>
                        </ol>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.eightParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.eightParagraph.listItem1")}</li>
                        <li>{t("terms.eightParagraph.listItem2")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.eightParagraph.listItem2.itemOne")}</li>
                            <li>{t("terms.eightParagraph.listItem2.itemTwo")}</li>
                        </ol>
                        <li>{t("terms.eightParagraph.listItem3")}</li>
                        <li>{t("terms.eightParagraph.listItem4")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.ninthParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.ninthParagraph.listItem1")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("terms.ninthParagraph.listItem1.itemOne")}</li>
                            <li>{t("terms.ninthParagraph.listItem1.itemTwo")}</li>
                        </ol>
                        <li>{t("terms.ninthParagraph.listItem2")}</li>
                        <li>
                        <Trans i18nKey="terms.ninthParagraph.listItem3">
                            terms.ninthParagraph.listItem3[0]
                            <a
                                className="link"
                                href={`mailto:${t("terms.ninthParagraph.listItem3.mail")}`}
                            >
                                {t("terms.ninthParagraph.listItem3.mail")}
                            </a>
                        </Trans>    
                        </li>
                        <li>{t("terms.ninthParagraph.listItem4")}</li>
                        <li>{t("terms.ninthParagraph.listItem5")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.tenthParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.tenthParagraph.listItem1")}</li>
                        <li>{t("terms.tenthParagraph.listItem2")}</li>
                        <li>{t("terms.tenthParagraph.listItem3")}</li>
                        <li>{t("terms.tenthParagraph.listItem4")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.eleventhParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.eleventhParagraph.listItem1")}</li>
                        <li>{t("terms.eleventhParagraph.listItem2")}</li>
                        <li>{t("terms.eleventhParagraph.listItem3")}</li>
                        <li>{t("terms.eleventhParagraph.listItem4")}</li>
                        <li>{t("terms.eleventhParagraph.listItem5")}</li>
                        <li>{t("terms.eleventhParagraph.listItem6")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.twelfthParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.twelfthParagraph.listItem1")}</li>
                        <li>{t("terms.twelfthParagraph.listItem2")}</li>
                        <li>{t("terms.twelfthParagraph.listItem3")}</li>
                        <li>{t("terms.twelfthParagraph.listItem4")}</li>
                        <li>{t("terms.twelfthParagraph.listItem5")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.thirteenthParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.thirteenthParagraph.listItem1")}</li>
                        <li>
                            <Trans i18nKey="terms.thirteenthParagraph.listItem2">
                                terms.thirteenthParagraph.listItem2[0]
                                <a
                                    className="link"
                                    href={`https://${t("terms.thirteenthParagraph.listItem2.www")}`}
                                    target="blank"
                                >
                                    {t("terms.thirteenthParagraph.listItem2.www")}
                                </a>
                            </Trans>
                        </li>
                    </ol>
                </section>
                <section>
                    <h4>{t("terms.fourteenthParagraph.header")}</h4>
                    <ol>
                        <li>{t("terms.fourteenthParagraph.listItem1")}</li>
                        <li>{t("terms.fourteenthParagraph.listItem2")}</li>
                        <li>{t("terms.fourteenthParagraph.listItem3")}</li>
                        <li>{t("terms.fourteenthParagraph.listItem4")}</li>
                        <li>{t("terms.fourteenthParagraph.listItem5")}</li>
                    </ol>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default TermsContainer;