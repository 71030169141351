import React from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";

import { State } from "models/StoreModel";
import * as dataThunk from "store/thunk/DataThunk";
import { FiltersGroup, Footer, Header, TablePagination } from "components";

import LargeColumns from "./HistoryContainerColumnsL";
import SmallColumns from "./HistoryContainerColumnsS";

const HistoryContainer: React.FC = () => {
  const { t } = useTranslation();
  const selectedLocation = useSelector((state: State) => state.account.selectedLocation);
  const dataState = useSelector((state: State) => state.data);
  const dispatch = useDispatch();

  const screenWidth = window.innerWidth;

  const fetchData = (tableState: any) => {
    if (!selectedLocation) {
      return;
    }

    dispatch(
      dataThunk.getHistoryForCompany(
        tableState.pageSize,
        tableState.page,
        tableState.sorted,
        tableState.filtered,
        selectedLocation.id,
      ),
    );
  };

  const filterOptions = [
    { id: "fullName", value: t("filters.location_name"), type: "text" },
    { id: "email", value: t("filters.email"), type: "text" },
  ];

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-4">
        <div className="context-menu row">
          <div className="col-md-9">
            <Breadcrumb>
              <BreadcrumbItem active>{t("header.bottom.history")}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <FiltersGroup className="mb-3" options={filterOptions} filtersKey="history" />
        <div className="table mt-4">
          <ReactTable
            columns={screenWidth > 768 ? LargeColumns() : SmallColumns()}
            manual
            resizable={false}
            data={dataState.HistoryRows}
            pages={dataState.pages}
            loading={dataState.loading}
            onFetchData={fetchData}
            minRows={0}
            pageSizeOptions={[5, 10, 20, 25]}
            defaultPageSize={10}
            className={`-striped -highlight ${screenWidth > 768 && "bg-white"}`}
            PaginationComponent={TablePagination}
            filtered={dataState.filters["history"]}
            noDataText={t("no_data.text")}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HistoryContainer;
