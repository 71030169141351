import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface TableCellProps {
  value: string;
  className?: string;
}

export const DiffTableCell: React.FC<TableCellProps> = ({ value = "", className }) => {
  const { t } = useTranslation();

  const renderDiff = (key: string, val: any[]) => {
    let prevVal = val[0];
    let newVal = val[1];
    const timeColumns = [
      "weekdaysTo",
      "weekdaysFrom",
      "saturdayFrom",
      "saturdayTo",
      "sundayHolidaysFrom",
      "sundayHolidaysTo",
      "weekdaysTo",
      "monitoryNoteFrom",
      "monitoryNoteTo",
      "monitoryNoteTime",
    ];
    if (!prevVal && !newVal) {
      return;
    }
    if (key === "type") {
      return <span className="txt-color-primary">{t(val[1])}:&nbsp;</span>;
    } else if (typeof prevVal === "number" || typeof newVal === "number") {
      return (
        <>
          <span className="txt-color-primary">{t("passport.location." + key)}:&nbsp;</span>
          <span className="txt-color-grafite">{`${val[0] || 0} - ${val[1] || 0}`}</span>
        </>
      );
    } else if (typeof prevVal === "boolean" || typeof newVal === "boolean") {
      prevVal = prevVal ? t("passport.location.value.yes") : t("passport.location.value.no");
      newVal = newVal ? t("passport.location.value.yes") : t("passport.location.value.no");
      return (
        <>
          <span className="txt-color-primary">{t(key)}:&nbsp;</span>
          <span className="txt-color-grafite">{`${prevVal} - ${newVal}`}</span>
        </>
      );
    } else if (Array.isArray(prevVal) || Array.isArray(newVal)) {
      prevVal = _.map(prevVal, (value) => t(`passport.location.${key}.${value}`));
      newVal = _.map(newVal, (value) => t(`passport.location.${key}.${value}`));
      return (
        <>
          <span className="txt-color-primary">{t(key)}:&nbsp;</span>{" "}
          <span className="txt-color-grafite">{`${prevVal.join(", ") || t("empty")} - ${newVal.join(", ") ||
            t("empty")}`}</span>
        </>
      );
    } else if (timeColumns.indexOf(key) !== -1) {
      return (
        <>
          <span className="txt-color-primary">{t(key)}:&nbsp;</span>
          <span className="txt-color-grafite">{`${prevVal || t("empty")} - ${newVal || t("empty")}`}</span>
        </>
      );
    } else if (typeof prevVal === "string" || typeof newVal === "string") {
      return (
        <>
          <span className="txt-color-primary">{t(key)}:&nbsp;</span>
          <span className="txt-color-grafite">{`${t(prevVal) || t("empty")} - ${t(newVal) || t("empty")}`}</span>
        </>
      );
    }
  };

  return (
    <>
      {_.map(value, (val: any[], key: string) => (
        <div key={key} className={`row font-size-medium text-wrap ${className}`}>
          <div className="col-12">{renderDiff(key, val)}</div>
        </div>
      ))}
    </>
  );
};

export default DiffTableCell;
