import * as PWAActions from "../actions/PWAActions";
import Cookies from "js-cookie";

export interface PWAState {
  installedOnDevice: boolean;
}

export const initialPWAState = {
  installedOnDevice: Cookies.get("installPrompt") === "accepted",
};

export default (state: PWAState = initialPWAState, action: PWAActions.PWAActions) => {
  switch (action.type) {
    case PWAActions.INSTALL_PWA_REJECT:
      return {
        ...state,
        installedOnDevice: false,
      };
    default:
      return { ...state };
  }
};
