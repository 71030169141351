import React from "react";

import { useTranslation } from "react-i18next";

interface Props {
  latitude: number;
  longitude: number;
}

const MapMarkerLink: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const url = `https://www.google.com/maps/search/?api=1&query=${props.latitude},${props.longitude}`;
  return (
    <a href={url} target="_blank" className="icon-text mt-1">
      <img src="/icons/icon-map-marker.svg" alt="map-marker" />
      <span>{t("map.showOnMap")}</span>
    </a>
  );
};

export default MapMarkerLink;
