import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { getOtherValueIfExist, QuestionProps } from "../PassportUtils";

const MultiInSeveralColumnsQuestion: React.FC<QuestionProps> = ({ field, mainObject }) => {
  const { t } = useTranslation();

  let render;
  if (!_.isEmpty(mainObject)) {
    render = _.map(field.availableValues, (value) => {
      const selectedValues = _.get(mainObject, field.value, null) || null;
      if (value === "other") {
        if (selectedValues !== null && selectedValues.indexOf("other") !== -1) {
          return (
            <div className="font-size-normal  d-flex flex-column p-2 company-details" key={uuid()}>
              <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
                {t("passport.location." + field.key + "." + value)}
              </label>
              <ul className="font-size-normal details-value font-weight-bold">
                {getOtherValueIfExist(field, mainObject)}
              </ul>
            </div>
          );
        }
      } else {
        return (
          <div className="font-size-normal  col-md-3 d-flex flex-column p-2 company-details" key={uuid()}>
            <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
              {t("passport.location." + field.key + "." + value)}
            </label>
            <span
              className={
                "font-size-normal details-value font-weight-bold " +
                (selectedValues !== null && selectedValues.indexOf(value) !== -1 ? "text-success" : "")
              }>
              {selectedValues !== null
                ? selectedValues && selectedValues.indexOf(value) !== -1
                  ? t("passport.location.value.yes")
                  : t("passport.location.value.no")
                : "-"}
            </span>
          </div>
        );
      }
    });
  }

  return <>{render}</>;
};

export default MultiInSeveralColumnsQuestion;
