import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { QuestionProps } from "../PassportUtils";

const SimpleQuestion: React.FC<QuestionProps> = ({ field, mainObject }) => {
  const { t } = useTranslation();

  const valueRender = () => {
    const value = _.get(mainObject, field.value, "-") || "-";
    if (!field.subType) {
      return value;
    } else if (field.subType === "email") {
      return (
        <a className="txt-color-link" href={`mailto:${value}`}>
          {value}
        </a>
      );
    } else if (field.subType === "www") {
      return (
        <a className="txt-color-link" href={`${value}`}>
          {value}
        </a>
      );
    }
  };

  const className = function() {
    let cssClasses = "";
    if (field.className !== undefined) {
      cssClasses = cssClasses + " " + field.className;
    }
    if (field.newLineAfter) {
      cssClasses = cssClasses + " p-0 col-md-12";
    }
    return cssClasses;
  };

  return (
    <div className={"font-size-normal  col-md-3 d-flex flex-column p-2 company-details " + className()} key={uuid()}>
      <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
        {t("passport.location." + field.key)}
      </label>
      <span
        className={
          "font-size-normal  details-value font-weight-bold " + (field.className !== undefined ? field.className : "")
        }>
        {valueRender()}
      </span>
    </div>
  );
};

export default SimpleQuestion;
