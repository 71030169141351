import React, { useEffect } from "react";
import { useParams } from "react-router";

import "react-table/react-table.css";
import CompanyInfoWithGlns from "../../components/companyDetails/CompanyInfoWithGlns";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { State } from "../../models/StoreModel";

export const CompanyDetails: React.FC = () => {
  const { companyID } = useParams();
  const history = useHistory();
  const selectedLocation = useSelector((state: State) => state.account.selectedLocation);

  useEffect(() => {
    if (selectedLocation !== null && selectedLocation.id === companyID) {
      history.push("/my_data/my_locations");
    }
  }, [selectedLocation]);

  return <CompanyInfoWithGlns companyId={companyID} fromState={false} />;
};

export default CompanyDetails;
