import React from "react";
import "./Input.scss";
import { useTranslation } from "react-i18next";

interface InputProps {
  label: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  inputType: string;
  className?: string;
  handleChange: any;
  phoneInput?: boolean;
  value?: any;
  name: string;
  translateErrors?: boolean;
  errors?: { [field: string]: string | null };
}

const NumberInput: React.FC<InputProps> = ({
  className = "",
  label = "SOME_LABEL",
  placeholder = "",
  phoneInput = false,
  required = false,
  inputType = "text",
  handleChange = (arg: any) => {
    console.log("change handler and it`s", arg);
  },
  value = "",
  name = "",
  errors = {},
  disabled = false,
  translateErrors = false,
}) => {
  const { t } = useTranslation();

  const onChange = (e: any) => {
    handleChange({ [name]: e.target.value });
  };

  const checkIfNumber = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (phoneInput) {
      const reg = /[\+\d]/;
      if (!reg.test(keyValue)) {
        e.preventDefault();
      }
    } else {
      if (!/[\d]/.test(keyValue)) {
        e.preventDefault();
      }
    }
  };

  const isValid = () => {
    return errors.hasOwnProperty(name) && errors[name] == null;
  };

  const errorMessage = () => {
    if (translateErrors && errors[name] !== null) {
      // @ts-ignore
      return t(errors[name]);
    }
    return errors[name];
  };

  return (
    <div className={className + " txt-color-grafite d-flex flex-column mb-2 font-size-normal"}>
      <label className="txt-color-grafite">
        {label}
        {required ? <span className="ml-1 txt-color-error">*</span> : ""}
      </label>

      <input
        disabled={disabled}
        className={!isValid() ? "error p-2" : "p-2"}
        placeholder={placeholder}
        required={required}
        type={inputType}
        onChange={onChange}
        onKeyPress={checkIfNumber}
        value={value}
      />
      {!isValid() ? (
        <label className="txt-color-bluish-grey text-error text-right mt-2">{errorMessage()}</label>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NumberInput;
