import _ from "lodash";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { State } from "models/StoreModel";
import Input from "components/Inputs/Input";
import NumberInput from "components/Inputs/NumberInput";
import { FormProps } from "components/wizard/WizardSteps";
import UserStatement from "./UserStatement";

const UserInformation: React.FC<FormProps> = (props) => {
  const [t] = useTranslation();

  const user = useSelector((state: State) => state.data.model);
  const [userExist, setUserExist] = useState(false);
  const [emailSuccessMessage, setEmailSuccessMessage] = useState("");
  const [firstNameSuccessMessage, setFirstNameSuccessMessage] = useState("");
  const [surnameSuccessMessage, setSurnameSuccessMessage] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  const HTMLTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#002C6C",
      color: "#FFFFFF",
      border: "none",
      borderRadius: "0",
      fontSize: "1em",
    },
  }))(Tooltip);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      setShowTooltip(true);
      setUserExist(true);
      setEmailSuccessMessage(t("check.user.success"));
      setFirstNameSuccessMessage(t("check.user.update_field"));
      setSurnameSuccessMessage(t("check.user.update_field"));
      props.setPartialForm({
        userName: user.firstName,
        userSurname: user.lastName,
        userAgreeToSellingEmails: user.agreeToSellingEmails,
        userAgreeToSellingPhones: user.agreeToSellingPhones,
        userAgreeToMarketingEmails: user.agreeToMarketingEmails,
        userAgreeToMarketingPhones: user.agreeToMarketingPhones,
        userAgreeToSharingData: user.agreeToSharingData,
      });
    } else {
      let name = props.form.userName;
      let surname = props.form.userSurname;
      if (userExist) {
        name = "";
        surname = "";
      }
      setUserExist(false);
      setEmailSuccessMessage("");
      setFirstNameSuccessMessage("");
      setSurnameSuccessMessage("");
      props.setPartialForm({
        userName: name,
        userSurname: surname,
        OWU: false,
        userAgreeToSellingEmails: false,
        userAgreeToSellingPhones: false,
        userAgreeToMarketingEmails: false,
        userAgreeToMarketingPhones: false,
        userAgreeToSharingData: false,
      });
    }
  }, [user]);

  const showFileMessage = () => {
    if (props.form.representativeFile) {
      if (props.form.representativeFile.name !== undefined) {
        return (
          <span className="text-muted txt-color--dusty-orange my-1 font-weight-bold">
            {t("input.file") + ": " + props.form.representativeFile.name}
          </span>
        );
      }
      return <span className="text-muted my-1 font-weight-bold">{t("input.no_file")}</span>;
    } else {
      return <span className="text-muted my-1 font-weight-bold">{t("input.no_file")}</span>;
    }
  };

  const showIsRepresentativeError = () => {
    return !(
      props.formErrors.hasOwnProperty("isUserRepresentative") && props.formErrors["isUserRepresentative"] == null
    );
  };

  return (
    <div className="container d-flex">
      <div className="row">
        <div className="col-12 container">
          <div className="row border-top-3-dusty-orange bg-white p-4">
            <div className="col-12">
              <h5 className="txt-color-marine-blue  font-size-normal">{t("register.new_user.title")}</h5>
              <hr />
            </div>
            <div className="col container">
              <div className="row">
                <div className="d-flex flex-column col-md-6 col-xs-12 py-2 px-4 p-md-4 bg-white txt-color-bluish-grey">
                  <Input
                    label={t("users.add.email")}
                    name="userEmail"
                    errors={props.formErrors}
                    required={true}
                    value={props.form.userEmail}
                    inputType="text"
                    handleChange={({ userEmail }: any) => props.setPartialForm({ userEmail: userEmail.toLowerCase() })}
                    successMessage={emailSuccessMessage}
                  />
                  <Input
                    label={t("users.add.firstName")}
                    name="userName"
                    errors={props.formErrors}
                    required={true}
                    disabled={userExist}
                    value={props.form.userName}
                    inputType="text"
                    handleChange={props.setPartialForm}
                    successMessage={firstNameSuccessMessage}
                  />
                  <Input
                    label={t("users.add.lastName")}
                    name="userSurname"
                    errors={props.formErrors}
                    required={true}
                    disabled={userExist}
                    value={props.form.userSurname}
                    inputType="text"
                    handleChange={props.setPartialForm}
                    successMessage={surnameSuccessMessage}
                  />
                  {showTooltip ? (
                    <HTMLTooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        <div className="gs1-tooltip gotham-font-stack font-size-regular ">
                          {t("tooltip.regon.found.userSettings")}
                        </div>
                      }
                      className="my-2 font-size-normal"
                      placement="top">
                      <div>
                        <img className="mx-1" src="/icons/icon-help-enabled.svg" alt="" />
                        {t("companyInformation.form.left.cantEditData")}
                      </div>
                    </HTMLTooltip>
                  ) : (
                    <></>
                  )}
                  <div className="d-none d-md-flex flex-column">
                    {UserStatement({
                      showFileMessage: showFileMessage,
                      showIsRepresentativeError: showIsRepresentativeError,
                      setPartialForm: props.setPartialForm,
                      form: props.form,
                      formErrors: props.formErrors,
                    })}
                  </div>
                  <div className="d-none py-2 h-100 d-md-flex">
                    <Button
                      className="font-weight-normal txt-color-dusty-orange mt-auto font-size-regula mb-2 bg-white ml-0 mr-auto rounded-0 min-width font-weight-bold "
                      variant="contained"
                      onClick={props.handlePrevious}>
                      <span className="back-chevron">{t("register.back_button")}</span>
                    </Button>
                  </div>
                </div>
                <div className="d-flex flex-column col-md-6 col-xs-12 py-2 px-4 p-md-4 bg-white txt-color-bluish-grey">
                  <Input
                    label={t("users.add.position")}
                    name="occupation"
                    errors={props.formErrors}
                    required={true}
                    value={props.form.occupation}
                    inputType="text"
                    handleChange={props.setPartialForm}
                  />
                  <div className="container">
                    <div className="row align-items-start">
                      <NumberInput
                        label={t("users.add.phone")}
                        name="userPhone"
                        errors={props.formErrors}
                        required={true}
                        phoneInput={true}
                        value={props.form.userPhone}
                        inputType="text"
                        placeholder={t("placeholder.phoneNumber")}
                        className="col-xs-12 px-0 col-lg-8 pr-lg-1 pl-lg-0"
                        handleChange={props.setPartialForm}
                      />
                      <NumberInput
                        label={t("users.add.internal_phone")}
                        name="userInternalNumber"
                        errors={props.formErrors}
                        className="col-xs-12 px-0 col-lg-4 pr-lg-0 pl-lg-1"
                        value={props.form.userInternalNumber}
                        inputType="text"
                        handleChange={props.setPartialForm}
                      />
                    </div>
                  </div>
                  <div className="d-flex d-md-none flex-column">
                    {UserStatement({
                      showFileMessage: showFileMessage,
                      showIsRepresentativeError: showIsRepresentativeError,
                      setPartialForm: props.setPartialForm,
                      form: props.form,
                      formErrors: props.formErrors,
                    })}
                  </div>
                  <div className="h-100 d-flex mt-3">
                    <div className="d-inline align-self-end font-size-regular">
                      <p>
                        {t("register.new_user.template_text")}
                        <Link
                          target="_blank"
                          className="txt-color-link"
                          download="wzor-uprawnienia.pdf"
                          to="/media/wzor-uprawnienia.pdf">
                          {t("register.new_user.template")}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="container px-0">
                    <div className="row d-flex flex-md-row-reverse justify-content-between">
                      <div className="col-auto d-flex py-2 h-100 d-md-none">
                        <Button
                          className="txt-color-dusty-orange mt-auto font-size-regula mb-2 bg-white ml-0 mr-auto rounded-0 min-width font-weight-bold "
                          variant="contained"
                          onClick={props.handlePrevious}>
                          <span className="back-chevron">{t("register.back_button")}</span>
                        </Button>
                      </div>
                      <div className="col-auto py-2 h-100 d-flex">
                        <Button
                          className="bg-dusty-orange  mt-auto mb-2 mr-0 ml-auto font-size-normal w-50 rounded-0 font-weight-bold"
                          variant="contained"
                          color="primary"
                          onClick={props.handleNext}>
                          {t("register.next_button")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
