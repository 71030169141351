import React from "react";
import { v4 as uuid } from "uuid";
import { Field, SubSection } from "../../pages/glnPassport/PassportMapping";
import _ from "lodash";

export interface QuestionProps {
  field: Field;
  mainObject: object;
}

export interface EditQuestionProps {
  field: Field;
  form: object;
  handleUpdate: any;
  errors?: any;
}

export const getOtherValueIfExist = (field: Field, mainObject: object) => {
  const values = _.get(mainObject, field.value);
  if (values !== undefined && values.indexOf("other") !== -1) {
    let path = field.value.slice();
    if (_.isArray(path)) {
      let otherProp = path.pop();
      path.push(otherProp + "Other");
    } else {
      path = path + "Other";
    }
    const otherValues = _.get(mainObject, path, "") || "";
    if (otherValues !== undefined && otherValues !== "") {
      return _.map(otherValues.split(";"), (val) => <li key={uuid()}>{val}</li>);
    }
  }
};

export const shouldRender = (field: Field, mainObject: object) => {
  if (field.showWhen !== undefined) {
    const parentValue = _.get(mainObject, field.showWhen.propPath);
    const value = field.showWhen.value;

    switch (field.showWhen.operator) {
      case "=":
        return parentValue === value;
      case "!=":
        return parentValue !== value;
      case ">=":
        return parentValue >= value;
      case "<=":
        return parentValue <= value;
      case "<":
        return parentValue < value;
      case ">":
        return parentValue > value;
      default:
        return false;
    }
  } else {
    return true;
  }
};

export const checkShowConditions = (field: Field, subsection: SubSection, sectionForm: object) => {
  const fieldCondition = shouldRender(field, sectionForm);
  let sectionConditions = [true];
  if (subsection.showWhenPropIsFilled !== undefined) {
    sectionConditions = _.map(subsection.showWhenPropIsFilled, (path) => {
      const propValue = _.get(sectionForm, path);
      return propValue !== "" && propValue > 0;
    });
  }
  return sectionConditions.indexOf(true) !== -1 && fieldCondition;
};

export function isLocationAdmin(user: any, selectedLocationId: string, locationId: string) {
  const isMember = _.find(
    user.membership,
    (membership) => membership.location.id === locationId && locationId === selectedLocationId,
  );
  if (isMember !== undefined) {
    return isMember.isLocationAdmin && isMember.location.isAuthorized;
  }
  return false;
}
