import { CurrentUser } from "models/UserModel";
import { GS1Location } from "models/LocationModel";

export const SET_SESSION_ACTION = "account.setSession";
export const UPDATE_SESSION_ACTION = "account.updateSession";
export const REMOVE_SESSION_ACTION = "account.removeSession";
export const SELECT_LOCATION_ACTION = "account.selectLocation";

export const ACTIVATION_SUCCESS = "activationSuccess";
export const PASSWORD_RESET_SUCCESS = "passwordResetSuccess";

interface SetSessionAction {
  type: typeof SET_SESSION_ACTION;
  payload: {
    user: CurrentUser;
  };
}

interface UpdateSessionAction {
  type: typeof UPDATE_SESSION_ACTION;
  payload: {
    user: CurrentUser;
  };
}

interface RemoveSessionAction {
  type: typeof REMOVE_SESSION_ACTION;
}

interface SelectLocationAction {
  type: typeof SELECT_LOCATION_ACTION;
  payload: {
    location: GS1Location;
    isAdmin: boolean;
    acceptedBySa: boolean;
  };
}

export type AccountAction = SetSessionAction | UpdateSessionAction | RemoveSessionAction | SelectLocationAction;

export function setSession(user: CurrentUser): SetSessionAction {
  return {
    type: SET_SESSION_ACTION,
    payload: {
      user,
    },
  };
}

export function updateSession(user: CurrentUser): UpdateSessionAction {
  return {
    type: UPDATE_SESSION_ACTION,
    payload: {
      user,
    },
  };
}

export function removeSession(): RemoveSessionAction {
  return {
    type: REMOVE_SESSION_ACTION,
  };
}

export function selectLocation(location: GS1Location, isAdmin: boolean, acceptedBySa: boolean): SelectLocationAction {
  return {
    type: SELECT_LOCATION_ACTION,
    payload: {
      location,
      isAdmin,
      acceptedBySa,
    },
  };
}
