import { EditQuestionProps } from "../PassportUtils";
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { NumberInput } from "../../index";

const EditPhoneNumberQuestion: React.FC<EditQuestionProps> = ({ field, errors, form, handleUpdate }) => {
  const { t } = useTranslation();
  const valueFromObject = _.get(form, field.value) || "";

  const [inputValue, setInputValue] = useState({ [field.key]: valueFromObject });

  const updateInputValue = (value: any) => {
    setInputValue(value);
    _.set(form, field.value, value[field.key]);
    handleUpdate(form);
  };
  return (
    <div className={"my-2 mx-1 p-0 col-md-12"}>
      <NumberInput
        phoneInput={true}
        key={"input-" + field.key}
        value={inputValue[field.key]}
        label={t("passport.location." + field.key)}
        inputType="text"
        handleChange={updateInputValue}
        placeholder={t("placeholder.phoneNumber")}
        errors={errors}
        name={field.key}
        translateErrors={true}
      />
    </div>
  );
};

export default EditPhoneNumberQuestion;
