import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { CheckBox, RadioInput } from "../../../index";
import { FormProps } from "../../WizardSteps";
import "./Agreements.scss";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const Agreements: React.FC<FormProps> = (props) => {
  const { t } = useTranslation();

  const phonesSellingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const emailSellingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const emailMarketingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const phonesMarketingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const dataSharingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];

  const [allChecked, setAllChecked] = useState(false);

  const setAllPermissions = (value: any) => {
    props.setPartialForm({
      agreeToSellingEmails: value,
      agreeToSellingPhones: value,
      agreeToMarketingEmails: value,
      agreeToMarketingPhones: value,
      agreeToSharingData: value,
    });
  };

  useEffect(() => {
    const agreements = {
      agreeToSellingEmails: props.form.agreeToSellingEmails,
      agreeToSellingPhones: props.form.agreeToSellingPhones,
      agreeToMarketingEmails: props.form.agreeToMarketingEmails,
      agreeToMarketingPhones: props.form.agreeToMarketingPhones,
      agreeToSharingData: props.form.agreeToSharingData,
    };
    const allChildrenChecked = Object.values(agreements).every((v) => v);
    setAllChecked(allChildrenChecked);
  }, [
    props.form.agreeToMarketingEmails,
    props.form.agreeToMarketingPhones,
    props.form.agreeToSellingEmails,
    props.form.agreeToSellingPhones,
    props.form.agreeToSharingData,
  ]);

  const showOwuError = () => {
    return !(props.formErrors.hasOwnProperty("OWU") && props.formErrors["OWU"] == null);
  };
  return (
    <div>
      <div className="d-flex container mx-auto flex-row-reverse px-0">
        <div className="row w-100 mx-auto">
          <div className="col-12 container">
            <div className="row">
              <div className="col-md-6 ml-auto bg-white border-top-3-dusty-orange">
                <h5 className="my-3 font-size-normal txt-color-marine-blue">
                  {t("registration.regon_title.company_data")}
                </h5>
                <hr />
                <div className="txt-color-bluish-grey my-4">
                  <p className="m-1 font-size-normal text-uppercase">
                    {t("form.agreements.details.regon") + ": "}
                    <b className="text-body">{props.form.regon}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    <span className="text-uppercase ">{t("nip") + ": "}</span>
                    <b className="text-body">{props.form.nip}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("companyInformation.form.left.fullName") + ": "}
                    <b className="text-body">{props.form.fullName}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("companyInformation.form.left.name") + ": "}
                    <b className="text-body">{props.form.name}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("form.agreements.details.profile") + ": "}
                    <b className="text-body">
                      {props.form.companyProfile.length !== 0 && props.form.companyProfile[0].id === "other"
                        ? props.form.companyProfileOther
                        : props.form.companyProfile[0].value}
                    </b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("address") + ": "}
                    <b className="text-body">{props.form.address}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("city") + ": "}
                    <b className="text-body">{props.form.city}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("form.agreements.details.postalCode") + ": "}
                    <b className="text-body">{props.form.postalCode}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("form.agreements.details.postalCity") + ": "}
                    <b className="text-body">{props.form.postalCity !== "" ? props.form.postalCity : "-"}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("district") + ": "}
                    <b className="text-body">
                      {props.form.district.length > 0 ? _.startCase(props.form.district[0].id) : ""}
                    </b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("form.agreements.details.country") + ": "}
                    <b className="text-body">{_.startCase(props.form.country[0].id)}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("form.agreements.details.phoneNumber") + ": "}
                    <b className="text-body">{props.form.phoneNumber}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("form.agreements.details.homepage") + ": "}
                    <a
                      target="_blank"
                      href={
                        props.form.homePage
                          ? props.form.homePage.startsWith("http://") || props.form.homePage.startsWith("https://")
                            ? props.form.homePage
                            : "http://" + props.form.homePage
                          : "#"
                      }
                      className="txt-color-link">
                      {props.form.homePage || ""}
                    </a>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("companyInformation.form.right.email") + ": "}
                    <a
                      className="txt-color-link"
                      href={"mailto:" + props.form.companyEmail}>{`${props.form.companyEmail}`}</a>
                  </p>
                </div>
              </div>
              <div className="col-md-6 mr-auto bg-white border-top-3-dusty-orange">
                <h5 className="my-3 my-1  font-size-normal txt-color-marine-blue">{t("register.new_user.title")}</h5>
                <hr />
                <div className="txt-color-bluish-grey my-4">
                  <p className="m-1 font-size-normal">
                    {t("users.add.firstName") + ": "}
                    <b className="text-body">{props.form.userName}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("users.add.lastName") + ": "}
                    <b className="text-body">{props.form.userSurname}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    {t("users.add.position") + ": "}
                    <b className="text-body">{props.form.occupation}</b>
                  </p>
                  <p className="m-1 font-size-normal">
                    <span>{t("register.form.step3.right.label.work_email") + ": "}</span>
                    <a href={"mailto:" + props.form.userEmail} className="txt-color-link">
                      {`${props.form.userEmail}`}
                    </a>
                  </p>
                  <p className="m-1 font-size-normal">
                    <span>{t("users.add.phone") + ": "}</span>
                    <b className="text-body">{props.form.userPhone}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agreements-title my-5">
        <div className="col-md-12 mx-auto px-0">
          <h5 className="my-2 font-size-medium txt-color-marine-blue">
            {t("form.agreements.details.agreementsAndStatements")}
          </h5>
          <div className="border-top-3-marine-blue agreements container bg-white mx-auto">
            <h5 className="py-3 font-size-normal txt-color-marine-blue">
              {t("form.agreements.details.statements")}
              <span className="ml-1 txt-color-error">*</span>
            </h5>
            <hr />
            <div className="d-flex txt-color-bluish-grey my-2">
              <div>
                <CheckBox
                  value={props.form.OWU}
                  name="OWU"
                  label={
                    <>
                      {t("OWU")}
                      <Link to="/terms">{t("OWU_regulations")}</Link>
                      {t("OWU_2")}
                    </>
                  }
                  showError={showOwuError()}
                  onChange={(checked) => props.setPartialForm({ OWU: checked })}
                />
              </div>
            </div>
            <div className="error-label font-size-regular mb-3">
              {Object.values(props.formErrors).find((el, index) => {
                return el !== null && index === Object.values(props.formErrors).length - 1; // OWU is the last element, so no need to check.
              }) !== undefined
                ? t("form.validation.required")
                : ""}
            </div>
            <h5 className="font-size-normal txt-color-marine-blue">
              {t("form.agreements.details.agreements")}
              <span className="ml-1 txt-color-error">*</span>
            </h5>
            <hr />
            <div className="d-flex my-3">
              <CheckBox
                label={t("form.agreements.details.checkAll")}
                value={allChecked}
                labelClass="font-size-normal"
                labelBold={true}
                onChange={(checked) => setAllPermissions(checked)}
                name="checkboxForAllAgreements"
              />
            </div>
            <div className="d-flex flex-column">
              <h3 className="font-size-regular text-uppercase txt-color-bluish-grey">
                {t("form.agreements.details.userComplies")}
              </h3>
              <div className="d-flex d-md-flex py-2">
                <RadioInput
                  label={t("agreeToSellingEmailsForCompany")}
                  name="agreeToSellingEmails"
                  errors={props.formErrors}
                  options={emailSellingOptions}
                  handleInput={props.setPartialForm}
                  defaultValue={props.form.agreeToSellingEmails}
                  direction="md-row flex-column"
                />
              </div>
              <div className="d-flex py-2">
                <RadioInput
                  label={t("agreeToSellingPhonesForCompany")}
                  name="agreeToSellingPhones"
                  errors={props.formErrors}
                  options={phonesSellingOptions}
                  handleInput={props.setPartialForm}
                  defaultValue={props.form.agreeToSellingPhones}
                  direction="md-row flex-column"
                />
              </div>
              <div className="d-flex py-2">
                <RadioInput
                  label={t("agreeToSharingDataForCompany")}
                  name="agreeToSharingData"
                  errors={props.formErrors}
                  options={dataSharingOptions}
                  handleInput={props.setPartialForm}
                  defaultValue={props.form.agreeToSharingData}
                  direction="md-row flex-column"
                />
              </div>
              <div className="d-flex py-2">
                <RadioInput
                  label={t("agreeToMarketingEmailsForCompany")}
                  name="agreeToMarketingEmails"
                  errors={props.formErrors}
                  options={emailMarketingOptions}
                  handleInput={props.setPartialForm}
                  defaultValue={props.form.agreeToMarketingEmails}
                  direction="md-row flex-column"
                />
              </div>
              <div className="d-flex py-2">
                <RadioInput
                  label={t("agreeToMarketingPhonesForCompany")}
                  name="agreeToMarketingPhones"
                  options={phonesMarketingOptions}
                  errors={props.formErrors}
                  handleInput={props.setPartialForm}
                  defaultValue={props.form.agreeToMarketingPhones}
                  direction="md-row flex-column"
                />
              </div>
              <div className="error-label font-size-regular">
                {Object.values(props.formErrors).find((el, index) => {
                  return el !== null && index !== Object.values(props.formErrors).length - 1; // OWU is the last element, so no need to check.
                }) !== undefined
                  ? t("form.agreements.someMissing")
                  : ""}
              </div>
            </div>
            <div className="py-3 h-100 d-flex">
              <Button
                className="txt-color-dusty-orange mt-auto font-size-normal mb-2 bg-white ml-0 mr-auto rounded-0 font-weight-bold"
                variant="contained"
                color="primary"
                disabled={props.disableNextButton}
                onClick={props.handlePrevious}>
                <span className="back-chevron">{t("register.back_button")}</span>
              </Button>
              <Button
                className="bg-dusty-orange mt-auto font-size-normal mb-2 mr-0 ml-auto rounded-0 font-weight-bold"
                variant="contained"
                color="primary"
                disabled={props.disableNextButton}
                onClick={props.handleNext}>
                <>
                  {t("navigation.stepper.finish")}
                  {props.disableNextButton ? (
                    <Spinner className="ml-2" animation="border" variant="light" size="sm" />
                  ) : (
                    <></>
                  )}
                </>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agreements;
