import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonToolbar, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { State } from "models/StoreModel";

import "./header.scss";

const HeaderBottom: React.FC = () => {
  const { t } = useTranslation();
  const isAdminForSelectedLocation = useSelector((state: State) => state.account.isAdminForSelectedLocation);
  const isAuthorizedCompany = useSelector((state: State) => {
    if (state.account.selectedLocation !== null) return state.account.selectedLocation.isAuthorized;
    return false;
  });

  return (
    <div className="header-bottom bg-white border-top-1-very-light-pink-two border-bottom-1-very-light-pink-two">
      <div className="container">
        <div className="row align-items-center py-2 justify-content-between">
          <div className="container">
            <ButtonToolbar className="d-flex row w-100">
              <Dropdown className="col-auto position-static">
                <Dropdown.Toggle className="font-size-normal txt-color-link" variant="primary" id="dropdown-basic">
                  {t("header.bottom.search_views")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link className="txt-color-link font-size-normal d-flex py-1 px-3 " to="/">
                    {t("header.bottom.glns")}
                  </Link>
                  <Link className="txt-color-link font-size-normal d-flex py-1 px-3 " to="/companies">
                    {t("header.bottom.companies")}
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
              {isAdminForSelectedLocation ? (
                <Dropdown className="col-auto position-static d-none d-md-block">
                  <Dropdown.Toggle className="txt-color-link font-size-normal " variant="primary" id="dropdown-basic">
                    {t("header.bottom.data_views")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Link className="txt-color-link font-size-normal d-flex py-1 px-3 " to="/data/export">
                      {t("header.bottom.export")}
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <></>
              )}
              <Dropdown className="col-auto position-static">
                <Dropdown.Toggle className="txt-color-link font-size-normal " variant="primary" id="dropdown-basic">
                  {t("header.bottom.my_data")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link className="txt-color-link font-size-normal d-flex py-1 px-3 r" to="/my_data/company">
                    {t("header.bottom.company")}
                  </Link>
                  {isAdminForSelectedLocation && isAuthorizedCompany ? (
                    <div className="">
                      <Link className="txt-color-link font-size-normal py-1 px-3 " to={`/my_data/my_locations`}>
                        {t("header.bottom.myLocations")}
                      </Link>
                      <Link
                        className="txt-color-link font-size-normal py-1 px-3 d-none d-md-block "
                        to="/my_data/whitelist">
                        {t("header.bottom.whitelist")}
                      </Link>
                      <Link
                        className="txt-color-link font-size-normal py-1 px-3 d-none d-md-block "
                        to="/my_data/import">
                        {t("header.bottom.import")}
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {isAdminForSelectedLocation ? (
                    <Link className="txt-color-link font-size-normal d-flex py-1 px-3 " to="/my_data/history">
                      {t("header.bottom.history")}
                    </Link>
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </ButtonToolbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBottom;
