import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";

import * as AccountActions from "store/actions/AccountActions";
import { CurrentUser } from "models/UserModel";
import { GS1Location } from "models/LocationModel";
import { findMemberByLocation } from "utils/LocationUtils";

type AccountAction = AccountActions.AccountAction;

export interface AccountState {
  user: CurrentUser | null;
  authenticated: boolean;
  selectedLocation: GS1Location | null;
  isAdminForSelectedLocation: boolean;
  acceptedBySa: boolean;
}

export const initialAccountState: AccountState = {
  user: null,
  authenticated: false,
  selectedLocation: null,
  isAdminForSelectedLocation: false,
  acceptedBySa: false,
};

function accountReducer(state: AccountState = initialAccountState, action: AccountAction): AccountState {
  switch (action.type) {
    case AccountActions.SET_SESSION_ACTION:
    case AccountActions.UPDATE_SESSION_ACTION: {
      let newSelectedLocation = state.selectedLocation;
      let newIsAdmin = state.isAdminForSelectedLocation;
      let newAcceptedBySa = state.acceptedBySa;
      const membership = action.payload.user.membership;

      if (membership.length > 0) {
        if (!state.selectedLocation) {
          // set the current selected location to the first location from the user's membership
          newSelectedLocation = membership[0].location;
          newIsAdmin = membership[0].isLocationAdmin;
          newAcceptedBySa = membership[0].confirmedBySa;
        } else {
          // update the current selected location with matching location from the user's membership
          const selectedLocation = state.selectedLocation;
          const member = findMemberByLocation(selectedLocation, membership);
          if (member) {
            newSelectedLocation = member.location;
            newIsAdmin = member.isLocationAdmin;
            newAcceptedBySa = member.confirmedBySa;
          }
        }
      }

      return {
        ...state,
        user: action.payload.user,
        authenticated: true,
        selectedLocation: newSelectedLocation,
        isAdminForSelectedLocation: newIsAdmin,
        acceptedBySa: newAcceptedBySa,
      };
    }

    case AccountActions.REMOVE_SESSION_ACTION:
      return {
        ...initialAccountState,
      };

    case AccountActions.SELECT_LOCATION_ACTION: {
      return {
        ...state,
        selectedLocation: action.payload.location,
        isAdminForSelectedLocation: action.payload.isAdmin,
        acceptedBySa: action.payload.acceptedBySa,
      };
    }

    default:
      return state;
  }
}

const persistConfig = {
  key: "account",
  storage: localStorage,
  whitelist: ["user", "selectedLocation", "isAdminForSelectedLocation", "acceptedBySa"],
};

export default persistReducer(persistConfig, accountReducer);
