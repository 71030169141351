import React from "react";
import { TableCell } from "components";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";

const LargeColumns = () => {
  const { t } = useTranslation();

  const largeColumns: Column[] = [
    {
      Header: t("table.company_name_header"),
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      id: "company_name",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal txt-color-primary"
            cssClassForSecond="font-size-regular"
            firstValue={d.companyName}
            secondValue={d.nip}
          />
        );
      },
    },
    {
      Header: t("table.gln"),
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      id: "glns",
      accessor: (d: any) => {
        return (
          <TableCell firstValue={"+ " + d.glns + " " + t("table.gln")} cssClassForFirst="font-size-normal gln-number" />
        );
      },
    },
    {
      Header: t("table.address_header"),
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      id: "location_extra_details__address",
      accessor: (d: any) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <TableCell
              cssClassForFirst="font-size-normal txt-color-primary"
              cssClassForSecond="font-size-regular"
              firstValue={d.address}
              secondValue={`${d.postalCode} ${d.city}, ${d.country}`}
            />
            <span className="d-flex align-items-center font-size-medium mr-4 txt-color-dusty-orange">></span>
          </div>
        );
      },
    },
  ];

  return largeColumns;
};

export default LargeColumns;
