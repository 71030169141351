import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { QuestionProps, shouldRender } from "../PassportUtils";

const BooleanQuestion: React.FC<QuestionProps> = ({ field, mainObject }) => {
  const { t } = useTranslation();
  const value = _.get(mainObject, field.value);
  const shouldRenderField = shouldRender(field, mainObject);
  if (shouldRenderField) {
    return (
      <div className="font-size-normal  col-md-3 d-flex flex-column p-2 company-details" key={uuid()}>
        <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
          {t("passport.location." + field.key)}
        </label>
        <span
          className={
            "font-size-normal details-value font-weight-bold " + (value === 1 || value === true ? "text-success" : "")
          }>
          {value !== undefined && value !== null
            ? value === 1 || value === true
              ? t("passport.location.value.yes")
              : t("passport.location.value.no")
            : "-"}
        </span>
      </div>
    );
  } else {
    return <></>;
  }
};

export default BooleanQuestion;
