import React from "react";
import "./RadioInput.scss";

interface RadioInputProps {
  options: {
    value: string | boolean | number;
    label: string;
    uuid: string;
  }[];
  label: string;
  name: string;
  handleInput: any;
  errors?: { [field: string]: string | null };
  required?: boolean;
  defaultValue?: string | boolean | number;
  direction?: string;
  disabled?: boolean;
  className?: string;
}

export const RadioInput: React.FC<RadioInputProps> = ({
  options = [],
  label = "",
  name = "",
  handleInput,
  errors = {},
  required = false,
  defaultValue = undefined,
  direction = "column",
  disabled,
  className = "",
}) => {
  const isValid = () => {
    return errors.hasOwnProperty(name) && errors[name] == null;
  };

  return (
    <>
      <div
        className={`${className} ${disabled &&
          "disabled"} mt-4 w-100 radioInput-wrapper justify-content-between d-flex flex-${direction}`}>
        {direction === "column" ? (
          <label className="my-auto txt-color-bluish-grey font-size-normal">
            {label}
            {required ? <span className="ml-1 txt-color-error">*</span> : ""}
          </label>
        ) : (
          <label className="txt-color-bluish-grey font-size-normal">
            {label}
            {required ? <span className="ml-1 txt-color-error">*</span> : ""}
          </label>
        )}
        <div className={"d-flex flex-" + direction}>
          {options.map((option) => {
            return (
              <div key={option.uuid} className="mx-1">
                <input
                  className="form-check-input mb-2"
                  type="radio"
                  name={name}
                  id={option.uuid}
                  value=""
                  disabled={disabled}
                  checked={defaultValue === option.value}
                  onChange={(e: any) => {
                    handleInput({ [name]: option.value });
                  }}
                />
                <label
                  className={`form-check-label font-size-normal txt-color-${!isValid() ? "error" : "bluish-grey"}`}
                  htmlFor={option.uuid}>
                  {option.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RadioInput;
