import React, { useEffect, useState } from "react";

export const TablePagination = (props: any) => {
  // Probably this should be in some gsone-utils file
  const range = (start: number, end: number) => {
    return Array(end - start + 1)
      .fill(0)
      .map((item, index) => start + index);
  };

  const [visiblePages, setVisiblePages] = useState([1]);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (props.pages === void 0) {
      setVisiblePages([1]);
    } else if (props.pages < 7) {
      return setVisiblePages(range(1, props.pages));
    } else if (activePage <= 3) {
      return setVisiblePages(range(1, 7));
    } else if (activePage + 3 >= props.pages) {
      return setVisiblePages(range(props.pages - 6, props.pages));
    } else {
      return setVisiblePages(range(activePage - 3, activePage + 3));
    }
  }, [activePage, props.pages]);

  const changePage = (page: number) => {
    const aPage = props.page + 1;
    if (page === aPage) {
      return;
    }
    setActivePage(page);
    props.onPageChange(page - 1);
  };

  return (
    <div className="Table__pagination">
      <div className="Table__visiblePagesWrapper">
        {visiblePages.map((pageNum, index, array) => {
          return (
            <span
              key={`${pageNum}`}
              className={
                props.page + 1 === pageNum ? "Table__pageButton Table__pageButton--active" : "Table__pageButton"
              }
              onClick={(e: any) => changePage(pageNum)}>
              {pageNum}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default TablePagination;
