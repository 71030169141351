import { isEmail } from "validator";

import React, { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";

import { Spinner } from "react-bootstrap";

import AccountService from "services/AccountService";
import { Header, Input, Messages, Footer } from "components";
import { useMessage } from "hooks";

const ResetPasswordSendEmailContainer: React.FC = () => {
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const [email, setEmail] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  const handleSubmit = async (e: FormEvent) => {
    setDisableSubmit(true);
    e.preventDefault();
    const isValid = isEmail(email, { allow_utf8_local_part: false });
    if (!isValid) {
      setEmailError(t("form.validation.email_format"));
      return;
    }

    setEmailError(null);

    try {
      await AccountService.resetPassword(email.toLowerCase());
      setDisableSubmit(false);
      setEmail("");
      addMessage({
        type: "success",
        text: t("reset_password.confirmation"),
      });
    } catch (error) {
      setDisableSubmit(false);
      addMessage({
        type: "error",
        text: t("reset_password.failure"),
      });
    }
  };

  return (
    <>
    <Header withBottom={false} withTitle={t("reset_password.reset_header")} />
    <div className="container">
      <div className="mt-3">
        <Messages />
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="bottom-part row my-5 justify-content-center">
            <div className="bg-white border-top-3-dusty-orange mx-6 w-100">
              <div className="row mt-3 text-center">
                <h5 className="text-left ml-5 w-100 pt-2 button-font txt-color-marine-blue">
                  <strong>{t("reset_password.title.emailInput")}</strong>
                </h5>
                <hr style={{ width: "80%" }} />
              </div>
              <div className="row mb-3 d-flex justify-content-center">
                <form onSubmit={handleSubmit} autoComplete={"on"} className="w-100 mx-5">
                  <div className="username-input my-3 d-flex flex-column">
                    <Input
                      label={t("set_email.your_email_label")}
                      inputType="email"
                      required={true}
                      name="email"
                      onKeyPress={(e: React.KeyboardEvent) => {
                        if (e.key === "Enter") handleSubmit(e);
                      }}
                      errors={{ email: emailError }}
                      value={email}
                      handleChange={({ email }: any) => setEmail(email.toLowerCase())}
                    />
                  </div>
                  <div className="d-flex flex-column txt-color-bluish-grey">
                    <button
                      className={
                        (disableSubmit || !email ? "bg-grey" : "bg-dusty-orange") + " text-white border-0 w-100 py-2 my-3 animated"
                      }
                      type="submit"
                      disabled={disableSubmit || !email}>
                      {t("reset_password.reset_button")}
                      {disableSubmit ? <Spinner className="ml-2" animation="border" variant="light" size="sm" /> : <></>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <Footer />
    </>
  );
};

export default ResetPasswordSendEmailContainer;
