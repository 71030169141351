import Select from "react-dropdown-select";
import React from "react";
import "./Input.scss";

interface InputProps {
  label: string;
  options: { [key: string]: string }[];
  required?: boolean;
  disabled?: boolean;
  handleChange: any;
  value?: { [key: string]: string }[];
  name: string;
  errors?: { [field: string]: string | null };
}

export const SelectInput: React.FC<InputProps> = ({
  label = "",
  options = [],
  required = false,
  value = [],
  name = "",
  disabled = false,
  errors = {},
  handleChange,
}) => {
  const isValid = () => {
    return errors.hasOwnProperty(name) && errors[name] == null;
  };
  const selectedIds = value ? value.map((val) => val.id) : [];
  const selectedValues = options.filter((option) => selectedIds.indexOf(option.id) !== -1);
  return (
    <div className="my-2">
      <label className="txt-color-grafite mb-0">
        {label}
        {required ? <span className="ml-1 txt-color-error">*</span> : ""}
      </label>
      <div
        className={
          "select-input border-1-borders " + (!isValid() ? "error " : " ") + (disabled ? "select-disabled" : " ")
        }>
        <Select
          disabled={disabled}
          options={options}
          values={selectedValues}
          valueField="id"
          labelField="value"
          dropdownGap={0}
          searchable={false}
          placeholder={""}
          onChange={(value) => handleChange({ [name]: value })}
        />
      </div>
      {!isValid() && <label className="w-100 txt-color-bluish-grey text-error text-right mt-1">{errors[name]}</label>}
    </div>
  );
};

export default SelectInput;
