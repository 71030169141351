import { useDispatch, useSelector } from "react-redux";

import { State, ThunkDispatch } from "models/StoreModel";
import { MessageOptions } from "models/MessageModel";
import * as MessageActions from "store/actions/MessageActions";
import * as MessageThunk from "store/thunk/MessageThunk";

export default function useMessage() {
  const dispatch = useDispatch<ThunkDispatch>();
  const { messages } = useSelector((state: State) => state.message);

  const removeMessage = (id: string) => {
    dispatch(MessageActions.removeMessage(id));
  };

  const removeCurrentMessage = () => {
    dispatch(MessageThunk.removeCurrentMessage());
  };

  const addMessage = (message: MessageOptions, replaceCurrent: boolean = true) => {
    dispatch(MessageThunk.addMessage(message, replaceCurrent));
  };

  return { messages, addMessage, removeMessage, removeCurrentMessage };
}
