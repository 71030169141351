export type Operator = "=" | "!=" | ">=" | "<=" | "<" | ">";

export interface Field {
  type: string;
  subType?: string;
  editType?: string;
  newLineAfter?: boolean;
  className?: string;
  key: string;
  subKey?: string;
  validators?: any;
  availableValues?: string[];
  showWhen?: {
    propPath: string[];
    operator: Operator;
    value: any;
  };
  value: string | string[];
}

export interface SubSection {
  titleKey: string;
  showTitle: boolean;
  showWhenPropIsFilled?: string[][];
  fields: Field[];
}

export interface Section {
  id: string;
  sectionTitleKey: string;
  relatedSectionKey?: string;
  section: SubSection[];
}

const COMPANY_INFORMATION = {
  id: "company_information",
  sectionTitleKey: "passport.location.companyInformation",
  section: [
    {
      titleKey: "",
      showTitle: false,
      fields: [
        {
          type: "simple",
          key: "glnNumber",
          className: "font-orange",
          value: ["parent", "glnNumber"],
        },
        {
          type: "simple",
          key: "companyName",
          value: ["parent", "fullName"],
        },
        {
          type: "address",
          key: "address",
          value: ["parent", "locationExtraDetails"],
        },
        {
          type: "simple",
          key: "nip",
          value: ["parent", "locationExtraDetails", "nip"],
        },
        {
          type: "simple",
          key: "regon",
          value: ["parent", "locationExtraDetails", "regon"],
        },
        {
          type: "simple",
          key: "phoneNumber",
          value: ["parent", "locationExtraDetails", "phoneNumber"],
        },
        {
          type: "simple",
          key: "companyEmail",
          subType: "email",
          value: ["parent", "locationExtraDetails", "companyEmail"],
        },
        {
          type: "simple",
          key: "homePage",
          subType: "www",
          value: ["parent", "locationExtraDetails", "homePage"],
        },
      ],
    },
  ],
};
const BASIC_INFORMATION = {
  id: "basic_information",
  sectionTitleKey: "passport.location.basicInformation",
  section: [
    {
      titleKey: "passport.location.locationType",
      showTitle: false,
      fields: [
        {
          type: "simple",
          editType: "non-editable",
          key: "glnNumber",
          className: "font-orange",
          value: "glnNumber",
        },
        {
          type: "simple",
          editType: "non-editable",
          key: "companyName",
          value: ["parent", "fullName"],
        },
        {
          type: "simple",
          editType: "non-editable",
          key: "fullName",
          value: "fullName",
        },
        {
          type: "address",
          editType: "non-editable",
          key: "address",
          value: ["locationExtraDetails"],
        },
        {
          type: "multiInOneColumn",
          key: "locationType",
          value: "locationType",
          availableValues: ["magazine", "production_facility", "forwarding_office", "shop", "other"],
        },
        {
          type: "simple",
          editType: "non-editable",
          key: "latitude",
          value: "latitude",
        },
        {
          type: "simple",
          editType: "non-editable",
          key: "longitude",
          value: "longitude",
        },
        {
          type: "map-marker",
          editType: "non-editable",
          key: "map",
          value: "",
        },
      ],
    },
  ],
};
const OBJECTS_IN_LOCATION = {
  id: "objects_in_location",
  sectionTitleKey: "passport.location.objectsInLocation",
  section: [
    {
      titleKey: "passport.location.objectsInLocation",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "objectsInLocation",
          availableValues: ["has_office", "has_gate", "has_parking", "has_internal_parking", "has_road", "other"],
          value: ["locationDetails", "objectsInLocation"],
        },
      ],
    },
    {
      titleKey: "passport.location.ramps",
      showTitle: true,
      fields: [
        {
          type: "simple",
          editType: "numeric",
          key: "numRamps",
          newLineAfter: true,
          value: ["locationDetails", "numRamps"],
        },
        {
          type: "simple",
          editType: "range",
          key: "countryLoadingRamps",
          showWhen: {
            propPath: ["locationDetails", "numRamps"],
            operator: ">",
            value: 0,
          },
          validators: [
            {
              rule: "rangeRequireWhen",
              options: {
                parentElement: ["locationDetails", "numRamps"],
                value: ["locationDetails", "countryLoadingRamps"],
              },
              message: "passport.location.requireWhen.numRamps",
            },
            {
              rule: "rangeLessThenParent",
              options: {
                parentElement: ["locationDetails", "numRamps"],
                value: ["locationDetails", "countryLoadingRamps"],
              },
              message: "passport.location.rangeLessThenParent.numRamps",
            },
            {
              rule: "rangeToLessThenFrom",
              options: {
                parentElement: ["locationDetails", "numRamps"],
              },
              message: "passport.location.rangeToLessThenFrom",
            },
          ],
          value: ["locationDetails", "countryLoadingRamps"],
        },
        {
          type: "simple",
          editType: "range",
          key: "countryUnloadingRamps",
          showWhen: {
            propPath: ["locationDetails", "numRamps"],
            operator: ">",
            value: 0,
          },
          validators: [
            {
              rule: "rangeRequireWhen",
              options: {
                parentElement: ["locationDetails", "numRamps"],
                value: ["locationDetails", "countryUnloadingRamps"],
              },
              message: "passport.location.requireWhen.numRamps",
            },
            {
              rule: "rangeLessThenParent",
              options: {
                parentElement: ["locationDetails", "numRamps"],
                value: ["locationDetails", "countryUnloadingRamps"],
              },
              message: "passport.location.rangeLessThenParent.numRamps",
            },
            {
              rule: "rangeToLessThenFrom",
              options: {
                parentElement: ["locationDetails", "numRamps"],
              },
              message: "passport.location.rangeToLessThenFrom",
            },
          ],
          value: ["locationDetails", "countryUnloadingRamps"],
        },
        {
          type: "simple",
          editType: "range",
          key: "nationalLoadingRamps",
          showWhen: {
            propPath: ["locationDetails", "numRamps"],
            operator: ">",
            value: 0,
          },
          validators: [
            {
              rule: "rangeLessThenParentNotEmpty",
              options: {
                parentElement: ["locationDetails", "numRamps"],
                value: ["locationDetails", "nationalLoadingRamps"],
              },
              message: "passport.location.rangeLessThenParent.numRamps",
            },
            {
              rule: "rangeToLessThenFromNotEmpty",
              options: {
                parentElement: ["locationDetails", "numRamps"],
              },
              message: "passport.location.rangeToLessThenFrom",
            },
          ],
          value: ["locationDetails", "nationalLoadingRamps"],
        },
        {
          type: "simple",
          editType: "range",
          key: "nationalUnloadingRamps",
          showWhen: {
            propPath: ["locationDetails", "numRamps"],
            operator: ">",
            value: 0,
          },
          validators: [
            {
              rule: "rangeLessThenParentNotEmpty",
              options: {
                parentElement: ["locationDetails", "numRamps"],
                value: ["locationDetails", "nationalUnloadingRamps"],
              },
              message: "passport.location.rangeLessThenParent.numRamps",
            },
            {
              rule: "rangeToLessThenFromNotEmpty",
              options: {
                parentElement: ["locationDetails", "numRamps"],
              },
              message: "passport.location.rangeToLessThenFrom",
            },
          ],
          value: ["locationDetails", "nationalUnloadingRamps"],
        },
      ],
    },
    {
      titleKey: "passport.location.warehouses",
      showTitle: true,
      fields: [
        {
          type: "simple",
          key: "numWarehouses",
          editType: "numeric",
          value: ["locationDetails", "numWarehouses"],
        },
      ],
    },
  ],
};
const EXTRA_LOCATION_INFORMATION = {
  id: "extra_location_information",
  sectionTitleKey: "passport.location.extraLocationInformation",
  section: [
    {
      titleKey: "passport.location.extraLocationInformation",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "extraLocationInformation",
          availableValues: [
            "toilets_for_drivers",
            "shower_for_drivers",
            "coffee_machine",
            "restaurant_canteen",
            "other",
          ],
          value: ["locationDetails", "extraLocationInformation"],
        },
      ],
    },
  ],
};
const EQUIPMENT = {
  id: "equipment",
  sectionTitleKey: "passport.location.equipment",
  section: [
    {
      titleKey: "passport.location.equipment",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "equipment",
          availableValues: ["is_forklift", "is_pallet_track", "is_crane", "is_elevator"],
          value: ["locationDetails", "equipment"],
        },
      ],
    },
  ],
};
const TIME_TRACKING = {
  id: "time_tracking",
  sectionTitleKey: "passport.location.timeTracking",
  section: [
    {
      titleKey: "passport.location.timeTracking",
      showTitle: true,
      fields: [
        {
          type: "singleSelect",
          key: "timeTrackingPlace",
          availableValues: ["entrance_gate", "drive_through", "in_office", "in_warehouse", "other"],
          value: ["locationDetails", "timeTrackingPlace"],
        },
        {
          type: "boolean",
          key: "isPaidEntrance",
          value: ["locationDetails", "isPaidEntrance"],
        },
        {
          type: "boolean",
          key: "twoDrivers",
          value: ["locationDetails", "twoDrivers"],
        },
        {
          type: "boolean",
          key: "otherDrivers",
          value: ["locationDetails", "otherDrivers"],
        },
      ],
    },
  ],
};
const CLIENT_RESTRICTION = {
  id: "client_restriction",
  sectionTitleKey: "passport.location.clientRestriction",
  section: [
    {
      titleKey: "passport.location.carDriverRestriction",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "carDriverRestriction",
          availableValues: [
            "own_forklift",
            "own_elevator",
            "helmet",
            "goggles",
            "protective_clothing",
            "lashing_straps",
            "anti_slip_mats",
            "safety_bars",
            "angles",
            "work_boots",
            "adr",
            "other",
          ],
          value: ["locationDetails", "carDriverRestriction"],
        },
      ],
    },
    {
      titleKey: "passport.location.supportedVehicles",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "supportedVehicles",
          availableValues: ["truck", "tank", "tilt", "cooler", "isotherm", "other"],
          value: ["locationDetails", "supportedVehicles"],
        },
      ],
    },
    {
      titleKey: "passport.location.loadingTechnicalDetails",
      showTitle: true,
      fields: [
        {
          type: "boolean",
          key: "isPalletShortSide",
          value: ["locationDetails", "isPalletShortSide"],
        },
        {
          type: "boolean",
          key: "isPalletLongSide",
          value: ["locationDetails", "isPalletLongSide"],
        },
        {
          type: "boolean",
          key: "isLevelingAllowed",
          value: ["locationDetails", "isLevelingAllowed"],
        },
        {
          type: "boolean",
          key: "freeLoadingUnloading",
          value: ["locationDetails", "freeLoadingUnloading"],
        },
      ],
    },
    {
      titleKey: "passport.location.supportedLanguages",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "supportedLanguages",
          availableValues: ["pl", "en", "de", "fr", "ru", "other"],
          value: ["locationDetails", "supportedLanguages"],
        },
      ],
    },
    {
      titleKey: "passport.location.other",
      showTitle: true,
      fields: [
        {
          type: "boolean",
          key: "referenceNumber",
          value: ["locationDetails", "referenceNumber"],
        },
      ],
    },
  ],
};
const SUPPORTED_UNITS = {
  id: "supported_units",
  sectionTitleKey: "passport.location.supportedUnits",
  section: [
    {
      titleKey: "passport.location.supportedUnits",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "supportedUnits",
          availableValues: [
            "unit_packaging",
            "bulk_packaging",
            "pallet_packaging",
            "containers",
            "only_eur_pallets",
            "eur_and_industrial_pallets",
            "barrels",
            "specialized_containers",
            "other",
          ],
          value: ["locationDetails", "supportedUnits"],
        },
      ],
    },
  ],
};
const THERMAL = {
  id: "thermal",
  sectionTitleKey: "passport.location.thermal",
  section: [
    {
      titleKey: "passport.location.thermal",
      showTitle: true,
      fields: [
        {
          type: "boolean",
          key: "thermalControl",
          value: ["locationDetails", "thermalControl"],
        },
        {
          type: "boolean",
          key: "adr",
          value: ["locationDetails", "adr"],
        },
      ],
    },
  ],
};
const HOURS = {
  id: "hours",
  sectionTitleKey: "passport.location.hours",
  section: [
    {
      titleKey: "passport.location.openHours.normal",
      showTitle: true,
      fields: [
        {
          type: "simple",
          editType: "timepicker",
          key: "weekdaysFrom",
          subKey: "normal",
          validators: [
            {
              rule: "timeRequired",
              options: {
                value: ["locationDetails", "openHours", "0", "weekdaysFrom"],
              },
              message: "passport.location.timeRequired",
            },
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "openHours", "0", "weekdaysFrom"],
              },
              message: "passport.location.timeLTE.weekdaysTo",
            },
          ],
          value: ["locationDetails", "openHours", "0", "weekdaysFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "weekdaysTo",
          subKey: "normal",
          validators: [
            {
              rule: "timeRequired",
              options: {
                value: ["locationDetails", "openHours", "0", "weekdaysTo"],
              },
              message: "passport.location.timeRequired",
            },
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                value: ["locationDetails", "openHours", "0", "weekdaysTo"],
              },
              message: "passport.location.timeGTE.weekdaysFrom",
            },
          ],
          value: ["locationDetails", "openHours", "0", "weekdaysTo"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "saturdayFrom",
          subKey: "normal",
          validators: [
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "saturdayTo"],
                value: ["locationDetails", "openHours", "0", "saturdayFrom"],
              },
              message: "passport.location.timeLTE.saturdayTo",
            },
          ],
          value: ["locationDetails", "openHours", "0", "saturdayFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "saturdayTo",
          subKey: "normal",
          validators: [
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "saturdayFrom"],
                value: ["locationDetails", "openHours", "0", "saturdayTo"],
              },
              message: "passport.location.timeGTE.saturdayFrom",
            },
          ],
          value: ["locationDetails", "openHours", "0", "saturdayTo"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "sundayHolidaysFrom",
          subKey: "normal",
          validators: [
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
              },
              message: "passport.location.timeLTE.sundayHolidaysTo",
            },
          ],
          value: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "sundayHolidaysTo",
          subKey: "normal",
          validators: [
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
                value: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
              },
              message: "passport.location.timeGTE.sundayHolidaysFrom",
            },
          ],
          value: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
        },
      ],
    },
    {
      titleKey: "passport.location.openHours.loading",
      showTitle: true,
      fields: [
        {
          type: "simple",
          editType: "timepicker",
          key: "weekdaysFrom",
          subKey: "loading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                value: ["locationDetails", "openHours", "1", "weekdaysFrom"],
              },
              message: "passport.location.timeRequiredWhen.weekdaysFrom",
            },
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "1", "weekdaysTo"],
                value: ["locationDetails", "openHours", "1", "weekdaysFrom"],
              },
              message: "passport.location.timeLTE.weekdaysTo",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "openHours", "1", "weekdaysFrom"],
              },
              message: "passport.location.timeBetween.weekdaysFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "1", "weekdaysFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "weekdaysTo",
          subKey: "loading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "openHours", "1", "weekdaysTo"],
              },
              message: "passport.location.timeRequiredWhen.weekdaysTo",
            },
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "1", "weekdaysFrom"],
                value: ["locationDetails", "openHours", "1", "weekdaysTo"],
              },
              message: "passport.location.timeGTE.weekdaysFrom",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "openHours", "1", "weekdaysTo"],
              },
              message: "passport.location.timeBetween.weekdaysFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "1", "weekdaysTo"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "saturdayFrom",
          subKey: "loading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "saturdayFrom"],
                value: ["locationDetails", "openHours", "1", "saturdayFrom"],
              },
              message: "passport.location.timeRequiredWhen.saturdayFrom",
            },
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "1", "saturdayTo"],
                value: ["locationDetails", "openHours", "1", "saturdayFrom"],
              },
              message: "passport.location.timeLTE.saturdayTo",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "saturdayFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "saturdayTo"],
                value: ["locationDetails", "openHours", "1", "saturdayFrom"],
              },
              message: "passport.location.timeBetween.saturdayFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "1", "saturdayFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "saturdayTo",
          subKey: "loading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "saturdayTo"],
                value: ["locationDetails", "openHours", "1", "saturdayTo"],
              },
              message: "passport.location.timeRequiredWhen.saturdayTo",
            },
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "1", "saturdayFrom"],
                value: ["locationDetails", "openHours", "1", "saturdayTo"],
              },
              message: "passport.location.timeGTE.saturdayFrom",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "saturdayFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "saturdayTo"],
                value: ["locationDetails", "openHours", "1", "saturdayTo"],
              },
              message: "passport.location.timeBetween.saturdayFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "1", "saturdayTo"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "sundayHolidaysFrom",
          subKey: "loading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
                value: ["locationDetails", "openHours", "1", "sundayHolidaysFrom"],
              },
              message: "passport.location.timeRequiredWhen.sundayHolidaysFrom",
            },
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "1", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "1", "sundayHolidaysFrom"],
              },
              message: "passport.location.timeLTE.sundayHolidaysTo",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "1", "sundayHolidaysFrom"],
              },
              message: "passport.location.timeBetween.sundayHolidaysFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "1", "sundayHolidaysFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "sundayHolidaysTo",
          subKey: "loading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "1", "sundayHolidaysTo"],
              },
              message: "passport.location.timeRequiredWhen.sundayHolidaysTo",
            },
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "1", "sundayHolidaysFrom"],
                value: ["locationDetails", "openHours", "1", "sundayHolidaysTo"],
              },
              message: "passport.location.timeGTE.sundayHolidaysFrom",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "1", "sundayHolidaysTo"],
              },
              message: "passport.location.timeBetween.sundayHolidaysFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "1", "sundayHolidaysTo"],
        },
      ],
    },
    {
      titleKey: "passport.location.openHours.unloading",
      showTitle: true,
      fields: [
        {
          type: "simple",
          editType: "timepicker",
          key: "weekdaysFrom",
          subKey: "unloading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                value: ["locationDetails", "openHours", "2", "weekdaysFrom"],
              },
              message: "passport.location.timeRequiredWhen.weekdaysFrom",
            },
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "2", "weekdaysTo"],
                value: ["locationDetails", "openHours", "2", "weekdaysFrom"],
              },
              message: "passport.location.timeLTE.weekdaysTo",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "openHours", "2", "weekdaysFrom"],
              },
              message: "passport.location.timeBetween.weekdaysFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "2", "weekdaysFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "weekdaysTo",
          subKey: "unloading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "openHours", "2", "weekdaysTo"],
              },
              message: "passport.location.timeRequiredWhen.weekdaysTo",
            },
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "2", "weekdaysFrom"],
                value: ["locationDetails", "openHours", "2", "weekdaysTo"],
              },
              message: "passport.location.timeGTE.weekdaysFrom",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "openHours", "2", "weekdaysTo"],
              },
              message: "passport.location.timeBetween.weekdaysFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "2", "weekdaysTo"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "saturdayFrom",
          subKey: "unloading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "saturdayFrom"],
                value: ["locationDetails", "openHours", "2", "saturdayFrom"],
              },
              message: "passport.location.timeRequiredWhen.saturdayFrom",
            },
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "2", "saturdayTo"],
                value: ["locationDetails", "openHours", "2", "saturdayFrom"],
              },
              message: "passport.location.timeLTE.saturdayTo",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "saturdayFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "saturdayTo"],
                value: ["locationDetails", "openHours", "2", "saturdayFrom"],
              },
              message: "passport.location.timeBetween.saturdayFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "2", "saturdayFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "saturdayTo",
          subKey: "unloading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "saturdayTo"],
                value: ["locationDetails", "openHours", "2", "saturdayTo"],
              },
              message: "passport.location.timeRequiredWhen.saturdayTo",
            },
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "2", "saturdayFrom"],
                value: ["locationDetails", "openHours", "2", "saturdayTo"],
              },
              message: "passport.location.timeGTE.saturdayFrom",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "saturdayFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "saturdayTo"],
                value: ["locationDetails", "openHours", "2", "saturdayTo"],
              },
              message: "passport.location.timeBetween.saturdayFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "2", "saturdayTo"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "sundayHolidaysFrom",
          subKey: "unloading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
                value: ["locationDetails", "openHours", "2", "sundayHolidaysFrom"],
              },
              message: "passport.location.timeRequiredWhen.sundayHolidaysFrom",
            },
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "openHours", "2", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "2", "sundayHolidaysFrom"],
              },
              message: "passport.location.timeLTE.sundayHolidaysTo",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "2", "sundayHolidaysFrom"],
              },
              message: "passport.location.timeBetween.sundayHolidaysFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "2", "sundayHolidaysFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "sundayHolidaysTo",
          subKey: "unloading",
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "2", "sundayHolidaysTo"],
              },
              message: "passport.location.timeRequiredWhen.sundayHolidaysTo",
            },
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "openHours", "2", "sundayHolidaysFrom"],
                value: ["locationDetails", "openHours", "2", "sundayHolidaysTo"],
              },
              message: "passport.location.timeGTE.sundayHolidaysFrom",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "sundayHolidaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "sundayHolidaysTo"],
                value: ["locationDetails", "openHours", "2", "sundayHolidaysTo"],
              },
              message: "passport.location.timeBetween.sundayHolidaysFromTo",
            },
          ],
          value: ["locationDetails", "openHours", "2", "sundayHolidaysTo"],
        },
      ],
    },
  ],
};
const MONITORY_NOTE = {
  id: "monitory_note",
  sectionTitleKey: "passport.location.monitoryNote",
  relatedSectionKey: "passport.location.hours",
  section: [
    {
      titleKey: "passport.location.monitoryNote",
      showTitle: true,
      fields: [
        {
          type: "boolean",
          key: "isMonitoryNote",
          value: ["locationDetails", "isMonitoryNote"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "monitoryNoteFrom",
          showWhen: {
            propPath: ["locationDetails", "isMonitoryNote"],
            operator: "=",
            value: true,
          },
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "isMonitoryNote"],
                value: ["locationDetails", "monitoryNoteFrom"],
              },
              message: "passport.location.timeRequiredWhen.isMonitoryNote",
            },
            {
              rule: "timeLTE",
              options: {
                parentElement: ["locationDetails", "monitoryNoteTo"],
                value: ["locationDetails", "monitoryNoteFrom"],
              },
              message: "passport.location.timeLTE.monitoryNoteTo",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "monitoryNoteFrom"],
              },
              message: "passport.location.timeBetween.weekdaysFromTo",
            },
          ],
          value: ["locationDetails", "monitoryNoteFrom"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "monitoryNoteTo",
          showWhen: {
            propPath: ["locationDetails", "isMonitoryNote"],
            operator: "=",
            value: true,
          },
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "isMonitoryNote"],
                value: ["locationDetails", "monitoryNoteTo"],
              },
              message: "passport.location.timeRequiredWhen.isMonitoryNote",
            },
            {
              rule: "timeGTE",
              options: {
                parentElement: ["locationDetails", "monitoryNoteFrom"],
                value: ["locationDetails", "monitoryNoteTo"],
              },
              message: "passport.location.timeGTE.monitoryNoteFrom",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "monitoryNoteTo"],
              },
              message: "passport.location.timeBetween.weekdaysFromTo",
            },
          ],
          value: ["locationDetails", "monitoryNoteTo"],
        },
        {
          type: "simple",
          editType: "timepicker",
          key: "monitoryNoteTime",
          showWhen: {
            propPath: ["locationDetails", "isMonitoryNote"],
            operator: "=",
            value: true,
          },
          validators: [
            {
              rule: "timeRequiredWhen",
              options: {
                parentElement: ["locationDetails", "isMonitoryNote"],
                value: ["locationDetails", "monitoryNoteTime"],
              },
              message: "passport.location.timeRequiredWhen.isMonitoryNote",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "openHours", "0", "weekdaysFrom"],
                parentToElement: ["locationDetails", "openHours", "0", "weekdaysTo"],
                value: ["locationDetails", "monitoryNoteTime"],
              },
              message: "passport.location.timeBetween.weekdaysFromTo",
            },
            {
              rule: "timeBetween",
              options: {
                parentFromElement: ["locationDetails", "monitoryNoteFrom"],
                parentToElement: ["locationDetails", "monitoryNoteTo"],
                value: ["locationDetails", "monitoryNoteTime"],
              },
              message: "passport.location.timeBetween.monitoryNoteFromTo",
            },
          ],
          value: ["locationDetails", "monitoryNoteTime"],
        },
        {
          type: "simple",
          editType: "numeric",
          key: "hoursBeforeMonitoryNote",
          showWhen: {
            propPath: ["locationDetails", "isMonitoryNote"],
            operator: "=",
            value: true,
          },
          value: ["locationDetails", "hoursBeforeMonitoryNote"],
        },
        {
          type: "simple",
          editType: "numeric",
          key: "awaitTime",
          showWhen: {
            propPath: ["locationDetails", "isMonitoryNote"],
            operator: "=",
            value: true,
          },
          value: ["locationDetails", "awaitTime"],
        },
      ],
    },
  ],
};
const AVG_TIME = {
  id: "avg_time",
  sectionTitleKey: "passport.location.avgTime",
  section: [
    {
      titleKey: "passport.location.avgTime",
      showTitle: true,
      fields: [
        {
          type: "simple",
          editType: "numeric",
          key: "avgLoadingTime",
          value: ["locationDetails", "avgLoadingTime"],
        },
        {
          type: "simple",
          editType: "numeric",
          key: "avgUnloadingTime",
          value: ["locationDetails", "avgUnloadingTime"],
        },
      ],
    },
  ],
};
const DRIVER_OPERATION = {
  id: "driver_operation",
  sectionTitleKey: "passport.location.driverOperation",
  section: [
    {
      titleKey: "passport.location.driverOperation",
      showTitle: true,
      fields: [
        {
          type: "boolean",
          key: "driverInOperation",
          value: ["locationDetails", "driverInOperation"],
        },
        {
          type: "boolean",
          key: "driverDoOperation",
          showWhen: {
            propPath: ["locationDetails", "driverInOperation"],
            operator: "=",
            value: true,
          },
          value: ["locationDetails", "driverDoOperation"],
        },
        {
          type: "singleSelect",
          key: "driverPlace",
          showWhen: {
            propPath: ["locationDetails", "driverInOperation"],
            operator: "=",
            value: false,
          },
          validators: [
            {
              rule: "requireWhenParent",
              options: {
                parentElement: ["locationDetails", "driverInOperation"],
                otherValue: ["locationDetails", "driverPlaceOther"],
                conditionValue: false,
                value: ["locationDetails", "driverPlace"],
              },
              message: "passport.location.requireWhen.driverInOperation",
            },
          ],
          availableValues: ["car_cabin", "canteen", "other"],
          value: ["locationDetails", "driverPlace"],
        },
      ],
    },
  ],
};
const DOCUMENTS = {
  id: "documents",
  sectionTitleKey: "passport.location.documents",
  section: [
    {
      titleKey: "passport.location.loadingDocuments",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "documentsForLoading",
          availableValues: [
            "cmr",
            "license",
            "tractor_registration",
            "semitrailer_registration",
            "id_card_passport",
            "aggregate_printout",
            "other",
          ],
          value: ["locationDetails", "documentsForLoading"],
        },
      ],
    },
    {
      titleKey: "passport.location.unloadingDocuments",
      showTitle: true,
      fields: [
        {
          type: "multiInSeveralColumn",
          key: "documentsForUnloading",
          availableValues: [
            "cmr",
            "license",
            "tractor_registration",
            "semitrailer_registration",
            "id_card_passport",
            "aggregate_printout",
            "other",
          ],
          value: ["locationDetails", "documentsForUnloading"],
        },
      ],
    },
    {
      titleKey: "passport.location.other",
      showTitle: true,
      fields: [
        {
          type: "singleSelect",
          key: "waybillAtLoading",
          availableValues: ["driver_driver", "driver_shipper", "shipper_shipper"],
          value: ["locationDetails", "waybillAtLoading"],
        },
      ],
    },
  ],
};
const DRIVING_DIRECTIONS = {
  id: "driving_directions",
  sectionTitleKey: "passport.location.drivingDirections",
  section: [
    {
      titleKey: "passport.location.drivingDirections",
      showTitle: true,
      fields: [
        {
          type: "simple",
          className: "col-md-12",
          key: "drivingDirections",
          value: ["locationDetails", "drivingDirections"],
        },
      ],
    },
  ],
};
const EMPTY_PALLETS = {
  id: "empty_pallets",
  sectionTitleKey: "passport.location.emptyPallet",
  section: [
    {
      titleKey: "passport.location.emptyPalletCollectionLoading",
      showTitle: true,
      fields: [
        {
          type: "boolean",
          key: "emptyPalletCollection",
          value: ["locationDetails", "emptyPalletCollection"],
        },
        {
          type: "singleSelect",
          key: "palletPlace",
          availableValues: ["side_box", "semitrailer"],
          showWhen: {
            propPath: ["locationDetails", "emptyPalletCollection"],
            operator: "=",
            value: true,
          },
          validators: [],
          value: ["locationDetails", "palletPlace"],
        },
        {
          type: "singleSelect",
          key: "palletsVerificationByDriver",
          availableValues: ["before_loading", "after_loading", "no"],
          showWhen: {
            propPath: ["locationDetails", "emptyPalletCollection"],
            operator: "=",
            value: true,
          },
          validators: [],
          value: ["locationDetails", "palletsVerificationByDriver"],
        },
      ],
    },
    {
      titleKey: "passport.location.emptyPalletCollectionUnloading",
      showTitle: true,
      fields: [
        {
          type: "boolean",
          key: "emptyPalletLeaving",
          value: ["locationDetails", "emptyPalletLeaving"],
        },
        {
          type: "singleSelect",
          key: "emptyPalletPlace",
          availableValues: ["ramp", "different_ramp", "other"],
          showWhen: {
            propPath: ["locationDetails", "emptyPalletLeaving"],
            operator: "=",
            value: true,
          },
          validators: [],
          value: ["locationDetails", "emptyPalletPlace"],
        },
      ],
    },
    {
      titleKey: "passport.location.emptyPalletCollectionDetails",
      showTitle: true,
      showWhenPropIsFilled: [
        ["locationDetails", "emptyPalletCollection"],
        ["locationDetails", "emptyPalletLeaving"],
      ],
      fields: [
        {
          type: "singleSelect",
          key: "exchangePalletPerson",
          availableValues: ["driver", "warehouseman_driver", "warehouseman_no_driver"],
          validators: [],
          value: ["locationDetails", "exchangePalletPerson"],
        },
        {
          type: "singleSelect",
          key: "notSupportedPallets",
          availableValues: ["eur_not_epal", "different"],
          validators: [],
          value: ["locationDetails", "notSupportedPallets"],
        },
        {
          type: "singleSelect",
          key: "whoFillPalletsDocument",
          availableValues: ["driver", "warehouseman"],
          validators: [],
          value: ["locationDetails", "whoFillPalletsDocument"],
        },
      ],
    },
  ],
};
const RETURN_OF_GOODS = {
  id: "return_of_goods",
  sectionTitleKey: "passport.location.returnOfGoods",
  section: [
    {
      titleKey: "passport.location.returnOfGoods",
      showTitle: true,
      fields: [
        {
          type: "boolean",
          key: "isReturnOfGoodsSupported",
          value: ["locationDetails", "isReturnOfGoodsSupported"],
        },
        {
          type: "singleSelect",
          key: "rogPlace",
          availableValues: ["ramp", "different_ramp", "different_place"],
          showWhen: {
            propPath: ["locationDetails", "isReturnOfGoodsSupported"],
            operator: "=",
            value: true,
          },
          validators: [
          ],
          value: ["locationDetails", "rogPlace"],
        },
        {
          type: "singleSelect",
          key: "rogPerson",
          availableValues: ["driver", "warehouseman_driver", "warehouseman_no_driver"],
          showWhen: {
            propPath: ["locationDetails", "isReturnOfGoodsSupported"],
            operator: "=",
            value: true,
          },
          validators: [
          ],
          value: ["locationDetails", "rogPerson"],
        },
        {
          type: "singleSelect",
          key: "whoFillRogDocument",
          availableValues: ["driver", "warehouseman"],
          showWhen: {
            propPath: ["locationDetails", "isReturnOfGoodsSupported"],
            operator: "=",
            value: true,
          },
          validators: [
          ],
          value: ["locationDetails", "whoFillRogDocument"],
        },
      ],
    },
  ],
};
const PHONE_NUMBERS = {
  id: "phone_numbers",
  sectionTitleKey: "passport.location.phoneNumbers",
  section: [
    {
      titleKey: "passport.location.phoneNumbers",
      showTitle: true,
      fields: [
        {
          type: "simple",
          editType: "phoneNumber",
          key: "phoneNumberToDriverContact",
          validators: [
            {
              rule: "isRequired",
              options: {
                value: ["locationDetails", "phoneNumberToDriverContact"],
              },
              message: "form.validation.required",
            },
            {
              rule: "isGsonePhoneNumber",
              options: {
                value: ["locationDetails", "phoneNumberToDriverContact"],
              },
              message: "form.validation.phone_format",
            },
            {
              rule: "IsLengthOptions",
              options: {
                min: 9,
                max: 15,
                value: ["locationDetails", "phoneNumberToDriverContact"],
              },
              message: "form.validation.hasToBeLessThan15AndMoreThan9",
            },
          ],
          value: ["locationDetails", "phoneNumberToDriverContact"],
        },
        {
          type: "simple",
          editType: "phoneNumber",
          key: "phoneNumberToResponsiblePerson",
          validators: [
            {
              rule: "isRequired",
              options: {
                value: ["locationDetails", "phoneNumberToResponsiblePerson"],
              },
              message: "form.validation.required",
            },
            {
              rule: "isGsonePhoneNumber",
              options: {
                value: ["locationDetails", "phoneNumberToResponsiblePerson"],
              },
              message: "form.validation.phone_format",
            },
            {
              rule: "IsLengthOptions",
              options: {
                min: 9,
                max: 15,
                value: ["locationDetails", "phoneNumberToResponsiblePerson"],
              },
              message: "form.validation.hasToBeLessThan15AndMoreThan9",
            },
          ],
          value: ["locationDetails", "phoneNumberToResponsiblePerson"],
        },
      ],
    },
  ],
};

const PASSPORT_MAPPING: Section[] = [
  COMPANY_INFORMATION,
  BASIC_INFORMATION,
  OBJECTS_IN_LOCATION,
  EXTRA_LOCATION_INFORMATION,
  EQUIPMENT,
  TIME_TRACKING,
  CLIENT_RESTRICTION,
  SUPPORTED_UNITS,
  THERMAL,
  HOURS,
  MONITORY_NOTE,
  AVG_TIME,
  DRIVER_OPERATION,
  DOCUMENTS,
  DRIVING_DIRECTIONS,
  EMPTY_PALLETS,
  RETURN_OF_GOODS,
  PHONE_NUMBERS,
];

export default PASSPORT_MAPPING;
