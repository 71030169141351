import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface MobileAppProps {
  isPrivateRoute: null | boolean;
  handleRejection: () => void;
  handleInstallation: () => void;
}

export const MobileApp: React.FC<MobileAppProps> = ({ isPrivateRoute, handleRejection, handleInstallation }) => {
  const handleInstallationCB = useCallback(() => {
    handleInstallation();
  }, [isPrivateRoute]);

  const { t } = useTranslation();

  return (
    <div
      className={`${isPrivateRoute ? "d-flex" : "d-none"} w-100 h-100 fixed-top align-items-center`}
      style={{ backgroundColor: "rgba(0,0,0,.8)" }}>
      <div
        style={{ justifyContent: "space-evenly" }}
        className="container w-100 h-100 d-flex flex-column justify-content-evenly">
        <div
          className="row d-flex flex-column align-content-center"
          onClick={() => {
            handleRejection();
          }}>
          <div className="col-7 px-0">
            <img src="/phone-gs-1-app.svg" className="w-100 h-100" alt="GS1 mobile app" />
          </div>
        </div>
        <div>
          <button
            onClick={() => handleInstallationCB()}
            className="mb-2 btn font-weight-bold txt-color-white btn-outline-secondary border-0 w-100 p-2 w-100 rounded-0 font-size-regular bg-dusty-orange"
            type="button">
            {t("pwa.addToHomeScreen")}
          </button>
          <button
            onClick={() => {
              handleRejection();
            }}
            className="btn font-weight-bold txt-color-dusty-orange btn-outline-secondary border-0 p-2 w-100 rounded-0 font-size-regular"
            type="button">
            {t("pwa.addToHomeScreenLater")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default MobileApp;
