import _ from "lodash";

import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, useStore } from "react-redux";

import FiltersGroup from "components/filtersGroup/FiltersGroup";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import { State } from "models/StoreModel";
import * as dataThunk from "store/thunk/DataThunk";
import { Filter } from "models/DataModel";

export const ExportContainer: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const dataState = useSelector((state: State) => state.data);
  const selectedLocation = useSelector((state: State) => state.account.selectedLocation);
  const store = useStore();

  if (!selectedLocation) {
    throw new Error("Invariant - for this route `selectedLocation` are required.");
  }

  const [taskInfo, setTaskInfo] = useState({
    percent: 0,
    label: t("task.info.starting"),
  });

  const exportLocations = () => {
    setTaskInfo({
      percent: 0,
      label: t("task.info.starting"),
    });
    const filters = store.getState().data.filters["export"];   
    if (filters) {
      const filtered: { [key: string]: any } = {
        excelWithRestrictions: false,
        selectedLocation: selectedLocation.id,
      };
      _.forEach(filters, (filter: Filter) => {
        filtered[filter.id] = _.map(filter.value, (value: string) => {
          // @ts-ignore
          return filter.id === "companyId" ? value.id : value.city;
        });
      });
      dispatch(dataThunk.exportLocations(filtered));
    }
  };

  useEffect(() => {
    dispatch(dataThunk.getCompaniesData(0, 0, "fullName", [], true));
    dispatch(dataThunk.getAllCities());
  }, []);

  useEffect(() => {
    if (dataState.taskData.processingRow > 0) {
      setTaskInfo({
        percent: (dataState.taskData.processingRow * 100) / dataState.taskData.rowsToProcess,
        label: dataState.taskData.taskStatus
          ? t("task.info.success")
          : dataState.taskData.processingRow + " / " + dataState.taskData.rowsToProcess,
      });
    }
  }, [dataState.taskData.processingRow, dataState.taskData.taskStatus]);

  useEffect(() => {
    if (dataState.taskData.taskId !== "" && !dataState.taskData.taskStatus) {
      const interval = setInterval(() => {
        dispatch(dataThunk.checkExportStatus(dataState.taskData.taskId, dataState.taskData.cacheKey));
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [
    dataState.taskData.taskStatus,
    dataState.taskData.startedExport,
    dataState.taskData.processingRow,
    dataState.taskData.taskId,
  ]);

  const filterOptions = [
    {
      id: "companyId",
      value: t("filters.company"),
      type: "select",
      options: dataState.CompaniesRows,
      selectKey: "id",
      selectValue: "fullName",
      searchBy: ["fullName", "nip", "regon"],
      searchLabel: t("export.search.company.help"),
    },
    {
      id: "city",
      value: t("filters.city"),
      type: "select",
      options: dataState.polandCities,
      selectKey: "city",
      selectValue: "city",
      searchBy: ["city"],
      searchLabel: t("export.search.city.help"),
    },
  ];

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-4">
        <div className="context-menu row">
          <div className="col-md-9">
            <Breadcrumb>
              <BreadcrumbItem active>{t("header.bottom.export")}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <div className="row border-top-3 p-4 bg-white mt-4">
          <div className="col-md-12 px-0">
            <div className="content-title">
              <h5 className="form-title form-header font-size-normal font-weight-bold  font-weight-bold font-size-normal  bold">
                {t("export.title")}
              </h5>
            </div>
            <hr />
            <p className="txt-color-grafite">{t("export.help_title")}</p>
            <FiltersGroup
              filterLabel={t("export.filter.label")}
              buttonLabel={t("export.button.label")}
              fullWidth={true}
              options={filterOptions}
              filtersKey="export"
              multiple={true}
              onSubmit={exportLocations}
              className="px-0 mb-3"
              disableEmpty={true}
            />
          </div>

          {dataState.taskData.taskStatus && dataState.taskData.rowsToProcess === 0 ? (
            <div className="col-md-12">{t("export.no.records")}</div>
          ) : (
            <div className="col-md-12">
              {dataState.taskData.taskId !== "" || dataState.taskData.startedExport ? (
                <div className="mt-5 col-md-12 px-0">
                  <p>{taskInfo.label}</p>
                  <ProgressBar
                    animated={true}
                    className="fadeIn"
                    variant={dataState.taskData.taskStatus ? "success" : "info"}
                    now={dataState.taskData.exportEnd ? 100 : taskInfo.percent}
                  />
                </div>
              ) : (
                <></>
              )}
              {dataState.taskData.taskStatus && !_.isEmpty(dataState.taskData.taskResult) ? (
                <div className="mb-5 col-md-12 fadeIn px-0 mt-2">
                  <a
                    /* eslint-disable-next-line react/jsx-no-target-blank */
                    target="_blank"
                    className="btn p-2 d-inline border-0 px-4 w-100 rounded-0 filters-font txt-color-white bg-dusty-orange"
                    download="lista_lokalizacji.xlsx"
                    href={dataState.taskData.taskResult}>
                    <b>{t("export.download")}</b>
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ExportContainer;
