import React from "react";
import { useTranslation } from "react-i18next";
import { Footer, Header } from "components";
import { useSelector } from "react-redux";

import { State } from "models/StoreModel";

    const ContactContainer: React.FC = () => {
    const { t } = useTranslation();
    const account = useSelector((state: State) => state.account);

    let headerProps = {
        withBottom: account.user ? true : false,
        withTitle: account.user ? "" : " "
    }

return (
    <>
        <Header withBottom={headerProps.withBottom} withTitle={headerProps.withTitle} />
        <div className="container my-5">
            <h5 className="mb-4 font-weight-bold font-size-medium">{t("contact")}</h5>
            <span className="d-flex flex-row mt-4">
                <span className="fas fa-phone-alt mr-3 align-self-center" aria-hidden="true" title={t("contact.phoneNumber")} />
                <span className="d-flex flex-column">
                    <span className="mr-2 font-weight-bold font-size-regular">{t("contact.helpdesk")}</span>
                    <a className="txt-color-primary" href={`tel:${t("contact.phoneNumber")}`}>
                    {t("contact.phoneNumber")}
                    </a>
                </span>
            </span>
            <span className="d-flex flex-row mt-4">
                <span className="fas fa-envelope mr-3 align-self-center" aria-hidden="true" title={t("contact.emailValue")} />
                <span className="d-flex flex-column">
                    <span className="mr-2 font-weight-bold font-size-regular">{t("contact.email")}</span>
                    <a className="txt-color-primary" href={`tel:${t("contact.emailValue")}`}>
                    {t("contact.emailValue")}
                    </a>
                </span>
            </span>
            <div className="mt-4">
                <span className="d-block">{t("contact.companyName")}</span>
                <span className="d-block">{t("contact.address.street")}</span>
                <span className="d-block">{t("contact.address.city")}</span>
                <span className="d-block">{t("contact.address.country")}</span>
            </div>
            <h5 className="mt-5 mb-4 font-weight-bold font-size-normal">{t("contact.gdpr")}</h5>
            <span className="d-block">
                <span className="fas fa-envelope mr-3" aria-hidden="true" title={t("contact.gdpr.emailValue")} />
                <a className="txt-color-primary" href={`mailto:${t("contact.gdpr.emailValue")}`}>
                {t("contact.gdpr.emailValue")}
                </a>
            </span>
        </div>
        <Footer />
    </>
);
}

export default ContactContainer;
