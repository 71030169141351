import React from "react";
import moment from "moment";

import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import { TableCell } from "components";
import DiffTableCell from "components/tableCell/DiffTableCell";

const LargeColumns = () => {
  const { t } = useTranslation();

  const largeColumns: Column[] = [
    {
      Header: t("history.date"),
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      id: "date",
      accessor: (d: any) => {
        const changeDate = moment(new Date(d.date));
        let date = changeDate.format("YYYY-MM-DD");
        let time = changeDate.format("H:mm");
        return (
          <TableCell
            cssClassForFirst="font-size-normal txt-color-primary"
            cssClassForSecond="font-size-regular txt-color-primary"
            firstValue={date}
            secondValue={time}
          />
        );
      },
    },
    {
      Header: t("history.who_did_the_change"),
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      id: "changer",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal"
            cssClassForSecond="font-size-regular"
            firstValue={t("changer." + d.changer)}
            showSecond={d.user}
            secondValue={d.user}
          />
        );
      },
    },
    {
      Header: t("history.change_type"),
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      id: "change_type",
      accessor: (d: any) => {
        const secondValue = d.changeType === "user" ? d.relatedUser : `${d.location} - ${d.relatedLocation}`;
        return (
          <TableCell
            cssClassForFirst="font-size-normal"
            cssClassForSecond="font-size-regular"
            firstValue={t("change_type." + d.changeType)}
            secondValue={secondValue}
          />
        );
      },
    },
    {
      Header: t("history.diff"),
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      id: "changes",
      sortable: false,
      accessor: (d: any) => {
        return <DiffTableCell className="font-size-normal font-weight-normal" value={d.changes} />;
      },
    },
  ];

  return largeColumns;
};

export default LargeColumns;
