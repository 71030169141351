import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { State } from "models/StoreModel";
import { LocationUser } from "models/UserModel";
import AccountService from "services/AccountService";
import UserForm from "./UserForm";

interface RouteParams {
  userId: string;
}

export type ContainerTypes = "edit" | "add";

interface UserFormContainerParams {
  typeOfContainer: ContainerTypes;
}

const UserFormContainer: React.FC<UserFormContainerParams> = ({ typeOfContainer }) => {
  const { userId } = useParams<RouteParams>();
  const selectedLocation = useSelector((state: State) => state.account.selectedLocation);
  const [locationUser, setLocationUser] = useState<LocationUser | null>(null);

  if (!selectedLocation) {
    throw new Error("Invariant - for this route `selectedLocation` are required.");
  }

  const loadUser = async () => {
    try {
      const response = await AccountService.loadUser(userId, selectedLocation.id);
      const userWithExtraData = response.data;
      setLocationUser({ ...userWithExtraData });
    } catch (error) {
      // error side effect
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return <UserForm locationUser={locationUser} selectedLocation={selectedLocation} typeOfContainer={typeOfContainer} />;
};

export default UserFormContainer;
