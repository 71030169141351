import _ from "lodash";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Button } from "react-bootstrap";

import * as dataThunk from "store/thunk/DataThunk";
import * as dataActions from "store/actions/DataActions";
import { FiltersGroup, Footer, Header, Message, Messages, TablePagination } from "components";
import * as AccountThunk from "store/thunk/AccountThunk";
import { State, ThunkDispatch } from "models/StoreModel";
import { useMessage } from "hooks";
import { ScrollToTop } from "utils/ScrollUp";

import LargeColumns from "./UsersContainerColumnsL";
import SmallColumns from "./UsersContainerColumnsS";
import DeleteModal from "../../components/modals/DeleteModal";

export interface ColumnsProps {
  currentUser: any;
  editUser: (userId: any) => void;
  changeUserStatus: (userId: any) => void;
  setUserId: (id: string) =>  void;
  setModalShow: (isShow: boolean) => void;
}

export const UsersContainer: React.FC = () => {
  const { t } = useTranslation();

  const dataState = useSelector((state: State) => state.data);
  const currentUser = useSelector((state: State) => state.account.user);
  const selectedLocation = useSelector((state: State) => state.account.selectedLocation);
  const dispatch = useDispatch<ThunkDispatch>();
  const tableRef = useRef<ReactTable<any>>(null);
  const { addMessage } = useMessage();

  if (!currentUser || !selectedLocation) {
    throw new Error("Invariant - for this route `currentUser` and` selectedLocation` are required.");
  }

  const fetchData = (tableState: any) => {
    dispatch(
      dataThunk.getUsersForLocation(
        tableState.pageSize,
        tableState.page,
        tableState.sorted,
        tableState.filtered,
        selectedLocation.id,
      ),
    );
  };

  useEffect(() => {
    fetchData(tableRef);
  }, [selectedLocation]);

  const editUser = (userId: string) => {
    dispatch(dataThunk.loadUser(userId, selectedLocation.id));
  };

  const deleteUser = async (userId: string) => {
    try {
      await dispatch(AccountThunk.deleteUser(userId, selectedLocation.id));
      if (tableRef.current) {
        fetchData(tableRef.current.props);
      }
    } catch (error) {
      const status = _.get(error, "response.status");
      const apiErrMsg = _.get(error, "response.data");
      addMessage({
        type: "error",
        text: status === 400 && apiErrMsg ? apiErrMsg : error.message,
      });
      ScrollToTop();
    }
  };

  const changeUserStatus = (userId: string) => {
    dispatch(
      dataThunk.changeUserStatus(userId, selectedLocation.id, () => {
        if (tableRef.current) {
          fetchData(tableRef.current.props);
        }
      }),
    );
  };

  const cleanAddMessage = () => {
    dispatch(dataActions.CleanAddMessage);
  };

  const [userId, setUserId] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const screenWidth = window.innerWidth;

  const filterOptions = [
    { id: "full_name", value: t("filters.username"), type: "text" },
    { id: "email", value: t("filters.email"), type: "text" },
  ];

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-2">
        <div className="context-menu row align-items-baseline justify-content-between">
          <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">
            <Breadcrumb>
              <BreadcrumbItem active>{t("users.title")}</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div className="col-12 col-sm-6 text-sm-right text-center">
            <div className="context-menu-action ">
              <Button
                variant="link"
                className="font-size-regular txt-color-dusty-orange font-weight-bold txt-color-link  p-0"
                onClick={(e: any) => cleanAddMessage()}
                href="/users/add">
                <img className="mx-1 h-100" src="/icons/icon-form-small-expand.svg" alt="GS1 logo" />
                <span style={{ verticalAlign: "middle" }}>{t("users.add_user")}</span>
              </Button>
            </div>
          </div>
        </div>
        <Messages />
        {dataState.loadingError && (
          <Message type="error" text={t("errors.fetch_data")} dismissible={false} autoHideDuration={null} />
        )}
        <FiltersGroup className="mb-3" options={filterOptions} filtersKey="users" />
        <div className="table mt-4">
          <ReactTable
            columns={
              screenWidth > 768
                ? LargeColumns({
                    currentUser: currentUser,
                    editUser: editUser,
                    setUserId: setUserId,
                    setModalShow: setModalShow,
                    changeUserStatus: changeUserStatus,
                  })
                : SmallColumns({
                    currentUser: currentUser,
                    editUser: editUser,
                    setUserId: setUserId,
                    setModalShow: setModalShow,
                    changeUserStatus: changeUserStatus,
                  })
            }
            manual
            resizable={false}
            data={dataState.UserRows}
            pages={dataState.pages}
            loading={dataState.loading}
            onFetchData={fetchData}
            minRows={0}
            pageSizeOptions={[5, 10, 20, 25]}
            defaultPageSize={10}
            ref={tableRef}
            className={`-striped -highlight ${screenWidth > 768 && "bg-white"}`}
            PaginationComponent={TablePagination}
            filtered={dataState.filters["users"]}
            noDataText={t("no_data.text")}
          />
        </div>
        <DeleteModal section={t("suggestion.modalDeleteUser")} modalShow={modalShow} setModalShow={setModalShow} userId={userId} deleteUser={deleteUser}/>
      </div>
      <Footer />
    </>
  );
};

export default UsersContainer;
