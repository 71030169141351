import uuid from "uuid";

import React from "react";
import {Modal, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import { Section } from "pages/glnPassport/PassportMapping";

import "./DeleteModal.scss";

interface ModalProps {
  section: Section;
  modalShow: boolean;
  userId: string;
  deleteUser: (id:string)=> void;
  setModalShow: (isShow: boolean)=> void;
}

const DeleteModal: React.FC<ModalProps> = ({section, modalShow,setModalShow, userId, deleteUser}) => {
  const { t } = useTranslation();

  return (
    <Modal
      key={uuid()}
      centered={true}
      show={modalShow}
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title>{section}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button className={'cancelButton'} variant="primary" onClick={()=> {
          setModalShow(false)
        }}>
          {t("suggestion.cancel")}
        </Button>
        <Button  className={'deleteButton'} variant="primary" onClick={() => {
          deleteUser(userId);
          setModalShow(false)
        }}>
          {t("suggestion.delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
