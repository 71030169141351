import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import FileInput from "components/Inputs/FileInput/FileInput";
import CheckBox from "components/checkBox/CheckBox";

interface UserStatementProps {
  showIsRepresentativeError: () => boolean | undefined;
  showFileMessage: () => ReactNode;
  form: any;
  formErrors: any;
  setPartialForm: any;
}

const UserStatement: React.FC<UserStatementProps> = (props) => {
  const [t] = useTranslation();

  return (
    <>
      <div className="py-2">
        <div>
          <CheckBox
            value={props.form.isUserRepresentative}
            name="isUserRepresentative"
            label={t("register.form.step3.left.label.agreement")}
            showError={props.showIsRepresentativeError()}
            onChange={(checked) => props.setPartialForm({ isUserRepresentative: checked })}
          />
          <div className="error-label font-size-regular d-flex flex-row-reverse mb-2">
            {props.formErrors.isUserRepresentative !== null ? t("form.validation.required") : ""}
          </div>
        </div>
      </div>
      <span className="txt-color-bluish-grey my-1 font-size-regular">{props.showFileMessage()}</span>
      <FileInput
        name="representativeFile"
        value={props.form.representativeFile}
        changeHandler={props.setPartialForm}
        label={t("register.form.step3.left.label.attachement")}
      />
    </>
  );
};

export default UserStatement;
