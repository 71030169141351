import { ThunkResult } from "models/StoreModel";
import { CurrentUser } from "models/UserModel";
import AccountService from "services/AccountService";
import * as AccountActions from "store/actions/AccountActions";
import * as DataActions from "store/actions/DataActions";
import { attachRegisterFile } from "./RegisterThunk";

export function login(email: string, password: string, rememberMe: boolean): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch) => {
    await AccountService.login(email, password, rememberMe);
    const response = await AccountService.fetchCurrentUser();
    const user = response.data.user;
    dispatch(AccountActions.setSession(user));
    return user;
  };
}

export function fetchCurrentUser(): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch) => {
    const response = await AccountService.fetchCurrentUser();
    const user = response.data.user;
    dispatch(AccountActions.updateSession(user));
    return user;
  };
}

export function logout(): ThunkResult<void> {
  return (dispatch) => {
    dispatch(AccountActions.removeSession());
    AccountService.logout();
  };
}

export function saveMeAndUpdate(
  data: any,
  selectedLocationID: string,
  membershipID: string,
  withRegisterFile: boolean = false,
): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch) => {
    await AccountService.saveUser(data, selectedLocationID);
    if (withRegisterFile) {
      dispatch(attachRegisterFile(data.representativeFile, membershipID));
    }
    const user = await dispatch(fetchCurrentUser());
    return user;
  };
}

export function deleteUser(userId: string, selectedLocationId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    await AccountService.deleteUser(userId, selectedLocationId);
    // @ts-ignore - TODO - move action to account actions and refactor data actions / reducer
    dispatch(DataActions.DeleteUserSuccess({ userId }));
  };
}
