import { EditQuestionProps } from "../PassportUtils";
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import BootstrapSwitchButton from "bootstrap-switch-button-react/lib/bootstrap-switch-button-react";

const EditBooleanQuestion: React.FC<EditQuestionProps> = ({ field, form, handleUpdate }) => {
  const { t } = useTranslation();
  const valueFromObject = _.get(form, field.value);

  const [inputValue, setInputValue] = useState(valueFromObject);

  const updateInputValue = (value: boolean) => {
    setInputValue(value);
    _.set(form, field.value, value);
    handleUpdate(form);
  };

  return (
    <div className="font-size-normal justify-content-end  col-md-3 d-flex flex-column p-2 company-details">
      <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
        {t("passport.location." + field.key)}
      </label>
      <span className="font-size-normal details-value font-weight-bold">
        <BootstrapSwitchButton
          checked={inputValue}
          onlabel={t("passport.location.value.yes")}
          offlabel={t("passport.location.value.no")}
          onstyle={"success"}
          offstyle={"secondary"}
          onChange={updateInputValue}
        />
      </span>
    </div>
  );
};

export default EditBooleanQuestion;
