import { BaseAction } from "models/StoreModel";
import * as registerActions from "../actions/RegisterActions";

export interface RegistrationState {
  registerSuccess: boolean;
  registerFailure: boolean;
  savedRegisterFileSuccess: boolean;
  savedRegisterFileFailure: boolean;
  membership: string;
  regon: string;
}

export default (state = {}, action: BaseAction) => {
  switch (action.type) {
    case registerActions.SIGN_UP:
      return {
        ...action.payload,
        sendingForm: true,
      };
    case registerActions.REGISTRATION_INPROGRESS:
      return {
        ...state,
        registerSuccess: false,
        registerFailure: false,
      };
    case registerActions.REGISTRATION_SUCCESS:
      return {
        ...state,
        registerSuccess: true,
        registerFailure: false,
        membership: action.payload,
      };
    case registerActions.REGISTRATION_FAILURE:
      return {
        ...state,
        registerFailure: true,
        registerSuccess: false,
      };
    case registerActions.FILE_SAVE_SUCCESS:
      return {
        ...state,
        savedRegisterFileFailure: false,
        savedRegisterFileSuccess: true,
      };
    case registerActions.FILE_SAVE_FAILURE:
      return {
        ...state,
        savedRegisterFileSuccess: false,
        savedRegisterFileFailure: true,
      };
    case registerActions.CHECK_STATE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
