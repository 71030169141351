// Auth0 Docs: Where to Store Tokens
// https://auth0.com/docs/security/store-tokens
import jwtDecode from "jwt-decode";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import ReactGA from "react-ga";
import _ from "lodash";

interface TokenBody {
  exp: number;
  iat: number;
}

interface Tokens {
  access: string;
  refresh: string;
}

interface ClientHooks {
  onSessionExpired?: () => void;
  onTokenRefreshed?: (data: Tokens) => void;
}

/* Config */

const COOKIE_EXPIRES = 30; // 30 days
const CSRF_ENABLED = process.env.REACT_APP_CSRF_ENABLED || false;
const baseURL = process.env.REACT_APP_API_BASE;

if (!baseURL) {
  throw new Error("REACT_APP_API_BASE is not defined in .env file");
}

/* Http */

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

/* TODO: Probably we should replace this by adding parameters to the orginal URL before sending the event to backend */
/* Interceptors */
axiosInstance.interceptors.request.use((config) => {
  if (!_.isEmpty(config.params)) {
    ReactGA.pageview(window.location.pathname + "?" + new URLSearchParams(config.params).toString());
  }
  return config;
});

/* Exceptions */

class TokenNotFound extends Error {
  constructor(item: string) {
    super(`cookie "${item}" not found`);
  }
}

class RefreshTokenExpired extends Error {
  constructor() {
    super(`Refresh token expired`);
  }
}

/* Auth */

let _tokenIsRefreshing = false;
let _refreshTokenPromiseMemoized: Promise<AxiosResponse<Tokens>>;
let _hooks: ClientHooks = {};

function getAccessToken() {
  const token = Cookies.get("accessToken");
  if (!token) {
    throw new TokenNotFound("accessToken");
  }
  return token;
}

function getRefreshToken() {
  const token = Cookies.get("refreshToken");
  if (!token) {
    throw new TokenNotFound("refreshToken");
  }
  return token;
}

function isRememberMe() {
  return Cookies.get("rememberMe") === "true";
}

function setTokens(accessToken: string, refreshToken: string, rememberMe: boolean = false) {
  const attributes = rememberMe ? { expires: COOKIE_EXPIRES } : {};
  Cookies.set("accessToken", accessToken, attributes);
  Cookies.set("refreshToken", refreshToken, attributes);
  Cookies.set("rememberMe", rememberMe.toString(), attributes);
}

function removeTokens() {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  Cookies.remove("rememberMe");
}

function tokenExpired(token: string) {
  const tokenDecoded = jwtDecode<TokenBody>(token);
  const currentTime = new Date().getTime();
  return currentTime >= tokenDecoded.exp * 1000;
}

function accessTokenExpired() {
  return tokenExpired(getAccessToken());
}

function refreshTokenExpired() {
  return tokenExpired(getRefreshToken());
}

function authenticated() {
  try {
    return !refreshTokenExpired();
  } catch (e) {
    return false;
  }
}

async function authenticate(email: string, password: string, rememberMe: boolean) {
  const response = await request<Tokens>({
    url: "/token/",
    method: "POST",
    data: { email, password },
  });
  setTokens(response.data.access, response.data.refresh, rememberMe);
  return response;
}

function logout() {
  removeTokens();
}

async function refreshToken() {
  const refreshToken = getRefreshToken();
  const response = await request<Tokens>({
    url: "/token/refresh/",
    method: "POST",
    data: { refresh: refreshToken },
  });
  const rememberMe = isRememberMe();
  setTokens(response.data.access, response.data.refresh, rememberMe);
  _hooks.onTokenRefreshed && _hooks.onTokenRefreshed(response.data);
  return response;
}

function request<T>(config: AxiosRequestConfig) {
  return axiosInstance.request<T>(config);
}

async function privateRequest<T>(config: AxiosRequestConfig) {
  if (refreshTokenExpired()) {
    _hooks.onSessionExpired && _hooks.onSessionExpired();
    throw new RefreshTokenExpired();
  }

  if (accessTokenExpired()) {
    // support multiple async requests
    if (!_tokenIsRefreshing) {
      // new promise with feedback
      _tokenIsRefreshing = true;
      _refreshTokenPromiseMemoized = refreshToken();
      try {
        await _refreshTokenPromiseMemoized;
        _tokenIsRefreshing = false;
      } catch (error) {
        _tokenIsRefreshing = false;
        throw error;
      }
    } else if (_refreshTokenPromiseMemoized) {
      // current promise without feedback
      await _refreshTokenPromiseMemoized;
    }
  }

  const accessToken = getAccessToken();
  config.headers = config.headers || {};
  config.headers["Authorization"] = `Bearer ${accessToken}`;

  if (CSRF_ENABLED && config.method !== "GET") {
    const csrfToken = Cookies.get("csrftoken");
    config.headers["X-CSRFToken"] = csrfToken;
    config.withCredentials = true;
  }

  return request<T>(config);
}

function setHooks(newHooks: ClientHooks) {
  _hooks = {
    ..._hooks,
    ...newHooks,
  };
}

/* Exports */

const instances = {
  axios: axiosInstance,
};

const exceptions = {
  TokenNotFound,
  RefreshTokenExpired,
};

const GS1APIClient = {
  // Public API
  authenticated,
  isRememberMe,
  authenticate,
  logout,
  request,
  privateRequest,
  setHooks,
  exceptions,
  // Private
  _instances: instances,
  _getAccessToken: getAccessToken,
  _getRefreshToken: getRefreshToken,
  _accessTokenExpired: accessTokenExpired,
  _refreshTokenExpired: refreshTokenExpired,
  _setTokens: setTokens,
  _removeTokens: removeTokens,
  _refreshToken: refreshToken,
};

export default GS1APIClient;
