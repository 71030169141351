import React from "react";
import moment from "moment";

import { useTranslation } from "react-i18next";
import DiffTableCell from "components/tableCell/DiffTableCell";

const SmallColumns = () => {
  const { t } = useTranslation();

  const smallColumns = [
    {
      headerClassName: "d-none",
      id: "gln_number",
      accessor: (d: any) => {
        const changeDate = moment(new Date(d.date));
        let date = changeDate.format("YYYY-MM-DD");
        let time = changeDate.format("H:mm");
        const secondValue = d.changeType === "user" ? d.relatedUser : `${d.location} - ${d.relatedLocation}`;
        return (
          <div className="d-flex flex-column bg-white p-3 my-3">
            <div className="d-flex container pr-0 justify-content-between my-1">
              <div className="row w-100">
                <div className="col-8 pl-0 pr-2 d-flex flex-column">
                  <span className="font-size-normal font-weight-bold txt-color-marine-blue mb-2">
                    {t("changer." + d.changer)}
                  </span>
                  <span className="font-size-regular txt-color-borders">{d.user}</span>
                </div>
                <div className="col-4 pr-0 pl-2 d-flex flex-column align-items-end">
                  <span className="font-size-regular txt-color-borders font-weight-bold">{date}</span>
                  <span className="font-size-regular txt-color-borders font-weight-bold">{time}</span>
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex my-1">
              <div className="d-flex flex-column">
                <span className="font-size-normal font-weight-bold txt-color-marine-blue mb-2">
                  {t("change_type." + d.changeType)}
                </span>
                <span className="font-size-regular txt-color-borders">{secondValue}</span>
              </div>
            </div>
            <div className="d-flex my-1">
              <div className="container">
                <div className="row">
                  <div className="col-auto px-0">
                    <DiffTableCell className="font-size-normal" value={d.changes} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return smallColumns;
};

export default SmallColumns;
