import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";

import GS1APIClient from "clients/GS1APIClient";
import { ThunkMiddleware } from "models/StoreModel";
import rootReducer from "./reducers/RootReducer";
import { initialAccountState } from "./reducers/AccountReducer";
import { initialDataState } from "./reducers/DataReducer";
import { logout } from "./thunk/AccountThunk";

export function configureStore() {
  const preloadState = {
    account: {
      ...initialAccountState,
      authenticated: GS1APIClient.authenticated(),
    },
    data: {
      ...initialDataState,
    },
  };

  const store = createStore(
    rootReducer,
    // @ts-ignore - armed redux State and implement initialState in reducers
    preloadState,
    composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware)),
  );

  const persistor = persistStore(store);

  GS1APIClient.setHooks({
    onSessionExpired: () => {
      store.dispatch(logout());
    },
  });

  return { store, persistor };
}
