import React from "react";
import { NumberInput } from "../../../index";
import Button from "@material-ui/core/Button";
import { FormProps } from "../../WizardSteps";
import { useTranslation } from "react-i18next";

const RegonCheck = (props: FormProps) => {
  const [t] = useTranslation();

  const goNext = () => {
    props.handleNext();
  };

  return (
    <div className="container d-flex ">
      <div className="row mb-5">
        <div className="col-12 container">
          <div className="row border-top-3-dusty-orange">
            <div className="d-flex flex-column font-size-normal col-xs-12 col-md-6 bg-white txt-color-bluish-grey p-4">
              <h5 className=" font-size-normal txt-color-marine-blue">{t("registration.regon_title.company_data")}</h5>
              <hr />
              <NumberInput
                label={t("companyInformation.form.left.regon")}
                inputType="text"
                value={props.form.regon}
                name="regon"
                required={true}
                handleChange={props.setPartialForm}
                errors={props.formErrors}
              />
              <div className="d-flex w-100 justify-content-around">
                <Button
                  className="my-4 rounded-0 font-weight-bold bg-dusty-orange"
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  onClick={goNext}>
                  {t("navigation.stepper.verify")}
                </Button>
              </div>
            </div>
            <div className="d-flex flex-column col-xs-12 col-md-6 bg-white txt-color-bluish-grey justify-content-center p-4">
              <p className="my-auto mx-2  font-size-regular">
                {t("register.regon.step.help_text.autorize_companies")} <br />
                <br />
                {t("register.regon.step.help_text.basic_companies")} <br />
                <br />
                {t("register.regon.step.help_text.verification")}
                {t("register.authorize")}
                {t("register.or") + " "}
                {t("register.basic")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegonCheck;
