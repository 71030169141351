import validator from "validator";
import _ from "lodash";
import { RegonValidator } from "./RegonValidator";
import NipValidator from "./NipValidator";
import moment from "moment";

export type Rule =
  | "isRequired"
  | "isEmail"
  | "isRegon"
  | "isNip"
  | "IsLengthOptions"
  | "isFileAdded"
  | "isPostalCode"
  | "isMobilePhone"
  | "isCheckbox"
  | "isBool"
  | "requireWhenParent"
  | "requireWhenOnOfParentsTrue"
  | "rangeLessThenParent"
  | "rangeToLessThenFrom"
  | "rangeRequireWhen"
  | "timeRequired"
  | "timeLTE"
  | "timeGTE"
  | "timeBetween"
  | "timeRequiredWhen"
  | "rangeToLessThenFromNotEmpty"
  | "rangeLessThenParentNotEmpty"
  | "isGsonePhoneNumber";

export type RuleFn = (value: any) => boolean;

export interface RuleConfig {
  rule: Rule | RuleFn;
  options?: {};
  message: string;
}

export interface FieldsConfig {
  [field: string]: RuleConfig[];
}

export interface FieldValues {
  [field: string]: any;
}

interface WeakMap<K extends object, V> {
  delete(key: K): boolean;
  get(key: K): V | undefined;
  has(key: K): boolean;
  set(key: K, value: V): this;
}

export interface ValidateResult {
  errors: {
    [field: string]: string | null;
  };
  isValid: boolean;
}

export default class FormValidator<A> {
  static sanitizeString(value: any): string {
    return String(value).trim();
  }

  fields: FieldsConfig = {};
  formValues: {};
  regonValidator: { validate: (val: string) => boolean };
  nipValidator: { validate: (val: string) => boolean };

  constructor(fields: FieldsConfig) {
    this.fields = fields;
    this.formValues = {};
    this.regonValidator = RegonValidator();
    this.nipValidator = NipValidator();
  }

  validateRule(ruleConfig: RuleConfig, value: any): boolean {
    if (typeof ruleConfig.rule === "function") {
      return ruleConfig.rule(value);
    }
    let parentValue;
    let parentFromValue;
    let parentToValue;
    switch (ruleConfig.rule) {
      case "isGsonePhoneNumber":
        let pluses = "";
        for (let ch of value.split("")) {
          if (ch === "+") {
            pluses = pluses + ch;
          }
        }
        return value.startsWith("+") && pluses.length === 1;
      case "isBool":
        return _.isBoolean(value);
      case "isCheckbox":
        return value;
      case "isPostalCode":
        return validator.isPostalCode(value, "PL");
      case "isRequired":
        return !_.isEmpty(value);
      case "isFileAdded":
        return !!(value && value.name);
      case "isEmail":
        return validator.isEmail(value, { allow_utf8_local_part: false });
      case "isRegon":
        return this.regonValidator.validate(value);
      case "isNip":
        return this.nipValidator.validate(value);
      case "IsLengthOptions":
        if (!value) return true;
        return validator.isLength(value, ruleConfig.options as ValidatorJS.IsLengthOptions);
      case "rangeLessThenParentNotEmpty":
        if (!value || value === "-") { return true }
        // @ts-ignore
        parentValue = _.get(this.formValues, ruleConfig.options.parentElement);
        value = value.split("-");
        if (parentValue === 0 || parentValue === "") {
          return true;
        }
        return parseInt(value[0]) <= parseInt(parentValue) && parseInt(value[1]) <= parseInt(parentValue);
      case "rangeToLessThenFromNotEmpty":
        if (!value || value === "-") { return true }
        value = value.split("-");
        return parseInt(value[0]) <= parseInt(value[1]);
      case "rangeLessThenParent":
        // @ts-ignore
        parentValue = _.get(this.formValues, ruleConfig.options.parentElement);
        if (!value && parentValue) {
          return false;
        }
        value = value.split("-");
        if (parentValue === 0 || parentValue === "") {
          return true;
        }
        return parseInt(value[0]) <= parseInt(parentValue) && parseInt(value[1]) <= parseInt(parentValue);
      case "rangeToLessThenFrom":
        // @ts-ignore
        value = value.split("-");
        return parseInt(value[0]) <= parseInt(value[1]);
      case "rangeRequireWhen":
        // @ts-ignore
        parentValue = _.get(this.formValues, ruleConfig.options.parentElement);
        if (parentValue === 0 || parentValue === "") {
          return true;
        }
        return parentValue > 0 && value;
      case "requireWhenParent":
        // @ts-ignore
        parentValue = _.get(this.formValues, ruleConfig.options.parentElement);
        // @ts-ignore
        let conditionValue = _.get(this.formValues, ruleConfig.options.parentElement);
        // @ts-ignore
        if (ruleConfig.options.otherValue !== undefined && parentValue === "other") {
          // @ts-ignore
          const otherValue = _.get(this.formValues, ruleConfig.options.otherValue);
          return parentValue === conditionValue && !_.isEmpty(value) && !_.isEmpty(otherValue);
        }
        return parentValue === conditionValue && !_.isEmpty(value);
      case "requireWhenOnOfParentsTrue":
        // @ts-ignore
        let parentCondition: boolean[] = _.map(ruleConfig.options.parentsElement, (parentElement) => {
          return _.get(this.formValues, parentElement);
        });
        return parentCondition.indexOf(true) !== -1 && !_.isEmpty(value);
      case "timeRequired":
        return !!value;
      case "timeLTE":
        // @ts-ignore
        parentValue = _.get(this.formValues, ruleConfig.options.parentElement);
        if (!parentValue && !value) return true;
        parentValue = moment(parentValue, "H:mm");
        value = moment(value, "H:mm");
        return value <= parentValue;
      case "timeGTE":
        // @ts-ignore
        parentValue = _.get(this.formValues, ruleConfig.options.parentElement);
        if (!parentValue && !value) return true;
        parentValue = moment(parentValue, "H:mm");
        value = moment(value, "H:mm");
        return value >= parentValue;
      case "timeBetween":
        // @ts-ignore
        parentFromValue = _.get(this.formValues, ruleConfig.options.parentFromElement);
        // @ts-ignore
        parentToValue = _.get(this.formValues, ruleConfig.options.parentToElement);
        if (!parentFromValue && !parentToValue && !value) return true;
        parentFromValue = moment(parentFromValue, "H:mm");
        parentToValue = moment(parentToValue, "H:mm");
        value = moment(value, "H:mm");
        return value >= parentFromValue && value <= parentToValue;
      case "timeRequiredWhen":
        // @ts-ignore
        parentValue = _.get(this.formValues, ruleConfig.options.parentElement);
        if (typeof parentValue === "boolean") {
          return !(parentValue && value === "");
        }
        return !(parentValue !== "" && value === "");
      default:
        return false;
    }
  }

  validateField(fieldName: string, value: any): string | null {
    const rules = this.fields[fieldName];
    if (rules !== undefined) {
      for (let ruleConfig of rules) {
        let isValid = this.validateRule(ruleConfig, value);
        if (!isValid) {
          return ruleConfig.message;
        }
      }
    }

    return null;
  }

  validate(values: FieldValues): ValidateResult {
    const result: ValidateResult = {
      errors: {},
      isValid: true,
    };

    for (let [field, value] of Object.entries(values)) {
      let message = this.validateField(field, value);
      result.errors[field] = message;

      if (message) {
        result.isValid = false;
      }
    }

    return result;
  }

  validateNested(values: FieldValues): ValidateResult {
    const result: ValidateResult = {
      errors: {},
      isValid: true,
    };
    this.formValues = values;
    let nestedValues = {};
    for (let [field, value] of Object.entries(this.fields)) {
      // @ts-ignore
      _.set(nestedValues, field, _.get(values, value[0].options.value));
    }

    for (let [field, value] of Object.entries(nestedValues)) {
      let message = this.validateField(field, value);
      result.errors[field] = message;

      if (message) {
        result.isValid = false;
      }
    }

    return result;
  }
}
