import React from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

interface NotFoundProps {
  regon: string;
  onNext: () => void;
  onBack: () => void;
}

export const RegonNotFound = (props: NotFoundProps) => {
  const [t] = useTranslation();
  return (
    <div className="d-flex container">
      <div className="row mb-5">
        <div className="col-12 container mx-auto">
          <div className="row">
            <div className="border-top-3-dusty-orange d-none d-md-flex col-md-6 bg-white flex-column justify-content-between">
              <div className="d-flex w-100 h-100 justify-content-center">
                <img className="w-100" src="/gs1_register_image.svg" alt="placeholder" />
              </div>
            </div>
            <div className="bg-white col-md-6 border-top-3-dusty-orange">
              <h5 className="txt-color-marine-blue pb-2 pt-4 font-size-normal ">
                {t("register.regonVerification.heading")}
              </h5>
              <hr />
              <h4 className="py-3 font-size-normal txt-colo-marine-blue">
                {t("register.regonVerification.subheadingNotFound")}
              </h4>
              <p className="py-2 font-weight-bold font-size-normal  txt-color-greyish-brown">{props.regon}</p>
              <p className="font-size-regular txt-color-error">{t("register.form.regonNotFound")}</p>
              <p className="py-2 font-size-regular txt-color-greyish-brown">
                {t("register.form.regon_not_found.help_text.company_not_found")} <br />
                <br />
                {t("register.form.regon_not_found.help_text.phone_number")} <br />
                <br />
                {t("register.form.regon_not_found.help_text.register_as_basic_company")}
              </p>
              <div className="d-flex flex-column">
                <div className="py-2 d-flex">
                  <Button
                    className="mx-auto bg-dusty-orange rounded-0 font-size-regular font-weight-bold "
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    onClick={props.onNext}>
                    {t("register.basic.button")}
                  </Button>
                </div>
                <div className="py-2 d-flex">
                  <Button
                    className="bg-white txt-color-dusty-orange mx-auto rounded-0 bg-white font-size-regular font-weight-bold "
                    variant="contained"
                    fullWidth={true}
                    onClick={props.onBack}>
                    <span className="back-chevron">{t("register.back_button")}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegonNotFound;
