import { combineReducers } from "redux";

import accountReducer from "./AccountReducer";
import dataReducer from "./DataReducer";
import messageReducer from "./MessageReducer";
import registrationReducer from "./RegisterReducer";
import PWAreducer from "./PWAreducer";

const rootReducer = combineReducers({
  account: accountReducer,
  data: dataReducer,
  message: messageReducer,
  registration: registrationReducer,
  PWA: PWAreducer,
});

export default rootReducer;
