import _ from "lodash";

import { GS1Location } from "models/LocationModel";
import { Membership } from "models/UserModel";

export function getLocationName(location: GS1Location | null, placeholder = "") {
  if (location) {
    if (location.locationExtraDetails && location.locationExtraDetails.name) {
      // short name
      return location.locationExtraDetails.name;
    }

    if (location.fullName) {
      return _.truncate(location.fullName, { length: 24 });
    }
  }

  return placeholder;
}

export function findMemberByLocation(location: GS1Location, membership: Membership[]) {
  const member = membership.find((member) => member.location.id === location.id);
  return member;
}
