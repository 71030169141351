import _ from "lodash";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { State } from "models/StoreModel";
import { attachRegisterFile, signUp } from "store/thunk/RegisterThunk";
import { getUserByEmail } from "store/thunk/DataThunk";
import { ScrollToTop } from "utils/ScrollUp";
import { useFormValidator } from "../../hooks";
import Agreements from "./steps/agreements/Agreements";
import { RegonCheck, RegonFound, RegonNotFound, RegonWithUserFound } from "./steps/regonCheck/index";
import CompanyInformation from "./steps/companyInformation/CompanyInformation";
import UserInformation from "./steps/userInformation/UserInformation";
import { Header, Message, Footer } from "components";

import "./WizardSteps.scss";
import { BackendService } from "../../services";
import { AxiosResponse } from "axios";

export interface FormFields {
  id: string;
  regon: string;
  nip: string;
  fullName: string;
  name: string;
  companyProfile: { [key: string]: string }[];
  companyProfileOther: string;
  address: string;
  city: string;
  postalCode: string;
  postalCity: string;
  district: { [key: string]: string }[];
  country: { [key: string]: string }[];
  phoneNumber: string;
  internalNumber: string;
  companyEmail: string;
  homePage: string;
  agreeToSellingEmails: boolean | undefined;
  agreeToSellingPhones: boolean | undefined;
  agreeToMarketingEmails: boolean | undefined;
  agreeToMarketingPhones: boolean | undefined;
  agreeToSharingData: boolean | undefined;
  userAgreeToSellingEmails: boolean | undefined;
  userAgreeToSellingPhones: boolean | undefined;
  userAgreeToMarketingEmails: boolean | undefined;
  userAgreeToMarketingPhones: boolean | undefined;
  userAgreeToSharingData: boolean | undefined;
  userName: string;
  userSurname: string;
  occupation: string;
  isUserRepresentative: boolean;
  representativeFile: File | undefined;
  userEmail: string;
  userInternalNumber: string;
  userPhone: string;
  OWU: boolean | undefined;
}

export interface FormProps {
  form: FormFields;
  formErrors: { [key: string]: string };
  handleNext: (values?: any) => void;
  handlePrevious: () => void;
  setPartialForm: (val: any) => void;
  disableNextButton?: boolean;
  foundRegon?: boolean;
}

const STEPS = ["Weryfikacja", "Dane Firmy", "Dane Wnioskodawcy", "Podumowanie i zgody"];

const initialForm = {
  id: "",
  regon: "",
  nip: "",
  fullName: "",
  name: "",
  companyProfile: [],
  companyProfileOther: "",
  address: "",
  city: "",
  postalCode: "",
  postalCity: "",
  district: [],
  country: [],
  phoneNumber: "",
  internalNumber: "",
  companyEmail: "",
  homePage: "",
  agreeToSellingEmails: undefined,
  agreeToSellingPhones: undefined,
  agreeToMarketingEmails: undefined,
  agreeToMarketingPhones: undefined,
  agreeToSharingData: undefined,
  userAgreeToSellingEmails: false,
  userAgreeToSellingPhones: false,
  userAgreeToMarketingEmails: false,
  userAgreeToMarketingPhones: false,
  userAgreeToSharingData: false,
  userName: "",
  userSurname: "",
  occupation: "",
  isUserRepresentative: false,
  representativeFile: undefined,
  userEmail: "",
  userInternalNumber: "",
  userPhone: "",
  OWU: undefined,
};

const WizardSteps: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state: State) => state);

  const [foundRegon, setFoundRegon] = useState<boolean | undefined>(undefined);
  const [hasUser, setHasUser] = useState(false);

  const [verification, setVerification] = useState({
    passed: false,
  });

  const [activeStep, setActiveStep] = useState(0);
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [form, setForm] = useState({ ...initialForm });
  const [disableAfterSubmit, setDisableAfterSubmit] = useState(false);

  const [regonStepErrors, setRegonStepErrors, validateRegonStep] = useFormValidator(
    {
      regon: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isRegon", message: t("form.validation.wrongRegon") },
      ],
    },
    [t],
  );

  const [addressRequired, setAddressRequired] = useState(true);
  const [phoneRequired, setPhoneRequired] = useState(true);
  const [districtRequired, setDistrictRequired] = useState(true);
  const [countryRequired, setCountryRequired] = useState(true);
  const [emailRequired, setEmailRequired] = useState(true);

  const setRequiredFields = useCallback((foundRegon, location) => {
    if (foundRegon !== undefined) {
      setAddressRequired(!foundRegon || (foundRegon && location !== null && !_.isEmpty(location.address)));
      setPhoneRequired(!foundRegon);
      setDistrictRequired(!foundRegon || (foundRegon && location !== null && !_.isEmpty(location.district)));
      setCountryRequired(!foundRegon || (foundRegon && location !== null && !_.isEmpty(location.country)));
      setEmailRequired(!foundRegon || (foundRegon && location !== null && !_.isEmpty(location.companyEmail)));
      toggleVerification();
    }
  }, []);

  const [companyInformationErrors, setCompanyInformationErrors, validateCompanyInformationStep] = useFormValidator(
    // ulica, wojewodztwo i kraj
    {
      regon: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isRegon", message: t("form.validation.wrongRegon") },
      ],
      nip: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isNip", message: t("form.validation.wrongNip") },
        {
          rule: "IsLengthOptions",
          options: { min: 10, max: 10 },
          message: t("form.validation.wrong_length"),
        },
      ],
      fullName: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 150 },
          message: t("form.validation.hasToBeLessThan150"),
        },
      ],
      name: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 150 },
          message: t("form.validation.hasToBeLessThan150"),
        },
      ],
      companyProfile: [{ rule: "isRequired", message: t("form.validation.required") }],
      companyProfileOther: [],
      address: addressRequired
        ? [
            { rule: "isRequired", message: t("form.validation.required") },
            {
              rule: "IsLengthOptions",
              options: { min: 1, max: 150 },
              message: t("form.validation.hasToBeLessThan150"),
            },
          ]
        : [],
      city: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 150 },
          message: t("form.validation.hasToBeLessThan50"),
        },
      ],
      postalCode: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "isPostalCode",
          message: t("form.validation.postalCode_format"),
        },
      ],
      postalCity: [
        {
          rule: "IsLengthOptions",
          options: { min: 0, max: 150 },
          message: t("form.validation.hasToBeLessThan50"),
        },
      ],
      district: districtRequired ? [{ rule: "isRequired", message: t("form.validation.required") }] : [],
      country: countryRequired ? [{ rule: "isRequired", message: t("form.validation.required") }] : [],
      phoneNumber: phoneRequired
        ? [
            { rule: "isRequired", message: t("form.validation.required") },
            { rule: "isGsonePhoneNumber", message: t("form.validation.phone_format") },
            {
              rule: "IsLengthOptions",
              options: { min: 9, max: 15 },
              message: t("form.validation.hasToBeLessThan15AndMoreThan9"),
            },
          ]
        : [],
      internalNumber: [
        {
          rule: "IsLengthOptions",
          options: { min: 0, max: 32 },
          message: t("form.validation.hasToBeLessThan32"),
        },
      ],
      companyEmail: emailRequired
        ? [
            { rule: "isRequired", message: t("form.validation.required") },
            { rule: "isEmail", message: t("form.validation.email_format") },
          ]
        : [],
      homePage: [
        {
          rule: "IsLengthOptions",
          options: { min: 0, max: 128 },
          message: t("form.validation.hasToBeLessThan128"),
        },
      ],
    },
    [t, addressRequired, districtRequired, countryRequired, emailRequired, foundRegon],
  );

  const [userInformationErrors, setUserInformationErrors, validateUserInformationStep] = useFormValidator(
    {
      userName: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 50 },
          message: t("form.validation.hasToBeLessThan50"),
        },
      ],
      userSurname: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 50 },
          message: t("form.validation.hasToBeLessThan50"),
        },
      ],
      occupation: [{ rule: "isRequired", message: t("form.validation.required") }],
      isUserRepresentative: [{ rule: "isCheckbox", message: t("form.validation.required") }],
      representativeFile: [],
      userEmail: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isEmail", message: t("form.validation.email_format") },
      ],
      userInternalNumber: [],
      userPhone: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isGsonePhoneNumber", message: t("form.validation.phone_format") },
        {
          rule: "IsLengthOptions",
          options: { min: 9, max: 15 },
          message: t("form.validation.hasToBeLessThan15AndMoreThan9"),
        },
      ],
    },
    [t, form.isUserRepresentative],
  );

  const [agreementsFormErrors, setAgreementsFormErrors, validateAgreementsForm] = useFormValidator(
    {
      agreeToSellingEmails: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToSellingPhones: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToMarketingEmails: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToMarketingPhones: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToSharingData: [{ rule: "isBool", message: t("form.validation.required") }],
      OWU: [{ rule: "isCheckbox", message: t("form.validation.required") }],
    },
    [t],
  );

  const setPartialForm = useCallback((partialForm: any) => {
    if (partialForm.hasOwnProperty("userEmail")) {
      dispatch(getUserByEmail(partialForm.userEmail));
    }
    setForm((prevState) => ({ ...prevState, ...partialForm }));
  }, []);

  const toggleVerification = useCallback(() => {
    setVerification((prevState) => {
      return { ...prevState, passed: !prevState.passed };
    });
  }, []);

  const nextWhenRegonWithUserFound = () => {
    setUserConfirmed((prevState: boolean) => !prevState);
    ScrollToTop();
  };

  const basicNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    ScrollToTop();
  }, []);

  const basicBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    ScrollToTop();
  }, []);

  const setFormData = useCallback((formData: any, regonFound: boolean) => {
    if (regonFound) {
      const district = _.get(formData, ["district"], "");
      const country = _.get(formData, ["country"], "");

      const selectedProfile =
        formData !== null
          ? formData.companyProfile.map((profile: any) => ({
              id: profile,
            }))
          : [];
      const selectedDistrict = district ? [{ id: district.toLowerCase() }] : [];
      const selectedCountry = country ? [{ id: country.toLowerCase() }] : [];
      setForm((prevState) => ({
        ...initialForm,
        ...formData,
        companyProfile: selectedProfile as [],
        district: selectedDistrict as [],
        country: selectedCountry as [],
        agreeToSellingEmails: undefined,
        agreeToSellingPhones: undefined,
        agreeToMarketingEmails: undefined,
        agreeToMarketingPhones: undefined,
        agreeToSharingData: undefined,
        representativeFile: undefined,
        OWU: undefined,
      }));
    } else if (!regonFound) {
      setForm((prevState) => ({
        ...initialForm,
        regon: prevState.regon,
      }));
    }
  }, []);

  const handleNextRegonCheck = async () => {
    // @ts-ignore
    const isValid = validateRegonStep({ regon: form.regon });
    if (isValid) {
      try {
        const response: AxiosResponse = await BackendService.location.checkRegon(form.regon);
        const location = {
          id: response.data.location.id,
          fullName: response.data.location.fullName,
          ...response.data.location.locationExtraDetails,
          externalId: response.data.location.externalId,
        };
        setHasUser(response.data.hasUser);
        setFoundRegon(true);
        setFormData(location, true);
        setRequiredFields(true, location);
      } catch (error) {
        setFoundRegon(false);
        setFormData(null, false);
        setRequiredFields(false, null);
      }
    }
  };

  const handleCompanyInformationStep = () => {
    // @ts-ignore
    const isValid = validateCompanyInformationStep(form);
    if (isValid) {
      basicNext();
    }
  };

  const handleUserInformationStep = () => {
    // @ts-ignore
    if (validateUserInformationStep(form)) {
      basicNext();
    }
  };

  const sendForm = () => {
    // @ts-ignore
    const isValid = validateAgreementsForm(form);
    if (isValid) {
      let selectedDistrict: string = "";
      if (!_.isEmpty(form.district)) {
        const { id } = form.district[0];
        selectedDistrict = id;
      }

      let selectedCompanyProfile: string[] = [];
      if (!_.isEmpty(form.companyProfile)) {
        const { id } = form.companyProfile[0];
        selectedCompanyProfile = [id];
      }

      let selectedCountry = "";
      if (!_.isEmpty(form.country)) {
        const { id } = form.country[0];
        selectedCountry = id;
      }

      const formToSend = {
        isCompanyRepresentative: form.isUserRepresentative,
        userPosition: form.occupation,
        userPhoneNumber: form.userPhone,
        userInternalNumber: form.userInternalNumber,
        user: {
          email: form.userEmail.toLowerCase(),
          firstName: form.userName,
          lastName: form.userSurname,
        },
        location: {
          fullName: form.fullName,
          confirmedStatute: form.OWU,
          locationExtraDetails: {
            regon: form.regon,
            name: form.name,
            nip: form.nip,
            address: form.address,
            postalCode: form.postalCode,
            postalCity: form.postalCity,
            city: form.city,
            district: selectedDistrict,
            country: selectedCountry,
            phoneNumber: form.phoneNumber,
            internalNumber: form.internalNumber,
            homePage: form.homePage,
            companyEmail: form.companyEmail,
            companyProfile: selectedCompanyProfile,
            companyProfileOther: form.companyProfileOther,
            agreeToSellingEmails: form.agreeToSellingEmails,
            agreeToSellingPhones: form.agreeToSellingPhones,
            agreeToMarketingEmails: form.agreeToMarketingEmails,
            agreeToMarketingPhones: form.agreeToMarketingPhones,
            agreeToSharingData: form.agreeToSharingData,
          },
        },
      };
      setDisableAfterSubmit(true);
      dispatch(signUp(formToSend));
      // redirect - use history.replace() instead of <Redirect /> component
      // add success message
      // add failure message
    }
  };

  useEffect(() => {
    if (!!(form.isUserRepresentative && form.representativeFile !== undefined && state.registration.registerSuccess)) {
      dispatch(attachRegisterFile(form.representativeFile as any, state.registration.membership));
    }
  }, [state.registration.registerSuccess]);

  const shouldRedirect = () => {
    if (state.registration.registerSuccess) return true;
    if (hasUser && verification.passed && userConfirmed) return true;
    else
      return (
        form.isUserRepresentative && state.registration.registerSuccess && state.registration.savedRegisterFileSuccess
      );
  };

  useEffect(() => {
    if (state.registration.registerFailure) {
      ScrollToTop();
      setDisableAfterSubmit(false);
    }
  }, [state.registration.registerFailure]);

  return shouldRedirect() ? (
    <Redirect to={"/login"} />
  ) : (
    <>
      <Header withBottom={false} withTitle={t("register.container.title")} />
      <div className="container mx-auto">
        {// TODO: - Messages
        state.registration.registerFailure ? <Message type={"error"} text={t("register.something.wrong")} /> : <></>}
        <Stepper className="bg-white-two mx-auto px-0 row" activeStep={activeStep} alternativeLabel>
          {STEPS.map((label, index) => {
            return (
              <Step key={label} className="mx-auto col-3 col-sm-3 col-md-2 col-lg-2 col-xlg-4">
                <StepLabel className="font">
                  <span
                    className={
                      "font-size-small font-size-sm-normal " +
                      (index === activeStep ? "currentStep" : activeStep > index ? "pastStep" : "")
                    }>
                    {label}
                  </span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 ? (
          verification.passed ? (
            !foundRegon ? (
              <RegonNotFound regon={form.regon} onNext={basicNext} onBack={toggleVerification} />
            ) : hasUser ? (
              <RegonWithUserFound regon={form.regon} onNext={nextWhenRegonWithUserFound} onBack={toggleVerification} />
            ) : (
              <RegonFound name={form.fullName} onNext={basicNext} onBack={toggleVerification} />
            )
          ) : (
            <RegonCheck
              form={form}
              formErrors={regonStepErrors}
              handleNext={handleNextRegonCheck}
              handlePrevious={basicBack}
              setPartialForm={setPartialForm}
            />
          )
        ) : activeStep === 1 ? (
          <CompanyInformation
            form={form}
            foundRegon={foundRegon}
            formErrors={companyInformationErrors}
            handleNext={handleCompanyInformationStep}
            handlePrevious={basicBack}
            setPartialForm={setPartialForm}
          />
        ) : activeStep === 2 ? (
          <UserInformation
            form={form}
            formErrors={userInformationErrors}
            handleNext={handleUserInformationStep}
            handlePrevious={basicBack}
            setPartialForm={setPartialForm}
          />
        ) : (
          activeStep === 3 && (
            <Agreements
              form={form}
              formErrors={agreementsFormErrors}
              handleNext={sendForm}
              disableNextButton={disableAfterSubmit}
              handlePrevious={basicBack}
              setPartialForm={setPartialForm}
            />
          )
        )}
      </div>
      <Footer />
    </>
  );
};

export default WizardSteps;
