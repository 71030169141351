import React from "react";
import "../Input.scss";

interface FileInputProps {
  changeHandler: (value: { [key: string]: any }) => void;
  name?: string;
  value: any;
  className?: string;
  accept?: string;
  label: string;
}

const FileInput: React.FC<FileInputProps> = ({
  name = "",
  value = "",
  className = "",
  changeHandler,
  accept = "image/*, .pdf",
  label = "",
}) => {
  const onChangeHandler = (e: any) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      if (file.size < 5000000) {
        changeHandler({ [name]: e.target.files[0] });
      }
    } else {
      changeHandler({ [name]: {} });
    }
  };

  return (
    <>
      <div className={"d-flex flex-column justify-content-end " + className}>
        <input
          type="file"
          id="file"
          name={name}
          className="inputfile d-flex text-center"
          style={{ height: "73px" }}
          onChange={onChangeHandler}
          accept={accept}
        />
        <label
          style={{ border: "1px dotted rgb(178, 180, 180)" }}
          className="d-flex justify-content-center font-size-regular"
          htmlFor="file">
          <img className="mx-1" height={20} width={20} src="/icons/Enable.svg" alt="upload file icon" />
          {label}
        </label>
      </div>
    </>
  );
};

export default FileInput;
