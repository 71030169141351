import { EditQuestionProps } from "../PassportUtils";
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const EditRangeQuestion: React.FC<EditQuestionProps> = ({ field, errors, form, handleUpdate }) => {
  const { t } = useTranslation();
  const valueFromObject = (_.get(form, field.value, "") || "").split("-");
  let from = "";
  let to = "";
  if (valueFromObject.length === 1 && valueFromObject[0] !== "") {
    from = valueFromObject[0];
  } else if (valueFromObject.length === 2) {
    from = valueFromObject[0];
    to = valueFromObject[1];
  }
  const [fromValue, setFromValue] = useState(from);

  const [toValue, setToValue] = useState(to);

  const updateInputValue = (from: string, to: string) => {
    _.set(form, field.value, [from, to].join("-"));
    handleUpdate(form);
  };

  const isValid = () => {
    if (_.isEmpty(errors)) return true;
    return errors.hasOwnProperty(field.key) && errors[field.key] == null;
  };

  const onChangeFrom = (e: any) => {
    setFromValue(e.target.value);
    updateInputValue(e.target.value, toValue);
  };

  const onChangeTo = (e: any) => {
    setToValue(e.target.value);
    updateInputValue(fromValue, e.target.value);
  };

  return (
    <div className="my-2 mx-1">
      <div className={" d-flex flex-column mb-2 font-size-regular"}>
        <label>{t("passport.location." + field.key)}</label>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <input
            className={!isValid() ? "error p-2" : "p-2"}
            type={"number"}
            value={fromValue}
            min="0"
            onChange={onChangeFrom}
          />
          <span className={"mx-2"}>-</span>
          <input
            className={!isValid() ? "error p-2" : "p-2"}
            type={"number"}
            value={toValue}
            min="0"
            onChange={onChangeTo}
          />
        </div>
        {!isValid() ? (
          <label className="txt-color-bluish-grey text-error text-right">{t(errors[field.key])}</label>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default EditRangeQuestion;
