import Routes from "./routes/routes";
import React from "react";

export const Layout: React.FC = () => {
  return (
    <div className="app min-vh-100">
      <main className="min-vh-100 d-flex flex-column">
        <Routes />
      </main>
    </div>
  );
};

export default Layout;
