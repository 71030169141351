import { BaseAction } from "models/StoreModel";
import { ReportForSections, Suggestion } from "models/SuggestionModel";
import { Filter } from "models/DataModel";
import { GLNrow } from "../reducers/DataReducer";

export const GET_COMPANY_DETAILS_REQUEST = "locations.details.request";
export const GET_COMPANY_DETAILS_SUCCESS = "locations.company.details.success";
export const GET_COMPANY_DETAILS_FAILURE = "locations.company.details.failure";
export const GET_DATA_REQUEST = "locations.Request";
export const GET_DATA_REQUEST_SUCCESS = "locations.Request.Success";
export const GET_DATA_REQUEST_FAILURE = "locations.Request.Failure";
export const UPDATE_FILTERS = "location.Filters.Update";
export const SAVE_DATA_REQUEST = "data.Save";
export const SAVE_DATA_REQUEST_SUCCESS = "data.Save.Success";
export const SAVE_DATA_REQUEST_FAILURE = "data.Save.Failure";
export const EMAIL_ALREADY_EXIST = "user.Save.EmailAlreadyExist";
export const EMAIL_NOT_EXIST = "user.Save.EmailNotExist";
export const CHECKING_EMAIL = "user.Checking.Email";
export const GET_USER_DATA = "user.data.Load";
export const GET_USER_DATA_SUCCESS = "user.data.Load.Success";
export const GET_USER_DATA_FAILURE = "user.data.Load.Failure";
export const DELETE_USER_SUCCESS = "user.Delete.Success";
export const DELETE_USER_FAILURE = "user.Delete.Failure";
export const USER_CHANGE_STATUS_SUCCESS = "user.ChangeStatus.Success";
export const USER_CHANGE_STATUS_FAILURE = "user.ChangeStatus.Failure";
export const CLEAN_ADD_MESSAGE = "clean.add.Message";
export const SEND_IMPORT_FILE = "import.send.file";
export const SEND_IMPORT_FILE_SUCCESS = "import.send.file.Success";
export const SEND_IMPORT_FILE_FAILURE = "import.send.file.Failure";
export const IMPORT_STATUS_SUCCESS = "import.status.Success";
export const IMPORT_STATUS_FAILURE = "import.status.Failure";
export const GET_CITIES_FAILURE = "get.cities.Success";
export const GET_CITIES_SUCCESS = "get.cities.Failure";
export const SEND_EXPORT_FILE = "export.send.file";
export const SEND_EXPORT_FILE_SUCCESS = "export.send.file.Success";
export const SEND_EXPORT_FILE_FAILURE = "export.send.file.Failure";
export const EXPORT_STATUS_SUCCESS = "export.status.Success";
export const EXPORT_STATUS_FAILURE = "export.status.Failure";
export const GET_LOCATION_DETAILS_SUCCESS = "location.details.Success";
export const GET_LOCATION_DETAILS_FAILURE = "location.details.Failure";
export const SAVE_COMPANY_DETAILS_INPROGRESS = "company.details.InProgress";
export const SAVE_COMPANY_DETAILS_SUCCESS = "company.details.Success";
export const SAVE_COMPANY_DETAILS_FAILURE = "company.details.Failure";

export const CHANGE_WHITELIST_PERMISSION_SUCCESS = "whitelist.permission.Success";
export const CHANGE_WHITELIST_PERMISSION_FAILURE = "whitelist.permission.Failure";
export const GET_MY_WHITELIST_SUCCESS = "whitelist.my.companies.Success";
export const GET_MY_WHITELIST_FAILURE = "whitelist.my.companies.Failure";
export const GET_NOT_IN_WHITELIST_SUCCESS = "whitelist.notmy.companies.Success";
export const GET_NOT_IN_WHITELIST_FAILURE = "whitelist.notmy.companies.Failure";
export const UPDATE_WHITELIST_SUCCESS = "whitelist.update.Success";
export const UPDATE_WHITELIST_FAILURE = "whitelist.update.Failure";
export const UPDATE_RESTRICTED_SECTIONS_SUCCESS = "restricted.sections.update.Success";
export const UPDATE_RESTRICTED_SECTIONS_FAILURE = "restricted.sections.update.Failure";

export const SET_SUGGESTION_REPORT_FOR_SECTIONS = "suggestion.report_for_sections.set";
export const SET_SUGGESTIONS = "suggestion.list.set";

export const GET_GLN_DATA_REQUEST_SUCCESS = "locations.Request.Success";
export const GET_GLN_REQUEST_FAILURE = "locations.Request.Failure";
export const GET_GLN_REQUEST = "locations.Request";

export const GET_COMPANIES_DATA_REQUEST_SUCCESS = "companies.Request.Success";
export const GET_COMPANIES_REQUEST_FAILURE = "companies.Request.Failure";
export const GET_COMPANIES_REQUEST = "companies.Request";

export const GET_MYLOCATIONS_DATA_REQUEST_SUCCESS = "myLocations.Request.Success";
export const GET_MYLOCATIONS_REQUEST_FAILURE = "myLocations.Request.Failure";
export const GET_MYLOCATIONS_REQUEST = "myLocations.Request";

export const GET_HISTORY_DATA_REQUEST_SUCCESS = "history.Request.Success";
export const GET_HISTORY_REQUEST_FAILURE = "history.Request.Failure";
export const GET_HISTORY_REQUEST = "history.Request";

export const GET_USERS_DATA_REQUEST_SUCCESS = "users.Request.Success";
export const GET_USERS_REQUEST_FAILURE = "users.Request.Failure";
export const GET_USERS_REQUEST = "users.Request";

export const GET_LAST_GLN_SEARCH_DATA_REQUEST_SUCCESS = "last.gln.Request.Success";
export const GET_LAST_GLN_SEARCH_DATA_REQUEST_FAILURE = "last.gln.Request.Failure";
export const GET_LAST_GLN_SEARCH_DATA_REQUEST = "last.gln.Request";

export const GET_LAST_GLN_PASSPORT_SEARCH_DATA_REQUEST_SUCCESS = "last.gln.passport.Request.Success";
export const GET_LAST_GLN_PASSPORT_SEARCH_DATA_REQUEST_FAILURE = "last.gln.passport.Request.Failure";
export const GET_LAST_GLN_PASSPORT_SEARCH_DATA_REQUEST = "last.gln.passport.Request";

export const GetDataRequest = (payload: { [fieldName: string]: any }): BaseAction => ({
  type: GET_DATA_REQUEST,
});

export const GetDataRequestSuccess = (data: any): BaseAction => ({
  type: GET_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const GetDataRequestFailure = (): BaseAction => ({
  type: GET_DATA_REQUEST_FAILURE,
});

export const GetGLNRequestSuccess = (data: any): BaseAction => ({
  type: GET_GLN_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const GetGLNRequestFailure = (): BaseAction => ({
  type: GET_GLN_REQUEST_FAILURE,
});

export const GetGLNRequest = (payload: { [fieldName: string]: any }): BaseAction => ({
  type: GET_GLN_REQUEST,
});
export const GetCompaniesRequestSuccess = (data: any): BaseAction => ({
  type: GET_COMPANIES_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const GetCompaniesRequestFailure = (): BaseAction => ({
  type: GET_COMPANIES_REQUEST_FAILURE,
});

export const GetCompaniesRequest = (payload: { [fieldName: string]: any }): BaseAction => ({
  type: GET_COMPANIES_REQUEST,
});
export const GetMyLocationsRequestSuccess = (data: any): BaseAction => ({
  type: GET_MYLOCATIONS_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const GetMyLocationsRequestFailure = (): BaseAction => ({
  type: GET_MYLOCATIONS_REQUEST_FAILURE,
});

export const GetMyLocationsRequest = (payload: { [fieldName: string]: any }): BaseAction => ({
  type: GET_MYLOCATIONS_REQUEST,
});
export const GetHistoryRequestSuccess = (data: any): BaseAction => ({
  type: GET_HISTORY_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const GetHistoryRequestFailure = (): BaseAction => ({
  type: GET_HISTORY_REQUEST_FAILURE,
});

export const GetHistoryRequest = (payload: { [fieldName: string]: any }): BaseAction => ({
  type: GET_HISTORY_REQUEST,
});
export const GetUsersRequestSuccess = (data: any): BaseAction => ({
  type: GET_USERS_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const GetUsersRequestFailure = (): BaseAction => ({
  type: GET_USERS_REQUEST_FAILURE,
});

export const GetUsersRequest = (payload: { [fieldName: string]: any }): BaseAction => ({
  type: GET_USERS_REQUEST,
});

export const GetCompanyDetailsRequest = (): BaseAction => ({
  type: GET_COMPANY_DETAILS_REQUEST,
});

export const GetCompanyDetailsSuccess = (data: any): BaseAction => ({
  type: GET_COMPANY_DETAILS_SUCCESS,
  payload: data,
});

export const GetCompanyDetailsFailure = () => ({
  type: GET_COMPANY_DETAILS_FAILURE,
});

export const UpdateFilters = (key: string, filters: Filter[]): BaseAction => ({
  type: UPDATE_FILTERS,
  payload: {
    key,
    filters,
  },
});

export const SaveDataRequest = (payload: { [key: string]: any }): BaseAction => ({
  type: SAVE_DATA_REQUEST,
  payload,
});

export const SaveDataRequestSuccess = (saveType: string): BaseAction => ({
  type: SAVE_DATA_REQUEST_SUCCESS,
  payload: saveType,
});

export const SaveDataRequestFailure = (): BaseAction => ({
  type: SAVE_DATA_REQUEST_FAILURE,
});

export const EmailAlreadyExist = (): BaseAction => ({
  type: EMAIL_ALREADY_EXIST,
});

export const EmailNotExist = (): BaseAction => ({
  type: EMAIL_NOT_EXIST,
});

export const CheckingEmail = (): BaseAction => ({
  type: CHECKING_EMAIL,
});

export const GetUserData = (payload: { userId: string; selectedLocationId: string }): BaseAction => ({
  type: GET_USER_DATA,
  payload,
});

export const GetUserDataSuccess = (payload: { user: any }): BaseAction => ({
  type: GET_USER_DATA_SUCCESS,
  payload,
});

export const GetUserDataFailure = (): BaseAction => ({
  type: GET_USER_DATA_FAILURE,
});

export const DeleteUserSuccess = (payload: { userId: string }): BaseAction => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const DeleteUserFailure = (payload: string): BaseAction => ({
  type: DELETE_USER_FAILURE,
  payload,
});

export const UserChangeStatusSuccess = (payload: { userId: string }): BaseAction => ({
  type: USER_CHANGE_STATUS_SUCCESS,
  payload,
});

export const UserChangeStatusFailure = (): BaseAction => ({
  type: USER_CHANGE_STATUS_FAILURE,
});

export const CleanAddMessage = (): BaseAction => ({
  type: CLEAN_ADD_MESSAGE,
});

export const SendImportFile = (): BaseAction => ({
  type: SEND_IMPORT_FILE,
});

export const SendImportFileSuccess = (payload: {
  taskId: string;
  rowsToImport: number;
  cacheKey: string;
}): BaseAction => ({
  type: SEND_IMPORT_FILE_SUCCESS,
  payload,
});

export const SendImportFileFailure = (): BaseAction => ({
  type: SEND_IMPORT_FILE_FAILURE,
});

export const ImportStatusSuccess = (payload: {
  taskStatus: boolean;
  processingRow: number;
  taskResult: [];
}): BaseAction => ({
  type: IMPORT_STATUS_SUCCESS,
  payload,
});

export const ImportStatusFailure = (): BaseAction => ({
  type: IMPORT_STATUS_FAILURE,
});

export const GetAllCitiesSuccess = (payload: object[]): BaseAction => ({
  type: GET_CITIES_SUCCESS,
  payload,
});

export const GetAllCitiesFailure = (): BaseAction => ({
  type: GET_CITIES_FAILURE,
});

export const SendExportFile = (): BaseAction => ({
  type: SEND_EXPORT_FILE,
});

export const SendExportFileSuccess = (payload: { taskId: string; cacheKey: string }): BaseAction => ({
  type: SEND_EXPORT_FILE_SUCCESS,
  payload,
});

export const ExportStatusSuccess = (payload: {
  taskStatus: boolean;
  processingRow: number;
  taskResult: [];
}): BaseAction => ({
  type: EXPORT_STATUS_SUCCESS,
  payload,
});

export const ExportStatusFailure = (): BaseAction => ({
  type: EXPORT_STATUS_FAILURE,
});

export const SendExportFileFailure = (): BaseAction => ({
  type: SEND_EXPORT_FILE_FAILURE,
});

export const GetLocationDetailsSuccess = (payload: { [key: string]: any }): BaseAction => ({
  type: GET_LOCATION_DETAILS_SUCCESS,
  payload,
});

export const GetLocationDetailsFailure = (): BaseAction => ({
  type: GET_LOCATION_DETAILS_FAILURE,
});

export const saveCompanyDetailsInProgress = (): BaseAction => ({
  type: SAVE_COMPANY_DETAILS_INPROGRESS,
});

export const saveCompanyDetailsSuccess = (): BaseAction => ({
  type: SAVE_COMPANY_DETAILS_SUCCESS,
});

export const saveCompanyDetailsFailure = (): BaseAction => ({
  type: SAVE_COMPANY_DETAILS_FAILURE,
});

export const getMyWhitelistCompaniesSuccess = (data: any): BaseAction => ({
  type: GET_MY_WHITELIST_SUCCESS,
  payload: data,
});

export const getMyWhitelistCompaniesFailure = (): BaseAction => ({
  type: GET_MY_WHITELIST_FAILURE,
});

export const getNotInWhitelistCompaniesSuccess = (data: any): BaseAction => ({
  type: GET_NOT_IN_WHITELIST_SUCCESS,
  payload: data,
});

export const getNotInWhitelistCompaniesFailure = (): BaseAction => ({
  type: GET_NOT_IN_WHITELIST_FAILURE,
});

export const updateCompanyWhitelistSuccess = (data: any): BaseAction => ({
  type: UPDATE_WHITELIST_SUCCESS,
  payload: data,
});

export const updateCompanyWhitelistFailure = (): BaseAction => ({
  type: UPDATE_WHITELIST_FAILURE,
});

export const updateRestrictedSectionsSuccess = (data: any): BaseAction => ({
  type: UPDATE_RESTRICTED_SECTIONS_SUCCESS,
  payload: data,
});

export const updateRestrictedSectionsFailure = (): BaseAction => ({
  type: UPDATE_RESTRICTED_SECTIONS_FAILURE,
});

export function setSuggestionReportForSections(payload: ReportForSections) {
  return {
    type: SET_SUGGESTION_REPORT_FOR_SECTIONS,
    payload,
  };
}

export function setSuggestions(payload: Suggestion[]) {
  return {
    type: SET_SUGGESTIONS,
    payload,
  };
}

export const getLastGlnRequest = (payload: { [fieldName: string]: any }): BaseAction => ({
  type: GET_LAST_GLN_SEARCH_DATA_REQUEST,
});

export const getLastGlnRequestSuccess = (data: GLNrow[]): BaseAction => ({
  type: GET_LAST_GLN_SEARCH_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const getLastGlnRequestFailure = (): BaseAction => ({
  type: GET_LAST_GLN_SEARCH_DATA_REQUEST_FAILURE,
});

export const getLastGlnPassportRequest = (payload: { [fieldName: string]: any }): BaseAction => ({
  type: GET_LAST_GLN_PASSPORT_SEARCH_DATA_REQUEST,
});

export const getLastGlnPassportRequestSuccess = (data: any): BaseAction => ({
  type: GET_LAST_GLN_PASSPORT_SEARCH_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const getLastGlnPassportRequestFailure = (): BaseAction => ({
  type: GET_LAST_GLN_PASSPORT_SEARCH_DATA_REQUEST_FAILURE,
});
