import React, { useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router";

import { MessageOptions, MessageItem } from "models/MessageModel";
import { useMessage } from "hooks";

import "./Message.scss";

import { useTranslation } from "react-i18next";

interface Props extends MessageOptions {
  onClose?: () => void;
}

interface MessageItemWrapperProps extends MessageItem {
  removeMessage: (id: string) => void;
}

const animationDuration = 500; // _base.scss animation time

export const Message: React.FC<Props> = ({
  text,
  type = "success",
  dismissible = true,
  autoHideDuration = 10000,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  if (text === t("navigator.offline")){
    autoHideDuration = 1000*60*60*12 //12h
  }
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeoutDuration: NodeJS.Timeout | null = null;
    let timeoutAnimation: NodeJS.Timeout | null = null;
    const unregister = history.listen(() => {
      onClose();
    });

    if (autoHideDuration !== null) {
      timeoutDuration = setTimeout(() => {
        if (ref.current) {
          ref.current.classList.add("fadeOut");
        }
        timeoutAnimation = setTimeout(() => {
          onClose();
        }, animationDuration);
      }, autoHideDuration - animationDuration);
    }

    return () => {
      unregister();
      timeoutDuration && clearTimeout(timeoutDuration);
      timeoutAnimation && clearTimeout(timeoutAnimation);
    };
  }, []);

  return (
    <div ref={ref} className={`font-size-regular message message--${type} p-3 fadeIn mb-5`}>
      {text}
      {dismissible && (
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  );
};

// Wrapper contains a handler
const MessageItemWrapper: React.FC<MessageItemWrapperProps> = ({ id, ...props }) => {
  const handleClose = useCallback(() => {
    props.removeMessage(id);
  }, [id, props.removeMessage]);

  return <Message {...props} onClose={handleClose} />;
};

export const Messages = () => {
  const { messages, removeMessage } = useMessage();
  return (
    <>
      {messages.map((messageItem) => {
        return <MessageItemWrapper key={messageItem.id} {...messageItem} removeMessage={removeMessage} />;
      })}
    </>
  );
};

export default Message;
