import _ from "lodash";
import { v4 as uuid } from "uuid";

import React, { useEffect, useState } from "react";
import { Accordion, Breadcrumb, BreadcrumbItem, Card, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";

import { State } from "models/StoreModel";
import * as dataThunk from "store/thunk/DataThunk";
import FileInput from "components/Inputs/FileInput/FileInput";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";

export const ImportContainer: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const taskStatus = useSelector((state: State) => state.data.taskData);
  const selectedLocation = useSelector((state: State) => state.account.selectedLocation);

  if (!selectedLocation) {
    throw new Error("Invariant - for this route `selectedLocation` are required.");
  }

  const [importFile, setImportFile] = useState();
  const [taskInfo, setTaskInfo] = useState({
    percent: 0,
    label: t("task.info.starting"),
  });

  const updateFile = (file: { [key: string]: any }) => {
    setImportFile(file.importFile);
  };

  const showFileMessage = () => {
    if (importFile) {
      if (importFile.name !== undefined) {
        return t("input.file") + ": " + importFile.name;
      }
      return t("input.no_file");
    } else {
      return t("input.no_file");
    }
  };

  const sendFile = () => {
    setTaskInfo({
      percent: 0,
      label: t("task.info.starting"),
    });
    dispatch(dataThunk.sendImportFile(importFile, selectedLocation.id));
  };

  useEffect(() => {
    if (taskStatus.taskId !== "" && !taskStatus.taskStatus) {
      const interval = setInterval(() => {
        if (taskStatus.startedExport) {
          dispatch(dataThunk.checkExportStatus(taskStatus.taskId, taskStatus.cacheKey));
        } else if (taskStatus.startedImport) {
          dispatch(dataThunk.checkImportStatus(taskStatus.taskId, taskStatus.cacheKey));
        }
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [
    taskStatus.taskStatus,
    taskStatus.startedExport,
    taskStatus.startedImport,
    taskStatus.processingRow,
    taskStatus.taskId,
  ]);

  useEffect(() => {
    if (taskStatus.processingRow > 0) {
      setTaskInfo({
        percent: (taskStatus.processingRow * 100) / taskStatus.rowsToProcess,
        label: taskStatus.taskStatus
          ? t("task.info.success")
          : taskStatus.processingRow + " / " + taskStatus.rowsToProcess,
      });
    }
  }, [taskStatus.processingRow, taskStatus.taskStatus]);

  const startExporting = () => {
    dispatch(
      dataThunk.exportLocations({
        companyId: [selectedLocation.id],
        excelWithRestrictions: true,
      }),
    );
  };

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-4">
        <div className="context-menu row">
          <div className="col-md-9">
            <Breadcrumb>
              <BreadcrumbItem active>{t("header.bottom.import")}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <div className="row border-top-3 p-4 bg-white mt-4">
          <div className="col-md-12 container">
            <div className="content-title">
              <h5 className="form-title form-header bold font-size-normal">{t("import.title")}</h5>
            </div>
            <hr />
            <p className="txt-color-primary">{t("import.help_title")}</p>
            <div className="row">
              <div className="col-md-12">
                <Button
                  className="no-text-transform btn txt-color-white  mb-2 font-weight-bold font-size-normal btn-outline-secondary border-0 px-4 w-100 rounded-0 filters-font bg-dusty-orange search-button"
                  onClick={startExporting}
                  type="button">
                  {t("export.button.label")}
                </Button>
                <label className="txt-color-bluish-grey my-1 font-weight-bold">{showFileMessage()}</label>
                <FileInput
                  accept=".xlsx"
                  name="importFile"
                  changeHandler={updateFile}
                  value={importFile}
                  label={t("input.choose_file")}
                  className="w-100"
                />
                <Button
                  className={`${
                    !(importFile && importFile.name) ? "bg-borders" : "bg-dusty-orange "
                  } no-text-transform txt-color-white  mt-auto mb-2 font-size-normal w-100 rounded-0 font-weight-bold`}
                  variant="contained"
                  disabled={!(importFile && importFile.name)}
                  color="primary"
                  onClick={sendFile}>
                  {t("input.submit")}
                </Button>
              </div>
            </div>
            {taskStatus.taskId !== "" || taskStatus.startedImport || taskStatus.startedExport ? (
              <div className="mt-1">
                <p>{taskInfo.label}</p>
                <ProgressBar
                  animated={taskStatus.processingRow < taskStatus.rowsToProcess}
                  variant={taskStatus.taskStatus ? "success" : "info"}
                  now={taskInfo.percent}
                />
              </div>
            ) : (
              <></>
            )}
            {taskStatus.taskStatus && taskStatus.exportEnd && !_.isEmpty(taskStatus.taskResult) ? (
              <div className="mb-5 col-md-12 px-0 fadeIn mt-2">
                <a
                  /* eslint-disable-next-line react/jsx-no-target-blank */
                  target="_blank"
                  className="btn p-2 d-inline btn-outline-secondary border-0 px-4 w-100 rounded-0 filters-font bg-dusty-orange txt-color-white"
                  download="lista_lokalizacji.xlsx"
                  href={taskStatus.taskResult}>
                  <b>{t("export.download")}</b>
                </a>
              </div>
            ) : (
              <></>
            )}
            {taskStatus.taskStatus && taskStatus.importEnd ? (
              <div className="mt-3">
                {t("import.imported_record") +
                  (taskStatus.rowsToProcess - Object.keys(taskStatus.taskResult.errors).length)}
              </div>
            ) : (
              <></>
            )}
            {taskStatus.taskStatus && taskStatus.importEnd && !_.isEmpty(taskStatus.taskResult.errors) ? (
              <div className="mt-3">
                <p className="d-flex justify-content-between align-items-center">
                  {t("errors.title")}
                  <a
                    /* eslint-disable-next-line react/jsx-no-target-blank */
                    target="_blank"
                    className="no-text-transform btn p-2 d-inline btn-outline-secondary border-0 px-4 w-100 rounded-0 filters-font bg-dusty-orange col-md-4 txt-color-white"
                    download="lista_lokalizacji.xlsx"
                    href={taskStatus.taskResult.fileLink}>
                    <span className="font-weight-bold font-size-normal">{t("export.download_with_errors")}</span>
                  </a>
                </p>

                <br />
                <Accordion defaultActiveKey="0">
                  {_.map(taskStatus.taskResult.errors, (rowErrors: any, key: string) => (
                    <Card key={uuid()}>
                      <Accordion.Toggle as={Card.Header} eventKey={key}>
                        {t("error.row") + " " + key}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={key}>
                        <Card.Body>
                          {_.map(rowErrors, (questionErrors: any, questionKey: string) =>
                            _.isArray(questionErrors) ? (
                              <div key={uuid()}>
                                <p>{t(questionKey)}</p>
                                <ul>
                                  {_.map(questionErrors, (questionError: string) => (
                                    <li key={uuid()}>{questionError}</li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <div key={uuid()}>
                                <p></p>
                                {_.map(questionErrors, (openHoursErrors: any, openHoursKey: string) => (
                                  <div key={uuid()}>
                                    <p>{t(questionKey) + " - " + t(openHoursKey)}</p>
                                    <ul>
                                      {_.map(openHoursErrors, (openHoursError: string) => (
                                        <li key={uuid()}>{openHoursError}</li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            ),
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ImportContainer;
