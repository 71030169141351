import React from "react";
import { TableCell } from "components";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";

const LargeColumns: (fromState: any) => Column[] = (fromState) => {
  const { t } = useTranslation();

  const largeColumns: Column[] = [
    {
      Header: t("table.company_name_header"),
      id: "company_name",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal"
            cssClassForSecond="font-size-regular"
            firstValue={d.companyName}
            secondValue={d.nip}
          />
        );
      },
    },
    {
      Header: t("table.gln_number_header"),
      id: "gln_number",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal txt-color-dusty-orange"
            cssClassForSecond="font-size-regular"
            firstValue={d.glnNumber}
            secondValue={"+ " + d.glns + " " + t("table.gln")}
            showSecond={d.glnNumber === void 0}
          />
        );
      },
    },
    {
      Header: t("table.address_header"),
      id: "location_extra_details__address",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal"
            cssClassForSecond="font-size-regular"
            firstValue={d.address}
            secondValue={`${d.postalCode} ${d.city}, ${d.country}`}
          />
        );
      },
    },
    {
      Header: t("table.localization_name_header"),
      id: "full_name",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal"
            cssClassForSecond="font-size-regular"
            firstValue={d.fullName}
            secondValue={d.shortName}
          />
        );
      },
    },
  ];

  if (fromState) {
    largeColumns.push({
      Header: t("table.suggestions"),
      id: "suggestions_count",
      width: 120,
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <div className="d-flex justify-content-center">
            {fromState && d.suggestionsCount > 0 && (
              <span className="float-right icon-text">
                <img src="/icons/icon-warning.svg" alt="icon-warning" />
                <span>{d.suggestionsCount}</span>
              </span>
            )}
            <span className="float-right font-size-large txt-color-dusty-orange">></span>
          </div>
        );
      },
    });
  }

  return largeColumns;
};

export default LargeColumns;
