import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Footer, Header } from "components";
import { useSelector } from "react-redux";
import { ScrollToTop } from "utils/ScrollUp";

import { State } from "models/StoreModel";

const PrivacyPolicyContainer: React.FC = () => {
    const { t } = useTranslation();
    const account = useSelector((state: State) => state.account);
    
    let headerProps = {
        withBottom: account.user ? true : false,
        withTitle: account.user ? "" : " "
    }

    useEffect(() => {
        ScrollToTop("auto");
    }, []);

    return (
        <>
        <Header withBottom={headerProps.withBottom} withTitle={headerProps.withTitle} />
            <div className="privacy-policy-page container my-5">
                <h2 className="uppercase">{t("privacyPolicy")}</h2>
                <section>
                    <h4>{t("privacyPolicy.intro.header")}</h4>
                    <ol>
                        <li>
                            <Trans i18nKey="privacyPolicy.intro.listItemOne">
                                privacyPolicy.intro.listItemOne[0]
                                <a
                                    className="link"
                                    href={`https://${t("privacyPolicy.intro.listItemOne.www")}`}
                                    target="blank"
                                >
                                    {t("privacyPolicy.intro.listItemOne.www")}
                                </a>
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="privacyPolicy.intro.listItemTwo">
                                privacyPolicy.intro.listItemTwo[0]
                                <a
                                    className="link"
                                    href={`mailto:${t("privacyPolicy.intro.listItemTwo.mail")}`}
                                >
                                    {t("privacyPolicy.intro.listItemTwo.mail")}
                                </a>
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="privacyPolicy.intro.listItemThree">
                                privacyPolicy.intro.listItemThree[0]
                                <a
                                    className="link"
                                    href={`mailto:${t("privacyPolicy.intro.listItemThree.mail")}`}
                                >
                                    {t("privacyPolicy.intro.listItemTwo.mail")}
                                </a>
                            </Trans>
                        </li>
                        <li>{t("privacyPolicy.intro.listItemFour")}</li>
                        <li>{t("privacyPolicy.intro.listItemFive")}</li>
                        <li>{t("privacyPolicy.intro.listItemSix")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("privacyPolicy.intro.listItemSix.itemOne")}</li>
                            <li>{t("privacyPolicy.intro.listItemSix.itemTwo")}</li>
                        </ol>
                        <li>{t("privacyPolicy.intro.listItemSeven")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("privacyPolicy.collectedData.header")}</h4>
                    <ol>
                        <li>{t("privacyPolicy.collectedData.listItemOne")}</li>
                        <li>{t("privacyPolicy.collectedData.listItemTwo")}</li>
                        <li>{t("privacyPolicy.collectedData.listItemThree")}</li>
                        <li>{t("privacyPolicy.collectedData.listItemFour")}</li>
                        <ol>
                            <li>{t("privacyPolicy.collectedData.listItemFour.itemOne")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemFour.itemTwo")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemFour.itemThree")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemFour.itemFour")}</li>
                        </ol>
                        <li>{t("privacyPolicy.collectedData.listItemFive")}</li>
                        <li>{t("privacyPolicy.collectedData.listItemSix")}</li>
                        <ol>
                            <li>{t("privacyPolicy.collectedData.listItemSix.itemOne")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemSix.itemTwo")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemSix.itemThree")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemSix.itemFour")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemSix.itemFive")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemSix.itemSix")}</li>
                            <li>{t("privacyPolicy.collectedData.listItemSix.itemSeven")}</li>
                        </ol>
                        <li>
                            <Trans i18nKey="privacyPolicy.collectedData.listItemSeven">
                                privacyPolicy.collectedData.listItemSeven[0]
                                <a
                                    className="link"
                                    href={`https://${t("privacyPolicy.collectedData.listItemSeven.www")}`}
                                    target="blank"
                                >
                                {t("privacyPolicy.collectedData.listItemSeven.www")}
                                </a>
                            </Trans>
                        </li>
                        <li>{t("privacyPolicy.collectedData.listItemEight")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("privacyPolicy.userData.header")}</h4>
                    <p>
                        <span className="font-size-medium font-weight-bold">{t("privacyPolicy.userData.groupA.header")}</span>
                    </p>
                    <ol>
                        <li>{t("privacyPolicy.userData.groupA.listItemOne")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("privacyPolicy.userData.groupA.listItemOne.itemOne")}</li>
                            <li>{t("privacyPolicy.userData.groupA.listItemOne.itemTwo")}</li>
                            <li>{t("privacyPolicy.userData.groupA.listItemOne.itemThree")}</li>
                            <li>{t("privacyPolicy.userData.groupA.listItemOne.itemFour")}</li>
                        </ol>
                        <li>{t("privacyPolicy.userData.groupA.listItemTwo")}</li>
                        <li>{t("privacyPolicy.userData.groupA.listItemThree")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("privacyPolicy.userData.groupA.listItemThree.itemOne")}</li>
                            <li>{t("privacyPolicy.userData.groupA.listItemThree.itemTwo")}</li>
                            <li>{t("privacyPolicy.userData.groupA.listItemThree.itemThree")}</li>
                            <li>{t("privacyPolicy.userData.groupA.listItemThree.itemFour")}</li>
                        </ol>
                    </ol>
                    <p>
                        <span className="font-size-medium font-weight-bold">{t("privacyPolicy.userData.groupB.header")}</span>
                    </p>
                    <ol>
                        <li>{t("privacyPolicy.userData.groupB.listItemOne")}</li>
                        <li>{t("privacyPolicy.userData.groupB.listItemTwo")}</li>
                        <li>{t("privacyPolicy.userData.groupB.listItemThree")}</li>
                    </ol>
                    <p>
                        <span className="font-size-medium font-weight-bold">{t("privacyPolicy.userData.groupC.header")}</span>
                    </p>
                    <ol>
                        <li>{t("privacyPolicy.userData.groupC.listItemOne")}</li>
                        <li>{t("privacyPolicy.userData.groupC.listItemTwo")}</li>
                        <li>{t("privacyPolicy.userData.groupC.listItemThree")}</li>
                        <li>{t("privacyPolicy.userData.groupC.listItemFour")}</li>
                        <ol>
                            <li>{t("privacyPolicy.userData.groupC.listItemFour.itemOne")}</li>
                            <li>
                                <Trans i18nKey="privacyPolicy.userData.groupC.listItemFour.itemTwo">
                                    privacyPolicy.userData.groupC.listItemFour.itemTwo[0]
                                    <a
                                        className="link"
                                        href={`mailto:${t("privacyPolicy.userData.groupC.listItemFour.itemTwo.mail")}`}
                                    >
                                        {t("privacyPolicy.userData.groupC.listItemFour.itemTwo.mail")}
                                    </a>
                                </Trans>
                            </li>
                        </ol>
                        <li>{t("privacyPolicy.userData.groupC.listItemFive")}</li>
                        </ol>
                    <p>
                        <span className="font-size-medium font-weight-bold">{t("privacyPolicy.userData.groupD.header")}</span>
                    </p>
                    <ol>
                        <li>{t("privacyPolicy.userData.groupD.listItemOne")}</li>
                        <li>{t("privacyPolicy.userData.groupD.listItemTwo")}</li>
                        <li>{t("privacyPolicy.userData.groupD.listItemThree")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("privacyPolicy.dataCollectedAutomatically.header")}</h4>
                    <ol>
                        <li>{t("privacyPolicy.dataCollectedAutomatically.listItemOne")}</li>
                        <li>{t("privacyPolicy.dataCollectedAutomatically.listItemTwo")}</li>
                        <li>{t("privacyPolicy.dataCollectedAutomatically.listItemThree")}</li>
                        <li>{t("privacyPolicy.dataCollectedAutomatically.listItemFour")}</li>
                        <li>{t("privacyPolicy.dataCollectedAutomatically.listItemFive")}</li>
                        <li>{t("privacyPolicy.dataCollectedAutomatically.listItemSix")}</li>
                        <li>{t("privacyPolicy.dataCollectedAutomatically.listItemSeven")}</li>
                    </ol>
                </section>
                <section>
                    <h4>{t("privacyPolicy.cookies.header")}</h4>
                    <ol>
                        <li>{t("privacyPolicy.cookies.listItemOne")}</li>
                        <li>{t("privacyPolicy.cookies.listItemTwo")}</li>
                        <li>{t("privacyPolicy.cookies.listItemThree")}</li>
                        <li>{t("privacyPolicy.cookies.listItemFour")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("privacyPolicy.cookies.listItemFour.itemOne")}</li>
                            <li>{t("privacyPolicy.cookies.listItemFour.itemTwo")}</li>
                            <li>{t("privacyPolicy.cookies.listItemFour.itemThree")}</li>
                        </ol>
                    </ol>
                </section>
                <section>
                    <h4>{t("privacyPolicy.cookiesGenerally.header")}</h4>
                    <ol>
                        <li>{t("privacyPolicy.cookiesGenerally.listItemOne")}</li>
                        <ol className="lower-alpha-parenthesis">
                            <li>{t("privacyPolicy.cookiesGenerally.listItemOne.itemOne")}</li>
                            <li>{t("privacyPolicy.cookiesGenerally.listItemOne.itemTwo")}</li>
                            <li>{t("privacyPolicy.cookiesGenerally.listItemOne.itemThree")}</li>
                        </ol>
                        <li>{t("privacyPolicy.cookiesGenerally.listItemTwo")}</li>
                        <ol>
                            <li>{t("privacyPolicy.cookiesGenerally.listItemTwo.itemOne")}</li>
                            <ol className="lower-alpha-parenthesis">
                                <li>
                                    <Trans i18nKey="privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partOne">
                                        privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partOne[0]
                                        <span className="underline">{t("privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partOne.underline")}</span>
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partTwo">
                                        privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partTwo[0]
                                        <span className="underline">{t("privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partTwo.underline")}</span>
                                        privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partTwo[2]
                                        <a
                                            className="link"
                                            href={`https://${t("privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partTwo.www")}`}
                                            target="blank"
                                        >
                                            {t("privacyPolicy.cookiesGenerally.listItemTwo.itemOne.partTwo.www")}
                                        </a>
                                    </Trans>
                                </li>
                            </ol>
                            <li>{t("privacyPolicy.cookiesGenerally.listItemTwo.itemTwo")}</li>
                            <ol className="lower-alpha-parenthesis">
                                <li>{t("privacyPolicy.cookiesGenerally.listItemTwo.itemTwo.partOne")}</li>
                                <li>{t("privacyPolicy.cookiesGenerally.listItemTwo.itemTwo.partTwo")}</li>
                            </ol>
                        </ol>
                        </ol>
                    <h4>{t("privacyPolicy.cookiesGenerally.analyticsTools.header")}</h4>
                    <ol>
                        <li>{t("privacyPolicy.cookiesGenerally.analyticsTools.listItemOne")}</li>
                            <ol className="lower-alpha-parenthesis">
                                <li>{t("privacyPolicy.cookiesGenerally.analyticsTools.listItemOne.itemOne")}</li>
                            </ol>
                        <span>
                            <Trans i18nKey="privacyPolicy.cookiesGenerally.analyticsTools.content">
                                privacyPolicy.cookiesGenerally.analyticsTools.content[0]
                                <a
                                    className="link"
                                    href={`https://${t("privacyPolicy.cookiesGenerally.analyticsTools.content.www")}`}
                                    target="blank"
                                >
                                    {t("privacyPolicy.cookiesGenerally.analyticsTools.content.www")}
                                </a>
                            </Trans>
                        </span>
                    </ol>
                    <h4>{t("privacyPolicy.cookiesGenerally.howTo.header")}</h4>
                    <ol>
                            <li>{t("privacyPolicy.cookiesGenerally.howTo.listItemOne")}</li>
                            <li>{t("privacyPolicy.cookiesGenerally.howTo.listItemTwo")}</li>
                            <li>{t("privacyPolicy.cookiesGenerally.howTo.listItemThree")}</li>
                            <li>{t("privacyPolicy.cookiesGenerally.howTo.listItemFour")}</li>
                            <ol className="lower-alpha-parenthesis">
                                <li>
                                    <Trans i18nKey="privacyPolicy.cookiesGenerally.howTo.listItemFour.itemOne">
                                        privacyPolicy.cookiesGenerally.howTo.listItemFour.itemOne[0]
                                        <a
                                            className="link"
                                            href={`https://${t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemOne.www")}`}
                                            target="blank"
                                        >
                                            {t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemOne.www")}
                                        </a>
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="privacyPolicy.cookiesGenerally.howTo.listItemFour.itemTwo">
                                        privacyPolicy.cookiesGenerally.howTo.listItemFour.itemTwo[0]
                                        <a
                                            className="link"
                                            href={`https://${t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemTwo.www")}`}
                                            target="blank"
                                        >
                                            {t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemTwo.www")}
                                        </a>
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="privacyPolicy.cookiesGenerally.howTo.listItemFour.itemThree">
                                        privacyPolicy.cookiesGenerally.howTo.listItemFour.itemThree[0]
                                        <a
                                            className="link"
                                            href={`https://${t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemThree.www")}`}
                                            target="blank"
                                        >
                                            {t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemThree.www")}
                                        </a>
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="privacyPolicy.cookiesGenerally.howTo.listItemFour.itemFour">
                                        privacyPolicy.cookiesGenerally.howTo.listItemFour.itemFour[0]
                                        <a
                                            className="link"
                                            href={`https://${t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemFour.www")}`}
                                            target="blank"
                                        >
                                            {t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemFour.www")}
                                        </a>
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="privacyPolicy.cookiesGenerally.howTo.listItemFour.itemFive">
                                        privacyPolicy.cookiesGenerally.howTo.listItemFour.itemFive[0]
                                        <a
                                            className="link"
                                            href={`https://${t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemFive.www")}`}
                                            target="blank"
                                        >
                                            {t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemFive.www")}
                                        </a>
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="privacyPolicy.cookiesGenerally.howTo.listItemFour.itemSix">
                                        privacyPolicy.cookiesGenerally.howTo.listItemFour.itemSix[0]
                                        <a
                                            className="link"
                                            href={`https://${t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemSix.www")}`}
                                            target="blank"
                                        >
                                            {t("privacyPolicy.cookiesGenerally.howTo.listItemFour.itemSix.www")}
                                        </a>
                                    </Trans>
                                </li>
                            </ol>
                    </ol>
                            <span>{t("privacyPolicy.cookiesGenerally.howTo.content")}</span>
                </section>
                <section>
                    <h4>{t("privacyPolicy.finalProvisions.header")}</h4>
                    <ol>
                        <li>{t("privacyPolicy.finalProvisions.listItemOne")}</li>
                        <li>{t("privacyPolicy.finalProvisions.listItemTwo")}</li>
                        <li>{t("privacyPolicy.finalProvisions.listItemThree")}</li>
                    </ol>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default PrivacyPolicyContainer;