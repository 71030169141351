import React from "react";

import "./Input.scss";

interface InputProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => any;
  value?: any;
  name: string;
  error: string | null;
  successMessage?: string;
  rows?: number;
  className?: string;
  backgroundColor?: string;
}

const TextArea: React.FC<InputProps> = ({
  label,
  placeholder = "",
  className = "",
  disabled = false,
  required = false,
  onChange,
  value = "",
  name = "",
  error = null,
  successMessage,
  rows = 4,
  backgroundColor,
}) => (
  <div className={className + " d-flex flex-column mb-2 font-size-regular txt-color-grafite"}>
    {label && (
      <label className="font-size-regular-sm">
        {label}
        {required && <span className="ml-1 txt-color-error">*</span>}
      </label>
    )}
    <textarea
      rows={rows}
      name={name}
      className={error ? "error p-2" : "p-2"}
      placeholder={placeholder}
      style={{ backgroundColor }}
      required={required}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      disabled={disabled}
    />
    {error && <label className="txt-color-bluish-grey text-error text-right mt-1">{error}</label>}
    {successMessage && <label className="txt-color-bluish-grey text-success text-right">{successMessage}</label>}
  </div>
);

export default TextArea;
