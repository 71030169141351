import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { getOtherValueIfExist, QuestionProps, shouldRender } from "../PassportUtils";

const SingleSelectQuestion: React.FC<QuestionProps> = ({ field, mainObject }) => {
  const { t } = useTranslation();
  const value = _.get(mainObject, field.value);
  const selectedValues = _.get(mainObject, field.value);
  const shouldRenderField = shouldRender(field, mainObject);
  let render;
  if (shouldRenderField) {
    if (value === "other") {
      if (selectedValues !== undefined && selectedValues.indexOf("other") !== -1) {
        render = (
          <div className="font-size-normal  d-flex flex-column p-2 company-details" key={uuid()}>
            <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
              {t("passport.location." + field.key)}
            </label>
            <ul className="font-size-normal details-value font-weight-bold">
              {getOtherValueIfExist(field, mainObject)}
            </ul>
          </div>
        );
      }
    } else {
      render = (
        <div className="font-size-normal  col-md-3 d-flex flex-column p-2 company-details" key={uuid()}>
          <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
            {t("passport.location." + field.key)}
          </label>
          <span className="font-size-normal details-value font-weight-bold">
            {selectedValues ? t("passport.location." + field.key + "." + value) : "-"}
          </span>
        </div>
      );
    }
  }

  return <>{render}</>;
};

export default SingleSelectQuestion;
