import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const FIELDS_TO_RENDER = [
  { key: "glnNumber", path: ["glnNumber"] },
  { key: "fullName", path: ["fullName"] },
  { key: "address", path: ["locationExtraDetails", "address"] },
  { key: "nip", path: ["locationExtraDetails", "nip"] },
  { key: "regon", path: ["locationExtraDetails", "regon"] },
  { key: "phoneNumber", path: ["locationExtraDetails", "phoneNumber"] },
  { key: "companyEmail", path: ["locationExtraDetails", "companyEmail"] },
  { key: "homePage", path: ["locationExtraDetails", "homePage"] },
];

export interface DetailsProps {
  companyDetails: object;
}

interface Field {
  key: string;
  path: string[];
}

const Details: React.FC<DetailsProps> = ({ companyDetails = {} }) => {
  const { t } = useTranslation();

  const specialFieldsHandler = (field: Field) => {
    const fieldValue = _.get(companyDetails, field.path);

    switch (field.key) {
      case "companyEmail":
        return (
          <a className="txt-color-link" href={`mailto:${fieldValue}`}>
            {fieldValue}
          </a>
        );
      case "homePage":
        return (
          <a className="txt-color-link" href={`${fieldValue}`}>
            {fieldValue}
          </a>
        );
      case "glnNumber":
        return <span className="txt-color-dusty-orange">{fieldValue || "-"}</span>;
      case "address":
        const postalCode = _.get(companyDetails, ["locationExtraDetails", "postalCode"]);
        const city = _.get(companyDetails, ["locationExtraDetails", "city"]);
        const country = _.get(companyDetails, ["locationExtraDetails", "country"]);
        return (
          <span>
            {fieldValue}, {postalCode} {city}, {country}{" "}
          </span>
        );
      default:
        return fieldValue;
    }
  };

  return (
    <>
      {_.map(FIELDS_TO_RENDER, (field: Field) => {
        return (
          <div className="col-md-3 d-flex flex-column p-2 company-details" key={field.key}>
            <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
              {t("passport.location." + field.key)}
            </label>
            <span className={"word-break-word font-size-normal details-value font-weight-bold"}>
              {specialFieldsHandler(field)}
            </span>
          </div>
        );
      })}
    </>
  );
};

export default Details;
