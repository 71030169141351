import { BackendService } from 'services'
import * as registerActions from "../actions/RegisterActions";

export const signUp = (data: any) => {
  return (dispatch: any) => {
    dispatch(registerActions.RegistrationInProgress());
    BackendService.account.signUp(data)
      .then((response: any) => {
        dispatch(registerActions.RegistrationSuccess(response.data.id));
      })
      .catch((error: any) => {
        dispatch(registerActions.RegistrationFailure());
      });
  };
};

export const attachRegisterFile = (file: File, membership: string) => {
  return (dispatch: any) => {
    BackendService.account.attachRegisterFile(file, membership)
      .then((response: any) => dispatch(registerActions.FileSaveSuccess()))
      .catch((error: any) => dispatch(registerActions.FileSaveFailure()));
  };
};
