import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import Input from "components/Inputs/Input";
import NumberInput from "components/Inputs/NumberInput";
import SelectInput from "components/Inputs/SelectInput";
import { FormProps } from "components/wizard/WizardSteps";

const HTMLTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#002C6C",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "0",
    fontSize: "1em",
  },
}))(Tooltip);

const CompanyInformation = (props: FormProps) => {
  const { t } = useTranslation();
  const foundRegon = props.foundRegon;

  const companyProfileOptions = [
    { id: "producer", value: t("company_profile.producer") },
    {
      id: "distributor_wholesaler",
      value: t("company_profile.distributor_wholesaler"),
    },
    { id: "tsl_company", value: t("company_profile.tsl_company") },
    {
      id: "commercial_network",
      value: t("company_profile.commercial_network"),
    },
    { id: "other", value: t("company_profile.other") },
  ];

  const countries = ["Polska"];

  const countryOptions = countries.map((country) => ({
    id: country.toLowerCase(),
    value: country,
  }));

  const districts = [
    "Dolnośląskie",
    "Kujawsko-Pomorskie",
    "Lubelskie",
    "Lubuskie",
    "Łódzkie",
    "Małopolskie",
    "Mazowieckie",
    "Opolskie",
    "Podkarpackie",
    "Podlaskie",
    "Pomorskie",
    "Śląskie",
    "Świętokrzyskie",
    "Warmińsko-Mazurskie",
    "Wielkopolskie",
    "Zachodniopomorskie",
  ];

  const districtOptions = districts.map((district) => ({
    id: district.toLowerCase(),
    value: district,
  }));

  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-12 container">
          <div className="row border-top-3-dusty-orange bg-white p-4">
            <div className="col-12">
              <h5 className="txt-color-marine-blue  font-size-normal">
                {foundRegon
                  ? t("companyInformation.form.left.heading.authorized")
                  : t("companyInformation.form.left.heading.basic")}
              </h5>
              <hr />
            </div>
            <div className="col container">
              <div className="row">
                <div className="font-size-normal col-md-6 px-4 ml-auto bg-white txt-color-bluish-grey left-side d-flex flex-column">
                  <Input
                    label={t("companyInformation.form.left.regon")}
                    name="regon"
                    errors={props.formErrors}
                    className="mb-1"
                    required={true}
                    disabled={true}
                    value={props.form.regon}
                    inputType="text"
                    handleChange={props.setPartialForm}
                  />

                  {foundRegon ? (
                    <HTMLTooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        <div className="gs1-tooltip gotham-font-stack font-size-regular ">
                          {t("tooltip.regon.found")}
                        </div>
                      }
                      placement="top">
                      <div>
                        <img className="mr-1" src="/icons/icon-help-enabled.svg" alt="" />
                        {t("companyInformation.form.left.cantEditData")}
                      </div>
                    </HTMLTooltip>
                  ) : (
                    <HTMLTooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        <div className="gs1-tooltip gotham-font-stack font-size-regular ">
                          {t("tooltip.regon.notFound")}
                        </div>
                      }
                      placement="bottom">
                      <div>
                        <img className="mr-1" src="/icons/icon-help-enabled.svg" alt="" />
                        {t("companyInformation.form.left.cantEditREGON")}
                      </div>
                    </HTMLTooltip>
                  )}

                  <NumberInput
                    label={t("nip")}
                    name="nip"
                    className="mt-1"
                    errors={props.formErrors}
                    required={true}
                    disabled={foundRegon}
                    value={props.form.nip}
                    placeholder={"XXXXXXXXXX"}
                    inputType="text"
                    handleChange={props.setPartialForm}
                  />
                  <Input
                    label={t("companyInformation.form.left.fullName")}
                    name="fullName"
                    errors={props.formErrors}
                    required={true}
                    disabled={foundRegon}
                    value={props.form.fullName}
                    inputType="text"
                    handleChange={props.setPartialForm}
                  />
                  <Input
                    label={t("companyInformation.form.left.name")}
                    name="name"
                    errors={props.formErrors}
                    required={true}
                    value={props.form.name}
                    inputType="text"
                    handleChange={props.setPartialForm}
                  />
                  <SelectInput
                    label={t("companyInformation.form.left.profile")}
                    options={companyProfileOptions}
                    value={props.form.companyProfile}
                    handleChange={props.setPartialForm}
                    required={true}
                    errors={props.formErrors}
                    name="companyProfile"
                  />
                  {props.form.companyProfile.length === 1 && props.form.companyProfile[0].id === "other" ? (
                    <Input
                      label={t("companyInformation.form.left.profileOther")}
                      name="companyProfileOther"
                      errors={props.formErrors}
                      value={props.form.companyProfileOther}
                      inputType="text"
                      handleChange={props.setPartialForm}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="d-none py-2 h-100 d-md-flex">
                    <Button
                      className="font-weight-normal txt-color-dusty-orange mt-auto font-size-regula mb-2 bg-white ml-0 mr-auto rounded-0 min-width font-weight-bold "
                      variant="contained"
                      onClick={props.handlePrevious}>
                      <span className="back-chevron">{t("register.back_button")}</span>
                    </Button>
                  </div>
                </div>
                <div className="col-md-6 px-4 font-size-normal mr-auto bg-white txt-color-bluish-grey right-side d-flex flex-column">
                  <Input
                    label={t("address")}
                    name="address"
                    errors={props.formErrors}
                    required={true}
                    disabled={foundRegon}
                    value={props.form.address}
                    inputType="text"
                    handleChange={props.setPartialForm}
                  />
                  <Input
                    label={t("city")}
                    name="city"
                    errors={props.formErrors}
                    required={true}
                    value={props.form.city}
                    inputType="text"
                    disabled={foundRegon}
                    handleChange={props.setPartialForm}
                  />
                  <div className="container">
                    <div className="row align-items-start">
                      <Input
                        label={t("form.agreements.details.postalCode")}
                        name="postalCode"
                        errors={props.formErrors}
                        required={true}
                        className="col-xs-12 px-0 col-lg-4 pr-lg-1 pl-lg-0"
                        disabled={foundRegon}
                        value={props.form.postalCode}
                        placeholder={t("placeholder.postalCode")}
                        inputType="text"
                        handleChange={props.setPartialForm}
                      />
                      <Input
                        label={t("companyInformation.form.right.postalCity")}
                        name="postalCity"
                        errors={props.formErrors}
                        value={props.form.postalCity}
                        className="col-xs-12 px-0 col-lg-8 pr-lg-0 pl-lg-1"
                        inputType="text"
                        handleChange={props.setPartialForm}
                      />
                    </div>
                  </div>
                  <SelectInput
                    label={t("district")}
                    options={districtOptions}
                    value={props.form.district}
                    handleChange={props.setPartialForm}
                    required={true}
                    disabled={foundRegon}
                    errors={props.formErrors}
                    name="district"
                  />
                  <SelectInput
                    label={t("form.agreements.details.country")}
                    options={countryOptions}
                    value={props.form.country}
                    handleChange={props.setPartialForm}
                    required={true}
                    errors={props.formErrors}
                    name="country"
                    disabled={foundRegon}
                  />
                  <div className="container mb-2">
                    <div className="row align-items-start">
                      <NumberInput
                        label={t("companyInformation.form.right.phone")}
                        name="phoneNumber"
                        errors={props.formErrors}
                        required={true}
                        placeholder={t("placeholder.phoneNumber")}
                        disabled={foundRegon}
                        phoneInput={true}
                        className="col-xs-12 px-0 col-lg-6 pr-lg-1 pl-lg-0"
                        value={props.form.phoneNumber}
                        inputType="text"
                        handleChange={props.setPartialForm}
                      />
                      <NumberInput
                        label={t("users.add.internal_phone")}
                        className="col-xs-12 px-0 col-lg-6 pr-lg-0 pl-lg-1"
                        name="internalNumber"
                        errors={props.formErrors}
                        value={props.form.internalNumber}
                        inputType="text"
                        handleChange={props.setPartialForm}
                      />
                    </div>
                  </div>
                  <Input
                    label={t("companyInformation.form.right.email")}
                    name="companyEmail"
                    errors={props.formErrors}
                    required={true}
                    disabled={foundRegon}
                    value={props.form.companyEmail}
                    inputType="text"
                    handleChange={({ companyEmail }: any) =>
                      props.setPartialForm({ companyEmail: companyEmail.toLowerCase() })
                    }
                  />
                  <Input
                    label={t("companyInformation.form.right.homepage")}
                    name="homePage"
                    errors={props.formErrors}
                    value={props.form.homePage}
                    inputType="text"
                    handleChange={props.setPartialForm}
                  />
                  <div className="container px-0">
                    <div className="row d-flex flex-md-row-reverse justify-content-between">
                      <div className="col-auto d-flex py-2 h-100 d-md-none">
                        <Button
                          className="txt-color-dusty-orange mt-auto font-size-regula mb-2 bg-white ml-0 mr-auto rounded-0 min-width font-weight-bold "
                          variant="contained"
                          onClick={props.handlePrevious}>
                          <span className="back-chevron">{t("register.back_button")}</span>
                        </Button>
                      </div>

                      <div className="col-auto py-2 h-100 d-flex">
                        <Button
                          className="bg-dusty-orange  mt-auto mb-2 mr-0 ml-auto font-size-normal w-50 rounded-0 font-weight-bold"
                          variant="contained"
                          color="primary"
                          onClick={props.handleNext}>
                          {t("register.next_button")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
