import React from "react";
import { useTranslation } from "react-i18next";

const SmallColumns = () => {
  const { t } = useTranslation();

  const smallColumns = [
    {
      headerClassName: "d-none",
      id: "gln_number",
      accessor: (d: any) => {
        return (
          <div className="d-flex flex-column bg-white p-3 my-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="font-size-normal font-weight-bold txt-color-marine-blue mb-2">{d.companyName}</span>
                <span className="font-size-regular txt-color-borders">{d.nip}</span>
              </div>
              <div className="d-flex">
                <span className="no-user-select d-flex align-items-center font-size-medium txt-color-dusty-orange cursor-pointer">
                  >
                </span>
              </div>
            </div>
            <hr />
            <div className="container justify-content-between my-2">
              <div className="row">
                <div className="col-8 pl-0 pr-2 d-flex flex-column">
                  <span className="font-size-regular txt-color-borders">{t("table.address_header")}</span>
                  <span className="font-size-regular txt-color-primary font-weight-bold white-space-normal word-break-word">
                    <p className="mr-1 my-0">{d.address},</p>
                    <p className="mr-1 my-0">{d.postalCode}</p>
                    <p className="mr-1 my-0">{d.city},</p>
                    <p className="mr-1 my-0">{d.country}</p>
                  </span>
                </div>
                <div className="col-4 pr-0 pl-2 d-flex flex-column ">
                  <span className="font-size-regular txt-color-borders text-uppercase">{t("gln.label")}</span>
                  <span className="font-size-regular txt-color-dusty-orange font-weight-bold">{d.glns}</span>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return smallColumns;
};

export default SmallColumns;
