import GS1APIClient from "clients/GS1APIClient";
import { GS1Location } from "models/LocationModel";

function checkRegon(regon: string) {
  return GS1APIClient.request({
    url: "/locations/by_regon/",
    method: "GET",
    params: { regon },
  });
}

function getCompaniesData(
  pageSize: number,
  pages: number,
  sorted: string,
  filtered: { id: string; value: string }[],
  getAll: boolean,
) {
  let params: { [index: string]: any } = { pageSize, pages, sorted, getAll };
  filtered.forEach((filter) => (params[filter.id] = filter.value));
  return GS1APIClient.privateRequest({
    url: "/locations/companies/",
    method: "GET",
    params,
  });
}

function getHistoryForCompany(
  pageSize: number,
  pages: number,
  sorted: string,
  filtered: { id: string; value: string }[],
  selectedId: string,
) {
  let params: { [index: string]: any } = { pageSize, pages, sorted, selectedId };
  filtered.forEach((filter) => (params[filter.id] = filter.value));
  return GS1APIClient.privateRequest({
    url: "/history/",
    method: "GET",
    params,
  });
}

function getCompanyData(id: string) {
  return GS1APIClient.privateRequest({
    url: `/locations/by_id/${id}/`,
    method: "GET",
  });
}

function getGLNsData(pageSize: number, pages: number, sorted: string, filtered: { id: string; value: string }[]) {
  let params: { [index: string]: any } = { pageSize, pages, sorted };
  if (filtered) {
    filtered.forEach((filter) => (params[filter.id] = filter.value));
  }
  return GS1APIClient.privateRequest({
    url: "/locations/glns/",
    method: "GET",
    params,
  });
}

function sendImportFile(file: File, selectedLocationId: string) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("selectedLocationId", selectedLocationId);
  return GS1APIClient.privateRequest({
    url: "/locations/import/" + file.name + "/",
    method: "PUT",
    data: formData,
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  });
}

function checkImportStatus(taskId: string, cacheKey: string) {
  return GS1APIClient.privateRequest({
    url: "/locations/check_import_status/" + taskId + "/" + cacheKey + "/",
    method: "GET",
  });
}

function getAllCities() {
  return GS1APIClient.privateRequest({
    url: "/locations/get_cities/",
    method: "GET",
  });
}

function exportLocations(filtered: object) {
  return GS1APIClient.privateRequest({
    url: "/locations/export/",
    method: "POST",
    data: { filtered },
  });
}

function checkExportStatus(taskId: string, cacheKey: string) {
  return GS1APIClient.privateRequest({
    url: "/locations/check_export_status/" + taskId + "/" + cacheKey + "/",
    method: "GET",
  });
}

function getLocationDetails(locationId: string) {
  return GS1APIClient.privateRequest({
    url: `/locations/location_details/${locationId}/`,
    method: "GET",
  });
}

function saveLocationDetails(locationDetails: any) {
  return GS1APIClient.privateRequest({
    url: "/locations/location_details/" + locationDetails.id + "/",
    method: "PATCH",
    data: locationDetails,
  });
}

function saveCompanyDetails(locationDetails: any) {
  return GS1APIClient.privateRequest({
    url: "/locations/company_details/" + locationDetails.id + "/",
    method: "PATCH",
    data: locationDetails,
  });
}

function getMyWhitelistCompanies(locationId: string, mode: string) {
  return GS1APIClient.privateRequest({
    url: "/locations/white_list/" + locationId + "/",
    method: "GET",
    params: { mode },
  });
}

function getNotInWhitelistCompanies(locationId: string, mode: string) {
  return GS1APIClient.privateRequest({
    url: "/locations/white_list/" + locationId + "/",
    method: "GET",
    params: { mode },
  });
}

function updateCompanyToWhitelist(
  locationId: string,
  whitelist: boolean,
  locationsId: string[],
  restrictedSections: string[],
) {
  return GS1APIClient.privateRequest<GS1Location>({
    url: "/locations/white_list/" + locationId + "/",
    method: "POST",
    data: {
      whitelist,
      locationsId,
      restrictedSections,
    },
  });
}

const LocationService = {
  checkRegon,
  getCompaniesData,
  getCompanyData,
  getGLNsData,
  sendImportFile,
  checkImportStatus,
  getAllCities,
  exportLocations,
  checkExportStatus,
  getLocationDetails,
  saveLocationDetails,
  saveCompanyDetails,
  getMyWhitelistCompanies,
  getNotInWhitelistCompanies,
  updateCompanyToWhitelist,
  getHistoryForCompany,
};

export default LocationService;
