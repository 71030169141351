import moment from "moment";

import React from "react";
import { useTranslation } from "react-i18next";

import { Suggestion } from "models/SuggestionModel";

import "../../components/suggestion/suggestion.scss";

interface Props {
  data: Suggestion[];
  onArchive: (id: string) => any;
}

const SuggestionList: React.FC<Props> = ({ data, onArchive }) => {
  const { t } = useTranslation();

  return (
    <div className="sugg-list">
      <div className="sugg-list__headline icon-text">
        <img src="/icons/icon-warning.svg" alt="icon-warning" />
        <span>{t("suggestion.list.headline")} ({data.length})</span>
      </div>
      {data.map((suggestion) => (
        <div className="sugg-list-item" key={`suggestion-${suggestion.id}`}>
          <div className="sugg-list-item__headline">
            <div className="sugg-list-item__date">{moment(suggestion.createdAt).format("HH:mm, DD.MM.YYYY")}</div>
            <div className="sugg-list-item__actions">
              <span className="sugg-list-item__action-link" onClick={() => onArchive(suggestion.id)}>
                {t("suggestion.actions.archive")}
              </span>
            </div>
          </div>
          <div className="sugg-list-item__body">{suggestion.body}</div>
        </div>
      ))}
    </div>
  );
};

export default SuggestionList;
