import React from "react";
import ReactTable from "react-table";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useHistory } from "react-router";

import TablePagination from "components/tablePagination/TablePagination";
import { FiltersGroup, Footer, Header, Messages } from "components";
import { State } from "models/StoreModel";
import { getGLNsData } from "store/thunk/DataThunk";
import { ScrollToTop } from "utils/ScrollUp";
import { getLastGlnRequestSuccess } from "../../store/actions/DataActions";
import SmallColumns from "./GlnContainerColumnsS";
import LargeColumns from "./GlnContainerColumnsL";

import * as dataThunk from "../../store/thunk/DataThunk";

import "react-table/react-table.css";

interface GlnContainerProps {
  networkOnline: boolean;
}

export const GlnContainer: React.FC<GlnContainerProps> = ({ networkOnline }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dataState = useSelector((state: State) => state.data);
  const dispatch = useDispatch();

  const fetchData = (tableState: any, instance: any) => {
    dispatch(getGLNsData(tableState.pageSize, tableState.page, tableState.sorted, tableState.filtered));
  };

  const storeForOfflineUse = (data: any) => {
    dispatch(getLastGlnRequestSuccess(data));
    dispatch(dataThunk.getLocationDetails(data.id));
  };

  const screenWidth = window.innerWidth;

  const filterOptions = [
    {
      id: "gln_number",
      value: t("filters.gln_number"),
      type: "number",
      validation: (value: string) => value.length !== 13,
      errorMessage: t("errors.gln_length"),
    },
    {
      id: "nip",
      value: t("nip").toUpperCase(),
      type: "number",
      validation: (value: string) => value.length !== 10,
      errorMessage: t("errors.nip_length"),
    },
    { id: "company_name", value: t("filters.company_name"), type: "text" },
    { id: "full_name", value: t("filters.location_name"), type: "text" },
    { id: "address", value: t("filters.address"), type: "text" },
  ];

  return (
    <>
      {networkOnline ? <Header withBottom={true} /> : <Header withBottom={false} withTitle={t("offline_mode")} />}
      <div className="container my-2">
        <div className="mb-4">
          <Messages />
        </div>
        {networkOnline && (
          <div className="context-menu row">
            <div className="col-md-9">
              <Breadcrumb>
                <BreadcrumbItem active>{t("localization.gln")}</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        )}
        {networkOnline && (
          <FiltersGroup className="mb-3" options={filterOptions} filtersKey="companies" multiple={true} />
        )}
        <ReactTable
          columns={screenWidth > 768 ? LargeColumns() : SmallColumns()}
          manual
          resizable={false}
          // when in offline mode the user should be presented with data in dataState.LastSearchedGlns
          data={networkOnline ? dataState.GLNRows : dataState.LastSearchedGlns}
          pages={networkOnline ? dataState.pages : 0}
          loading={dataState.loading}
          onFetchData={fetchData}
          minRows={0}
          className={`-striped -highlight ${screenWidth > 768 && "bg-white"}`}
          PaginationComponent={TablePagination}
          getTrProps={(state: any, rowInfo: any) => ({
            onClick: (e: any) => {
              history.push(`/passport/${rowInfo.original.id}`);
              storeForOfflineUse(rowInfo.original);
              ScrollToTop();
            },
          })}
          filtered={dataState.filters["companies"]}
          noDataText={t("no_data.text")}
        />
      </div>
      <Footer />
    </>
  );
};

export default GlnContainer;
