import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import AccountService from "services/AccountService";
import { User } from "models/UserModel";
import { Header, Input, Messages, Footer } from "components";
import PasswordRules, { validateNewPassword, PasswordRule } from "components/passwordRules/PasswordRules";
import { useMessage } from "hooks";
import {Spinner} from "react-bootstrap";

interface RouteParams {
  resetPasswordKey: string;
}

export const ResetPasswordContainer: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { resetPasswordKey } = useParams<RouteParams>();

  const { addMessage } = useMessage();
  const [user, setUser] = useState<User | null>(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [fetchingUser, setFetchingUser] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [passwordRulesFulfilled, setPasswordRulesFulfilled] = useState<PasswordRule[]>([]);

  const passwordIsValid = () => {
    const userEmail = user ? user.email : "";
    const { isValid, rulesFulfilled } = validateNewPassword(password, passwordConfirm, userEmail);
    setPasswordRulesFulfilled(rulesFulfilled);
    return isValid;
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);
    if (passwordIsValid()) {
      try {
        await AccountService.resetPasswordByKey(resetPasswordKey, password);
        setDisableSubmit(false);
        history.push("/login");
        addMessage({
          type: "success",
          text: t("reset_password.success"),
        });
      } catch (error) {
        setDisableSubmit(false);
        addMessage({
          type: "error",
          text: t("reset_password.failure"),
        });
      }
    }
  };

  const loadUser = async () => {
    setFetchingUser(true);
    try {
      const response = await AccountService.loadUserToResetPassword(resetPasswordKey);
      const user = response.data.user;
      setUser(user);
    } catch (error) {
      // User not found
    }
    setFetchingUser(false);
  };

  useEffect(() => {
    loadUser();
  }, [resetPasswordKey]);

  useEffect(() => {
    passwordIsValid();
  }, [password, passwordConfirm, user]);

  if (fetchingUser) {
    return <></>;
  }

  return (
    <>
    <Header withBottom={false} withTitle={t("reset_password.reset_header")} />
    <div className="container">
      {!user ? (
        <div className="container pt-5">
          <div className="col-md-12 mx-auto pt-5 text-center">{t("errors.fetch_data")}</div>
        </div>
      ) : (
        <>
          <div className="mt-3">
            <Messages />
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="bottom-part row my-5 justify-content-center">
                <div className="bg-white border-top-3 border-top-3-dusty-orange mx-6 w-100">
                  <div className="row mt-3 text-center">
                    <h5 className="text-left ml-5 w-100 pt-2 button-font txt-color-marine-blue">
                      <strong>{t("reset_password.title.passwordInput")}</strong>
                    </h5>
                    <hr style={{ width: "80%" }} />
                  </div>
                  <div className="row mb-3 d-flex justify-content-center">
                    <div className="w-100 mx-5">
                      <div className="d-flex flex-column mb-3">
                        <span className="txt-color-bluish-grey font-size-normal">
                          {t("set_password.your_login")} <strong className="text-black">{user.email}</strong>
                        </span>
                      </div>
                      <div className="username-input d-flex flex-column">
                        <Input
                          label={t("set_password.your_password_label")}
                          inputType="password"
                          required={true}
                          name="password"
                          value={password}
                          handleChange={({ password }: any) => setPassword(password)}
                          onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === "Enter") handleSubmit();
                          }}
                        />
                      </div>
                      <PasswordRules passwordRulesFulfilled={passwordRulesFulfilled} />
                      <div className="username-input d-flex flex-column mt-2">
                        <Input
                          label={t("set_password.your_password_confirm_label")}
                          inputType="password"
                          required={true}
                          value={passwordConfirm}
                          name="passwordConfirm"
                          handleChange={({ passwordConfirm }: any) => setPasswordConfirm(passwordConfirm)}
                          onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === "Enter") handleSubmit();
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column txt-color-bluish-grey mb-2">
                        <button className="text-white border-0 w-100 py-2 mt-2 bg-dusty-orange"
                                disabled={disableSubmit}
                                onClick={handleSubmit}>
                          {t("reset_password.reset_button")}
                          {disableSubmit ? <Spinner className="ml-2" animation="border" variant="light" size="sm" /> : <></>}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
      <Footer />
      </>
  );
};

export default ResetPasswordContainer;
