import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

interface RouteInterceptorProps {
  networkOnline: boolean;
  path: string;
  component: React.FC;
  privateRoute?: boolean;
  availableOffline: boolean;
}

export const RouteInterceptor: React.FC<RouteInterceptorProps> = ({
  privateRoute = true,
  path,
  component,
  networkOnline,
  availableOffline,
}): JSX.Element => {
  useEffect(() => {}, [networkOnline]); // Refresh based on networkActivity of the user

  if (!networkOnline && !availableOffline) {
    return <Redirect to="/offline_mode" />;
  }

  if (privateRoute)
    return <PrivateRoute networkOnline={networkOnline} exact={true} path={path} component={component} />;

  return <Route exact={true} path={path} component={component} />;
};

export default RouteInterceptor;
