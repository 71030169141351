import { useState, useMemo, useCallback } from "react";
import FormValidator, { FieldsConfig } from "../utils/FormValidator";

export default function useFormValidator(rules: FieldsConfig, deps: any[]) {
  const validator = useMemo(() => {
    return new FormValidator(rules);
  }, deps);

  const initialErrors = useMemo(() => {
    const errors = {};
    for (let key in validator.fields) {
      // @ts-ignore
      errors[key] = null;
    }
    return errors;
  }, [validator]);

  const [errors, setErrors] = useState(initialErrors);

  const validate = useCallback(
    (form, nested = false) => {
      if (!nested) {
        const { isValid, errors } = validator.validate(form);
        setErrors(errors);
        return isValid;
      } else {
        const { isValid, errors } = validator.validateNested(form);
        setErrors(errors);
        return isValid;
      }
    },
    [validator],
  );

  const setPartialErrors = useCallback((partialForm: any) => {
    setErrors((prevState) => ({ ...prevState, ...partialForm }));
  }, []);

  return [errors, setPartialErrors, validate];
}
