import React from "react";
import ReactTable from "react-table";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useHistory } from "react-router";

import { State } from "models/StoreModel";
import { getCompaniesData } from "store/thunk/DataThunk";
import TablePagination from "components/tablePagination/TablePagination";
import { FiltersGroup, Footer, Header } from "components";
import { ScrollToTop } from "utils/ScrollUp";

import SmallColumns from "./CompaniesContainerColumnsS";
import LargeColumns from "./CompaniesContainerColumnsL";

import "react-table/react-table.css";

export const CompaniesContainer: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dataState = useSelector((state: State) => state.data);
  const selectedLocation = useSelector((state: State) => state.account.selectedLocation);
  const user = useSelector((state: State) => state.account);

  const selectedLocationId = selectedLocation !== null ? selectedLocation.id : null;

  const dispatch = useDispatch();

  const isAuthorizedAdmin =
    selectedLocation !== null ? selectedLocation.isAuthorized && user.isAdminForSelectedLocation : false;

  const fetchData = (tableState: any, instance: any) => {
    dispatch(getCompaniesData(tableState.pageSize, tableState.page, tableState.sorted, tableState.filtered, false));
  };

  const screenWidth = window.innerWidth;

  const filterOptions = [
    { id: "company_name", value: t("filters.company_name"), type: "text" },
    {
      id: "nip",
      value: t("nip").toUpperCase(),
      type: "number",
      validation: (value: string) => value.length !== 10,
      errorMessage: t("errors.nip_length"),
    },
    { id: "address", value: t("filters.address"), type: "text" },
  ];

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-4">
        <div className="context-menu row">
          <div className="col-md-9">
            <Breadcrumb>
              <BreadcrumbItem active>{t("localization.company")}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <FiltersGroup
          className="mb-3"
          fullWidth={false}
          options={filterOptions}
          filtersKey="companies"
          multiple={true}
        />
        <ReactTable
          columns={screenWidth > 768 ? LargeColumns() : SmallColumns()}
          manual
          resizable={false}
          data={dataState.CompaniesRows}
          pages={dataState.pages}
          loading={dataState.loading}
          onFetchData={fetchData}
          minRows={0}
          pageSizeOptions={[5, 10, 20, 25]}
          defaultPageSize={10}
          className={`-striped -highlight ${screenWidth > 768 && "bg-white"}`}
          PaginationComponent={TablePagination}
          filtered={dataState.filters["companies"]}
          getTrProps={(state: any, rowInfo: any) => ({
            onClick: (e: any) => {
              if (selectedLocationId === rowInfo.original.id && isAuthorizedAdmin) {
                history.push(`/my_data/my_locations`);
              } else {
                history.push(`/companies/${rowInfo.original.id}`);
              }
              ScrollToTop();
            },
          })}
          noDataText={t("no_data.text")}
        />
      </div>
      <Footer />
    </>
  );
};

export default CompaniesContainer;
