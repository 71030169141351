import React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import { TableCell } from "components";
import { v4 as uuid } from "uuid";
import moment from "moment";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import { ColumnsProps } from "./UsersContainer";

const HTMLTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#002C6C",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "0",
    fontSize: "1em",
  },
}))(Tooltip);

const LargeColumns = ({ currentUser, editUser, setUserId, setModalShow, changeUserStatus }: ColumnsProps) => {
  const { t } = useTranslation();

  const largeColumns: Column[] = [
    {
      Header: t("users.name_header"),
      id: "full_name",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal"
            cssClassForSecond="font-size-regular"
            firstValue={d.fullName}
            showSecond={d.isCompanyRepresentative}
            secondValue={t("users.representative_person")}
          />
        );
      },
    },
    {
      Header: t("users.permission_group_header"),
      id: "permission_groups",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal"
            firstValue={t("permissions." + d.permissionGroups)}
            showSecond={false}
          />
        );
      },
    },
    {
      Header: t("users.email_header"),
      id: "email",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return <TableCell cssClassForFirst="txt-color-link font-size-normal" firstValue={d.email} showSecond={false} />;
      },
    },
    {
      Header: t("users.last_login"),
      id: "last_login",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        const lastLogin = moment(new Date(d.lastLogin));
        let date = lastLogin.format("YYYY-MM-DD");
        let time = lastLogin.format("H:mm");
        if (d.firstLogin) {
          return (
            <TableCell
              cssClassForFirst="font-size-normal"
              cssClassForSecond="font-size-regular"
              firstValue={date}
              secondValue={time}
            />
          );
        } else {
          return (
            <TableCell cssClassForFirst="font-size-normal" firstValue={t("user.not_first_login")} showSecond={false} />
          );
        }
      },
    },
    {
      Header: t("users.actions"),
      id: "edit_action",
      sortable: false,
      headerClassName: "font-size-normal px-2 py-3",
      className: "px-2",
      accessor: (d: any) =>
        d.email !== currentUser.email ? (
          <div key={uuid()}>
            <HTMLTooltip
              title={
                <div className="gs1-tooltip gotham-font-stack font-size-regular ">{t("tooltip.userSettings.edit")}</div>
              }
              placement="bottom">
              <Link
                className="btn btn-link txt-color-link fas fa-user-edit no-decoration"
                onClick={(e) => {
                  editUser(d.userId);
                }}
                to={d.userId === currentUser.id ? "/user/settings" : `users/edit/${d.userId}`}
              />
            </HTMLTooltip>
            <HTMLTooltip
              title={
                <div className="gs1-tooltip gotham-font-stack font-size-regular ">
                  {t("tooltip.userSettings.delete")}
                </div>
              }
              placement="bottom">
              <button
                className="btn btn-link txt-color-link fas fa-user-minus no-decoration"
                onClick={(e) => {
                  setUserId(d.userId);
                  setModalShow(true)
                }}
              />
            </HTMLTooltip>
            {d.userIsActive ? (
              <HTMLTooltip
                key={uuid()}
                title={
                  <div className="gs1-tooltip gotham-font-stack font-size-regular ">
                    {t("tooltip.userSettings.deactivate")}
                  </div>
                }
                placement="bottom">
                <button
                  className="btn btn-link txt-color-link fas fa-user-times no-decoration"
                  onClick={(e) => changeUserStatus(d.userId)}
                />
              </HTMLTooltip>
            ) : (
              d.firstLogin && (
                <HTMLTooltip
                  key={uuid()}
                  title={
                    <div className="gs1-tooltip gotham-font-stack font-size-regular ">
                      {t("tooltip.userSettings.activate")}
                    </div>
                  }
                  placement="bottom">
                  <button
                    className="btn btn-link txt-color-link fas fa-user-check no-decoration"
                    onClick={(e) => changeUserStatus(d.userId)}
                  />
                </HTMLTooltip>
              )
            )}
          </div>
        ) : (
          <div key={uuid()}>
            <HTMLTooltip
              title={
                <div className="gs1-tooltip gotham-font-stack font-size-regular ">{t("tooltip.userSettings.edit")}</div>
              }
              placement="bottom">
              <Link
                className="btn btn-link txt-color-link fas fa-user-edit no-decoration no-decoration"
                onClick={(e) => {
                  editUser(d.userId);
                }}
                to={d.userId === currentUser.id ? "/user/settings" : `users/edit/${d.userId}`}
              />
            </HTMLTooltip>
          </div>
        ),
    },
  ];

  return largeColumns;
};

export default LargeColumns;
