import { RefObject } from "react";
import { Card } from "react-bootstrap";

export const ScrollToTop = (behavior: ScrollOptions["behavior"] = "smooth") => {
  var scrollOptions: ScrollToOptions = {
    left: 0,
    top: 0,
    behavior: behavior,
  };
  window.scrollTo(scrollOptions);
};

export const ScrollToElement = (element: RefObject<Card<"div">> & RefObject<HTMLDivElement>) => {
  if (element.current !== null) {
    var scrollOptions: ScrollToOptions = {
      left: element.current.offsetLeft,
      top: element.current.offsetTop - 40,
      behavior: 'smooth'
    };
    window.scrollTo(scrollOptions)
  }
};
