import _ from "lodash";

import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import { BaseAction } from "models/StoreModel";
import { ReportForSectionsDict, SuggestionsDict } from "models/SuggestionModel";
import { FilterGroups } from "models/DataModel";
import * as dataActions from "../actions/DataActions";

export interface taskData {
  startedImport: boolean;
  taskId: string;
  rowsToProcess: number;
  importEnd: boolean;
  exportEnd: boolean;
  taskResult: any;
  cacheKey: string;
  taskStatus: boolean;
  processingRow: number;
  startedExport: boolean;
  errorsInExport: boolean;
  errorsInImport: boolean;
}

export interface whiteListData {
  permissionChanged: boolean;
  myCompanies: any;
  restCompanies: any;
}

export interface LocationDataI {
  id: string | null;
  isGsoneLocation: boolean | null;
  fullName: string | null;
  latitude: number | null;
  longitude: number | null;
  locationType: string[] | null;
  locationTypeOther: null;
  glnNumber: string | null;
  locationDetails: null;
  locationExtraDetails: {
    regon: string | null;
    name: string | null;
    nip: string | null;
    address: string | null;
    postalCode: string | null;
    postalCity: string | null;
    city: string | null;
    district: string | null;
    country: string | null;
    phoneNumber: string | null;
    internalNumber: string | null;
    homePage: string | null;
    companyEmail: string | null;
    companyProfile: [];
    companyProfileOther: null;
    agreeToSellingEmails: boolean | null;
    agreeToSellingPhones: boolean | null;
    agreeToMarketingEmails: boolean | null;
    agreeToMarketingPhones: boolean | null;
    agreeToSharingData: boolean | null;
  };
  parent: {
    id: string | null;
    fullName: string | null;
    locationExtraDetails: {
      regon: string | null;
      name: string | null;
      nip: string | null;
      address: string | null;
      postalCode: string | null;
      postalCity: string | null;
      city: string | null;
      district: string | null;
      country: string | null;
      phoneNumber: string | null;
      internalNumber: string | null;
      homePage: string | null;
      companyEmail: string | null;
      companyProfile: [];
      companyProfileOther: null;
      agreeToSellingEmails: boolean | null;
      agreeToSellingPhones: boolean | null;
      agreeToMarketingEmails: boolean | null;
      agreeToMarketingPhones: boolean | null;
      agreeToSharingData: boolean | null;
    };
    externalId: number | null;
    isGsoneLocation: boolean | null;
    whitelistEnabled: boolean | null;
    whitelist: [];
    restrictedSections: null;
    isAuthorized: boolean | null;
    confirmedStatute: boolean | null;
  };
}

export interface DataState {
  GLNRows: GLNrow[];
  CompaniesRows: any[];
  HistoryRows: any[];
  UsersRows: any[];
  MyLocationRows: any[];
  UserRows: any[];
  LastSearchedGlns: GLNrow[];
  LastSearchedGlnsPassportData: LocationDataI[];
  model: any;
  pages: number;
  loading: boolean;
  loadingError: boolean;
  saving: boolean;
  savingError: boolean;
  savingSuccess: boolean;
  companyDetails: any;
  savingType: string;
  filters: FilterGroups;
  emailExist: boolean;
  checkingEmail: boolean;
  changeStatusSuccess: boolean;
  changeStatusFailure: boolean;
  deleteSuccess: boolean;
  deleteFailure: boolean;
  deleteMessage: string;
  taskData: taskData;
  polandCities: object[];
  locationData: LocationDataI | {};
  whiteListData: whiteListData;
  suggestionReportForSections: ReportForSectionsDict;
  suggestions: SuggestionsDict;
}

const initialTaskData = {
  startedImport: false,
  taskId: "",
  rowsToProcess: 0,
  importEnd: false,
  exportEnd: false,
  cacheKey: "",
  taskStatus: false,
  processingRow: 0,
  errorsInImport: false,
  taskResult: [],
  startedExport: false,
  errorsInExport: false,
};

export const initialDataState: DataState = {
  GLNRows: [],
  CompaniesRows: [],
  HistoryRows: [],
  MyLocationRows: [],
  UserRows: [],
  UsersRows: [],
  LastSearchedGlns: [],
  LastSearchedGlnsPassportData: [],
  model: {},
  pages: 0,
  loading: false,
  loadingError: false,
  saving: false,
  savingError: false,
  savingSuccess: false,
  savingType: "",
  filters: {},
  companyDetails: {},
  emailExist: false,
  checkingEmail: false,
  changeStatusSuccess: false,
  changeStatusFailure: false,
  deleteSuccess: false,
  deleteFailure: false,
  deleteMessage: "",
  taskData: initialTaskData,
  polandCities: [],
  locationData: {},
  whiteListData: {
    permissionChanged: false,
    myCompanies: [],
    restCompanies: [],
  },
  suggestionReportForSections: {},
  suggestions: {},
};

export interface GLNrow {
  id: string;
  fullName: string;
  glnNumber: string;
  locationType: any;
  nip: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  shortName: string;
  glns: number;
  companyName: string;
  suggestionsCount: number;
}

function dataReducer(state: DataState = initialDataState, action: BaseAction): DataState {
  switch (action.type) {
    case "LOCATION_CHANGE":
      return {
        ...state,
        taskData: initialTaskData,
      };
    case dataActions.GET_GLN_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        GLNRows: [...action.payload.data.data],
        pages: action.payload.data.pages,
        loading: false,
        loadingError: false,
      };
    case dataActions.GET_GLN_REQUEST_FAILURE:
      return { ...state, loading: false, loadingError: true };
    case dataActions.GET_COMPANIES_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        CompaniesRows: [...action.payload.data.data],
        pages: action.payload.data.pages,
        loading: false,
        loadingError: false,
      };
    case dataActions.GET_COMPANIES_REQUEST_FAILURE:
      return { ...state, loading: false, loadingError: true };
    case dataActions.GET_MYLOCATIONS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        MyLocationRows: [...action.payload.data.data],
        pages: action.payload.data.pages,
        loading: false,
        loadingError: false,
      };
    case dataActions.GET_MYLOCATIONS_REQUEST_FAILURE:
      return { ...state, loading: false, loadingError: true };
    case dataActions.GET_HISTORY_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        HistoryRows: [...action.payload.data.data],
        pages: action.payload.data.pages,
        loading: false,
        loadingError: false,
      };
    case dataActions.GET_HISTORY_REQUEST_FAILURE:
      return { ...state, loading: false, loadingError: true };
    case dataActions.GET_USERS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        UserRows: [...action.payload.data.data],
        pages: action.payload.data.pages,
        loading: false,
        loadingError: false,
      };
    case dataActions.GET_USERS_REQUEST_FAILURE:
      return { ...state, loading: false, loadingError: true };

    case dataActions.GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: { ...action.payload.data },
        loading: false,
        loadingError: false,
      };
    case dataActions.GET_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loadingError: true,
      };
    case dataActions.SAVE_DATA_REQUEST:
      return {
        ...state,
        saving: false,
        savingSuccess: false,
        savingError: false,
        emailExist: false,
      };
    case dataActions.SAVE_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        saving: false,
        savingSuccess: true,
        savingType: action.payload,
        savingError: false,
      };
    case dataActions.SAVE_DATA_REQUEST_FAILURE:
      return {
        ...state,
        saving: false,
        savingError: true,
      };
    case dataActions.CHECKING_EMAIL:
      return {
        ...state,
        checkingEmail: true,
        emailExist: false,
      };
    case dataActions.EMAIL_ALREADY_EXIST:
      return {
        ...state,
        emailExist: true,
        checkingEmail: false,
      };
    case dataActions.EMAIL_NOT_EXIST:
      return {
        ...state,
        emailExist: false,
        checkingEmail: false,
      };
    case dataActions.GET_USER_DATA:
      return {
        ...state,
        loading: true,
        loadingError: false,
        savingSuccess: false,
      };
    case dataActions.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        model: action.payload,
        loadingError: false,
      };
    case dataActions.GET_USER_DATA_FAILURE:
      return {
        ...state,
        loadingError: true,
        model: {},
      };
    case dataActions.USER_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        changeStatusSuccess: true,
      };
    case dataActions.USER_CHANGE_STATUS_FAILURE:
      return {
        ...state,
        changeStatusFailure: true,
      };
    case dataActions.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        UsersRows: state.UsersRows.filter((row: any) => row.id !== action.payload.userId),
      };
    case dataActions.DELETE_USER_FAILURE:
      return {
        ...state,
        deleteFailure: true,
        deleteMessage: action.payload,
      };
    case dataActions.CLEAN_ADD_MESSAGE:
      return {
        ...state,
        savingSuccess: false,
        savingError: false,
      };
    case dataActions.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: [...action.payload.filters],
        },
      };
    case dataActions.SEND_IMPORT_FILE:
      return {
        ...state,
        taskData: {
          ...initialTaskData,
          startedImport: true,
        },
      };
    case dataActions.SEND_IMPORT_FILE_SUCCESS:
      return {
        ...state,
        taskData: {
          ...state.taskData,
          taskId: action.payload.taskId,
          rowsToProcess: action.payload.rowsToImport,
          cacheKey: action.payload.cacheKey,
        },
      };
    case dataActions.SEND_IMPORT_FILE_FAILURE:
      return {
        ...state,
        taskData: {
          ...state.taskData,
          errorsInImport: true,
        },
      };
    case dataActions.IMPORT_STATUS_SUCCESS:
      const importEnd = !!action.payload.taskStatus;
      return {
        ...state,
        taskData: {
          ...state.taskData,
          taskStatus: action.payload.taskStatus,
          importEnd: importEnd,
          startedImport: !importEnd,
          processingRow: action.payload.processingRow,
          taskResult: action.payload.taskResult,
        },
      };
    case dataActions.IMPORT_STATUS_FAILURE:
      return {
        ...state,
        taskData: {
          ...state.taskData,
          errorsInImport: true,
        },
      };
    case dataActions.SEND_EXPORT_FILE:
      return {
        ...state,
        taskData: {
          ...initialTaskData,
          startedExport: true,
        },
      };
    case dataActions.SEND_EXPORT_FILE_SUCCESS:
      return {
        ...state,
        taskData: {
          ...state.taskData,
          errorsInExport: false,
          rowsToProcess: action.payload.rowsToExport,
          cacheKey: action.payload.cacheKey,
          taskStatus: false,
          processingRow: 0,
          taskId: action.payload.taskId,
        },
      };
    case dataActions.SEND_EXPORT_FILE_FAILURE:
      return {
        ...state,
        taskData: {
          ...state.taskData,
          errorsInExport: true,
        },
      };
    case dataActions.EXPORT_STATUS_SUCCESS:
      const exportEnd = !!action.payload.taskStatus;
      return {
        ...state,
        taskData: {
          ...state.taskData,
          taskStatus: action.payload.taskStatus,
          exportEnd: exportEnd,
          startedExport: !exportEnd,
          processingRow: action.payload.processingRow,
          taskResult: action.payload.taskResult,
        },
      };
    case dataActions.EXPORT_STATUS_FAILURE:
      return {
        ...state,
        taskData: {
          ...state.taskData,
          errorsInImport: true,
        },
      };
    case dataActions.GET_CITIES_SUCCESS:
      return {
        ...state,
        polandCities: action.payload,
      };
    case dataActions.GET_CITIES_FAILURE:
      return {
        ...state,
      };
    case dataActions.GET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        locationData: action.payload,
      };
    case dataActions.GET_LOCATION_DETAILS_FAILURE:
      return {
        ...state,
        locationData: {},
      };
    case dataActions.SAVE_COMPANY_DETAILS_INPROGRESS:
      return {
        ...state,
        saving: true,
        savingSuccess: false,
        savingError: false,
      };
    case dataActions.SAVE_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        saving: false,
        savingSuccess: true,
      };
    case dataActions.SAVE_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        saving: false,
        savingError: true,
      };
    case dataActions.CHANGE_WHITELIST_PERMISSION_SUCCESS:
      return {
        ...state,
        whiteListData: {
          ...state.whiteListData,
          permissionChanged: true,
        },
      };
    case dataActions.CHANGE_WHITELIST_PERMISSION_FAILURE:
      return {
        ...state,
        whiteListData: {
          ...state.whiteListData,
          permissionChanged: false,
        },
      };
    case dataActions.GET_MY_WHITELIST_SUCCESS:
      return {
        ...state,
        whiteListData: {
          ...state.whiteListData,
          myCompanies: action.payload,
        },
      };
    case dataActions.GET_MY_WHITELIST_FAILURE:
      return {
        ...state,
        whiteListData: {
          ...state.whiteListData,
          myCompanies: [],
        },
      };
    case dataActions.GET_NOT_IN_WHITELIST_SUCCESS:
      return {
        ...state,
        whiteListData: {
          ...state.whiteListData,
          restCompanies: action.payload,
        },
      };
    case dataActions.GET_NOT_IN_WHITELIST_FAILURE:
      return {
        ...state,
        whiteListData: {
          ...state.whiteListData,
          restCompanies: [],
        },
      };
    case dataActions.UPDATE_WHITELIST_SUCCESS:
    case dataActions.UPDATE_RESTRICTED_SECTIONS_SUCCESS:
      return {
        ...state,
        companyDetails: { location: action.payload },
      };
    case dataActions.SET_SUGGESTION_REPORT_FOR_SECTIONS:
      return {
        ...state,
        suggestionReportForSections: action.payload.reduce(
          // @ts-ignore TODO: - define actions with specific payload in StoreModel.ts -> Action
          (dict, row) => ({
            ...dict,
            [row.section]: row.suggestionCount,
          }),
          {},
        ),
      };
    case dataActions.SET_SUGGESTIONS:
      return {
        ...state,
        suggestions: _.groupBy(action.payload, "section"),
      };

    case dataActions.GET_LAST_GLN_SEARCH_DATA_REQUEST_SUCCESS: {
      let data: GLNrow = action.payload; // Row of data taken from react-table

      const findDuplicates = () => {
        return state.LastSearchedGlns.findIndex((el) => {
          return el.id === data.id;
        });
      }; // returns -1 if not found. in other case returns index of found duplicate

      let duplicateIndexPosition: number = findDuplicates();

      let newGlnRows = state.LastSearchedGlns.filter((e) => {
        return e.id !== data.id;
      }); // get rid of first searched element

      let newLastSearchedGlns = [...newGlnRows, data];

      if (newLastSearchedGlns.length > 10) {
        let trashFirstElement = newLastSearchedGlns.shift()!;
      }

      if (duplicateIndexPosition > -1) {
        // no duplicates found
        return { ...state };
      } else {
        return { ...state, LastSearchedGlns: newLastSearchedGlns };
      }
    }

    case dataActions.GET_LAST_GLN_SEARCH_DATA_REQUEST_FAILURE:
      return { ...state, loading: false, loadingError: true };

    // LastSearchedGlnsPassportData

    case dataActions.GET_LAST_GLN_PASSPORT_SEARCH_DATA_REQUEST_SUCCESS: {
      let data: any = action.payload; // Row of data taken from react-table

      const findDuplicates = () => {
        return state.LastSearchedGlnsPassportData.findIndex((el) => {
          return el.id === data.id;
        });
      }; // returns -1 if not found. in other case returns index of found duplicate

      let duplicateIndexPosition: number = findDuplicates();

      let newGlnRows = state.LastSearchedGlnsPassportData.filter((e) => {
        return e.id !== data.id;
      }); // get rid of first searched element

      let newLastSearchedGlnsPassportData = [...newGlnRows, data];

      if (newLastSearchedGlnsPassportData.length > 10) {
        let trashFirstElement = newLastSearchedGlnsPassportData.shift()!;
      }

      if (duplicateIndexPosition > -1) {
        // no duplicates found
        return { ...state };
      } else {
        return { ...state, LastSearchedGlnsPassportData: newLastSearchedGlnsPassportData };
      }
    }

    case dataActions.GET_LAST_GLN_PASSPORT_SEARCH_DATA_REQUEST_FAILURE:
      return { ...state, loading: false, loadingError: true };

    default:
      return state;
  }
}

const persistConfig = {
  key: "data",
  storage: localStorage,
  whitelist: [
    "GLNRows",
    "CompaniesRows",
    "HistoryRows",
    "MyLocationRows",
    "UserRows",
    "UsersRows",
    "LastSearchedGlns",
    "LastSearchedGlnsPassportData",
  ],
};

export default persistReducer(persistConfig, dataReducer);
