import React from "react";
import { FilterProps } from "../Filter";

export const NumericFilter: React.FC<FilterProps> = ({ id, value, handleChange, className }) => {
  const onChange = (e: any) => {
    handleChange(e);
  };

  const checkIfNumber = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/\d/.test(keyValue)) {
      e.preventDefault();
    }
  };

  return (
    <input
      key={id}
      type="text"
      value={value}
      className={"form-control rounded-0 border search-input " + className}
      onChange={onChange}
      onKeyPress={checkIfNumber}
    />
  );
};

export default NumericFilter;
