import { v4 as uuid } from "uuid";

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";

import { saveMeAndUpdate } from "store/thunk/AccountThunk";
import { State, ThunkDispatch } from "models/StoreModel";
import { Membership } from "models/UserModel";
import FileInput from "components/Inputs/FileInput/FileInput";
import { Footer, Header, Input, NumberInput, Messages, CheckBox, RadioInput } from "components";
import { useFormValidator, useMessage } from "hooks";
import { ScrollToTop } from "utils/ScrollUp";

const AccountSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch>();
  const account = useSelector((state: State) => state.account);
  const { user: currentUser, selectedLocation, isAdminForSelectedLocation, acceptedBySa } = account;

  useEffect(() => {
    ScrollToTop();
  }, []);

  if (!currentUser || !selectedLocation) {
    throw new Error("Invariant - for this route `currentUser` and` selectedLocation` are required.");
  }

  const currentMembership = currentUser.membership.find((membership) => {
    return membership.location.id === selectedLocation.id;
  }) as Membership;

  const { messages, addMessage } = useMessage();
  const [membershipID, setMembershipID] = useState();
  const [firstLogin, setFirstLogin] = useState(false);

  const [userSettings, setUserSettings] = useState<any>({
    ...currentUser,
    permissions: "",
    isCompanyRepresentative: false,
    representativeFile: undefined,
    firstLoginConfirmed: false,
  });

  useEffect(() => {
    setMembershipID(currentMembership.id);
    setUserSettings({
      ...currentUser,
      phoneNumber: currentMembership.userPhoneNumber,
      position: currentMembership.userPosition,
      internalNumber: currentMembership.userInternalNumber,
      permissions: currentMembership.permissionGroup,
      isCompanyRepresentative: currentMembership.isCompanyRepresentative,
      firstLoginConfirmed: currentMembership.firstLoginConfirmed,
    });
    setFirstLogin(currentMembership.firstLoginConfirmed);
  }, [selectedLocation.id, currentMembership]);

  const [settingFormErrors, setSettingFormErrors, validateSettingsForm] = useFormValidator(
    {
      internalNumber: [],
      phoneNumber: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isGsonePhoneNumber", message: t("form.validation.phone_format") },
        {
          rule: "IsLengthOptions",
          options: { min: 9, max: 15 },
          message: t("form.validation.hasToBeLessThan15AndMoreThan9"),
        },
      ],
      firstName: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 50 },
          message: t("form.validation.hasToBeLessThan50"),
        },
      ],
      lastName: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 50 },
          message: t("form.validation.hasToBeLessThan50"),
        },
      ],
      position: [{ rule: "isRequired", message: t("form.validation.required") }],
      isCompanyRepresentative: [],
    },
    [t],
  );

  const [agreementsErrors, setAgreementsErrors, validateAgreementsForm] = useFormValidator(
    {
      agreeToMarketingEmails: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToMarketingPhones: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToSellingEmails: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToSellingPhones: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToSharingData: [{ rule: "isBool", message: t("form.validation.required") }],
    },
    [t],
  );

  const [allChecked, setAllChecked] = useState(false);

  const phonesSellingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const emailSellingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const emailMarketingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const phonesMarketingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const dataSharingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];

  const setAllPermissions = (value: boolean) => {
    setPartialForm({
      agreeToSellingEmails: value,
      agreeToSellingPhones: value,
      agreeToMarketingEmails: value,
      agreeToMarketingPhones: value,
      agreeToSharingData: value,
    });
  };

  useEffect(() => {
    const agreements = {
      agreeToSellingEmails: userSettings.agreeToSellingEmails,
      agreeToSellingPhones: userSettings.agreeToSellingPhones,
      agreeToMarketingEmails: userSettings.agreeToMarketingEmails,
      agreeToMarketingPhones: userSettings.agreeToMarketingPhones,
      agreeToSharingData: userSettings.agreeToSharingData,
    };
    const allChildrenChecked = Object.values(agreements).every((v) => v);
    setAllChecked(allChildrenChecked);
  }, [
    userSettings.agreeToMarketingEmails,
    userSettings.agreeToMarketingPhones,
    userSettings.agreeToSellingEmails,
    userSettings.agreeToSellingPhones,
    userSettings.agreeToSharingData,
  ]);

  const setPartialForm = useCallback((partialForm: any) => {
    setUserSettings((prevState: any) => ({ ...prevState, ...partialForm }));
  }, []);

  const updateUser = async () => {
    const withRegisterFile =
      isAdminForSelectedLocation &&
      userSettings.isCompanyRepresentative &&
      userSettings.representativeFile !== undefined;

    try {
      await dispatch(
        saveMeAndUpdate(
          {
            user: userSettings,
            isCompanyRepresentative: userSettings.isCompanyRepresentative,
            permissionGroup: userSettings.permissions,
            userPhoneNumber: userSettings.phoneNumber,
            userInternalNumber: userSettings.internalNumber,
            userPosition: userSettings.position,
            firstLoginConfirmed: true,
          },
          selectedLocation.id,
          membershipID,
          withRegisterFile,
        ),
      );
      if (!firstLogin) {
        return <Redirect to={"/"} />;
      }
      addMessage({
        type: "success",
        text: t("user.settings.saving.success"),
      });
    } catch (error) {
      addMessage({
        type: "error",
        text: t("user.settings.saving.failure"),
      });
    }
    ScrollToTop();
  };

  const saveUserSettings = () => {
    //@ts-ignore
    const validData = validateSettingsForm(userSettings);

    if (validData) {
      if (isAdminForSelectedLocation) {
        //@ts-ignore
        const validAgreements = validateAgreementsForm(userSettings);
        if (validAgreements) {
          updateUser();
        }
      } else {
        updateUser();
      }
    }
  };

  const showIsRepresentativeError = () => {
    return !(
      settingFormErrors.hasOwnProperty("isCompanyRepresentative") &&
      //@ts-ignore
      settingFormErrors["isCompanyRepresentative"] === null
    );
  };

  const [showFullDisclaimer, setShowFullDisclaimer] = useState(false);

  const toggleFullDisclaimer = () => {
    setShowFullDisclaimer((prevState: any) => {
      return !prevState;
    });
    return showFullDisclaimer;
  };

  const showFileMessage = () => {
    if (userSettings.representativeFile) {
      if (userSettings.representativeFile.name !== undefined) {
        return t("input.file") + ": " + userSettings.representativeFile.name;
      }
      return t("input.no_file");
    } else {
      return t("input.no_file");
    }
  };

  return (
    <>
      <Header withBottom={acceptedBySa} />

      {messages.length > 0 && (
        <div className="container mt-3">
          <div className="col-md-10 px-0 mx-auto">
            <Messages />
          </div>
        </div>
      )}

      <div className="container d-flex mx-auto my-4">
        <div className="row">
          {currentMembership.firstLoginConfirmed && (
            <div className="col-12 mb-2">
              <Breadcrumb>
                <BreadcrumbItem active>{t("localization.settings")}</BreadcrumbItem>
              </Breadcrumb>
            </div>
          )}
          <div className="border-top-3-dusty-orange col-12 py-4 container bg-white">
            <div className="row">
              {!currentMembership.firstLoginConfirmed && !isAdminForSelectedLocation && !acceptedBySa && (
                <div className="col-12 mt-2 mb-4 font-size-normal txt-color-marine-blue">
                  {t("account.settings.acceptance.criteria.accept")}
                </div>
              )}
              {!currentMembership.firstLoginConfirmed && isAdminForSelectedLocation && !acceptedBySa && (
                <div className="col-12 mt-2 mb-4 font-size-normal txt-color-marine-blue">
                  {t("account.settings.acceptance.criteria.fill.accept")}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <h5 className="font-size-normal  txt-color-marine-blue">{t("personalData")}</h5>
                <Input
                  label={t("users.add.firstName")}
                  inputType={"text"}
                  errors={settingFormErrors}
                  handleChange={setPartialForm}
                  name={"firstName"}
                  value={userSettings.firstName}
                />
                <Input
                  label={t("users.add.lastName")}
                  inputType={"text"}
                  errors={settingFormErrors}
                  handleChange={setPartialForm}
                  name={"lastName"}
                  value={userSettings.lastName}
                />
                <Input
                  label={t("users.add.position")}
                  errors={settingFormErrors}
                  inputType={"text"}
                  handleChange={setPartialForm}
                  name={"position"}
                  value={userSettings.position}
                />
                <Input
                  label={t("users.add.email")}
                  inputType={"text"}
                  handleChange={setPartialForm}
                  name={"email"}
                  disabled={true}
                  value={userSettings.email}
                />
                <div className="d-flex flex-column flex-lg-row mb-2 font-size-regular">
                  <NumberInput
                    label={t("companyInformation.form.right.phone")}
                    name="phoneNumber"
                    errors={settingFormErrors}
                    required={true}
                    phoneInput={true}
                    placeholder={t("placeholder.phoneNumber")}
                    className="px-0 pr-lg-3 col-xs-12 col-md-12 col-lg-6 align-self-start"
                    value={userSettings.phoneNumber}
                    inputType="text"
                    handleChange={setPartialForm}
                  />
                  <NumberInput
                    label={t("users.add.internal_phone")}
                    className="px-0 pl-lg-3 col-xs-12 col-md-12 col-lg-6 align-self-start"
                    name="internalNumber"
                    errors={settingFormErrors}
                    value={userSettings.internalNumber || ""}
                    inputType="text"
                    handleChange={setPartialForm}
                  />
                </div>
                <div className="d-flex">
                  <span className="font-size-regular ">
                    {t("users.add.permissions")}
                    <span className="ml-1 font-weight-bold">
                      {userSettings.permissions === "location_admin"
                        ? t("user.permission.admin")
                        : userSettings.permissions === "location_user"
                        ? t("user.permission.basic")
                        : "błąd"}
                    </span>
                  </span>
                </div>
                {isAdminForSelectedLocation && (
                  <div className="mt-3 mb-1">
                    <div className="font-size-normal">{showFileMessage()}</div>
                    <FileInput
                      name="representativeFile"
                      value={userSettings.representativeFile}
                      changeHandler={setPartialForm}
                      label={t("register.form.step3.left.label.attachement")}
                    />
                    <CheckBox
                      value={userSettings.isCompanyRepresentative}
                      name="isCompanyRepresentative"
                      label={t("register.form.step3.left.label.agreement")}
                      showError={showIsRepresentativeError()}
                      onChange={(checked) => setPartialForm({ isCompanyRepresentative: checked })}
                    />
                    <div className="font-size-regular mt-3">
                      <p className="d-inline">{t("register.new_user.template_text")}</p>
                      <Link
                        target="_blank"
                        className="txt-color-link d-inline"
                        download="wzor-uprawnienia.pdf"
                        to="/media/wzor-uprawnienia.pdf">
                        {t("register.new_user.template")}
                      </Link>
                    </div>
                  </div>
                )}
                <div className="d-none d-md-flex align-self-start">
                  <Button
                    className="bg-dusty-orange mt-5 font-size-normal  font-weight-bold rounded-0"
                    variant="contained"
                    color="primary"
                    onClick={saveUserSettings}>
                    {t("navigation.stepper.save")}
                  </Button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mx-auto">
                  {isAdminForSelectedLocation && (
                    <div className="agreements bg-white mx-auto">
                      <div className="d-flex my-3">
                        <CheckBox
                          label={t("form.agreements.details.checkAll")}
                          value={allChecked}
                          labelBold={true}
                          onChange={(checked) => setAllPermissions(checked)}
                          name="checkboxForAllAgreements"
                        />
                      </div>
                      <h3 className="font-size-small text-uppercase font-color-bluish-grey">
                        {t("user.settings.agreements.title")}
                      </h3>
                      <div className="d-flex py-2">
                        <RadioInput
                          label={t("agreeToSellingEmailsForUser")}
                          name="agreeToSellingEmails"
                          errors={agreementsErrors}
                          options={emailSellingOptions}
                          handleInput={setPartialForm}
                          direction={"md-row flex-column"}
                          defaultValue={userSettings.agreeToSellingEmails}
                        />
                      </div>
                      <div className="d-flex py-2">
                        <RadioInput
                          label={t("agreeToSellingPhonesForUser")}
                          name="agreeToSellingPhones"
                          errors={agreementsErrors}
                          options={phonesSellingOptions}
                          handleInput={setPartialForm}
                          direction={"md-row flex-column"}
                          defaultValue={userSettings.agreeToSellingPhones}
                        />
                      </div>
                      <div className="d-flex py-2">
                        <RadioInput
                          label={t("agreeToSharingDataForUser")}
                          name="agreeToSharingData"
                          errors={agreementsErrors}
                          options={dataSharingOptions}
                          handleInput={setPartialForm}
                          direction={"md-row flex-column"}
                          defaultValue={userSettings.agreeToSharingData}
                        />
                      </div>
                      <div className="d-flex py-2">
                        <RadioInput
                          label={t("agreeToMarketingEmailsForUser")}
                          name="agreeToMarketingEmails"
                          errors={agreementsErrors}
                          options={emailMarketingOptions}
                          handleInput={setPartialForm}
                          direction={"md-row flex-column"}
                          defaultValue={userSettings.agreeToMarketingEmails}
                        />
                      </div>
                      <div className="d-flex py-2">
                        <RadioInput
                          label={t("agreeToMarketingPhonesForUser")}
                          name="agreeToMarketingPhones"
                          options={phonesMarketingOptions}
                          errors={agreementsErrors}
                          handleInput={setPartialForm}
                          direction={"md-row flex-column"}
                          defaultValue={userSettings.agreeToMarketingPhones}
                        />
                      </div>
                      <div className="error-label font-size-regular my-1">
                        {Object.values(agreementsErrors).find((el) => {
                          return el !== null;
                        }) !== undefined
                          ? t("form.agreements.someMissing")
                          : ""}
                      </div>
                    </div>
                  )}
                  <div className="d-flex py-2">
                    <div className="disclaimer w-100 font-size-regular  txt-color-bluish-grey border rounded-0 p-3">
                      <div className="row mt-1 mb-2">
                        <div className="col-md-8">
                          <h5 className="font-size-regular m-0  text-uppercase">
                            {t("registration.legal.disclaimer.partOne.header")}
                          </h5>
                        </div>
                        <div className="col-md-4 d-flex flex-row-reverse">
                          <button
                            onClick={toggleFullDisclaimer}
                            className="btn-link btn p-0 font-size-regular txt-color-link  text-uppercase">
                            {showFullDisclaimer ? t("rollup") : t("rollout")}
                          </button>
                        </div>
                      </div>
                      {showFullDisclaimer ? (
                        <div style={{ whiteSpace: "pre-line" }}>
                          <p className="">
                            {t("registration.legal.disclaimer.partOne")}
                            <a
                              className="txt-color-link"
                              href={"mailto:" + t("registration.legal.disclaimer.partOneEmail")}>
                              {t("registration.legal.disclaimer.partOneEmail")},
                            </a>
                            {t("registration.legal.disclaimer.partOneAfterEmail")}
                          </p>

                          <h2 className="font-size-regular m-0  text-uppercase">
                            {t("registration.legal.disclaimer.partTwo.header")}
                          </h2>

                          <p>
                            {t("registration.legal.disclaimer.partTwo")}
                            <a
                              className="txt-color-link"
                              href={"mailto:" + t("registration.legal.disclaimer.partTwoEmail")}>
                              {t("registration.legal.disclaimer.partTwoEmail")}
                            </a>
                            {t("registration.legal.disclaimer.partTwoAfterEmail")}
                            <a
                              className="txt-color-link"
                              href={t("registration.legal.disclaimer.partTwoAfterEmailWebsite")}>
                              {t("registration.legal.disclaimer.partTwoAfterEmailWebsite")}
                            </a>
                            .
                          </p>
                          <h2 className="font-size-regular m-0  text-uppercase">
                            {t("registration.legal.disclaimer.partThree.header")}
                          </h2>
                          <p className="my-2">{t("registration.legal.disclaimer.partThree")}</p>
                          <h2 className="font-size-regular m-0  text-uppercase">
                            {t("registration.legal.disclaimer.partFour.header")}
                          </h2>
                          <p className="my-2">{t("registration.legal.disclaimer.partFour")}</p>
                          <h2 className="font-size-regular m-0  text-uppercase">
                            {t("registration.legal.disclaimer.partFive.header")}
                          </h2>
                          <p className="my-2">{t("registration.legal.disclaimer.partFive")}</p>
                          <h2 className="font-size-regular m-0  text-uppercase">
                            {t("registration.legal.disclaimer.partSix.header")}
                          </h2>
                          <p className="my-2">{t("registration.legal.disclaimer.partSix")}</p>
                        </div>
                      ) : (
                        t("registration.legal.disclaimer.partOne").slice(0, 100) + "..."
                      )}
                    </div>
                  </div>
                  <div className="d-flex d-md-none align-self-start">
                    <Button
                      className="bg-dusty-orange mt-5 font-size-normal  font-weight-bold rounded-0"
                      variant="contained"
                      color="primary"
                      onClick={saveUserSettings}>
                      {t("navigation.stepper.save")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccountSettings;
