import { EditQuestionProps } from "../PassportUtils";
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import NumericInput from "../../Inputs/NumericInput";

const EditSimpleNumericQuestion: React.FC<EditQuestionProps> = ({ field, form, handleUpdate }) => {
  const { t } = useTranslation();
  const valueFromObject = parseInt(_.get(form, field.value, 0)) || 0;
  const [inputValue, setInputValue] = useState({ [field.key]: valueFromObject });

  const updateInputValue = (value: any) => {
    setInputValue(value);
    _.set(form, field.value, value[field.key]);
    handleUpdate(form);
  };

  return (
    <div className={"my-2 mx-1 " + (field.newLineAfter ? "p-0 col-md-12" : "")}>
      <NumericInput
        key={"input-" + field.key}
        value={inputValue[field.key]}
        label={t("passport.location." + field.key)}
        inputType="number"
        handleChange={updateInputValue}
        errors={{ [field.key]: null }}
        name={field.key}
      />
    </div>
  );
};

export default EditSimpleNumericQuestion;
