import React, { useState } from "react";
import _ from "lodash";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Details from "./Details";

interface CompanyInfoProps {
  companyId: string | undefined;
  companyDetails: object;
  fromState: boolean;
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({ companyId = null, companyDetails = {}, fromState }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(true);
  return (
    <div className="row">
      <div className="container">
        {!_.isEmpty(companyDetails) ? (
          <Breadcrumb>
            {!fromState ? (
              <>
                <BreadcrumbItem href="/companies">
                  <span className="txt-color-link">{t("localization.company")}</span>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <span>{_.get(companyDetails, ["fullName"], "")}</span>
                </BreadcrumbItem>
              </>
            ) : (
              <BreadcrumbItem active>
                <span>{t("header.bottom.myLocations")}</span>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        ) : (
          <></>
        )}
      </div>
      {showDetails ? (
        <div className="container my-2">
          <div className="border border-top-3-marine-blue col-md-12 bg-white row d-flex mx-auto p-3 company-details-container">
            <div className="row col-md-12 justify-content-between d-flex">
              <div className="col-8 px-0">
                <h5 className="font-size-medium txt-color-marine-blue mb-0">{t("companyInfo.company.data")}</h5>
              </div>
              <div className="col-4 px-0 d-flex align-self-center justify-content-end">
                <span className="minimize d-flex flex-align-center justify-content-end">
                  <img
                    src="/icons/Fold.svg"
                    alt="minimize results"
                    className="cursor-pointer"
                    onClick={() => {
                      setShowDetails((prevState: any) => {
                        return !prevState;
                      });
                    }}
                  />
                </span>
              </div>
              <hr />
            </div>
            <Details companyDetails={companyDetails} />
          </div>
        </div>
      ) : (
        <div className="container my-2">
          <div className="border border-top-3-marine-blue col-md-12 bg-white row d-flex mx-auto p-3">
            <div className="row col-md-12">
              <div className="col-8 px-0">
                <h5 className="font-size-medium txt-color-marine-blue mb-0">{t("companyInfo.company.data")}</h5>
              </div>
              <div className="col-4 px-0">
                <span className="minimize d-flex flex-align-center justify-content-end">
                  <img
                    src="/icons/Expand.svg"
                    alt="expand results"
                    className="cursor-pointer"
                    onClick={() => {
                      setShowDetails((prevState: any) => {
                        return !prevState;
                      });
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyInfo;
