import { v4 as uuid } from "uuid";

import { ThunkResult } from "models/StoreModel";
import { MessageOptions } from "models/MessageModel";
import * as MessageActions from "store/actions/MessageActions";

export function removeCurrentMessage(): ThunkResult<any> {
  return (dispatch, getState) => {
    const { messages } = getState().message;
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      dispatch(MessageActions.removeMessage(lastMessage.id));
    }
  };
}

export function addMessage(message: MessageOptions, replaceCurrent: boolean): ThunkResult<string> {
  return (dispatch) => {
    if (replaceCurrent) {
      dispatch(removeCurrentMessage());
    }
    const id = uuid();
    dispatch(MessageActions.addMessage({ id, ...message }));
    return id;
  };
}
