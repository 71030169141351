import { EditQuestionProps } from "../PassportUtils";
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DateTime from "react-datetime";
import moment from "moment";

const EditTimePickerQuestion: React.FC<EditQuestionProps> = ({ field, errors, form, handleUpdate }) => {
  const { t } = useTranslation();
  const valueFromObject = moment(_.get(form, field.value), "H:mm");

  const errorKey = field.subKey !== undefined ? field.subKey + "-" + field.key : field.key;

  const [inputValue, setInputValue] = useState(valueFromObject);

  const updateInputValue = (value: any) => {
    if (value === "") {
      _.set(form, field.value, value);
      handleUpdate(form);
    }
    const toMomentValue = moment(value, "H:mm");
    if (toMomentValue.isValid()) {
      setInputValue(value);
      _.set(form, field.value, toMomentValue.format("H:mm"));
      handleUpdate(form);
    }
  };

  const blurInputValue = (value: any) => {
    const toMomentValue = moment(value, "H:mm");
    if (toMomentValue.isValid()) {
      setInputValue(toMomentValue);
    }
  };

  const isValid = () => {
    if (_.isEmpty(errors) || errors[errorKey] === undefined) return true;
    return errors.hasOwnProperty(errorKey) && errors[errorKey] == null;
  };

  return (
    <div className="font-size-normal justify-content-end col-md-3 pt-2 d-flex flex-column company-details">
      <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase">
        {t("passport.location." + field.key)}
      </label>
      <span className="font-size-normal details-value font-weight-bold border">
        <DateTime
          onChange={updateInputValue}
          onBlur={blurInputValue}
          value={inputValue}
          dateFormat={false}
          timeFormat="H:mm"
          viewMode={"time"}
        />
      </span>
      {!isValid() ? <label className="txt-color-bluish-grey text-error text-right">{t(errors[errorKey])}</label> : ""}
    </div>
  );
};

export default EditTimePickerQuestion;
