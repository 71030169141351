import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import { ColumnsProps } from "./UsersContainer";

const SmallColumns = ({ currentUser, editUser, setUserId, setModalShow, changeUserStatus }: ColumnsProps) => {
  const { t } = useTranslation();

  const smallColumns = [
    {
      headerClassName: "d-none",
      id: "permission_groups",
      className: "",
      accessor: (d: any) => {
        const lastLogin = moment(new Date(d.lastLogin));
        let date = lastLogin.format("YYYY-MM-DD");
        let time = lastLogin.format("H:mm");

        return (
          <div className="container bg-white p-3 my-3">
            <div className="row w-100">
              <div style={{ wordBreak: "normal" }} className="d-flex col-12 col-sm-8">
                <div className="container d-flex my-2">
                  <div className="row w-100 d-flex">
                    <div className="d-flex flex-column col-12 px-0">
                      <span className="font-size-normal font-weight-bold txt-color-marine-blue mb-2">
                        {t("users.name_header")}:
                      </span>
                      <div className="d-flex flex-column my-2">
                        <span className="font-size-regular txt-color-marine-blue">
                          {t("users.user_details_header")}:
                        </span>
                        <span className="font-size-regular txt-color-primary">{d.fullName}</span>
                        <span className="font-size-regular txt-color-primary">{d.email}</span>
                        <span className="font-size-regular txt-color-primary">{d.isCompanyRepresentative}</span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span className="font-size-regular txt-color-marine-blue">
                          {t("users.permission_group_header")}:
                        </span>
                        <span className="font-size-regular txt-color-primary">
                          {t("permissions." + d.permissionGroups)}
                        </span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span className="font-size-regular txt-color-marine-blue">{t("users.last_login")}</span>
                        <span className="font-size-regular txt-color-primary">
                          {d.firstLogin ? (
                            <>
                              <span className="font-size-regular txt-color-primary">{date}</span>
                              <span className="font-size-regular txt-color-primary">{time}</span>
                            </>
                          ) : (
                            <span className="font-size-regular txt-color-error">{t("user.not_first_login")}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ wordBreak: "normal" }} className="d-flex col-12 col-sm-4">
                <div className="container d-flex my-2">
                  <div className="row w-100 d-flex flex-column ">
                    <span className="font-size-normal font-weight-bold txt-color-marine-blue mb-2">
                      {t("users.actions")}
                    </span>
                    <div className="d-flex flex-column align-items-baseline">
                      {d.email !== currentUser.email ? (
                        <>
                          <div className="d-flex font-size-regular flex-row-reverse my-2">
                            <Link
                              className="btn btn-link txt-color-link fas fa-user-edit no-decoration"
                              onClick={(e) => {
                                editUser(d.userId);
                              }}
                              to={d.userId === currentUser.id ? "/user/settings" : `users/edit/${d.userId}`}>
                              <span className="ml-2 txt-color-link gotham-font-stack font-weight-normal">
                                {t("tooltip.userSettings.edit")}
                              </span>
                            </Link>
                          </div>
                          <div className="d-flex font-size-regular flex-row-reverse my-2">
                            <button
                              className="btn btn-link txt-color-link fas fa-user-minus no-decoration"
                              onClick={(e) => {
                                setUserId(d.userId);
                                setModalShow(true)}
                              }>
                              <span className="ml-2 txt-color-link gotham-font-stack font-weight-normal">
                                {t("tooltip.userSettings.delete")}
                              </span>
                            </button>
                          </div>
                          {d.isActive ? (
                            <div className="d-flex font-size-regular flex-row-reverse my-2">
                              <button
                                className="btn btn-link txt-color-link fas fa-user-times no-decoration"
                                onClick={(e) => changeUserStatus(d.userId)}>
                                <span className="ml-2 txt-color-link gotham-font-stack font-weight-normal">
                                  {t("tooltip.userSettings.deactivate")}
                                </span>
                              </button>
                            </div>
                          ) : (
                            d.firstLogin && (
                              <div className="d-flex font-size-regular flex-row-reverse my-2">
                                <span className="link txt-color-link align-self-center d-flex">
                                  {t("tooltip.userSettings.activate")}
                                </span>
                                <button
                                  className="btn btn-link txt-color-link fas fa-user-check no-decoration"
                                  onClick={(e) => changeUserStatus(d.userId)}
                                />
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <div className="d-flex font-size-regular flex-row-reverse my-2">
                          <Link
                            className="btn btn-link txt-color-link fas fa-user-edit no-decoration no-decoration"
                            onClick={(e) => {
                              editUser(d.userId);
                            }}
                            to={d.userId === currentUser.id ? "/user/settings" : "users/edit"}>
                            <span className="ml-2 txt-color-link gotham-font-stack font-weight-normal">
                              {t("tooltip.userSettings.edit")}
                            </span>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return smallColumns;
};

export default SmallColumns;
