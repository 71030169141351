import _ from "lodash";

import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import TextArea from "components/Inputs/TextArea";
import { EditQuestionProps } from "components/passport/PassportUtils";

const EditSimpleQuestion: React.FC<EditQuestionProps> = ({ field, form, handleUpdate }) => {
  const { t } = useTranslation();
  const initialValue = _.get(form, field.value);
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback(
    (value: string) => {
      setValue(value);
      _.set(form, field.value, value);
      handleUpdate(form);
    },
    [value, field.value, form],
  );

  return (
    <div className={"my-2 mx-1 p-0 col-md-12"}>
      <TextArea
        key={"input-" + field.key}
        value={value}
        label={t("passport.location." + field.key)}
        onChange={handleChange}
        error={null}
        name={field.key}
      />
    </div>
  );
};

export default EditSimpleQuestion;
