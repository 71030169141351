import { ThunkResult } from "../../models/StoreModel";
import { GS1Location } from "../../models/LocationModel";
import { BackendService } from "../../services";
import LocationService from "../../services/LocationService";
import * as dataActions from "../actions/DataActions";
import { fetchCurrentUser } from "./AccountThunk";

export const getGLNsData = (
  pageSize: number,
  pages: number,
  sorted: string,
  filtered: { id: string; value: string }[],
) => {
  return (dispatch: any) => {
    dispatch(dataActions.GetGLNRequest({ pageSize, pages, sorted, filtered }));
    BackendService.location
      .getGLNsData(pageSize, pages, sorted, filtered)
      .then((response: any) => dispatch(dataActions.GetGLNRequestSuccess(response)))
      .catch(() => dispatch(dataActions.GetGLNRequestFailure()));
  };
};

export const getCompaniesData = (
  pageSize: number,
  pages: number,
  sorted: string,
  filtered: { id: string; value: string }[],
  getAll: boolean,
) => {
  return (dispatch: any) => {
    dispatch(dataActions.GetCompaniesRequest({ pageSize, pages, sorted, filtered }));
    BackendService.location
      .getCompaniesData(pageSize, pages, sorted, filtered, getAll)
      .then((response: any) => dispatch(dataActions.GetCompaniesRequestSuccess(response)))
      .catch(() => dispatch(dataActions.GetCompaniesRequestFailure()));
  };
};

export const getCompanyDetails = (id: string) => {
  return (dispatch: any) => {
    dispatch(dataActions.GetCompanyDetailsRequest());
    BackendService.location
      .getCompanyData(id)
      .then((response: any) => {
        dispatch(dataActions.GetCompanyDetailsSuccess(response));
      })
      .catch(() => dispatch(dataActions.GetCompanyDetailsFailure()));
  };
};

export const getAllCities = () => {
  return (dispatch: any) => {
    BackendService.location
      .getAllCities()
      .then((response: any) => dispatch(dataActions.GetAllCitiesSuccess(response.data)))
      .catch(() => dispatch(dataActions.GetAllCitiesFailure()));
  };
};

export const getUsersForLocation = (
  pageSize: number,
  pages: number,
  sorted: string,
  filtered: { id: string; value: string }[],
  selectedLocation: string,
) => {
  return (dispatch: any) => {
    dispatch(dataActions.GetUsersRequest({ pageSize, pages, sorted, filtered }));
    BackendService.account
      .getUsersForLocation(pageSize, pages, sorted, filtered, selectedLocation)
      .then((response: any) => dispatch(dataActions.GetUsersRequestSuccess(response)))
      .catch(() => dispatch(dataActions.GetUsersRequestFailure()));
  };
};

export const loadUser = (userId: string, selectedLocationId: string) => {
  return (dispatch: any) => {
    dispatch(dataActions.GetUserData({ userId, selectedLocationId }));
    BackendService.account
      .loadUser(userId, selectedLocationId)
      .then((response: any) => dispatch(dataActions.GetUserDataSuccess(response.data)))
      .catch(() => dispatch(dataActions.GetUserDataFailure()));
  };
};

export const changeUserStatus = (userId: string, selectedLocationId: string, successCb: () => any) => {
  return (dispatch: any) => {
    BackendService.account
      .changeUserStatus(userId, selectedLocationId)
      .then((response: any) => {
        dispatch(dataActions.UserChangeStatusSuccess({ userId: userId }));
        successCb();
      })
      .catch(() => dispatch(dataActions.UserChangeStatusFailure()));
  };
};

export const getUserByEmail = (userEmail: string) => {
  return (dispatch: any) => {
    dispatch(dataActions.CheckingEmail());
    BackendService.account
      .getUserByEmail(userEmail)
      .then((response: any) => dispatch(dataActions.GetUserDataSuccess(response.data)))
      .catch((error: any) => dispatch(dataActions.GetUserDataFailure()));
  };
};

export const sendImportFile = (file: File, selectedLocationId: string) => {
  return (dispatch: any) => {
    dispatch(dataActions.SendImportFile());
    BackendService.location
      .sendImportFile(file, selectedLocationId)
      .then((response: any) => dispatch(dataActions.SendImportFileSuccess(response.data)))
      .catch((error: any) => dispatch(dataActions.SendImportFileFailure()));
  };
};

export const checkImportStatus = (taskId: string, cacheKey: string) => {
  return (dispatch: any) => {
    BackendService.location
      .checkImportStatus(taskId, cacheKey)
      .then((response: any) => dispatch(dataActions.ImportStatusSuccess(response.data)))
      .catch((error: any) => dispatch(dataActions.ImportStatusFailure()));
  };
};

export const exportLocations = (filtered: object) => {
  return (dispatch: any) => {
    dispatch(dataActions.SendExportFile());
    BackendService.location
      .exportLocations(filtered)
      .then((response: any) => dispatch(dataActions.SendExportFileSuccess(response.data)))
      .catch((error: any) => dispatch(dataActions.SendExportFileFailure()));
  };
};

export const checkExportStatus = (taskId: string, cacheKey: string) => {
  return (dispatch: any) => {
    BackendService.location
      .checkExportStatus(taskId, cacheKey)
      .then((response: any) => dispatch(dataActions.ExportStatusSuccess(response.data)))
      .catch((error: any) => dispatch(dataActions.ExportStatusFailure()));
  };
};

export const getLocationDetails = (locationId: string) => {
  return (dispatch: any) => {
    BackendService.location
      .getLocationDetails(locationId)
      .then((response: any) => {
        dispatch(dataActions.GetLocationDetailsSuccess(response.data));
        dispatch(dataActions.getLastGlnPassportRequestSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(dataActions.GetLocationDetailsFailure());
        dispatch(dataActions.getLastGlnPassportRequestFailure());
      });
  };
};

export const saveLocationDetails = (locationDetails: object) => {
  return (dispatch: any) => {
    BackendService.location
      .saveLocationDetails(locationDetails)
      .then((response: any) => dispatch(dataActions.GetLocationDetailsSuccess(response.data)))
      .catch((error: any) => {
        dispatch(dataActions.GetLocationDetailsFailure());
      });
  };
};

export function saveCompanyDetails(locationDetails: any): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    await LocationService.saveCompanyDetails(locationDetails);
    await dispatch(fetchCurrentUser());
  };
}

export const getMyWhitelistCompanies = (locationId: string, mode: string) => {
  return (dispatch: any) => {
    BackendService.location
      .getMyWhitelistCompanies(locationId, mode)
      .then((response: any) => {
        dispatch(dataActions.getMyWhitelistCompaniesSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(dataActions.getMyWhitelistCompaniesFailure());
      });
  };
};

export const getNotInWhitelistCompanies = (locationId: string, mode: string) => {
  return (dispatch: any) => {
    BackendService.location
      .getNotInWhitelistCompanies(locationId, mode)
      .then((response: any) => {
        dispatch(dataActions.getNotInWhitelistCompaniesSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(dataActions.getNotInWhitelistCompaniesFailure());
      });
  };
};

export function updateWhitelistAndRestrictedSections(
  locationId: string,
  whitelistEnabled: boolean,
  whitelistSelectedValues: string[],
  restrictedSectionSelectedValues: string[],
): ThunkResult<Promise<GS1Location>> {
  return async (dispatch) => {
    // Update whitelist
    let response = await LocationService.updateCompanyToWhitelist(
      locationId,
      whitelistEnabled,
      whitelistSelectedValues,
      restrictedSectionSelectedValues,
    );
    let location = response.data;
    location = { ...location, ...response.data };

    // @ts-ignore - TODO - arm types for data reducer and data actions, also rename action
    dispatch(dataActions.updateCompanyWhitelistSuccess(location));
    return location;
  };
}

export const getHistoryForCompany = (
  pageSize: number,
  pages: number,
  sorted: string,
  filtered: { id: string; value: string }[],
  selectedLocation: string,
) => {
  return (dispatch: any) => {
    dispatch(dataActions.GetHistoryRequest({ pageSize, pages, sorted, filtered }));
    BackendService.location
      .getHistoryForCompany(pageSize, pages, sorted, filtered, selectedLocation)
      .then((response: any) => {
        dispatch(dataActions.GetHistoryRequestSuccess(response));
      })
      .catch((error: any) => {
        dispatch(dataActions.GetHistoryRequestFailure());
      });
  };
};

export function getSuggestionReportForSections(locationId: string) {
  return (dispatch: any) => {
    // Side effect - request
    BackendService.suggestion
      .getReportForSections(locationId)
      .then((response: any) => {
        // Side effect - response
        dispatch(dataActions.setSuggestionReportForSections(response.data));
      })
      .catch(() => {
        // Side effect - failure
      });
  };
}

export function getSuggestions(locationId: string) {
  return (dispatch: any) => {
    // Side effect - request
    BackendService.suggestion
      .getSuggestions(locationId)
      .then((response: any) => {
        // Side effect - response
        dispatch(dataActions.setSuggestions(response.data));
      })
      .catch(() => {
        // Side effect - failure
      });
  };
}
