import React from "react";
import { useTranslation } from "react-i18next";

import "./CheckBox.scss";

interface CheckBoxProps {
  value: boolean | undefined;
  name: string;
  label: any;
  labelBold?: boolean;
  required?: boolean;
  onChange?: (value: boolean) => void;
  labelClass?: string;
  showError?: boolean;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  value = false,
  name,
  label = "",
  labelBold,
  labelClass = "",
  required = false,
  onChange,
  showError = false,
}) => {
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        id={"checkBox-for-" + name}
        name={name}
        checked={value}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      <label
        className={
          "custom-control-label " + (labelBold ? "font-weight-bold " : " ") + (showError ? "error " : " ") + labelClass
        }
        htmlFor={"checkBox-for-" + name}>
        {label}
        {required ? <span className="ml-1 font-color-error">*</span> : <></>}
      </label>
    </div>
  );
};

export default CheckBox;
