export const PENDING = "pending";
export const ARCHIVED = "archived";

export interface Suggestion {
  id: string;
  body: string;
  user: string;
  location: string;
  section: string;
  status: typeof PENDING | typeof ARCHIVED;
  createdAt: string;
}

export interface SuggestionsDict {
  [section: string]: Suggestion[];
}

export type ReportForSingleSection = {
  section: string;
  suggestionCount: number;
};

export type ReportForSections = ReportForSingleSection[]

export interface ReportForSectionsDict {
  [section: string]: number;
}
