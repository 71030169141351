import { v4 as uuid } from "uuid";
import _ from "lodash";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import Button from "@material-ui/core/Button";

import { State, ThunkDispatch } from "models/StoreModel";
import * as dataThunk from "store/thunk/DataThunk";
import { ScrollToTop } from "utils/ScrollUp";
import { Footer, Header, Input, Messages, NumberInput, RadioInput, SelectInput } from "components";
import { useFormValidator, useMessage } from "../../hooks";

interface LocationEditState {
  id: string;
  fullName: string;
  nip: string;
  regon: string;
  name: string;
  companyProfile: { [key: string]: string }[];
  companyProfileOther: string;
  address: string;
  city: string;
  postalCode: string;
  postalCity: string;
  district: { [key: string]: string }[];
  country: { [key: string]: string }[];
  phoneNumber: string;
  internalNumber: string;
  companyEmail: string;
  homePage: string;
  agreeToSellingEmails: boolean;
  agreeToSellingPhones: boolean;
  agreeToSharingData: boolean;
  agreeToMarketingEmails: boolean;
  agreeToMarketingPhones: boolean;
}

const initialState: LocationEditState = {
  id: "",
  fullName: "",
  nip: "",
  regon: "",
  name: "",
  companyProfile: [],
  companyProfileOther: "",
  address: "",
  city: "",
  postalCode: "",
  postalCity: "",
  district: [],
  country: [],
  phoneNumber: "",
  internalNumber: "",
  companyEmail: "",
  homePage: "",
  agreeToSellingEmails: false,
  agreeToSellingPhones: false,
  agreeToSharingData: false,
  agreeToMarketingEmails: false,
  agreeToMarketingPhones: false,
};

export const MyCompanyDetailsContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch>();
  const account = useSelector((state: State) => state.account);
  const { selectedLocation, isAdminForSelectedLocation } = account;

  const { addMessage } = useMessage();

  const [locationDetails, setLocationDetails] = useState(initialState);

  if (!selectedLocation) {
    throw new Error("Invariant - for this route `selectedLocation` are required.");
  }

  const setPartialForm = useCallback((partialForm: any) => {
    setLocationDetails((prevState) => ({ ...prevState, ...partialForm }));
  }, []);

  const disableInput = (canEdit = false) => {
    if (selectedLocation.isGsoneLocation && selectedLocation.isAuthorized) {
      return !(canEdit && isAdminForSelectedLocation);
    } else {
      return !isAdminForSelectedLocation;
    }
  };

  const addressRequired = disableInput() && !_.isEmpty(locationDetails.address);
  const districtRequired = disableInput() && !_.isEmpty(locationDetails.district);
  const countryRequired = disableInput() && !_.isEmpty(locationDetails.country);
  const emailRequired = disableInput() && !_.isEmpty(locationDetails.companyEmail);

  const [companyInformationErrors, setCompanyInformationErrors, validateCompanyInformationStep] = useFormValidator(
    {
      regon: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isRegon", message: t("form.validation.wrongRegon") },
      ],
      nip: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isNip", message: t("form.validation.wrongNip") },
        {
          rule: "IsLengthOptions",
          options: { min: 10, max: 10 },
          message: t("form.validation.wrong_length"),
        },
      ],
      fullName: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 150 },
          message: t("form.validation.hasToBeLessThan150"),
        },
      ],
      name: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 150 },
          message: t("form.validation.hasToBeLessThan150"),
        },
      ],
      companyProfile: [{ rule: "isRequired", message: t("form.validation.required") }],
      companyProfileOther: [],
      address: addressRequired
        ? [
            { rule: "isRequired", message: t("form.validation.required") },
            {
              rule: "IsLengthOptions",
              options: { min: 1, max: 150 },
              message: t("form.validation.hasToBeLessThan150"),
            },
          ]
        : [],
      city: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "IsLengthOptions",
          options: { min: 1, max: 150 },
          message: t("form.validation.hasToBeLessThan50"),
        },
      ],
      postalCode: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "isPostalCode",
          message: t("form.validation.postalCode_format"),
        },
      ],
      postalCity: [
        {
          rule: "IsLengthOptions",
          options: { min: 0, max: 150 },
          message: t("form.validation.hasToBeLessThan50"),
        },
      ],
      district: districtRequired ? [{ rule: "isRequired", message: t("form.validation.required") }] : [],
      country: countryRequired ? [{ rule: "isRequired", message: t("form.validation.required") }] : [],
      phoneNumber: [
        { rule: "isRequired", message: t("form.validation.required") },
        {
          rule: "isGsonePhoneNumber",
          options: {
            value: ["locationDetails", "phoneNumberToResponsiblePerson"],
          },
          message: "form.validation.phone_format",
        },
        {
          rule: "IsLengthOptions",
          options: { min: 9, max: 15 },
          message: t("form.validation.hasToBeLessThan15AndMoreThan9"),
        },
      ],
      internalNumber: [
        {
          rule: "IsLengthOptions",
          options: { min: 0, max: 32 },
          message: t("form.validation.hasToBeLessThan32"),
        },
      ],
      companyEmail: emailRequired
        ? [
            { rule: "isRequired", message: t("form.validation.required") },
            { rule: "isEmail", message: t("form.validation.email_format") },
          ]
        : [],
      homePage: [
        {
          rule: "IsLengthOptions",
          options: { min: 0, max: 128 },
          message: t("form.validation.hasToBeLessThan128"),
        },
      ],
      agreeToSellingEmails: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToSellingPhones: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToMarketingEmails: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToMarketingPhones: [{ rule: "isBool", message: t("form.validation.required") }],
      agreeToSharingData: [{ rule: "isBool", message: t("form.validation.required") }],
    },
    [t],
  );

  const companyProfileOptions = [
    { id: "producer", value: t("company_profile.producer") },
    {
      id: "distributor_wholesaler",
      value: t("company_profile.distributor_wholesaler"),
    },
    { id: "tsl_company", value: t("company_profile.tsl_company") },
    {
      id: "commercial_network",
      value: t("company_profile.commercial_network"),
    },
    { id: "other", value: t("company_profile.other") },
  ];

  const countries = ["Polska"];

  const countryOptions = countries.map((country) => ({
    id: country.toLowerCase(),
    value: country,
  }));

  const districts = [
    "Dolnośląskie",
    "Kujawsko-Pomorskie",
    "Lubelskie",
    "Lubuskie",
    "Łódzkie",
    "Małopolskie",
    "Mazowieckie",
    "Opolskie",
    "Podkarpackie",
    "Podlaskie",
    "Pomorskie",
    "Śląskie",
    "Świętokrzyskie",
    "Warmińsko-Mazurskie",
    "Wielkopolskie",
    "Zachodniopomorskie",
  ];

  const districtOptions = districts.map((district) => ({
    id: district.toLowerCase(),
    value: district,
  }));

  const phonesSellingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const emailSellingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const emailMarketingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const phonesMarketingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];
  const dataSharingOptions = [
    { value: true, label: t("form.agreements.options.yes"), uuid: uuid() },
    { value: false, label: t("form.agreements.options.no"), uuid: uuid() },
  ];

  const saveDetails = async () => {
    // @ts-ignore
    if (validateCompanyInformationStep(locationDetails)) {
      let selectedDistrict: string = "";
      if (!_.isEmpty(locationDetails.district)) {
        const { id } = locationDetails.district[0];
        selectedDistrict = id;
      }

      let selectedCompanyProfile: string[] = [];
      if (!_.isEmpty(locationDetails.companyProfile)) {
        const { id } = locationDetails.companyProfile[0];
        selectedCompanyProfile = [id];
      }

      let selectedCountry = "";
      if (!_.isEmpty(locationDetails.country)) {
        const { id } = locationDetails.country[0];
        selectedCountry = id;
      }

      try {
        await dispatch(
          dataThunk.saveCompanyDetails({
            id: locationDetails.id,
            fullName: locationDetails.fullName,
            nip: locationDetails.nip,
            regon: locationDetails.regon,
            name: locationDetails.name,
            companyProfile: selectedCompanyProfile,
            companyProfileOther: locationDetails.companyProfileOther,
            address: locationDetails.address,
            city: locationDetails.city,
            postalCode: locationDetails.postalCode,
            postalCity: locationDetails.postalCity,
            district: selectedDistrict,
            country: selectedCountry,
            phoneNumber: locationDetails.phoneNumber,
            internalNumber: locationDetails.internalNumber,
            companyEmail: locationDetails.companyEmail,
            homePage: locationDetails.homePage,
            agreeToSellingEmails: locationDetails.agreeToSellingEmails,
            agreeToSellingPhones: locationDetails.agreeToSellingPhones,
            agreeToMarketingEmails: locationDetails.agreeToMarketingEmails,
            agreeToMarketingPhones: locationDetails.agreeToMarketingPhones,
            agreeToSharingData: locationDetails.agreeToSharingData,
          }),
        );
        addMessage({
          type: "success",
          text: t("my_data.company.update.success"),
        });
      } catch (error) {
        addMessage({
          type: "error",
          text: "Something went wrong",
        });
      }
      ScrollToTop();
    }
  };

  useEffect(() => {
    const companyProfileId =
      selectedLocation.locationExtraDetails.companyProfile !== null
        ? selectedLocation.locationExtraDetails.companyProfile.map((profile: string) => ({
            id: profile.toLowerCase(),
          }))
        : [];
    const selectedDistrict =
      selectedLocation.locationExtraDetails.district !== null
        ? [{ id: selectedLocation.locationExtraDetails.district.toLowerCase() }]
        : [];
    const selectedCountry =
      selectedLocation.locationExtraDetails.country !== null
        ? [{ id: selectedLocation.locationExtraDetails.country.toLowerCase() }]
        : [];

    setPartialForm({
      id: selectedLocation.id,
      fullName: selectedLocation.fullName,
      nip: selectedLocation.locationExtraDetails.nip,
      regon: selectedLocation.locationExtraDetails.regon,
      name: selectedLocation.locationExtraDetails.name,
      companyProfile: companyProfileId,
      companyProfileOther: selectedLocation.locationExtraDetails.companyProfileOther,
      address: selectedLocation.locationExtraDetails.address,
      city: selectedLocation.locationExtraDetails.city,
      postalCode: selectedLocation.locationExtraDetails.postalCode,
      postalCity: selectedLocation.locationExtraDetails.postalCity,
      district: selectedDistrict,
      country: selectedCountry,
      phoneNumber: selectedLocation.locationExtraDetails.phoneNumber,
      internalNumber: selectedLocation.locationExtraDetails.internalNumber || "",
      companyEmail: selectedLocation.locationExtraDetails.companyEmail,
      homePage: selectedLocation.locationExtraDetails.homePage,
      agreeToSellingEmails: selectedLocation.locationExtraDetails.agreeToSellingEmails,
      agreeToSellingPhones: selectedLocation.locationExtraDetails.agreeToSellingPhones,
      agreeToMarketingEmails: selectedLocation.locationExtraDetails.agreeToMarketingEmails,
      agreeToMarketingPhones: selectedLocation.locationExtraDetails.agreeToMarketingPhones,
      agreeToSharingData: selectedLocation.locationExtraDetails.agreeToSharingData,
    });
  }, [selectedLocation, setPartialForm]);

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-4">
        <div className="context-menu mb-2">
          <Breadcrumb>
            <BreadcrumbItem active>{t("my_data.company.title")}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Messages />
        <div className="row border-top-3 p-4 bg-white mt-4">
          <div className="col-md-12">
            <div className="content-title">
              <h5 className="form-title form-header bold font-size-normal">{t("my_data.company.title")}</h5>
            </div>
            <hr />
            <div className={"mt-3 mb-2 font-size-normal"}>
              <span className="mr-2">{t("companyInformation.companyType")}:</span>
              <span className="font-size-normal txt-color-primary font-weight-bold">
                {selectedLocation.isAuthorized ? t("companyInformation.authorized") : t("companyInformation.ordinary")}
              </span>
            </div>
            <div className="content-form form-row margin-0">
              <div className="col-12 col-md-6 pr-md-4">
                <Input
                  label={t("companyInformation.form.left.regon")}
                  name="regon"
                  errors={companyInformationErrors}
                  required={true}
                  disabled={true}
                  value={locationDetails.regon}
                  inputType="text"
                  handleChange={setPartialForm}
                />
                <NumberInput
                  className="text-uppercase"
                  label={t("nip")}
                  name="nip"
                  errors={companyInformationErrors}
                  required={true}
                  disabled={disableInput()}
                  value={locationDetails.nip}
                  inputType="text"
                  handleChange={setPartialForm}
                />
                <Input
                  label={t("companyInformation.form.left.fullName")}
                  name="fullName"
                  errors={companyInformationErrors}
                  required={true}
                  disabled={disableInput()}
                  value={locationDetails.fullName}
                  inputType="text"
                  handleChange={setPartialForm}
                />
                <Input
                  label={t("companyInformation.form.left.name")}
                  name="name"
                  errors={companyInformationErrors}
                  required={true}
                  value={locationDetails.name}
                  inputType="text"
                  handleChange={setPartialForm}
                  disabled={disableInput(true)}
                />
                <SelectInput
                  label={t("companyInformation.form.left.profile")}
                  options={companyProfileOptions}
                  // @ts-ignore
                  value={locationDetails.companyProfile}
                  handleChange={setPartialForm}
                  required={true}
                  errors={companyInformationErrors}
                  name="companyProfile"
                  disabled={disableInput(true)}
                />
                {locationDetails.companyProfile.length === 1 && locationDetails.companyProfile[0].id === "other" && (
                  <Input
                    label={t("companyInformation.form.left.profileOther")}
                    name="companyProfileOther"
                    errors={companyInformationErrors}
                    value={locationDetails.companyProfileOther}
                    inputType="text"
                    handleChange={setPartialForm}
                    disabled={disableInput(true)}
                  />
                )}
              </div>
              <div className="col-12 col-md-6 pl-md-4">
                <Input
                  label={t("address")}
                  name="address"
                  errors={companyInformationErrors}
                  required={true}
                  disabled={disableInput()}
                  value={locationDetails.address}
                  inputType="text"
                  handleChange={setPartialForm}
                />
                <Input
                  label={t("city")}
                  name="city"
                  errors={companyInformationErrors}
                  required={true}
                  value={locationDetails.city}
                  inputType="text"
                  disabled={disableInput()}
                  handleChange={setPartialForm}
                />
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start container px-0">
                  <Input
                    label={t("form.agreements.details.postalCode")}
                    name="postalCode"
                    errors={companyInformationErrors}
                    required={true}
                    className="col-md-6 px-0 pr-md-1"
                    disabled={disableInput()}
                    placeholder={t("placeholder.postalCode")}
                    value={locationDetails.postalCode}
                    inputType="text"
                    handleChange={setPartialForm}
                  />
                  <Input
                    label={t("companyInformation.form.right.postalCity")}
                    name="postalCity"
                    errors={companyInformationErrors}
                    value={locationDetails.postalCity}
                    className="col-md-6 px-0 pl-md-1"
                    inputType="text"
                    disabled={disableInput(true)}
                    handleChange={setPartialForm}
                  />
                </div>
                <SelectInput
                  label={t("district")}
                  options={districtOptions}
                  value={locationDetails.district}
                  handleChange={setPartialForm}
                  required={true}
                  disabled={disableInput()}
                  errors={companyInformationErrors}
                  name="district"
                />
                <SelectInput
                  label={t("form.agreements.details.country")}
                  options={countryOptions}
                  value={locationDetails.country}
                  handleChange={setPartialForm}
                  required={true}
                  errors={companyInformationErrors}
                  name="country"
                  disabled={disableInput()}
                />
                <div className="align-items-end d-flex flex-column flex-lg-row justify-content-between container px-0 mt-2">
                  <NumberInput
                    label={t("companyInformation.form.right.phone")}
                    name="phoneNumber"
                    errors={companyInformationErrors}
                    required={true}
                    disabled={disableInput()}
                    phoneInput={true}
                    placeholder={t("placeholder.phoneNumber")}
                    className="col-lg-6 px-0 pr-lg-1 align-self-start"
                    value={locationDetails.phoneNumber}
                    inputType="text"
                    handleChange={setPartialForm}
                  />
                  <NumberInput
                    label={t("users.add.internal_phone")}
                    className="col-lg-6 px-0 pl-lg-1 align-self-start"
                    name="internalNumber"
                    errors={companyInformationErrors}
                    value={locationDetails.internalNumber}
                    inputType="text"
                    handleChange={setPartialForm}
                    disabled={disableInput(true)}
                  />
                </div>
                <Input
                  label={t("companyInformation.form.right.email")}
                  name="companyEmail"
                  errors={companyInformationErrors}
                  required={true}
                  disabled={disableInput()}
                  value={locationDetails.companyEmail}
                  inputType="text"
                  handleChange={setPartialForm}
                />
                <Input
                  label={t("companyInformation.form.right.homepage")}
                  name="homePage"
                  errors={companyInformationErrors}
                  value={locationDetails.homePage}
                  inputType="text"
                  handleChange={setPartialForm}
                  disabled={disableInput(true)}
                />
              </div>
            </div>
            <div className="buttons-section mt-4"></div>
          </div>
          {isAdminForSelectedLocation ? (
            <div className="col-md-12">
              <div className="content-title">
                <h5 className="form-title form-header font-size-normal bold">{t("my_data.company.agreements")}</h5>
              </div>
              <hr />
              <div className="content-form form-row margin-0">
                <div className="d-flex p-2 w-100">
                  <RadioInput
                    label={t("agreeToSellingEmailsForCompany")}
                    name="agreeToSellingEmails"
                    errors={companyInformationErrors}
                    options={emailSellingOptions}
                    handleInput={setPartialForm}
                    direction={"md-row flex-column"}
                    defaultValue={locationDetails.agreeToSellingEmails}
                    className="font-size-medium align-items-baseline"
                  />
                </div>
                <div className="d-flex p-2 w-100">
                  <RadioInput
                    label={t("agreeToSellingPhonesForCompany")}
                    name="agreeToSellingPhones"
                    errors={companyInformationErrors}
                    options={phonesSellingOptions}
                    handleInput={setPartialForm}
                    direction={"md-row flex-column"}
                    defaultValue={locationDetails.agreeToSellingPhones}
                    className="font-size-medium align-items-baseline"
                  />
                </div>
                <div className="d-flex p-2 w-100">
                  <RadioInput
                    label={t("agreeToSharingDataForCompany")}
                    name="agreeToSharingData"
                    errors={companyInformationErrors}
                    options={dataSharingOptions}
                    handleInput={setPartialForm}
                    direction={"md-row flex-column"}
                    defaultValue={locationDetails.agreeToSharingData}
                    className="font-size-medium align-items-baseline"
                  />
                </div>
                <div className="d-flex p-2 w-100">
                  <RadioInput
                    label={t("agreeToMarketingEmailsForCompany")}
                    name="agreeToMarketingEmails"
                    errors={companyInformationErrors}
                    options={emailMarketingOptions}
                    handleInput={setPartialForm}
                    direction={"md-row flex-column"}
                    defaultValue={locationDetails.agreeToMarketingEmails}
                    className="font-size-medium align-items-baseline"
                  />
                </div>
                <div className="d-flex p-2 w-100">
                  <RadioInput
                    label={t("agreeToMarketingPhonesForCompany")}
                    name="agreeToMarketingPhones"
                    options={phonesMarketingOptions}
                    errors={companyInformationErrors}
                    handleInput={setPartialForm}
                    direction={"md-row flex-column"}
                    defaultValue={locationDetails.agreeToMarketingPhones}
                    className="font-size-medium align-items-baseline"
                  />
                </div>
              </div>
              <div className="buttons-section mt-4 col-md-12">
                <Button
                  className="bg-dusty-orange  float-right px-5 mt-auto mb-2 mr-0 ml-auto font-size-normal rounded-0 font-weight-bold"
                  variant="contained"
                  color="primary"
                  onClick={saveDetails}>
                  {t("my_data.company.save")}
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyCompanyDetailsContainer;
