import _ from "lodash";
import React, { useState } from "react";
import PASSPORT_MAPPING, { Field, Section } from "../../../pages/glnPassport/PassportMapping";
import EditMultipleSelectQuestion from "./EditMulipleSelectQuestion";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EditSimpleNumericQuestion from "./EditSimpleNumericQuestion";
import EditRangeQuestion from "./EditRangeQuestion";
import { useFormValidator } from "../../../hooks";
import { checkShowConditions, shouldRender } from "../PassportUtils";
import EditSingleSelectQuestion from "./EditSingleSelectQuestion";
import EditBooleanQuestion from "./EditBooleanQuestion";
import EditTimePickerQuestion from "./EditTimePickerQuestion";
import EditSimpleQuestion from "./EditSimpleQuestion";
import EditPhoneNumberQuestion from "./EditPhoneNumberQuestion";

interface ModalFormProps {
  section: Section;
  mainObject: object;
  handleClose: any;
  extraBody?: any;
}

const ModalForm: React.FC<ModalFormProps> = ({ section, mainObject, handleClose, extraBody }) => {
  const { t } = useTranslation();

  const initialState = {};

  const validators = {};

  const updateInitialState = (sectionConfig: Section) => {
    _.forEach(sectionConfig.section, (subsection) => {
      _.forEach(subsection.fields, (field: Field) => {
        if (field.availableValues !== undefined && field.availableValues.indexOf("other") !== -1) {
          let otherPath = field.value.slice();
          let otherKey = "";
          if (_.isArray(otherPath)) {
            let otherProp = otherPath.pop();
            otherKey = otherProp + "Other";
            otherPath.push(otherKey);
          } else {
            otherKey = otherPath + "Other";
            otherPath = otherKey;
          }
          const otherFromObject = _.get(mainObject, otherPath);
          _.set(initialState, otherPath, otherFromObject);
        }
        _.set(initialState, field.value, _.get(mainObject, field.value));
      });
    });
  };
  updateInitialState(section);

  const relatedSection = _.find(
    PASSPORT_MAPPING,
    (relatedSection) => relatedSection.sectionTitleKey === section.relatedSectionKey,
  );
  if (relatedSection) {
    updateInitialState(relatedSection);
  }
  const [sectionForm, setSectionForm] = useState(initialState);

  const updateValidators = (sectionConfig: Section) => {
    _.forEach(sectionConfig.section, (subsection) => {
      _.forEach(subsection.fields, (field: Field) => {
        if (!_.isEmpty(field.validators) && checkShowConditions(field, subsection, sectionForm)) {
          if (field.subKey !== undefined) {
            _.set(validators, field.subKey + "-" + field.key, field.validators);
          } else {
            _.set(validators, field.key, field.validators);
          }
        }
      });
    });
  };

  updateValidators(section);

  const [sectionErrors, setSectionErrors, validateSection] = useFormValidator(validators, [t, validators]);

  const handleUpdate = (value: object) => {
    setSectionForm({ ...value });
  };

  const validateAndSave = () => {
    // @ts-ignore
    if (validateSection(sectionForm, true)) {
      _.forEach(section.section, (subsection) => {
        _.forEach(subsection.fields, (field: Field) => {
          if (!checkShowConditions(field, subsection, sectionForm)) {
            _.set(sectionForm, field.value, null);
          }
        });
      });
      handleClose(sectionForm, true);
    }
  };

  const onlyClose = () => {
    handleClose(sectionForm, false);
  };

  const renderFields = (field: Field) => {
    const fieldType = field.editType || field.type;
    const shouldRenderField = shouldRender(field, sectionForm);
    if (shouldRenderField) {
      switch (fieldType) {
        case "simple":
          return <EditSimpleQuestion key={field.key} form={sectionForm} field={field} handleUpdate={handleUpdate} />;
        case "numeric":
          return (
            <EditSimpleNumericQuestion key={field.key} form={sectionForm} field={field} handleUpdate={handleUpdate} />
          );
        case "range":
          return (
            <EditRangeQuestion
              errors={sectionErrors}
              key={field.key}
              form={sectionForm}
              field={field}
              handleUpdate={handleUpdate}
            />
          );
        case "timepicker":
          return (
            <EditTimePickerQuestion
              errors={sectionErrors}
              key={field.key}
              form={sectionForm}
              field={field}
              handleUpdate={handleUpdate}
            />
          );
        case "boolean":
          return <EditBooleanQuestion key={field.key} form={sectionForm} field={field} handleUpdate={handleUpdate} />;
        case "singleSelect":
          return (
            <EditSingleSelectQuestion
              errors={sectionErrors}
              key={field.key}
              form={sectionForm}
              field={field}
              handleUpdate={handleUpdate}
            />
          );
        case "multiInOneColumn":
          return (
            <EditMultipleSelectQuestion key={field.key} form={sectionForm} field={field} handleUpdate={handleUpdate} />
          );
        case "multiInSeveralColumn":
          return (
            <EditMultipleSelectQuestion key={field.key} form={sectionForm} field={field} handleUpdate={handleUpdate} />
          );
        case "phoneNumber":
          return (
            <EditPhoneNumberQuestion
              errors={sectionErrors}
              key={field.key}
              form={sectionForm}
              field={field}
              handleUpdate={handleUpdate}
            />
          );
        case "non-editable":
          return "";
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <Modal.Body key={"modalBody"}>
        {_.map(section.section, (subsection) => {
          let shouldShowSection = [true];
          if (subsection.showWhenPropIsFilled !== undefined) {
            shouldShowSection = _.map(subsection.showWhenPropIsFilled, (path) => {
              const propValue = _.get(sectionForm, path);
              return propValue !== "" && propValue > 0;
            });
          }
          if (shouldShowSection.indexOf(true) !== -1) {
            return (
              <div className="row m-0 pb-4" key={subsection.titleKey}>
                <h5 className="form-title form-header bold">{t(subsection.titleKey)}</h5>
                <hr className="m-0" />
                {_.map(subsection.fields, (field) => renderFields(field))}
              </div>
            );
          }
        })}
        {extraBody}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="txt-color-primary mt-auto font-size-normal mb-2 bg-white ml-0 rounded-0 font-weight-bold"
          variant="link"
          color="primary"
          onClick={onlyClose}>
          {t("location.passport.cancel")}
        </Button>
        <Button
          className="bg-dusty-orange border-dusty-orange mt-auto font-size-normal mb-2 px-5 mr-0 b-0 rounded-0 font-weight-bold"
          variant="primary"
          color="primary"
          onClick={validateAndSave}>
          {t("location.passport.save")}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ModalForm;
