import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="footer__background">
      <footer className="container footer">
        <div className="row">
          <div>
            <Link className="link" to="/terms">
              {t("terms")}
            </Link>
            <a className="link" href="https://gs1pl.org/polityka-prywatnosci/">
              {t("privacyPolicy")}
            </a>
            <a className="link" href="https://gs1pl.org/app/uploads/2022/02/Polityka_cookies_serwis_globloc.pl_.pdf">
              {t("cookiesPolicy")}
            </a>
            <Link className="link" to="/contact">
              {t("contact")}
            </Link>
          </div>
          <div className="footer__gs1">
            <div className="footer__contact">
              <div className="pt-2">
                <a href="https://www.facebook.com/GS1Polska" className="mr-3" target="_blank">
                  <img src="/icons/social/facebook.svg" alt={t("footer.social.facebook")} />
                </a>
                <a href="https://www.youtube.com/channel/UCPGTAFOoqAGAz9QEcdDS9PQ" className="mr-3" target="_blank">
                  <img src="/icons/social/youtube.svg" alt={t("footer.social.youtube")} />
                </a>
                <a href="https://www.linkedin.com/company/gs1-polska" className="mr-3" target="_blank">
                  <img src="/icons/social/linkedin.svg" alt={t("footer.social.linkedIn")} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
