import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ThunkDispatch } from "models/StoreModel";
import * as AccountThunk from "store/thunk/AccountThunk";
import { Header, Messages, Footer } from "components";

export const WaitingRoomContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch>();

  const handleLogout = useCallback(() => {
    dispatch(AccountThunk.logout());
  }, []);

  return (
    <>
      <Header withBottom={false} />
      <div className="container">
        <Messages/>
        <div className="row border-top-3-dusty-orange bg-white my-5 w-75 mx-auto">
          <div className="col-12 my-3 p-5 text-center txt-color-grafite">
            {t("message.firstLoginCompleted.notAuthorized")}
          </div>
          <div className="col-12 my-3 p-5 mx-auto d-flex text-center">
            <p className="my-0 w-100 txt-color-grafite">
              {t("waitingRoom.go.back")}
              <a className="txt-color-link pl-1" onClick={handleLogout}>
                {t("waitingRoom.click.here")}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WaitingRoomContainer;
