import _ from "lodash";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import AccountService from "services/AccountService";
import { User } from "models/UserModel";
import { Header, Input, Messages, CheckBox, Footer } from "components";
import PasswordRules, { validateNewPassword, PasswordRule } from "components/passwordRules/PasswordRules";
import { useMessage } from "hooks";
import { ScrollToTop } from "../../utils/ScrollUp";

interface RouteParams {
  activationKey: string;
}

export const SetPasswordContainer: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { activationKey } = useParams<RouteParams>();
  const { addMessage } = useMessage();
  const [user, setUser] = useState<User | null>(null);
  const [userError, setUserError] = useState<string | null>(null);
  const [fetchingUser, setFetchingUser] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [statute, setStatute] = useState(false);
  const [showStatuteError, setShowStatuteError] = useState(false);

  const [passwordRulesFulfilled, setPasswordRulesFulfilled] = useState<PasswordRule[]>([]);

  const passwordIsValid = () => {
    const userEmail = user ? user.email : "";
    const { isValid, rulesFulfilled } = validateNewPassword(password, passwordConfirm, userEmail);
    setPasswordRulesFulfilled(rulesFulfilled);
    return isValid;
  };

  const statuteIsValid = () => {
    const isValid = statute;
    if (!isValid) {
      setShowStatuteError(true);
    }
    return isValid;
  };

  const handleSubmit = async () => {
    if (passwordIsValid() && statuteIsValid()) {
      try {
        await AccountService.activateUser(activationKey, password);
        history.push("/login");
        addMessage({
          type: "success",
          text: t("user.activation.success"),
        });
      } catch (error) {
        addMessage({
          type: "error",
          text: t("reset_password.failure"),
        });
      }
    }
  };

  const loadUser = async () => {
    setFetchingUser(true);
    try {
      const response = await AccountService.loadUserToActivate(activationKey);
      const user = response.data.user;
      setUser(user);
    } catch (error) {
      if (_.get(error, "response.status") === 400) {
        setUserError(t("errors.alreadyActivated"));
      } else {
        setUserError(t("errors.fetch_data"));
      }
    }
    setFetchingUser(false);
  };

  useEffect(() => {
    loadUser();
  }, [activationKey]);

  useEffect(() => {
    passwordIsValid();
  }, [password, passwordConfirm, user]);

  if (fetchingUser) {
    return <></>;
  }

  return (
    <>
      <Header withBottom={false} withTitle={t("user.activation.header")} />
      <div className="container">
        {!user ? (
          <div className="container pt-5">
            <div className="col-md-12 mx-auto pt-5 text-center">{userError}</div>
          </div>
        ) : (
          <>
            <div className="mt-3">
              <Messages />
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="bottom-part row my-5 justify-content-center">
                  <div className="bg-white border-top-3 border-dusty-orange mx-6 w-100">
                    <div className="row mt-3 text-center">
                      <h5 className="text-left ml-5 w-100 pt-2 font-size-normal  txt-color-marine-blue">
                        <strong>{t("set_password.title")}</strong>
                      </h5>
                      <hr className="w-75" />
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                      <div className="w-100 mx-5">
                        <div className="d-flex flex-column mb-3">
                          <span className="txt-color-bluish-grey font-size-normal">
                            {t("set_password.your_login")} <strong className="text-black">{user.email}</strong>
                          </span>
                        </div>
                        <div className="username-input d-flex flex-column">
                          <Input
                            label={t("set_password.your_password_label")}
                            inputType="password"
                            required={true}
                            name="password"
                            value={password}
                            handleChange={({ password }: any) => setPassword(password)}
                          />
                        </div>
                        <PasswordRules passwordRulesFulfilled={passwordRulesFulfilled} />
                        <div className="username-input d-flex flex-column mt-2">
                          <Input
                            label={t("set_password.your_password_confirm_label")}
                            inputType="password"
                            required={true}
                            value={passwordConfirm}
                            name="passwordConfirm"
                            handleChange={({ passwordConfirm }: any) => setPasswordConfirm(passwordConfirm)}
                          />
                        </div>
                        <div className="d-flex flex-column txt-color-bluish-grey my-3">
                          <CheckBox
                            name="statute"
                            value={statute}
                            showError={showStatuteError}
                            required={true}
                            label={
                              <>
                                {t("set_password.confirm_statute")}{" "}
                                <Link to="/terms">{t("set_password.confirm_statute_link")}</Link>
                              </>
                            }
                            onChange={(checked) => setStatute(checked)}
                          />
                          <div className="error-label font-size-regular d-flex flex-row-reverse mb-2">
                            {showStatuteError ? t("form.validation.required") : ""}
                          </div>
                        </div>
                        <div className="d-flex flex-column txt-color-bluish-grey">
                          <button
                            className="text-white border-0 w-100 py-2 mt-2 bg-dusty-orange"
                            onClick={() => {
                              handleSubmit();
                              ScrollToTop();
                            }}>
                            {t("set_password.activate_button")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default SetPasswordContainer;
