import React, { useEffect, useState } from "react";

import "react-table/react-table.css";
import CompanyInfoWithGlns from "../../components/companyDetails/CompanyInfoWithGlns";
import { useSelector } from "react-redux";
import { State } from "../../models/StoreModel";

export const CompanyDetails: React.FC = () => {
  const selectedLocation = useSelector((state: State) => state.account.selectedLocation);
  const [companyID, setCompanyId] = useState(selectedLocation !== null ? selectedLocation.id : undefined);

  useEffect(() => {
    if (selectedLocation !== null) setCompanyId(selectedLocation.id);
  }, [selectedLocation]);

  return <CompanyInfoWithGlns key={companyID} companyId={companyID} fromState={true} />;
};

export default CompanyDetails;
