import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { EditQuestionProps } from "../PassportUtils";
import { Input } from "../../index";
import Item from "./Item";

const EditMultipleSelectQuestion: React.FC<EditQuestionProps> = ({ field, form, handleUpdate }) => {
  const { t } = useTranslation();
  const valueFromObject = _.get(form, field.value, []) || [];
  let otherKey: string | null = null;

  const [selectedValues, setSelectedValues] = useState(valueFromObject.slice());
  const [showOtherOption, setShowOtherOption] = useState(valueFromObject.indexOf("other") !== -1);

  let otherPath = field.value.slice();
  if (_.isArray(otherPath)) {
    let otherProp = otherPath.pop();
    otherKey = otherProp + "Other";
    otherPath.push(otherKey);
  } else {
    otherKey = otherPath + "Other";
    otherPath = otherKey;
  }
  const otherFromObject = _.get(form, otherPath);
  const [otherValue, setOtherValue] = useState({ [otherKey]: otherFromObject });

  const updateValue = (value: any) => {
    const index = selectedValues.indexOf(value);
    index !== -1 ? selectedValues.splice(index, 1) : selectedValues.push(value);
    const otherSelected = selectedValues.indexOf("other") !== -1;
    setShowOtherOption(otherSelected);
    setSelectedValues(selectedValues);
    updateForm(selectedValues, otherValue);
  };

  const updateOtherField = (value: any) => {
    setOtherValue(value);
    updateForm(selectedValues, value);
  };

  const updateForm = (selectedValue: string[], value: { [key: string]: any }) => {
    const otherSelected = selectedValue.indexOf("other") !== -1;
    _.set(form, field.value, selectedValue);
    if (otherKey) {
      if (!otherSelected) {
        _.set(form, otherPath, "");
      } else {
        _.set(form, otherPath, _.get(value, otherKey));
      }
    }
    handleUpdate(form);
  };

  return (
    <div className=" d-flex flex-column p-2 w-100" key={field.key}>
      <div className="d-flex flex-row flex-wrap m-0">
        {_.map(field.availableValues, (value) => {
          const isSelected = selectedValues.indexOf(value) !== -1;
          return (
            <Item key={value} value={value} questionKey={field.key} isSelected={isSelected} updateValue={updateValue} />
          );
        })}
      </div>
      {otherKey !== null && showOtherOption ? (
        <div className="my-2 mx-1">
          <Input
            key={"otherInputKey"}
            value={otherValue[otherKey]}
            label={t("label.other")}
            inputType="text"
            handleChange={updateOtherField}
            name={otherKey}
          />
          <label className="float-right txt-color-bluish-grey">{t("other.help_text")}</label>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditMultipleSelectQuestion;
