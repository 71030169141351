import uuid from "uuid";

import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Section } from "pages/glnPassport/PassportMapping";
import ModalForm from "./edit/ModalForm";

import "./EditModal.scss";

interface ModalProps {
  section: Section;
  mainObject: object;
  show: any;
  handleClose: any;
  extraBody?: any;
}

const EditModal: React.FC<ModalProps> = ({ section, mainObject, show, handleClose, extraBody }) => {
  const { t } = useTranslation();

  return (
    <Modal
      key={uuid()}
      restoreFocus={true}
      enforceFocus={false}
      autoFocus={false}
      show={show}
      animation={false} // bug - blinking when new props
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>{t(section.sectionTitleKey)}</Modal.Title>
      </Modal.Header>
      <ModalForm section={section} mainObject={mainObject} handleClose={handleClose} extraBody={extraBody} />
    </Modal>
  );
};

export default EditModal;
