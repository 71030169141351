import _ from "lodash";

import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useMessage } from "hooks";
import { State, ThunkDispatch } from "models/StoreModel";
import * as AccountThunk from "store/thunk/AccountThunk";

interface Props {
  component: React.FC;
  path: string;
  exact: boolean;
  networkOnline?: boolean;
}

function PrivateRouteRenderer({ component: PageComponent, path, networkOnline }: Props) {
  const { addMessage } = useMessage();
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch>();
  const account = useSelector((state: State) => state.account);
  const { user: currentUser, selectedLocation, isAdminForSelectedLocation, authenticated, acceptedBySa } = account;

  const onlyAdminPaths = ["/users", "/users/add", "/users/edit", "/data/export", "/my_data/history"];

  const onlyAuthorizedAdminPaths = onlyAdminPaths.concat([
    "/my_data/whitelist",
    "/my_data/sections",
    "/my_data/import",
    "/my_data/my_locations",
  ]);

  const fetchCurrentUser = async () => {
    if (authenticated && !currentUser) {
      try {
        await dispatch(AccountThunk.fetchCurrentUser());
      } catch (error) {
        if (_.get(error, "response.status") === 401) {
          dispatch(AccountThunk.logout());
        }
      }
    }
  };

  const isRestrictedPage = function() {
    return onlyAuthorizedAdminPaths.includes(path);
  };

  const haveAccessToAuthorizedPages = function() {
    return (
      onlyAuthorizedAdminPaths.includes(path) &&
      isAdminForSelectedLocation &&
      selectedLocation &&
      selectedLocation.isAuthorized
    );
  };

  const haveAccessAdminPages = function() {
    return onlyAdminPaths.includes(path) && isAdminForSelectedLocation;
  };

  useEffect(() => {
    if (!account.user) fetchCurrentUser();
  }, [currentUser]);

  if (!authenticated || !currentUser) {
    return <Redirect to="/login" />;
  }

  // TODO: - move these rules to <Private[Specific]Route /> or to the [Specific]Page

  if (selectedLocation) {
    const currentMembership = currentUser.membership.find((membership: any) => {
      return membership.location.id === selectedLocation.id;
    });

    if (currentMembership && !currentMembership.firstLoginConfirmed && path !== "/user/settings") {
      if (networkOnline) {
        return <Redirect to="/user/settings" />;
      } else {
        dispatch(AccountThunk.logout());
        addMessage({
          type: "error",
          text: t("user.networkConnectionLost"),
        });
      }
    }
  }

  if (path !== "/user/settings") {
    if (!acceptedBySa && path !== "/awaiting") {
      return <Redirect to="/awaiting" />;
    } else if (acceptedBySa && path === "/awaiting") {
      return <Redirect to="/" />;
    }
  }

  if (isRestrictedPage() && !haveAccessToAuthorizedPages() && !haveAccessAdminPages()) {
    return <Redirect to="/" />;
  }

  return <PageComponent />;
}

export const PrivateRoute = ({ component, ...rest }: Props) => {
  return <Route {...rest} render={() => <PrivateRouteRenderer component={component} {...rest} />} />;
};

export default PrivateRoute;
