export const COMPANY_PROFILE_PRODUCER = "producer";
export const COMPANY_PROFILE_DISTRIBUTOR_WHOLESALER = "distributor_wholesaler";
export const COMPANY_PROFILE_TSL_COMPANY = "tsl_company";
export const COMPANY_PROFILE_COMMERCIAL_NETWORK = "commercial_network";
export const COMPANY_PROFILE_OTHER = "other";

export const SECTION_OBJECTS_IN_LOCATION = "objects_in_location";
export const SECTION_EXTRA_LOCATION_INFORMATION = "extra_location_information";
export const SECTION_EQUIPMENT = "equipment";
export const SECTION_TIME_TRACKING = "time_tracking";
export const SECTION_CLIENT_RESTRICTION = "client_restriction";
export const SECTION_SUPPORTED_UNITS = "supported_units";
export const SECTION_THERMAL = "thermal";
export const SECTION_HOURS = "hours";
export const SECTION_MONITORY_NOTE = "monitory_note";
export const SECTION_AVG_TIME = "avg_time";
export const SECTION_DRIVER_OPERATION = "driver_operation";
export const SECTION_DOCUMENT = "documents";
export const SECTION_DRIVING_DIRECTIONS = "driving_directions";
export const SECTION_EMPTY_PALLETS = "empty_pallets";
export const SECTION_RETURN_OF_GOODS = "return_of_goods";
export const SECTION_PHONE_NUMBERS = "phone_numbers";
