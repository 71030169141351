import React from "react";
import {useTranslation} from "react-i18next";
import {v4 as uuid} from "uuid";

interface ItemProps {
  value: string
  updateValue: any;
  isSelected: boolean;
  questionKey: string | string[]
}

export const Item: React.FC<ItemProps> = ({value, questionKey, isSelected, updateValue}) => {
  const [t] = useTranslation();

  const updateSelected = (value: any) => {
    updateValue(value);
  };

  return (
    <div key={uuid()} onClick={(e: any) => updateSelected(value)}
         className={"tag d-flex flex-row " + (isSelected ? "selected" : "")}>
      <div
        className={"my-auto font-size-normal align-content-center justify-content-center"}>{t("passport.location." + questionKey + "." + value)}</div>
    </div>
  )
};

export default Item;
