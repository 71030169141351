import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { getOtherValueIfExist, QuestionProps } from "../PassportUtils";

const MultiInOneColumnQuestion: React.FC<QuestionProps> = ({ field, mainObject }) => {
  const { t } = useTranslation();
  return (
    <div className="font-size-normal  col-md-3 d-flex flex-column p-2 company-details" key={uuid()}>
      <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
        {t("passport.location." + field.key)}
      </label>
      <ul className="font-size-normal details-value font-weight-bold">
        {_.map(_.get(mainObject, field.value), (val) => {
          if (val === undefined || val === null) {
            return <li>-</li>;
          } else if (val !== "other") {
            return <li key={uuid()}>{t("passport.location.value." + val)}</li>;
          }
        })}
        {getOtherValueIfExist(field, mainObject)}
      </ul>
    </div>
  );
};

export default MultiInOneColumnQuestion;
