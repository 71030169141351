export const NipValidator = () => {
  const validate = (nip: string) => {
    const reg = /^[0-9]{10}$/;
    if (!reg.test(nip)) {
      return false;
    } else {
      const digits = ("" + nip).split("");
      const checksum =
        (6 * parseInt(digits[0]) +
          5 * parseInt(digits[1]) +
          7 * parseInt(digits[2]) +
          2 * parseInt(digits[3]) +
          3 * parseInt(digits[4]) +
          4 * parseInt(digits[5]) +
          5 * parseInt(digits[6]) +
          6 * parseInt(digits[7]) +
          7 * parseInt(digits[8])) %
        11;
      return parseInt(digits[9]) === checksum;
    }
  };

  return {
    validate: validate,
  };
};

export default NipValidator;
