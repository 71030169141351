import { MessageItem } from "models/MessageModel";
import * as Actions from "store/actions/MessageActions";

type MessageAction = Actions.MessageAction;

export interface MessageState {
  messages: MessageItem[];
}

const initialState: MessageState = {
  messages: [],
};

function messageReducer(state: MessageState = initialState, action: MessageAction): MessageState {
  switch (action.type) {
    case Actions.ADD_MESSAGE_ACTION:
      return {
        ...state,
        messages: [...state.messages, action.payload.message],
      };

    case Actions.REMOVE_MESSAGE_ACTION:
      return {
        ...state,
        messages: state.messages.filter((message) => message.id !== action.payload.id),
      };

    default:
      return state;
  }
}

export default messageReducer;
