import _ from "lodash";
import { v4 as uuid } from "uuid";

//@ts-ignore
import MultiselectTwoSides from "react-multiselect-two-sides";
import React, { useEffect, useState, useCallback } from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BootstrapSwitchButton from "bootstrap-switch-button-react/lib/bootstrap-switch-button-react";
import Button from "@material-ui/core/Button";

import { State, ThunkDispatch } from "models/StoreModel";
import * as dataThunk from "store/thunk/DataThunk";
import * as locationConstants from "constants/LocationConstants";
import { ScrollToTop } from "utils/ScrollUp";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import { Messages } from "components/message/Message";
import { useMessage } from "hooks";

import "./WhiteListContainer.scss";

function useSectionOptions() {
  const { t } = useTranslation();
  return [
    { key: locationConstants.SECTION_OBJECTS_IN_LOCATION, value: t("passport.location.objectsInLocation") },
    {
      key: locationConstants.SECTION_EXTRA_LOCATION_INFORMATION,
      value: t("passport.location.extraLocationInformation"),
    },
    { key: locationConstants.SECTION_EQUIPMENT, value: t("passport.location.equipment") },
    { key: locationConstants.SECTION_TIME_TRACKING, value: t("passport.location.timeTracking") },
    { key: locationConstants.SECTION_CLIENT_RESTRICTION, value: t("passport.location.clientRestriction") },
    { key: locationConstants.SECTION_SUPPORTED_UNITS, value: t("passport.location.supportedUnits") },
    { key: locationConstants.SECTION_THERMAL, value: t("passport.location.thermal") },
    { key: locationConstants.SECTION_HOURS, value: t("passport.location.hours") },
    { key: locationConstants.SECTION_MONITORY_NOTE, value: t("passport.location.monitoryNote") },
    { key: locationConstants.SECTION_AVG_TIME, value: t("passport.location.avgTime") },
    { key: locationConstants.SECTION_DRIVER_OPERATION, value: t("passport.location.driverOperation") },
    { key: locationConstants.SECTION_DOCUMENT, value: t("passport.location.documents") },
    { key: locationConstants.SECTION_DRIVING_DIRECTIONS, value: t("passport.location.drivingDirections") },
    { key: locationConstants.SECTION_EMPTY_PALLETS, value: t("passport.location.emptyPallet") },
    { key: locationConstants.SECTION_RETURN_OF_GOODS, value: t("passport.location.returnOfGoods") },
    { key: locationConstants.SECTION_PHONE_NUMBERS, value: t("passport.location.phoneNumbers") },
  ];
}

const WhiteListContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch>();
  const { selectedLocation } = useSelector((state: State) => state.account);
  const whiteListData = useSelector((state: State) => state.data.whiteListData);
  const dataState = useSelector((state: State) => state.data);

  if (!selectedLocation) {
    throw new Error("Invariant - for this route `selectedLocation` are required.");
  }

  const { addMessage } = useMessage();
  const sectionOptions = useSectionOptions();
  const [whitelistEnabled, setWhitelistEnabled] = useState<boolean>(false);
  const [whitelistOptions, setWhitelistOptions] = useState([]);
  const [restrictedSectionSelectedValues, setRestrictedSectionSelectedValues] = useState<string[]>([]);
  const [whitelistSelectedValues, setWhitelistSelectedValues] = useState<string[]>([]);

  const whitelistAvailableCount = whitelistOptions.length - whitelistSelectedValues.length;
  const restrcitedSectionAvailableCount = sectionOptions.length - restrictedSectionSelectedValues.length;

  const whitelistHandleChange = useCallback((value: any) => {
    setWhitelistSelectedValues(value);
  }, []);

  const restrictedSectionsHandleChange = useCallback((value: any) => {
    setRestrictedSectionSelectedValues(value);
  }, []);

  const saveHandler = async () => {
    try {
      await dispatch(
        dataThunk.updateWhitelistAndRestrictedSections(
          selectedLocation.id,
          whitelistEnabled,
          whitelistSelectedValues,
          restrictedSectionSelectedValues,
        ),
      );
      addMessage({
        type: "success",
        text: t("my_data.whitelist.update.success"),
      });
    } catch (error) {
      addMessage({
        type: "error",
        text: error.message,
      });
    }
    ScrollToTop();
  };

  const whiteListSwitcher = useCallback((value: boolean) => {
    setWhitelistEnabled(value);
  }, []);

  const fetchData = () => {
    dispatch(dataThunk.getCompanyDetails(selectedLocation.id));
    dispatch(dataThunk.getMyWhitelistCompanies(selectedLocation.id, "white_list"));
    dispatch(dataThunk.getNotInWhitelistCompanies(selectedLocation.id, "not_in_white_list"));
  };

  const filterElements = (option: any, filter: string, labelKey: string) => {
    return (
      option["fullName"].toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
      option["locationExtraDetails"]["nip"].toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
      option["locationExtraDetails"]["regon"].toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
  };

  useEffect(() => {
    fetchData();
  }, [selectedLocation]);

  useEffect(() => {
    setWhitelistOptions(whiteListData.restCompanies);
  }, [whiteListData.restCompanies]);

  useEffect(() => {
    if (!_.isEmpty(dataState.companyDetails)) {
      setWhitelistEnabled(dataState.companyDetails.location.whitelistEnabled);
      setRestrictedSectionSelectedValues(dataState.companyDetails.location.restrictedSections || []);
    }
  }, [dataState.companyDetails]);

  useEffect(() => {
    const selectedIds: string[] = _.map(whiteListData.myCompanies, (company) => company.id);
    setWhitelistSelectedValues(selectedIds);
  }, [whiteListData.myCompanies]);

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-4">
        <div className="context-menu mb-2">
          <Breadcrumb>
            <BreadcrumbItem active>{t("my_data.whitelist.title")}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Messages />
        <div className="row border-top-3 p-4 bg-white mt-4">
          <div className="col-md-12">
            <div className={"mb-3"}>
              <h5 className="form-title form-header font-weight-bold font-size-normal">
                {t("my_data.whitelist.title")}
              </h5>
              <hr />
              <label className="ml-3 font-size-normal">{t("my_data.whitelist.enable.help_text")}</label>
              <BootstrapSwitchButton
                key={uuid()}
                checked={whitelistEnabled}
                onlabel={t("passport.location.value.yes")}
                offlabel={t("passport.location.value.no")}
                onstyle={"success"}
                offstyle={"secondary"}
                onChange={whiteListSwitcher}
                style={"float-right mr-3"}
              />
            </div>
            {whitelistEnabled && (
              <>
                <div className="col-md-12 mt-5">
                  <h5 className="form-subtitle form-header font-weight-bold font-size-normal">
                    {t("my_data.whitelist.my_companies")}
                  </h5>
                  <MultiselectTwoSides
                    options={whitelistOptions}
                    value={whitelistSelectedValues}
                    valueKey="id"
                    placeholder={t("my_data.whitelist.search.placeholder")}
                    className="msts_theme_example font-size-normal"
                    onChange={whitelistHandleChange}
                    filterBy={filterElements}
                    availableHeader={t("my_data.whitelist.available")}
                    availableFooter={`${t("my_data.available")}: ${whitelistAvailableCount}`}
                    selectedHeader={t("my_data.whitelist.selected")}
                    selectedFooter={`${t("my_data.selected")}: ${whitelistSelectedValues.length}`}
                    labelKey="fullName"
                    showControls
                    searchable
                  />
                </div>
                <div className="col-md-12 mt-5">
                  <h5 className="form-title form-header font-weight-bold font-size-normal">
                    {t("my_data.restrictedSections.subTitle")}
                  </h5>
                  <MultiselectTwoSides
                    options={sectionOptions}
                    value={restrictedSectionSelectedValues}
                    valueKey="key"
                    className="msts_theme_example font-size-normal"
                    onChange={restrictedSectionsHandleChange}
                    availableHeader={t("my_data.sections.available")}
                    availableFooter={`${t("my_data.available")}: ${restrcitedSectionAvailableCount}`}
                    selectedHeader={t("my_data.sections.selected")}
                    selectedFooter={`${t("my_data.selected")}: ${restrictedSectionSelectedValues.length}`}
                    labelKey="value"
                    showControls
                    searchable={false}
                  />
                </div>
              </>
            )}
            <div className="buttons-section mt-4 col-md-12">
              <Button
                className="bg-dusty-orange  float-right px-5 mt-auto mb-2 mr-0 ml-auto font-size-normal rounded-0 font-weight-bold"
                variant="contained"
                color="primary"
                onClick={saveHandler}>
                {t("my_data.company.save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WhiteListContainer;
