import React from "react";

interface TableCellProps {
  firstValue: string;
  secondValue?: string;
  showSecond?: boolean;
  cssClassForFirst?: string;
  cssClassForSecond?: string;
}

export const TableCell: React.FC<TableCellProps> = ({
  firstValue = "",
  secondValue = "",
  showSecond = true,
  cssClassForFirst,
  cssClassForSecond,
}) => {
  return (
    <div className="white-space-normal">
      <div className={cssClassForFirst}>{firstValue}</div>
      {showSecond && <div className={`${cssClassForSecond} txt-color-bluish-grey`}>{secondValue}</div>}
    </div>
  );
};

export default TableCell;
