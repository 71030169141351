import _ from "lodash";

import React, { FormEvent, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { State, ThunkDispatch } from "models/StoreModel";
import * as AccountThunk from "store/thunk/AccountThunk";
import { Input } from "components";
import { useFormValidator } from "hooks";
import * as RegisterActions from "store/actions/RegisterActions";
import { Header, Message, Messages, Footer } from "components";
import { useMessage } from "hooks";
import { ScrollToTop } from "../../utils/ScrollUp";

export const LoginContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch>();
  const authenticated = useSelector((state: State) => state.account.authenticated);
  const registerSuccess = useSelector((state: State) => state.registration.registerSuccess);

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const { addMessage } = useMessage();

  const [loginFormErrors, setLoginFormErrors, validateloginFormErrors] = useFormValidator(
    {
      email: [{ rule: "isRequired", message: t("form.validation.required") }],
      password: [{ rule: "isRequired", message: t("form.validation.required") }],
    },
    [t],
  );

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await dispatch(AccountThunk.login(loginForm.email, loginForm.password, false));
    } catch (error) {
      const status = _.get(error, "response.status");
      addMessage({
        type: "error",
        text: status === 403 ? error.response.data : t("login_form.login_error"),
      });
    }
  };

  const setPasswordForm = useCallback((partialForm: any) => {
    setLoginForm((prevState) => ({ ...prevState, ...partialForm }));
  }, []);

  useEffect(() => {
    return () => {
      // @ts-ignore - TODO - define types for reg actions
      dispatch(RegisterActions.RegistrationInProgress());
    };
  }, []);

  if (authenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
    <Header withTitle="Logowanie użytkownika w systemie GlobLOC" />
    <div className="login-form">
      <div className="container">
        {/* TODO: - Messages */}
        {registerSuccess && <Message type="success" text={t("registration.success")} />}
        <Messages />
        <div className="row justify-content-center">
          <div className="col-10 col-sm-8 col-md-6 col-lg-5 container bg-white border-top-3-marine-blue mx-1 w-100">
            <div className="row mt-3 text-center">
              <h5 className="col-12 text-left pt-2 txt-color-marine-blue font-size-normal text-center">
                <strong>{t("login_form.login_welcome_msg")}</strong>
              </h5>
              <hr style={{ width: "80%" }} />
            </div>
            <div className="row mb-4 d-flex justify-content-center">
              <form onSubmit={handleSubmit} autoComplete={"on"} action="" className="w-100 mx-5">
                <Input
                  label={t("login_form.login_label_username")}
                  value={loginForm.email}
                  inputType={"text"}
                  handleChange={({ email }: any) => setPasswordForm({ email: email.toLowerCase() })}
                  name={"email"}
                  autocomplete="username"
                  errors={loginFormErrors}
                  required={true}
                  className="txt-color-primary"
                />
                <Input
                  label={t("login_form.login_label_password")}
                  value={loginForm.password}
                  inputType={"password"}
                  placeholder={"*******"}
                  errors={loginFormErrors}
                  handleChange={setPasswordForm}
                  name={"password"}
                  autocomplete="current-password"
                  required={true}
                  className="txt-color-primary"
                />
                <Link
                  className="mt-3 mb-2 justify-content-end  mt-2 d-flex txt-color-link font-size-regular"
                  to="/reset_password">
                  {t("login_form.reset_password")}
                </Link>
                <Link
                  className="my-2 justify-content-end  d-flex txt-color-link font-size-regular"
                  style={{ textAlign: "end" }}
                  to="/register">
                  {t("login_form.no_agreement")}
                </Link>
                <div className="form-submit">
                  <button
                    type="submit"
                    onClick={() => {
                      ScrollToTop();
                    }}
                    className="text-white border-0 w-100 py-1 mt-2 bg-dusty-orange">
                    {t("login_form.login")}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-10 col-sm-8 col-md-6 col-lg-5 container bg-white border-top-3-orange mx-1 w-100 p-4 txt-color-primary d-flex flex-column txt-color-grafite font-size-regular">
            <p>{t("login_form.description")}</p>
            <p>{t("login_form.description_2")}</p>
            <p>{t("login_form.invitation")}</p>
          </div>
          <div className="col-10 container bg-white border-top-3-green my-6 mx-6 w-100 mt-2 p-4 txt-color-primary d-flex flex-column txt-color-grafite font-size-regular">
            <p>{t("login_form.globloc_is")}</p>
            <ul className="pl-3">
              {[...t("login_form.globloc_features", { returnObjects: true })].map((feature: string, index: number) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default LoginContainer;
