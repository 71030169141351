import { v4 as uuid } from "uuid";
import _ from "lodash";

import React, { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Filter from "components/filters/Filter";
import { Filter as IFilter } from "models/DataModel";
import { UpdateFilters } from "store/actions/DataActions";

interface FiltersGroupProps {
  options: {
    id: string;
    value: string;
    type: string;
    validation?: (val: string) => boolean;
  }[];
  filtersKey: string;
  multiple?: boolean;
  fullWidth?: boolean;
  filterLabel?: string;
  buttonLabel?: string;
  onSubmit?: () => void;
  className?: string;
  disableEmpty?: boolean;
}

const defaultFilter = {
  id: "",
  value: "",
  type: "text",
  validation: (val: string) => false,
};

export const FiltersGroup: React.FC<FiltersGroupProps> = ({
  options = [defaultFilter],
  filtersKey,
  multiple = false,
  fullWidth = false,
  filterLabel = "",
  buttonLabel = "",
  onSubmit = () => {},
  className = "",
  disableEmpty = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState([
    {
      id: "",
      value: "",
      uuid: uuid(),
      hasError: false,
      errorMessage: "",
      validation: (val: string) => false,
    },
  ]);
  const [lastFilterHasError, setLastFilterHasError] = useState(false);
  const [disabled, setDisabled] = useState(disableEmpty);

  useEffect(() => {
    if (disableEmpty) {
      setDisabled(!filters.every(
        filter => filter.value !== "" && (Array.isArray(filter.value) ? filter.value.length > 0 : true))
        );
    }
  }, [filters, disableEmpty]);

  const filterData = () => {
    const globalFilters: IFilter[] = [];
    let anyFilterHasError = false;

    filters.forEach((filter) => {
      if (filter.hasError) anyFilterHasError = filter.hasError;
      globalFilters.push({
        id: filter.id,
        value: filter.value,
      });
    });
    if (!anyFilterHasError) {
      dispatch(UpdateFilters(filtersKey, globalFilters));
    }
  };

  const addFilter = () => {
    const filter = _.last(filters);
    if (filter !== undefined && filter.id !== "") {
      filters.push({
        id: "",
        value: "",
        errorMessage: "",
        hasError: false,
        uuid: uuid(),
        validation: (val: string) => false,
      });
      setFilters([...filters]);
    }
    validateFilters();
  };

  const removeFilter = (id: string) => {
    setFilters([...filters.filter((obj) => obj.id !== id)]);
    validateFilters();
  };

  const validateFilters = () => {
    filters.forEach((filter) => {
      setLastFilterHasError(filter.hasError);
    });
  };

  const updateFilters = (filter: any) => {
    const filterToUpdate = _.find(filters, (f) => f.uuid === filter.uuid);
    if (filterToUpdate !== undefined) {
      filterToUpdate.id = filter.id;
      filterToUpdate.value = filter.value;
      filterToUpdate.hasError = filter.hasError;
      setFilters([...filters]);
      validateFilters();
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    filterData();
    onSubmit();
  };

  useEffect(() => {
    return () => {
      dispatch(UpdateFilters(filtersKey, []));
    };
  }, [filtersKey]);

  return (
    <form onSubmit={handleSubmit} autoComplete={"on"}>
      <div className={`filters d-flex mx-auto row ${className}`}>
        <div className={"container px-0 ml-0 mr-auto " + (fullWidth ? "col-md-12" : "col-md-9")}>
          {filters.map((filter, index) => {
            const selectedIds: string[] = filters.map((fil) => fil.id);
            const availableOptions = _.filter(options, (obj) => selectedIds.indexOf(obj.id) === -1);
            return (
              <Filter
                filterLabel={filterLabel}
                key={filter.uuid}
                filter={filter}
                options={availableOptions}
                index={index}
                addFilter={addFilter}
                removeFilter={removeFilter}
                updateFilters={updateFilters}
                multiple={multiple}
                filtersCount={filters.length}
              />
            );
          })}
        </div>
        <div
          className={`d-flex input-group col-12 col-md-2 px-0 align-self-end ml-md-auto ${
            lastFilterHasError ? " margin-bottom-error" : "mb-3"
          }`}>
          <button
            style={{ padding: "0.68rem 0" }}
            className="btn font-weight-bold txt-color-white btn-outline-secondary border-0 px-4 w-100 rounded-0 font-size-regular  bg-dusty-orange search-button"
            type="submit"
            disabled={disabled}>
            {_.isEmpty(buttonLabel) ? t("filters.search_button") : buttonLabel}
          </button>
        </div>
      </div>
    </form>
  );
};

export default FiltersGroup;
