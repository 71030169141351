import { BaseAction } from "models/StoreModel";

export const CHECK_STATE = "auth.Register.StateCheck";
export const SIGN_UP = "auth.Register.SignUp";
export const REGISTRATION_INPROGRESS = "auth.register.inProgress";
export const REGISTRATION_FAILURE = "auth.Register.Failure";
export const REGISTRATION_SUCCESS = "auth.Register.Success";
export const FILE_SAVE_FAILURE = "auth.File.Save.Failure";
export const FILE_SAVE_SUCCESS = "auth.File.Save.Success";

export const RegistrationInProgress = (): BaseAction => ({
  type: REGISTRATION_INPROGRESS,
});

export const RegistrationFailure = (): BaseAction => ({
  type: REGISTRATION_FAILURE,
});

export const RegistrationSuccess = (payload: { [id: string]: string }): BaseAction => ({
  type: REGISTRATION_SUCCESS,
  payload,
});

export const FileSaveSuccess = (): BaseAction => ({
  type: FILE_SAVE_SUCCESS,
});

export const FileSaveFailure = (): BaseAction => ({
  type: FILE_SAVE_FAILURE,
});
