import React from "react";
import { FilterProps } from "../Filter";

export const TextFilter: React.FC<FilterProps> = ({ id, value, handleChange, className }) => {
  return (
    <input
      key={id}
      type="text"
      value={value}
      className={`form-control rounded-0 border search-input ${className ? className : ""}`}
      onChange={handleChange}
    />
  );
};

export default TextFilter;
