import { createBrowserHistory } from "history";

import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "react-router-dom";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

import { configureStore } from "store/ConfigureStore";
import { Layout } from "./components/Layout";
import * as PWAActions from "./store/actions/PWAActions";

import "./App.scss";
import { MobileApp } from "./pages/mobileApp/MobileApp";
const { store, persistor } = configureStore();

const history = createBrowserHistory();

history.listen((location) => {
  const { account } = store.getState();

  let userType = "";
  let userId = "Anonymous user";
  let selectedLocation = "No location";
  if (account.user && account.selectedLocation) {
    userId = account.user.email;
    selectedLocation = account.selectedLocation.fullName;
    if (account.isAdminForSelectedLocation && account.selectedLocation.isAuthorized) {
      userType = "Authorized admin";
    } else if (account.isAdminForSelectedLocation && !account.selectedLocation.isAuthorized) {
      userType = "Standard admin";
    } else {
      userType = "Standard user";
    }
  }

  ReactGA.set({
    page: location.pathname,
    userId: userId,
    dimension1: userType,
    dimension2: selectedLocation,
  });
  ReactGA.pageview(location.pathname);

  // TODO - define the action
  store.dispatch({ type: "LOCATION_CHANGE" });
});

const App: React.FC = () => {
  const dispatch = useDispatch();

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || "", {
    debug: true,
    testMode: process.env.NODE_ENV === "test",
  });

  type StateT = {
    isPrivateRoute: null | boolean;
    displayModal: null | boolean;
    deferredPrompt: any;
  };

  const [state, setState] = useState<StateT>({
    isPrivateRoute: null,
    displayModal: null,
    deferredPrompt: null,
  });

  const safeSpace = ["/login", "/register", "/set_password", "/reset_password"];

  history.listen((ev: any) => {
    setState((prevState) => ({
      ...prevState,
      isPrivateRoute:
        safeSpace.find((el: string) => {
          return el === ev.pathname;
        }) === undefined, // Check if publicRoute not found
    }));
  });

  const checkPathOnInitialLoad = () => {
    setState((prevState) => ({
      ...prevState,
      isPrivateRoute:
        safeSpace.find((el: string) => {
          return el === history.location.pathname;
        }) === undefined,
    }));
  }; // When user comes from elsewhere and was logged-in already

  const handleError = (error: any) => {
    setState((prevState) => ({ ...prevState, displayModal: false }));
    console.error(error);
  };

  const handleAcceptance = () => {
    setState((prevState) => ({ ...prevState, displayModal: false }));
    console.log("A2HS accepted!");
  };

  const handleRejection = () => {
    setState((prevState) => ({ ...prevState, displayModal: false }));
    dispatch(PWAActions.PWAInstallationRejected());
  };

  const handleInstallation = () => {
    setState((prevState) => ({ ...prevState, displayModal: false }));
    state.deferredPrompt.prompt();
    state.deferredPrompt.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          handleAcceptance();
        } else {
          handleRejection();
        }
      })
      .catch(handleError);
  };

  function promptHandler(e: any) {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, deferredPrompt: e }));

    let installPrompt = Cookies.get("installPrompt");
    if (installPrompt && installPrompt === "accepted") {
      Cookies.remove("installPrompt");
    } else {
      setState((prevState) => ({ ...prevState, displayModal: true }));
    } // If the cookie exists and is set to accepted reset it.
  }

  function isAppInstalledHandler(e: any) {
    setState((prevState) => ({
      ...prevState,
      displayModal: false,
    }));
    dispatch(PWAActions.PWAInstallationAccepted());
  }

  useEffect(() => {
    checkPathOnInitialLoad();
    window.addEventListener("beforeinstallprompt", (e) => promptHandler(e));
    window.addEventListener("appinstalled", (e) => isAppInstalledHandler(e));
    return () => {
      window.removeEventListener("beforeinstallprompt", (e) => promptHandler(e));
      window.removeEventListener("appinstalled", (e) => isAppInstalledHandler(e));
    };
  }, []);

    useEffect(() => {

    const cookieBotScript = document.createElement('script');
    cookieBotScript.src = "https://consent.cookiebot.com/uc.js";
    cookieBotScript.type = "text/javascript";
    cookieBotScript.id = "Cookiebot"
    cookieBotScript.setAttribute('data-cbid',"aa37da17-d3fe-4ae2-9d1a-8bcbf80608d6")
    cookieBotScript.setAttribute('data-blockingmode', "auto")
    document.head.appendChild(cookieBotScript);

    const cookieBotDeclarationScript = document.createElement('script');
    cookieBotDeclarationScript.src = "https://consent.cookiebot.com/aa37da17-d3fe-4ae2-9d1a-8bcbf80608d6/cd.js";
    cookieBotDeclarationScript.async = true;
    cookieBotDeclarationScript.type = "text/javascript";
    cookieBotDeclarationScript.id = "CookieDeclaration"
    document.head.appendChild(cookieBotDeclarationScript);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          {state.displayModal && window.innerWidth < 756 ? (
            <MobileApp
              isPrivateRoute={state.isPrivateRoute}
              handleInstallation={handleInstallation}
              handleRejection={handleRejection}
            />
          ) : null}
          <Layout />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
