import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { configureStore } from "store/ConfigureStore";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import "./i18n/i18n";
const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,

  document.getElementById("root"),
);

serviceWorker.register();
