import AccountService from "./AccountService";
import LocationService from "./LocationService";
import SuggestionService from "./SuggestionService";

// TODO - remove me in next PR
export const BackendService = {
  account: AccountService,
  location: LocationService,
  suggestion: SuggestionService,
};

export { AccountService, LocationService, SuggestionService };
