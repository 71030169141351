import React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";

const SmallColumns: (fromState: boolean) => Column[] = (fromState) => {
  const { t } = useTranslation();

  const smallColumns: Column[] = [
    {
      headerClassName: "d-none",
      id: "gln_number",
      accessor: (d: any) => {
        return (
          <div className="d-flex flex-column bg-white p-3 my-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="font-size-regular font-weight-bold txt-color-marine-blue mb-2">{d.companyName}</span>
                <span className="font-size-regular txt-color-borders">{d.nip}</span>
              </div>
              <div className="d-flex">
                {fromState && d.suggestionsCount > 0 && (
                  <span className="float-right icon-text">
                    <img src="/icons/icon-warning.svg" alt="icon-warning" />
                    <span>{d.suggestionsCount}</span>
                  </span>
                )}

                <span className="no-user-select d-flex align-items-center font-size-medium txt-color-dusty-orange cursor-pointer"></span>
              </div>
            </div>
            <hr className="my-2" />
            <div className="container justify-content-between my-2">
              <div className="row">
                <div className="col-12 d-flex flex-column px-0">
                  <span className="my-1 font-size-regular txt-color-borders">
                    {t("table.localization_name_header")}
                  </span>
                  <span className="my-1 font-size-regular txt-color-primary font-weight-bold white-space-normal word-break-word">
                    {d.fullName}
                  </span>
                </div>
              </div>
            </div>
            <div className="container px-0 justify-content-between my-2">
              <div className="row">
                <div className="col-7 d-flex flex-column">
                  <span className="my-1 font-size-regular txt-color-borders">{t("table.address_header")}</span>
                  <span className="my-1 font-size-regular txt-color-primary font-weight-bold white-space-normal word-break-word">
                    <p className="mr-1 my-0">{d.address},</p>
                    <p className="mr-1 my-0">{d.postalCode}</p>
                    <p className="mr-1 my-0">{d.city},</p>
                    <p className="mr-1 my-0">{d.country}</p>
                  </span>
                </div>
                <div className="col-5 d-flex flex-column">
                  <span className="my-1 font-size-regular txt-color-borders text-uppercase">{t("gln.label")}</span>
                  <span className="my-1 font-size-regular txt-color-dusty-orange font-weight-bold">{d.glnNumber}</span>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return smallColumns;
};

export default SmallColumns;
