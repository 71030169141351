import _ from "lodash";

import React from "react";
import { useTranslation } from "react-i18next";

import "./PasswordRules.scss";

const PASSWORD_LENGTH = "passwordLength";
const SPECIAL_CHARACTERS = "specialCharacters";
const OTHER_THAN_LOGIN = "otherThenLogin";
const MISSMATCH_IN_PASSWORDS = "missmatchInPasswords";

export type PasswordRule =
  | typeof PASSWORD_LENGTH
  | typeof SPECIAL_CHARACTERS
  | typeof OTHER_THAN_LOGIN
  | typeof MISSMATCH_IN_PASSWORDS;

interface Props {
  passwordRulesFulfilled: string[];
}

export function validateNewPassword(password: string, passwordConfirm: string, userEmail: string) {
  const rulesFulfilled: PasswordRule[] = [];
  let isValid = true;

  if (_.isEmpty(password)) {
    isValid = false;
  } else {
    if (password.length >= 8) {
      rulesFulfilled.push(PASSWORD_LENGTH);
    } else {
      isValid = false;
    }

    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      rulesFulfilled.push(SPECIAL_CHARACTERS);
    } else {
      isValid = false;
    }

    if (password.toLowerCase() !== userEmail.toLowerCase()) {
      rulesFulfilled.push(OTHER_THAN_LOGIN);
    } else {
      isValid = false;
    }

    if (password === passwordConfirm) {
      rulesFulfilled.push(MISSMATCH_IN_PASSWORDS);
    } else {
      isValid = false;
    }
  }

  return {
    isValid,
    rulesFulfilled,
  };
}

export const PasswordRules: React.FC<Props> = ({ passwordRulesFulfilled }) => {
  const { t } = useTranslation();

  const passwordRules = [
    {
      key: PASSWORD_LENGTH,
      text: t("set_password.password_length"),
    },
    {
      key: SPECIAL_CHARACTERS,
      text: t("set_password.special_characters"),
    },
    {
      key: OTHER_THAN_LOGIN,
      text: t("set_password.other_then_login"),
    },
    {
      key: MISSMATCH_IN_PASSWORDS,
      text: t("form.validation.missmatch_in_passwords"),
    },
  ];

  const markRuleFulfilled = (ruleKey: string) => {
    return passwordRulesFulfilled.includes(ruleKey) ? "correct" : "error";
  };

  return (
    <>
      {passwordRules.map((rule) => (
        <div
          key={rule.key}
          className={
            "d-flex flex-column txt-color-bluish-grey icon-message font-size-normal " + markRuleFulfilled(rule.key)
          }>
          <span>{rule.text}</span>
        </div>
      ))}
    </>
  );
};

export default PasswordRules;
