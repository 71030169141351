import * as _ from "lodash";
import { v4 as uuid } from "uuid";

import React, { useCallback, useEffect, useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { State } from "models/StoreModel";
import { GS1Location } from "models/LocationModel";
import { LocationUser } from "models/UserModel";
import AccountService from "services/AccountService";
import { getUserByEmail } from "store/thunk/DataThunk";
import { Footer, Header, Input, NumberInput, RadioInput, Messages } from "components";
import { useFormValidator, useMessage } from "hooks";
import { ContainerTypes } from "./UserFormContainer";
import Button from "@material-ui/core/Button";
import { ScrollToTop } from "utils/ScrollUp";

interface UserFormContainerProps {
  locationUser?: LocationUser | null;
  selectedLocation: GS1Location;
  typeOfContainer: ContainerTypes;
}

const HTMLTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#002C6C",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "0",
    fontSize: "1em",
  },
}))(Tooltip);

export const UserFormContainer: React.FC<UserFormContainerProps> = ({
  locationUser,
  selectedLocation,
  typeOfContainer,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dataState = useSelector((state: State) => state.data);
  const dispatch = useDispatch();
  const { addMessage } = useMessage();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const initialFormState = {
    id: "",
    user: {
      confirmedEmail: false,
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      confirmedStatute: false,
      agreeToSellingEmails: undefined,
      agreeToSellingPhones: undefined,
      agreeToMarketingEmails: undefined,
      agreeToMarketingPhones: undefined,
      agreeToSharingData: undefined,
    },
    location: "",
    permissionGroup: "",
    isCompanyRepresentative: false,
    userPhoneNumber: "",
    userInternalNumber: "",
    userPosition: "",
    firstLoginConfirmed: false,
    createdDate: "",
  };

  const [blockEditing, setBlockEditing] = useState(typeOfContainer === "edit");
  const [formState, setFormState] = useState<LocationUser>(initialFormState);
  const [userFormErrors, setUserFormErrors, validateUserForm] = useFormValidator(
    {
      firstName: [{ rule: "isRequired", message: t("form.validation.required") }],
      lastName: [{ rule: "isRequired", message: t("form.validation.required") }],
      userPosition: [{ rule: "isRequired", message: t("form.validation.required") }],
      email: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isEmail", message: t("form.validation.email_format") },
      ],
      userPhoneNumber: [
        { rule: "isRequired", message: t("form.validation.required") },
        { rule: "isGsonePhoneNumber", message: t("form.validation.phone_format") },
        {
          rule: "IsLengthOptions",
          options: { min: 9, max: 15 },
          message: t("form.validation.phone_number"),
        },
      ],
      userInternalNumber: [],
      permissionGroup: [{ rule: "isRequired", message: t("form.validation.required") }],
    },
    [t],
  );

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      ...locationUser,
    }));

    if (!_.isEmpty(locationUser)) {
      setBlockEditing(true);
    }
  }, [locationUser]);

  const onSave = async () => {
    setDisableSubmit(true);
    try {
      await AccountService.saveUser(formState, selectedLocation.id);
      history.push("/users");
      addMessage({
        type: "success",
        text: formState.user.id ? t("users.update_user") : t("users.added_new_user"),
      });
    } catch (error) {
      const status = _.get(error, "response.status");
      const membership = _.get(error, "response.data.membership");
      if (status === 400 && membership) {
        // @ts-ignore
        setUserFormErrors((prevState) => ({ ...prevState, email: t("form.validation.email_exist") }));
      }
      addMessage({
        type: "error",
        text: t("users.added_new_user_error"),
      });
      setDisableSubmit(false);
      ScrollToTop();
    }
  };

  const formSubmit = async (e: FormEvent) => {
    // @ts-ignore
    const isValid = validateUserForm({
      firstName: formState.user.firstName,
      lastName: formState.user.lastName,
      email: formState.user.email,
      // the fields above are nested, hence this bloat.
      permissionGroup: formState.permissionGroup,
      userInternalNumber: formState.userInternalNumber,
      userPhoneNumber: formState.userPhoneNumber,
      userPosition: formState.userPosition,
    });
    if (isValid) {
      onSave();
    }
  };

  const radioInputOptions = [
    {
      value: "location_admin",
      label: t("permissions.location_admin"),
      uuid: uuid(),
    },
    {
      value: "location_user",
      label: t("permissions.location_user"),
      uuid: uuid(),
    },
  ];

  const setPartialForm = useCallback((partialForm: any) => {
    setFormState((prevState) => ({ ...prevState, ...partialForm }));
  }, []);

  const setUserPartialForm = useCallback((partialForm: any) => {
    if (partialForm.hasOwnProperty("email") && typeOfContainer === "add") {
      dispatch(getUserByEmail(partialForm.email));
    }
    setFormState((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        ...partialForm,
      },
    }));
  }, []);

  useEffect(() => {
    let lastFoundEmail = "";

    if (!dataState.loadingError && !_.isEmpty(dataState.model)) {
      setFormState((prevState) => ({
        ...prevState,
        user: {
          ...prevState.user,
          email: !_.isEmpty(dataState.model.email) ? dataState.model.email.toLowerCase() : "",
          firstName: dataState.model.firstName,
          lastName: dataState.model.lastName,
        },
      }));
      setBlockEditing(true);
      lastFoundEmail = formState.user.email;
    } else if (dataState.loadingError && lastFoundEmail !== formState.user.email) {
      setFormState((prevState) => ({
        ...prevState,
        user: {
          ...prevState.user,
          email: prevState.user.email,
          firstName: "",
          lastName: "",
        },
      }));
      setBlockEditing(false);
    }
  }, [dataState.loadingError]);

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-5">
        <div className="context-menu pb-4">
          <Breadcrumb>
            <BreadcrumbItem href="/users">
              <span className="txt-color-link">{t("users.title")}</span>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {formState.user.id === "" ? t("users.add_user") : t("users.edit_user")}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Messages />
        <div className="row border-top-3 p-4 bg-white mt-4">
          <div className="col-md-12">
            <div className="content-title">
              <h5 className="form-title form-header font-size-normal font-weight-bold  font-weight-bold font-size-normal  bold">
                {t("users.add.title")}
              </h5>
            </div>
            <hr />
            <div className="content-form form-row margin-0">
              <div className="col pr-lg-4 ">
                <Input
                  label={t("users.add.email")}
                  name="email"
                  errors={userFormErrors}
                  value={formState.user.email}
                  required={true}
                  inputType="email"
                  handleChange={({ email }: any) =>
                    setUserPartialForm({ email: !_.isEmpty(email) ? email.toLowerCase() : "" })
                  }
                  disabled={blockEditing && typeOfContainer === "edit"}
                />
                <Input
                  label={t("users.add.firstName")}
                  name="firstName"
                  errors={userFormErrors}
                  required={true}
                  value={formState.user.firstName}
                  inputType="text"
                  handleChange={({ firstName }: any) => setUserPartialForm({ firstName })}
                  disabled={blockEditing}
                />
                <Input
                  label={t("users.add.lastName")}
                  name="lastName"
                  errors={userFormErrors}
                  required={true}
                  value={formState.user.lastName}
                  inputType="text"
                  handleChange={({ lastName }: any) => setUserPartialForm({ lastName })}
                  disabled={blockEditing}
                />
                {blockEditing && (!dataState.loadingError || typeOfContainer === "edit") && (
                  <HTMLTooltip
                    disableFocusListener
                    disableTouchListener
                    title={
                      <div className="gs1-tooltip gotham-font-stack font-size-regular ">
                        {typeOfContainer === "add" && t("tooltip.user.addBlocked")}
                        {typeOfContainer === "edit" && t("tooltip.user.editBlocked")}
                      </div>
                    }
                    placement="top">
                    <div className="font-size-regular txt-color-gray mt-3">
                      <img className="mr-1" src="/icons/icon-help-enabled.svg" alt="" />
                      {t("companyInformation.form.left.cantEditData")}
                    </div>
                  </HTMLTooltip>
                )}
              </div>
              <div className="col pl-lg-4">
                <Input
                  label={t("users.add.position")}
                  name="userPosition"
                  errors={userFormErrors}
                  required={true}
                  value={formState.userPosition}
                  inputType="text"
                  handleChange={({ userPosition }: any) => setPartialForm({ userPosition })}
                />
                <div className="margin-0 form-row">
                  <div className="col-md-9 col-lg-10">
                    <NumberInput
                      label={t("users.add.phone")}
                      name="userPhoneNumber"
                      errors={userFormErrors}
                      value={formState.userPhoneNumber}
                      required={true}
                      phoneInput={true}
                      inputType="text"
                      placeholder={t("placeholder.phoneNumber")}
                      handleChange={({ userPhoneNumber }: any) => setPartialForm({ userPhoneNumber })}
                    />
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <NumberInput
                      label={t("users.add.internal_phone")}
                      errors={userFormErrors}
                      name="userInternalNumber"
                      inputType="text"
                      value={formState.userInternalNumber}
                      handleChange={({ userInternalNumber }: any) => setPartialForm({ userInternalNumber })}
                    />
                  </div>
                </div>
                <RadioInput
                  name="permissionGroup"
                  defaultValue={formState.permissionGroup}
                  options={radioInputOptions}
                  direction={"column"}
                  disabled={typeOfContainer === "edit"}
                  errors={userFormErrors}
                  required={true}
                  label={t("users.add.permissions")}
                  handleInput={({ permissionGroup }: any) => setPartialForm({ permissionGroup })}
                />
              </div>
            </div>
            <div className="buttons-section mt-4">
              <Link
                className="btn btn-link txt-color-link link context-menu-action pl-0 font-weight-bold font-size-normal "
                to="/users">
                <img src="/icons/icon-arrow-small-left-enabled.svg" height="18" alt="Left Arrow" />
                {t("users.button.back")}
              </Link>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disableSubmit}
                className={
                  "text-white border-0 float-right rounded-0 py-2 px-5 mt-2 bg-dusty-orange font-weight-bold font-size-normal" +
                  (disableSubmit ? "disabled" : "")
                }
                onClick={formSubmit}>
                {formState.user.id === "" ? t("users.button.add") : t("users.button.edit")}
                {disableSubmit ? <Spinner className="ml-2" animation="border" variant="light" size="sm" /> : <></>}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserFormContainer;
