import GS1APIClient from "clients/GS1APIClient";
import { Suggestion, ReportForSections, ARCHIVED } from "models/SuggestionModel";

function addSuggestion(body: string, locationId: string, section: string) {
  return GS1APIClient.privateRequest<Suggestion>({
    url: "/suggestions/",
    method: "POST",
    data: { body, location: locationId, section },
  });
}

function archiveSuggestion(suggestionId: string, locationId: string) {
  return GS1APIClient.privateRequest<Suggestion>({
    url: `/suggestions/detail/${suggestionId}/`,
    method: "PATCH",
    params: { location: locationId },
    data: { status: ARCHIVED },
  });
}

function getSuggestions(locationId: string) {
  return GS1APIClient.privateRequest<Suggestion[]>({
    url: "/suggestions/",
    method: "GET",
    params: { location: locationId },
  });
}

function getReportForSections(locationId: string) {
  return GS1APIClient.privateRequest<ReportForSections>({
    url: "/suggestions/report_for_sections/",
    method: "GET",
    params: { location: locationId },
  });
}

const SuggestionService = {
  addSuggestion,
  archiveSuggestion,
  getSuggestions,
  getReportForSections,
};

export default SuggestionService;
