import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { QuestionProps } from "../PassportUtils";

const AddressQuestion: React.FC<QuestionProps> = ({ field, mainObject }) => {
  const { t } = useTranslation();
  const address = _.get(mainObject, field.value.slice().concat("address"));
  const postalCode = _.get(mainObject, field.value.slice().concat("postalCode"));
  const city = _.get(mainObject, field.value.slice().concat("city"));
  const country = _.get(mainObject, field.value.slice().concat("country"));

  return (
    <div className="font-size-normal  col-md-3 d-flex flex-column p-2 company-details" key={uuid()}>
      <label className="font-size-normal details-header txt-color-bluish-grey text-uppercase pt-2">
        {t("passport.location." + field.key)}
      </label>
      <span className="font-size-normal  details-value font-weight-bold">
        {address}, {postalCode} {city}, {country}
      </span>
    </div>
  );
};

export default AddressQuestion;
