import _ from "lodash";
import { v4 as uuid } from "uuid";

import React, { useEffect, useState, useCallback, useRef, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Breadcrumb, BreadcrumbItem, Card } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";

import { State } from "models/StoreModel";
import * as dataThunk from "store/thunk/DataThunk";
import { SuggestionService } from "services";
import { Footer, Header, Messages } from "components";
import SimpleQuestion from "components/passport/view/SimpleQuestion";
import BooleanQuestion from "components/passport/view/BooleanQuestion";
import SingleSelectQuestion from "components/passport/view/SingleSelectQuestion";
import MultiInOneColumnQuestion from "components/passport/view/MultiInOnecolumnQuestion";
import MultiInSeveralColumnsQuestion from "components/passport/view/MultiInSeveralColumnsQuestion";
import AddressQuestion from "components/passport/view/AddressQuestion";
import MapMarkerQuestion from "components/passport/view/MapMarkerQuestion";
import EditModal from "components/passport/EditModal";
import { shouldRender, isLocationAdmin } from "components/passport/PassportUtils";
import { AddModal as AddSuggestionModal, SuggestionList } from "components/suggestion";
import PASSPORT_MAPPING, { Field, Section, SubSection } from "./PassportMapping";
import { useMessage } from "hooks";
import { ScrollToTop, ScrollToElement } from "../../utils/ScrollUp";
import * as dataActions from "../../store/actions/DataActions";

import "../../components/suggestion/suggestion.scss";
import "./GlnPassportContainer.scss";

const emptySection = {
  id: "",
  sectionTitleKey: "",
  section: [] as SubSection[],
};

interface SectionRefs {
  [key: string]: RefObject<Card<"div">> & RefObject<HTMLDivElement>;
}

interface GlnPassportContainerProps {
  networkOnline?: boolean;
}

export const GlnPassportContainer: React.FC<GlnPassportContainerProps> = ({ networkOnline }) => {
  const { t } = useTranslation();
  const { user: currentUser, selectedLocation } = useSelector((state: State) => state.account);
  const dataState = useSelector((state: State) => state.data);

  if (!selectedLocation) {
    throw new Error("Invariant - for this route `selectedLocation` are required.");
  }

  const suggestions = dataState.suggestions;
  const locationDetails = dataState.locationData;
  const parentId = _.get(locationDetails, ["parent", "id"], null);

  const whitelistEnabled = _.get(locationDetails, ["parent", "whitelistEnabled"], false);
  const whitelist = _.get(locationDetails, ["parent", "whitelist"], []) || [];
  const restrictedSection = _.get(locationDetails, ["parent", "restrictedSections"], []) || [];

  const isMember = selectedLocation.id === parentId;
  const userHaveAccess = whitelist.includes(selectedLocation.id);

  const suggestionReportForSections = dataState.suggestionReportForSections;
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const showSection = (section: Section) => {
    if (["company_information", "basic_information"].indexOf(section.id) !== -1) return true;
    if (isMember) return true;
    if (whitelistEnabled) {
      return !(!userHaveAccess && restrictedSection.indexOf(section.id) !== -1);
    } else {
      return true;
    }
  };

  const refs: SectionRefs = PASSPORT_MAPPING.reduce(
    (refs, section) => ({
      ...refs,
      [section.id]: React.createRef<Card>(),
    }),
    {},
  );

  const sectionRefs = useRef(refs);

  const accessToAllSections = () => {
    const checkRestriction = _.map(PASSPORT_MAPPING, (passportSection) => {
      return showSection(passportSection);
    });
    return checkRestriction.indexOf(false) === -1;
  };

  const canEditPassport = isLocationAdmin(currentUser, selectedLocation.id, parentId);

  const { messages, addMessage, removeCurrentMessage } = useMessage();

  const dispatch = useDispatch();
  const { glnId } = useParams();
  const [activeAccordion, setActiveAccordion] = useState("passport.location.basicInformation");

  const [location, setLocation] = useState(locationDetails);

  const [show, setShow] = useState(false);
  const [suggestionModalIsVisible, setSuggestionModalIsVisible] = useState(false);

  const [editSection, setEditSection] = useState(emptySection);

  const handleClose = (sectionForm: object, save: boolean) => {
    if (save) {
      _.forEach(editSection.section, (subsection) => {
        _.forEach(subsection.fields, (field: Field) => {
          if (field.availableValues !== undefined && field.availableValues.indexOf("other") !== -1) {
            let otherPath = field.value.slice();
            let otherKey = "";
            if (_.isArray(otherPath)) {
              let otherProp = otherPath.pop();
              otherKey = otherProp + "Other";
              otherPath.push(otherKey);
            } else {
              otherKey = otherPath + "Other";
              otherPath = otherKey;
            }
            _.set(location, otherPath, _.get(sectionForm, otherPath));
          }
          _.set(location, field.value, _.get(sectionForm, field.value));
        });
      });
      setLocation({ ...location });
      dispatch(dataThunk.saveLocationDetails(location));
    }
    setShow(false);
  };

  const handleShow = (section: Section) => {
    setEditSection(section);
    setShow(true);
  };

  const showSuggestionModal = useCallback((section: Section) => {
    setEditSection(section);
    removeCurrentMessage();
    setSuggestionModalIsVisible(true);
  }, []);

  const hideSuggestionModal = useCallback(() => {
    setEditSection(emptySection);
    setSuggestionModalIsVisible(false);
  }, []);

  //TODO: Offline mode does not support edition nor suggestions

  const fetchSuggestions = () => {
    if (glnId) {
      dispatch(dataThunk.getSuggestionReportForSections(glnId));
      if (canEditPassport) {
        dispatch(dataThunk.getSuggestions(glnId));
      }
    }
  };

  const handleSaveSuggestion = useCallback(
    async (body: string) => {
      if (glnId) {
        try {
          await SuggestionService.addSuggestion(body, glnId, editSection.id);
          fetchSuggestions();
          hideSuggestionModal();
          addMessage({
            type: "success",
            text: t("suggestion.added"),
          });
        } catch (error) {
          addMessage({
            type: "error",
            text: error.message,
          });
        }
        ScrollToTop();
      }
    },
    [glnId, editSection, parentId],
  );

  const handleSuggestionArchive = useCallback(
    async (suggestionId: string) => {
      if (glnId) {
        try {
          await SuggestionService.archiveSuggestion(suggestionId, glnId);
          fetchSuggestions();
        } catch (error) {
          alert(`Error: ${error.message}`);
          // TODO: - feedback
        }
      }
    },
    [glnId, parentId],
  );

  useEffect(() => {
    if (glnId) {
      if (navigator.onLine) {
        dispatch(dataThunk.getLocationDetails(glnId));
      } else {
        let foundPassportID = dataState.LastSearchedGlnsPassportData.findIndex((el) => {
          return el.id === glnId;
        });
        dispatch(dataActions.GetLocationDetailsSuccess(dataState.LastSearchedGlnsPassportData[foundPassportID]));
      }
    }
  }, [glnId]);

  useEffect(() => {
    if (glnId) {
      fetchSuggestions();
    }
  }, [glnId, parentId, selectedLocation]);

  useEffect(() => {
    if (!_.isEmpty(locationDetails)) {
      setLocation(locationDetails);
    }
  }, [locationDetails]);

  const renderField = (field: Field) => {
    const shouldRenderField = shouldRender(field, location);
    if (shouldRenderField) {
      switch (field.type) {
        case "simple":
          return <SimpleQuestion key={uuid()} field={field} mainObject={location} />;
        case "address":
          return <AddressQuestion key={uuid()} field={field} mainObject={location} />;
        case "boolean":
          return <BooleanQuestion key={uuid()} field={field} mainObject={location} />;
        case "singleSelect":
          return <SingleSelectQuestion key={uuid()} field={field} mainObject={location} />;
        case "multiInOneColumn":
          return <MultiInOneColumnQuestion key={uuid()} field={field} mainObject={location} />;
        case "multiInSeveralColumn":
          return <MultiInSeveralColumnsQuestion key={uuid()} field={field} mainObject={location} />;
        case "map-marker":
          return <MapMarkerQuestion key={uuid()} field={field} mainObject={location} />;
        default:
          return "";
      }
    }
  };

  const setSection = (key: string) => {
    if (activeAccordion === key) {
      setActiveAccordion("");
    } else {
      setActiveAccordion(key);
    }
    setIsInitialLoad(false);
  };

  useEffect(() => {
    const activeSection = _.find(PASSPORT_MAPPING, (section: Section) => section.sectionTitleKey === activeAccordion);
    if (activeSection !== undefined && !isInitialLoad) {
      ScrollToElement(sectionRefs.current[activeSection.id]);
    }
  }, [activeAccordion]);

  const renderSuggestions = useCallback(
    (sectionId: string) => {
      if (!suggestions[sectionId]) {
        return <></>;
      }
      return <SuggestionList data={suggestions[sectionId]} onArchive={handleSuggestionArchive} />;
    },
    [suggestions, handleSuggestionArchive],
  );

  const breadcrumbs = () => {
    return canEditPassport ? (
      <Breadcrumb>
        <BreadcrumbItem href={"/my_data/my_locations"}>
          <span className="txt-color-link">{_.get(location, ["parent", "fullName"], "")}</span>
        </BreadcrumbItem>
        <BreadcrumbItem active>{_.get(location, ["fullName"], "")}</BreadcrumbItem>
      </Breadcrumb>
    ) : (
      <Breadcrumb>
        <BreadcrumbItem href="/companies">
          <span className="txt-color-link">{t("localization.company")}</span>
        </BreadcrumbItem>
        <BreadcrumbItem href={"/companies/" + _.get(location, ["parent", "id"], "")}>
          <span className="txt-color-link">{_.get(location, ["parent", "fullName"], "")}</span>
        </BreadcrumbItem>
        <BreadcrumbItem active>{_.get(location, ["fullName"], "")}</BreadcrumbItem>
      </Breadcrumb>
    );
  };

  const returnToGlns = () => {
    return (
      <Breadcrumb>
        <Link to={"/"}>
          <span className="txt-color-link">{t("breadcrumbs.homepage.offline")}</span>
        </Link>
        <span className="txt-color-muted">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
        <Link to={""} className="txt-color-muted">
          {_.get(location, ["fullName"], "")}
        </Link>
      </Breadcrumb>
    );
  };

  return (
    <>
      <Header withBottom={networkOnline} withTitle={networkOnline ? undefined : t("offline_mode")} />
      <div className="container my-4" key={uuid()}>
        <div className="context-menu mb-4">
          {!_.isEmpty(location) && (networkOnline ? breadcrumbs() : returnToGlns())}
        </div>
        {!accessToAllSections() && (
          <div className="container font-size-normal border-top-3 border-info p-3 mb-5 bg-white">
            <img
              style={{ verticalAlign: "sub" }}
              height={20}
              width={20}
              className="mr-1"
              src="/icons/icon-help-enabled.svg"
              alt=""
            />
            {t("passport.info.message.restriction")}
          </div>
        )}
        {messages.length > 0 && (
          <div className={"mb-4"}>
            <Messages />
          </div>
        )}
        <div>
          <Accordion defaultActiveKey={activeAccordion}>
            {_.map(PASSPORT_MAPPING, (passportSection, sectionIdx) => {
              if (showSection(passportSection)) {
                const suggestionsCount = _.get(suggestionReportForSections, passportSection.id, 0);
                return (
                  <Card
                    key={passportSection.sectionTitleKey}
                    ref={sectionRefs.current[passportSection.id]}
                    className={"col-md-12 bg-white d-flex mx-auto mb-md-5 px-0 gsone-accordion"}>
                    <Accordion.Toggle
                      key={uuid()}
                      className={"bg-white b-0 px-3"}
                      as={Card.Header}
                      eventKey={passportSection.sectionTitleKey}
                      onClick={(e: any) => {
                        setSection(passportSection.sectionTitleKey);
                      }}>
                      <div className="row">
                        <div className="col-9 pr-1">
                          <div className="my-auto marine-blue float-left w-90">
                            {sectionIdx > 0
                              ? `${sectionIdx}. ${t(passportSection.sectionTitleKey)}`
                              : t(passportSection.sectionTitleKey)}
                          </div>
                        </div>
                        <div className="col-3 pl-1">
                          <div className="row d-flex flex-column">
                            <div className="col mb-2">
                              <div
                                className={
                                  "my-auto float-right align-content-center justify-content-center circle " +
                                  (activeAccordion === passportSection.sectionTitleKey
                                    ? "accordion-open"
                                    : "accordion-close")
                                }>
                                {activeAccordion === passportSection.sectionTitleKey ? "-" : "+"}
                              </div>
                              {!!suggestionsCount && canEditPassport && (
                                <div className="my-auto float-right icon-text">
                                  <img src="/icons/icon-warning.svg" alt="icon-warning" />
                                  <span>{suggestionsCount}</span>
                                </div>
                              )}
                            </div>
                            <div className="col">
                              {!!sectionIdx &&
                                !!parentId &&
                                (canEditPassport ? (
                                  <span
                                    key={uuid()}
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      handleShow(passportSection);
                                    }}
                                    className="link w-100 txt-color-link mr-3 float-left align-content-center justify-content-center text-right ">
                                    {t("location.passport.edit")}
                                  </span>
                                ) : (
                                  <span
                                    key={uuid()}
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      showSuggestionModal(passportSection);
                                    }}
                                    className="link w-100 my-auto txt-color-link mr-3 float-left align-content-center justify-content-center text-right ">
                                    {t("suggestion.add")}
                                  </span>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse key={uuid()} eventKey={passportSection.sectionTitleKey}>
                      <Card.Body className={"p-3"}>
                        {_.map(passportSection.section, (section: SubSection, idx: number) => {
                          let shouldShowSection = [true];
                          if (section.showWhenPropIsFilled !== undefined) {
                            shouldShowSection = _.map(section.showWhenPropIsFilled, (path) => {
                              const propValue = _.get(location, path);
                              return propValue !== "" && propValue > 0;
                            });
                          }
                          if (shouldShowSection.indexOf(true) !== -1) {
                            return (
                              <div
                                key={uuid()}
                                className={`row m-0 ${idx < passportSection.section.length - 1 && "pb-2"}`}>
                                {section.showTitle && (
                                  <h5 className="form-title form-header bold">{t(section.titleKey)}</h5>
                                )}
                                {section.showTitle && <hr className="m-0" />}
                                {_.map(section.fields, (field: Field) => renderField(field))}
                              </div>
                            );
                          }
                        })}
                        {suggestionsCount && canEditPassport ? renderSuggestions(passportSection.id) : <></>}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              } else {
                return "";
              }
            })}
          </Accordion>
        </div>
        <div>
          <EditModal
            key="editModal"
            section={editSection}
            show={show}
            mainObject={location}
            handleClose={handleClose}
            extraBody={editSection && renderSuggestions(editSection.id)}
          />
          <AddSuggestionModal
            show={suggestionModalIsVisible}
            onClose={hideSuggestionModal}
            onSave={handleSaveSuggestion}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GlnPassportContainer;
