export const RegonValidator = () => {
  const validate = (regon: string) => {
    const regonLength = regon.length;
    let weightDigits;
    let controlDigit = 0;

    if (regonLength !== 9 && regonLength !== 14) return false;

    if (regonLength === 9) {
      weightDigits = [8, 9, 2, 3, 4, 5, 6, 7];
    } else {
      weightDigits = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
    }

    for (let i = 0; i < regonLength - 1; i++) {
      controlDigit += weightDigits[i] * parseInt(regon.charAt(i));
    }

    controlDigit %= 11;

    if (controlDigit === 10) {
      controlDigit = 0;
    }

    if (controlDigit !== parseInt(regon.charAt(regonLength - 1))) {
      return false;
    } else {
      return true;
    }
  };

  return {
    validate: validate,
  };
};
