import Cookies from "js-cookie";
import { BaseAction } from "models/StoreModel";
import * as PWAActions from "../actions/PWAActions";
import { persistReducer } from "redux-persist";
import { PWAState, initialPWAState } from "../reducers/PWAreducer";

export const INSTALL_PWA_REJECT = "pwa.Installation.Rejected";
export const INSTALL_PWA_ACCEPT = "pwa.Installation.Accept";
interface PWAInstallActionReject {
  type: typeof INSTALL_PWA_REJECT;
}

interface PWAInstallActionAccept {
  type: typeof INSTALL_PWA_ACCEPT;
}

export type PWAActions = PWAInstallActionAccept | PWAInstallActionReject;

export const PWAInstallationRejected = (): BaseAction => {
  Cookies.set("installPrompt", "rejected", { expires: 1 });
  return {
    type: INSTALL_PWA_REJECT,
  };
};

export const PWAInstallationAccepted = (): BaseAction => {
  Cookies.set("installPrompt", "accepted", { expires: 999999 });
  return {
    type: INSTALL_PWA_ACCEPT,
  };
};

function PWAReducer(state: PWAState = initialPWAState, action: PWAActions): PWAState {
  switch (action.type) {
    case PWAActions.INSTALL_PWA_REJECT: {
      return {
        installedOnDevice: false,
        ...state,
      };
    }
    default:
      return { ...state };
  }
}

const persistConfig = {
  key: "pwa",
  storage: localStorage,
  whitelist: ["installed"],
};

export default persistReducer(persistConfig, PWAReducer);
