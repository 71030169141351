import { MessageItem } from "models/MessageModel";

export const ADD_MESSAGE_ACTION = "message.add";
export const REMOVE_MESSAGE_ACTION = "message.remove";

interface AddMessageAction {
  type: typeof ADD_MESSAGE_ACTION;
  payload: {
    message: MessageItem;
  };
}

interface RemoveMessageAction {
  type: typeof REMOVE_MESSAGE_ACTION;
  payload: {
    id: string;
  };
}

export type MessageAction = AddMessageAction | RemoveMessageAction;

export function addMessage(message: MessageItem): AddMessageAction {
  return {
    type: ADD_MESSAGE_ACTION,
    payload: {
      message,
    },
  };
}

export function removeMessage(id: string): RemoveMessageAction {
  return {
    type: REMOVE_MESSAGE_ACTION,
    payload: {
      id,
    },
  };
}
