import _ from "lodash";

import React, { useState, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { TextArea } from "components";

interface ModalProps {
  show: boolean;
  onClose: () => any;
  onSave: (body: string) => any;
}

const AddModal: React.FC<ModalProps> = ({ show, onClose, onSave }) => {
  const { t } = useTranslation();
  const [body, setBody] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleSave = useCallback(() => {
    if (_.isEmpty(body)) {
      setError(t("suggestion.body.required"));
      return;
    }
    onSave(body);
  }, [body]);

  return (
    <Modal
      restoreFocus={true}
      enforceFocus={false}
      autoFocus={false}
      show={show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title className="font-size-medium-sm">{t("suggestion.modalTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextArea
          className="d-flex w-100"
          name="body"
          label={t("suggestion.body.label")}
          value={body}
          rows={6}
          onChange={setBody}
          error={error}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="txt-color-primary mt-auto font-size-normal mb-2 bg-white ml-0 rounded-0 font-weight-bold"
          variant="link"
          color="primary"
          onClick={onClose}>
          {t("suggestion.cancel")}
        </Button>
        <Button
          className="bg-dusty-orange border-1-dusty-orange mt-auto font-size-normal mb-2 px-5 mr-0 b-0 rounded-0 font-weight-bold"
          variant="primary"
          color="primary"
          onClick={handleSave}>
          {t("suggestion.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModal;
