import React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import { TableCell } from "components";

const LargeColumns = () => {
  const { t } = useTranslation();

  const largeColumns: Column[] = [
    {
      Header: t("table.company_name_header"),
      id: "company_name",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal txt-color-primary word-wrap-word"
            cssClassForSecond="font-size-regular my-1"
            firstValue={d.companyName}
            secondValue={d.nip}
          />
        );
      },
    },
    {
      Header: t("table.gln_number_header"),
      id: "gln_number",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            firstValue={d.glnNumber}
            secondValue={"+ " + d.glns + " " + t("table.gln")}
            showSecond={d.glnNumber === void 0}
            cssClassForFirst="font-size-normal gln-number"
            cssClassForSecond="font-size-regular"
          />
        );
      },
    },
    {
      Header: t("table.address_header"),
      id: "location_extra_details__address",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <TableCell
            cssClassForFirst="font-size-normal txt-color-primary"
            cssClassForSecond="font-size-regular"
            firstValue={d.address}
            secondValue={`${d.postalCode} ${d.city}, ${d.country}`}
          />
        );
      },
    },
    {
      Header: t("table.localization_name_header"),
      id: "full_name",
      headerClassName: "font-size-normal px-3 py-3",
      className: "px-3 my-2",
      accessor: (d: any) => {
        return (
          <div className="d-flex justify-content-between">
            <TableCell
              cssClassForFirst="font-size-normal txt-color-primary"
              cssClassForSecond="font-size-regular"
              firstValue={d.fullName}
              secondValue={d.shortName}
            />
            <span className="d-flex align-items-center font-size-medium mr-4 txt-color-dusty-orange">></span>
          </div>
        );
      },
    },
  ];

  return largeColumns;
};

export default LargeColumns;
