import GS1APIClient from "clients/GS1APIClient";
import { User, CurrentUser, LocationUser } from "models/UserModel";

function login(email: string, password: string, rememberMe: boolean) {
  return GS1APIClient.authenticate(email, password, rememberMe);
}

function logout() {
  GS1APIClient.logout();
}

function signUp(data: any) {
  return GS1APIClient.request<any>({
    url: "/accounts/register/",
    method: "POST",
    data,
  });
}

function fetchCurrentUser() {
  return GS1APIClient.privateRequest<{ user: CurrentUser }>({
    url: "/accounts/me/",
    method: "GET",
  });
}

function getUsersForLocation(
  pageSize: number,
  pages: number,
  sorted: string,
  filtered: { id: string; value: string }[],
  selectedLocation: string,
) {
  let params: { [index: string]: any } = { pageSize, pages, sorted, selectedLocation };
  if (filtered) {
    filtered.forEach((filter) => (params[filter.id] = filter.value));
  }
  return GS1APIClient.privateRequest({
    url: "/accounts/for_location/",
    method: "GET",
    params,
  });
}

function saveUser(membership: { [key: string]: any }, selectedLocationId: string) {
  return GS1APIClient.privateRequest({
    url: "/accounts/user/",
    method: membership.user.id === "" ? "POST" : "PUT",
    data: { ...membership, location: selectedLocationId },
  });
}

function loadUser(userId: string, selectedLocationId: string) {
  return GS1APIClient.privateRequest<LocationUser>({
    url: "/accounts/user/",
    method: "GET",
    params: { userId, selectedLocationId },
  });
}

function deleteUser(userId: string, selectedLocationId: string) {
  return GS1APIClient.privateRequest({
    url: "/accounts/user/",
    method: "DELETE",
    data: { user: { id: userId }, location: selectedLocationId },
  });
}

function changeUserStatus(userId: string, selectedLocationId: string) {
  return GS1APIClient.privateRequest({
    url: "/accounts/user/",
    method: "PATCH",
    data: { user: { id: userId }, location: selectedLocationId },
  });
}

function loadUserToActivate(activationKey: string) {
  return GS1APIClient.request<{ user: User }>({
    url: "/accounts/confirm/email/" + activationKey + "/",
    method: "GET",
  });
}

function activateUser(activationKey: string, password: string) {
  return GS1APIClient.request({
    url: "/accounts/confirm/email/" + activationKey + "/",
    method: "POST",
    data: { password },
  });
}

function getUserByEmail(userEmail: string) {
  return GS1APIClient.request<{ user: User }>({
    url: "/accounts/user/" + userEmail + "/",
    method: "GET",
  });
}

function attachRegisterFile(file: File, membership: string) {
  var formData = new FormData();
  formData.append("file", file, file.name);
  return GS1APIClient.request({
    url: "/accounts/register/" + membership + "/" + file.name + "/",
    method: "PUT",
    data: formData,
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  });
}

function resetPassword(email: string) {
  return GS1APIClient.request({
    url: "/accounts/reset_password/",
    method: "POST",
    data: { email },
  });
}

function loadUserToResetPassword(reset_password_key: string) {
  return GS1APIClient.request<{ user: User }>({
    url: "/accounts/reset_password/get_user/" + reset_password_key + "/",
    method: "GET",
  });
}

function resetPasswordByKey(reset_password_key: string, password: string) {
  return GS1APIClient.request({
    url: "/accounts/reset_password/" + reset_password_key + "/",
    method: "POST",
    data: { password },
  });
}

const AccountService = {
  login,
  logout,
  fetchCurrentUser,
  signUp,
  getUsersForLocation,
  saveUser,
  loadUser,
  deleteUser,
  changeUserStatus,
  loadUserToActivate,
  activateUser,
  getUserByEmail,
  attachRegisterFile,
  resetPassword,
  loadUserToResetPassword,
  resetPasswordByKey,
};

export default AccountService;
