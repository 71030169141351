import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReactTable from "react-table";

import { getGLNsData } from "../../store/thunk/DataThunk";
import * as dataThunk from "../../store/thunk/DataThunk";
import { FiltersGroup, Footer, Header } from "../../components";
import TablePagination from "../../components/tablePagination/TablePagination";
import { State } from "../../models/StoreModel";
import _ from "lodash";

import "react-table/react-table.css";
import { LocationService } from "../../services";
import { ScrollToTop } from "../../utils/ScrollUp";
import CompanyInfo from "../../components/companyDetails/CompanyInfo";
import { getLastGlnRequestSuccess } from "../../store/actions/DataActions";

import SmallColumns from "./CompanyDetailsColumnsS";
import LargeColumns from "./CompanyDetailsColumnsL";

export interface CompanyInfoWithGlnsProps {
  companyId: string | undefined;
  fromState: boolean;
}

const CompanyInfoWithGlns: React.FC<CompanyInfoWithGlnsProps> = ({ companyId, fromState }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dataState = useSelector((state: State) => state.data);

  const dispatch = useDispatch();

  const [companyDetails, setCompanyDetails] = useState({});
  const screenWidth = window.innerWidth;

  const fetchData = (tableState: any, instance: any) => {
    const filters = tableState.filtered;
    filters.push({
      id: "parentId",
      value: companyId as string,
    });
    dispatch(getGLNsData(tableState.pageSize, tableState.page, tableState.sorted, tableState.filtered));
  };

  const storeForOfflineUse = (data: any) => {
    dispatch(getLastGlnRequestSuccess(data));
    dispatch(dataThunk.getLocationDetails(data.id));
  };

  const loadCompanyDetails = async () => {
    try {
      const response = await LocationService.getCompanyData(`${companyId}`);
      // @ts-ignore
      setCompanyDetails({ ...response.data.location });
    } catch (error) {}
  };

  useEffect(() => {
    loadCompanyDetails();
  }, []);

  const basicFilters = [
    {
      id: "gln_number",
      value: t("filters.gln_number"),
      type: "number",
      validation: (value: string) => value.length !== 13,
    },
    { id: "full_name", value: t("filters.location_name"), type: "text" },
    { id: "address", value: t("filters.address"), type: "text" },
  ];

  return (
    <>
      <Header withBottom={true} />
      <div className="container my-5">
        {_.get(companyDetails, ["fullName"], "") !== "" && (
          <CompanyInfo companyDetails={companyDetails} companyId={companyId} fromState={fromState} />
        )}
        <FiltersGroup className="my-3" options={basicFilters} filtersKey="companies" multiple={true} />
        <ReactTable
          columns={screenWidth > 768 ? LargeColumns(fromState) : SmallColumns(fromState)}
          manual
          className={`-striped -highlight ${screenWidth > 768 && "bg-white"}`}
          resizable={false}
          data={dataState.GLNRows}
          pages={dataState.pages}
          loading={dataState.loading}
          onFetchData={fetchData}
          minRows={0}
          defaultPageSize={10}
          PaginationComponent={TablePagination}
          filtered={dataState.filters["companies"]}
          getTrProps={(state: any, rowInfo: any) => ({
            onClick: (e: any) => {
              history.push(`/passport/${rowInfo.original.id}`);
              storeForOfflineUse(rowInfo.original);
              ScrollToTop();
            },
          })}
        />
      </div>
      <Footer />
    </>
  );
};

export default CompanyInfoWithGlns;
