import React from "react";
import "./Input.scss";

interface InputProps {
  background?: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  inputType: string;
  className?: string;
  handleChange: any;
  phoneInput?: boolean;
  value?: any;
  name: string;
  errors?: { [field: string]: string | null };
}

const NumericInput: React.FC<InputProps> = ({
  background,
  className = "",
  label = "SOME_LABEL",
  placeholder = "",
  required = false,
  handleChange = (arg: any) => {
    console.log("change handler and it`s", arg);
  },
  value = "",
  name = "",
  errors = {},
  disabled = false,
}) => {
  const onChange = (e: any) => {
    value = parseInt(e.target.value);
    if (value >= 0 && value <= 2147483647) handleChange({ [name]: isNaN(value) ? 0 : value });
  };

  const isValid = () => {
    return errors.hasOwnProperty(name) && errors[name] == null;
  };

  const increase = () => {
    handleChange({ [name]: value + 1 });
  };

  const decrease = () => {
    if (value - 1 >= 0) handleChange({ [name]: value - 1 });
  };

  return (
    <div className={className + " d-flex flex-column mb-2 txt-color-grafite font-size-normal"}>
      <label>
        {label}
        {required ? <span className="ml-1 txt-color-error">*</span> : ""}
      </label>
      <div className="d-flex flex-row input">
        <div onClick={decrease} className={"my-auto pointer mr-2 align-content-center justify-content-center circle"}>
          -
        </div>
        <input
          disabled={disabled}
          className={!isValid() ? "error p-2" : "p-2"}
          style={{ backgroundColor: background }}
          placeholder={placeholder}
          required={required}
          type={"number"}
          onChange={onChange}
          min="0"
          max="100000"
          value={value}
        />
        <div onClick={increase} className={"my-auto pointer ml-2 align-content-center justify-content-center circle"}>
          +
        </div>
      </div>
      {!isValid() && <label className="txt-color-bluish-grey text-error text-right mt-1">{errors[name]}</label>}
    </div>
  );
};

export default NumericInput;
