import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Filter.scss";
import Select, { IRenderer } from "react-dropdown-select";
import TextFilter from "./TextFilter/TextFilter";
import NumericFilter from "./NumericFilter/NumericFilter";
import _ from "lodash";
import { v4 as uuid } from "uuid";

export interface FilterProps {
  id: string;
  value: string;
  className?: string;
  validation?: (val: string) => boolean;
  handleChange: (e: any) => void;
}

interface FilterOptions {
  id: string;
  value: string;
  type?: string;
  validation?: (val: string) => boolean;
  errorMessage: string;
  hasError: boolean;
  options?: object[];
  searchBy?: string[];
  searchLabel?: string;
  selectKey?: string;
  selectValue?: string;
  selectedOptions?: object[];
}

interface FiltersProps {
  filter: FilterOptions;
  options: any[];
  index: number;
  filtersCount: number;
  addFilter: () => void;
  removeFilter: (idx: string) => void;
  updateFilters: any;
  multiple: boolean;
  filterLabel?: string;
}

const Filter: React.FC<FiltersProps> = ({
  filter,
  options,
  index,
  addFilter,
  removeFilter,
  updateFilters,
  multiple,
  filtersCount,
  filterLabel = "",
}) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState(filter);

  const defaultSelectedOption = options.filter((option) => option.id === filter.id);

  const setFilterId = (selectedId: any) => {
    if (!_.isEmpty(selectedId)) {
      selectedFilter.id = selectedId[0].id;
      selectedFilter.value = "";
      selectedFilter.hasError = false;
      selectedFilter.type = selectedId[0].type;
      selectedFilter.validation = selectedId[0].validation || void 0;
      selectedFilter.errorMessage = selectedId[0].errorMessage || "";
      selectedFilter.options = selectedId[0].options || undefined;
      selectedFilter.searchBy = selectedId[0].searchBy || undefined;
      selectedFilter.searchLabel = selectedId[0].searchLabel || undefined;
      selectedFilter.selectKey = selectedId[0].selectKey || "";
      selectedFilter.selectValue = selectedId[0].selectValue || "";
      selectedFilter.selectedOptions = selectedId[0].selectedOptions || [];
      setSelectedFilter({ ...selectedFilter });
      updateFilter();
    }
  };

  const removeThisFilter = () => {
    removeFilter(selectedFilter.id);
  };

  const addNewFilter = () => {
    addFilter();
  };

  const updateValue = (value: any) => {
    selectedFilter.value = value;
    selectedFilter.selectedOptions = value;
    if (selectedFilter.validation !== void 0 && !_.isEmpty(selectedFilter.value)) {
      selectedFilter.hasError = selectedFilter.validation(selectedFilter.value);
    } else if (_.isEmpty(selectedFilter.value)) {
      selectedFilter.hasError = false;
    }
    setSelectedFilter({ ...selectedFilter });
    updateFilter();
  };

  const updateFilter = () => {
    updateFilters(selectedFilter);
  };

  const moreActionButtons = () => {
    return filtersCount !== 1 && index + 1 === filtersCount;
  };

  const customDropdownRenderer = ({ props, state, methods }: IRenderer) => {
    if (state !== undefined && props !== undefined && methods !== undefined) {
      return (
        <div>
          <div className="search-and-toggle" color={props.color}>
            <div className="search-and-toggle-title">
              <div>
                {selectedFilter !== undefined && selectedFilter.searchLabel !== undefined
                  ? selectedFilter.searchLabel
                  : ""}
              </div>
            </div>
          </div>
          <div className="search-items">
            {props.options
              .filter((item) => {
                if (selectedFilter !== undefined && selectedFilter.searchBy !== undefined) {
                  const search = selectedFilter.searchBy.map((field: string) => {
                    //@ts-ignore
                    return item[field].toLowerCase().includes(state.search.toLowerCase());
                  });
                  return search.indexOf(true) !== -1;
                }
                return true;
              })
              .map((option) => {
                return (
                  <div
                    key={uuid()}
                    className="form-check search-item"
                    //@ts-ignore
                    onClick={
                      //@ts-ignore
                      option.disabled ? null : () => methods.addItem(option)
                    }>
                    <input
                      className="form-check-input border-0"
                      type="checkbox"
                      //@ts-ignore
                      onChange={() => methods.addItem(option)}
                      //@ts-ignore
                      checked={state.values.indexOf(option) !== -1}
                    />
                    <label className="form-check-label search-item-label">
                      {
                        //@ts-ignore
                        option[props.labelField]
                      }
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
      );
    }
  };

  const filterType = (filterType: string | undefined) => {
    if (filterType === "text") {
      return (
        <TextFilter
          id={selectedFilter.id}
          value={selectedFilter.value}
          className={`border font-size-medium ${
            !multiple ? "col-7" : moreActionButtons() ? "col-5" : "col-6"
          } ${selectedFilter.hasError && "error"}`}
          handleChange={(e: any) => updateValue(e.target.value)}
        />
      );
    } else if (filterType === "number") {
      return (
        <NumericFilter
          id={selectedFilter.id}
          value={selectedFilter.value}
          className={`border font-size-medium ${
            !multiple ? "col-7" : moreActionButtons() ? "col-5" : "col-6"
          } ${selectedFilter.hasError && "error"}`}
          validation={filter.validation}
          handleChange={(e: any) => updateValue(e.target.value)}
        />
      );
    } else if (filterType === "select" && selectedFilter.options !== undefined) {
      return (
        <div
          className={`${moreActionButtons() ? "col-5" : "col-6"
            } border d-table form-control m-0 p-0 rounded-0 search-input font-size-medium`}>
          <Select
            options={selectedFilter.options}
            //@ts-ignore
            values={selectedFilter.selectedOptions}
            valueField={selectedFilter.selectKey}
            dropdownGap={0}
            labelField={selectedFilter.selectValue}
            placeholder={t("filters.choose_option")}
            onChange={updateValue}
            clearable={true}
          />
        </div>
      );
    } else if (filterType === "multiselect" && selectedFilter.options !== undefined) {
      return (
        <div
          className={`${
            moreActionButtons() ? "col-5" : "col-6"
          } border d-table form-control m-0 p-0 rounded-0 search-input font-size-medium`}>
          <Select
            options={selectedFilter.options}
            //@ts-ignore
            values={selectedFilter.selectedOptions}
            multi={true}
            valueField={selectedFilter.selectKey}
            dropdownGap={0}
            labelField={selectedFilter.selectValue}
            dropdownRenderer={customDropdownRenderer}
            placeholder={t("filters.choose_option")}
            onChange={updateValue}
          />
        </div>
      );
    } else {
      return (
        <input
          disabled
          type="text"
          className={`form-control rounded-0 search-input border font-size-medium ${
            !multiple ? "col-7" : moreActionButtons() ? "col-5" : "col-6"
          }`}
        />
      );
    }
  };

  return (
    <div className="filters mt-3 mb-3 mx-auto">
      {index === 0 && (
        <label htmlFor="filters-form" className="txt-color-bluish-grey font-size-regular ">
          {_.isEmpty(filterLabel) ? t("filters.search_by") : filterLabel}
        </label>
      )}
      <div className="container px-0 font-size-regular">
        <div className="row mx-0 w-100">
          <div
            className={`col-4 px-0 border align-items-stretch stretch-child ${selectedFilter.hasError ? "error" : ""}`}>
            <Select
              key="main-select"
              options={options}
              values={defaultSelectedOption}
              valueField="id"
              dropdownGap={0}
              labelField="value"
              noDataRenderer={() => <div style={{ padding: "0.35rem" }}>{t("noData")}</div>}
              searchable={true}
              className="h-100 align-items-center"
              placeholder={t("filters.choose_option")}
              onChange={(value) => setFilterId(value)}
            />
          </div>

          {filterType(selectedFilter.type)}

          {multiple && (
            <div
              className={`${
                moreActionButtons()
                  ? "px-0 w-100 col-3 container justify-content-between"
                  : "px-0 w-100 col-2 container justify-content-center"
              } bg-white d-flex border`}>
              <div className="row w-100 mx-0">
                {index <= filtersCount && filtersCount > 1 && (
                  <button
                    type="button"
                    className={`${
                      moreActionButtons() ? "col-6" : "col-12"
                    } justify-content-center border-0 bg-transparent txt-color-dusty-orange rounded-0 font-size-large  search-button align-self-center ${selectedFilter.hasError &&
                      "error"}`}
                    onClick={() => removeThisFilter()}>
                    -
                  </button>
                )}

                {index + 1 === filtersCount && (
                  <button
                    type="button"
                    className={`${
                      moreActionButtons() ? "col-6" : "col-12"
                    } justify-content-center border-0 bg-transparent txt-color-dusty-orange rounded-0 font-size-large  search-button align-self-center ${selectedFilter.hasError &&
                      "error"}`}
                    onClick={() => addNewFilter()}>
                    +
                  </button>
                )}
              </div>
            </div>
          )}

          {selectedFilter.hasError && (
            <div className="d-flex w-100 py-2">
              <label className="ml-2 txt-color-bluish-grey my-0 text-error text-right">
                {selectedFilter.errorMessage}
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;
