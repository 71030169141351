import React from "react";
import _ from "lodash";
import "./Input.scss";

interface InputProps {
  background?: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  inputType: string;
  handleChange: any;
  value?: any;
  name: string;
  className?: string;
  errors?: { [field: string]: string | null };
  successMessage?: string;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  autocomplete?: string;
}

const Input: React.FC<InputProps> = ({
  background,
  label = "SOME_LABEL",
  placeholder = "",
  className = "",
  required = false,
  inputType = "text",
  handleChange = (arg: any) => {
    console.log("change handler and it`s", arg);
  },
  value = "",
  name = "",
  errors = {},
  successMessage = "",
  disabled = false,
  onKeyPress,
  autocomplete = "",
}) => {
  const isValid = () => {
    if (_.isEmpty(errors)) return true;
    return errors.hasOwnProperty(name) && errors[name] == null;
  };

  return (
    <div className={className + " d-flex flex-column mb-2 txt-color-grafite font-size-normal"}>
      <label>
        {label}
        {required ? <span className="ml-1 txt-color-error">*</span> : ""}
      </label>

      <input
        className={!isValid() ? "error p-2" : "p-2"}
        placeholder={placeholder}
        style={{ backgroundColor: background }}
        required={required}
        type={inputType}
        onChange={(e: any) => handleChange({ [name]: e.target.value })}
        value={value || ""}
        autoComplete={autocomplete}
        onKeyPress={onKeyPress}
        disabled={disabled}
      />
      {!isValid() && <label className="txt-color-bluish-grey text-error text-right mt-2">{errors[name]}</label>}
      {!_.isEmpty(successMessage) ? (
        <label className="txt-color-bluish-grey text-success text-right">{successMessage}</label>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Input;
