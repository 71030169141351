import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface OfflineModeProps {
  networkOnline: boolean;
}

const OfflineMode: React.FC<OfflineModeProps> = ({ networkOnline }) => {
  const [t] = useTranslation();
  useEffect(() => {}, [networkOnline]);

  return (
    <div className="container mt-5 justify-content-center align-content-center vh-100">
      <div className="row mt-5">
        <div className="col-10 mt-5 mx-auto">
          <p>{t("offline.cant.access")}</p>
          <div className="w-100 py-3 d-flex justify-content-center">
            <span className="fa-stack fa-2x justify-content-center d-flex">
              <i className="fas fa-wifi fa-stack-2x"></i>
              <i
                style={{ opacity: 0.8 }}
                className="d-flex txt-color-error justify-content-end fas fa-times fa-stack-1x"></i>
            </span>
          </div>

          <Link className="txt-color-link d-flex justify-content-center mt-5" to={"/"}>
            {t("goto.glns")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OfflineMode;
