import _ from "lodash";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";

import { State, ThunkDispatch } from "models/StoreModel";
import { Membership } from "models/UserModel";
import * as AccountActions from "store/actions/AccountActions";
import * as AccountThunk from "store/thunk/AccountThunk";
import { getLocationName } from "utils/LocationUtils";
import HeaderBottom from "./HeaderBottom";

import "./header.scss";

interface HeaderProps {
  withBottom?: boolean;
  withTitle?: string;
}

const Header: React.FC<HeaderProps> = ({ withBottom = false, withTitle = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch>();
  const account = useSelector((state: State) => state.account);
  const { user: currentUser, selectedLocation, isAdminForSelectedLocation, acceptedBySa } = account;

  const [showUpperMenu, setShowUpperMenu] = useState(false);

  const logout = () => {
    dispatch(AccountThunk.logout());
  };

  const selectLocationFromMembership = (membership: Membership) => {
    dispatch(AccountActions.selectLocation(membership.location, membership.isLocationAdmin, membership.confirmedBySa));
    document.dispatchEvent(new MouseEvent("click"));
  };

  const { firstName, lastName } = currentUser || {};
  const selectedLocationName = getLocationName(selectedLocation, t("header.selectLocation"));
  const usernameLabel = `${firstName} ${lastName} (${selectedLocationName})`;

  return (
    <div className="px-0" style={{ zIndex: 1 }}>
      <div className="header-top bg-transparent container">
        <div className="row justify-content-between">
          <div className="logo col-auto">
            <div className="logo1 d-flex my-4 align-items-center justify-content-center justify-content-md-start">
              <Link to="/">
                <img
                  className="h-75 my-auto"
                  src="/gs-1-poland-logo.svg"
                  width={window.innerWidth / 3 > 120 ? 100 : window.innerWidth / 3}
                  alt="GS1 logo"
                />
              </Link>
              <span className="align-self-center d-flex mx-3 spacer bg-grey" />
              <h3 className="font-size-medium my-auto txt-color-marine-blue ">{t("header.bottom_name")}</h3>
            </div>
          </div>
          {_.isEmpty(withTitle) && (
            <div className="d-flex d-md-none col-auto ml-auto mr-3 justify-content-center align-self-center">
              <p
                className="my-0 font-size-normal"
                onClick={() => {
                  setShowUpperMenu((prevState) => {
                    return !prevState;
                  });
                }}>
                {showUpperMenu ? (
                  <span className="fadeIn txt-color-link link">
                    <i className="fas fa-times"></i>
                  </span>
                ) : (
                  <span className="link txt-color-link ">
                    <i className="fas fa-bars"></i>
                  </span>
                )}
              </p>
            </div>
          )}
          <div
            className={`px-0 flex-grow-1 d-md-flex col-auto mb-0 align-self-center justify-content-center justify-content-md-end ${
              showUpperMenu ? "fadeIn d-flex mx-auto " : !withTitle ? "d-none " : " "
            }`}>
            {!_.isEmpty(withTitle) ? (
              <div className="mx-auto mx-md-0">
                <h2 className="px-3 align-self-center font-size-medium txt-color-marine-blue mb-1 text-center ">
                  {withTitle}
                </h2>
              </div>
            ) : (
              <div className="menu container align-items-center d-flex flex-column flex-md-row justify-content-center justify-content-lg-end">
                {isAdminForSelectedLocation && acceptedBySa && (
                  <>
                    <Link
                      className="bg-transparent border-0 rounded-0 txt-color-link font-size-normal my-2 my-md-0 btn-primary"
                      to="/users">
                      {t("users.title")}
                    </Link>
                    <hr className="spacer mx-2 d-none d-md-block" />
                  </>
                )}
                <Dropdown>
                  <DropdownToggle
                    id="dropdown-basic"
                    className="bg-transparent border-0 px-3 py-2 txt-color-link font-size-normal my-2 my-md-0 btn btn-link p-0">
                    {usernameLabel}
                  </DropdownToggle>
                  <DropdownMenu alignRight rootCloseEvent="click" className="p-1">
                    <Link to="/user/settings" className="txt-color-bluish-grey font-size-normal d-flex py-1 px-3">
                      {t("header.settings")}
                    </Link>
                    <hr className="mx-0" />
                    {currentUser && (
                      <>
                        {currentUser.membership.map((membership) => (
                          <Link
                            to={"#"}
                            key={membership.location.id}
                            onClick={() => selectLocationFromMembership(membership)}
                            className="txt-color-bluish-grey font-size-normal d-flex py-1 px-3">
                            {getLocationName(membership.location)}
                          </Link>
                        ))}
                        <hr className="mx-0" />
                      </>
                    )}
                    <Link
                      to={"#"}
                      onClick={() => logout()}
                      className="txt-color-bluish-grey font-size-normal d-flex py-1 px-3">
                      {t("header.logout")}
                    </Link>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
      {withBottom && <HeaderBottom />}
    </div>
  );
};

export default Header;
